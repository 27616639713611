import React from "react";
import styles from "./card.module.scss";
import { ReactComponent as PlaceholderCardImage } from "../../assets/images/PlaceholderCardImage.svg";
import { ReactComponent as ModalCloseIcon } from "../../assets/images/closeIcon.svg";
import { Tooltip } from "react-tippy";
import Tippy from "@tippyjs/react/headless";

import getFormattedTime from "../../utils/getFormattedDate";
const Card = ({ achievement = {}, createdAt, holdersPercent }) => {
  return (
    <Tippy
      animation={false}
      placement='right'
      render={attrs => (
        <div className={styles.modal}>
          <div className={styles.modalClose}>
            <ModalCloseIcon />
          </div>
          <div className={styles.modalImage}>
            {achievement.image ? (
              <img
                src={window.location.origin + achievement.image.image}
                alt='card'
              />
            ) : (
              <PlaceholderCardImage />
            )}
          </div>

          <div className={styles.modalContent}>
            <div className={styles.modalSubTitle}>
              Есть у {holdersPercent}% пользователей
            </div>
            <div className={styles.modalTitle}>{achievement.name}</div>
            <div className={styles.modalSubTitleMobile}>
              Есть у {holdersPercent}% пользователей
            </div>
            <div className={styles.modalDescription}>
              {achievement.description}
            </div>
          </div>
          <div className={styles.modalDate}>{getFormattedTime(createdAt)}</div>
        </div>
      )}
    >
      <div className={styles.Card}>
        {achievement.image ? (
          <div className={styles.imageWrapper}>
            <img
              src={window.location.origin + achievement.image.image}
              alt='card'
            />
          </div>
        ) : (
          <PlaceholderCardImage />
        )}
        <div className='mt1'></div>
        <div className={styles.title}>{achievement.name}</div>
      </div>
    </Tippy>
  );
};

export default Card;
