import React from "react";
import styles from "./cardsList.module.scss";
import Card from "../Card";
import { ReactComponent as EmptyTable } from "../../assets/images/EmptyTable.svg";
import CardMobile from "../Card/CardMobile";
import Pagination from "../Tables/Pagination";

const CardsList = ({ cards = [], loading }) => {
  if (loading) {
    return (
      <div className={styles.LoadScreen}>
        <div className='mainFountainG'>
          <div className='fountainG fountainG_1'></div>
          <div className='fountainG fountainG_2'></div>
          <div className='fountainG fountainG_3'></div>
          <div className='fountainG fountainG_4'></div>
          <div className='fountainG fountainG_5'></div>
          <div className='fountainG fountainG_6'></div>
          <div className='fountainG fountainG_7'></div>
          <div className='fountainG fountainG_8'></div>
        </div>
      </div>
    );
  }
  if (!loading && cards.length === 0) {
    return (
      <div className={styles.EmptyTableBlock}>
        <EmptyTable />
        <div className={styles.Text}>Нет данных</div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.CardsList}>
        {cards.map(item => {
          if (window.innerWidth < 991) {
            return <CardMobile {...item} key={item.id}/>;
          } else {
            return <Card {...item} key={item.id}/>;
          }
        })}
      </div>
    </>
  );
};

export default CardsList;
