import {
  getAllAchievementsRequest,
  getAllAchievementsFailure,
  getAllAchievementsSuccess,
  getAchievementRequest,
  getAchievementFailure,
  getAchievementSuccess,
  getAchievementOwnersRequest,
  getAchievementOwnersFailure,
  getAchievementOwnersSuccess,
  getOwnAchievementsRequest,
  getOwnAchievementsFailure,
  getOwnAchievementsSuccess,
  getUserAchievementsRequest,
  getUserAchievementsFailure,
  getUserAchievementsSuccess,
  createAchievement,
  editAchievement,
} from "./actionCreators";

import getTimestampDateXDaysAgo from "../../utils/getTimestampDateXDaysAgo";

const mapTimeFilterIdToTimestamp = {
  1: 0, // За все время
  2: getTimestampDateXDaysAgo(1), // За сегодня
  3: getTimestampDateXDaysAgo(7), // За неделю
  4: getTimestampDateXDaysAgo(30), // За месяц
};

const getAllAchievements = api => (filter = {}) => dispatch => {
  let formattedFilter = {
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    limit: filter.limit || 999999,
    offset: filter.offset,
    name: filter.name || null,
  };

  // generate actualRequestId
  const actualRequestId = Date.now().toString();

  dispatch(getAllAchievementsRequest(actualRequestId));
  api
    .getAllAchievements(formattedFilter)
    .then(res => {
      console.log(res, "res getAllAchievements");
      dispatch(getAllAchievementsSuccess(res.data, actualRequestId));
    })
    .catch(err => {
      console.log(err, "getAllAchievements");
      dispatch(getAllAchievementsFailure(err));
    });
};

const getAchievement = api => achievementId => dispatch => {
  // generate actualRequestId
  const actualRequestId = Date.now().toString();

  dispatch(getAchievementRequest(actualRequestId));
  api
    .getAchievement(achievementId)
    .then(res => {
      console.log(res, "res getAllAchievements");
      dispatch(getAchievementSuccess([res.data], actualRequestId));
    })
    .catch(err => {
      console.log(err, "getAllAchievements");
      dispatch(getAchievementFailure(err));
    });
};

const getAchievementOwners = api => (achievementId, filter) => dispatch => {
  const actualRequestId = Date.now().toString();
  dispatch(getAchievementOwnersRequest(actualRequestId));
  api
      .getAchievementOwners(achievementId, filter)
      .then(res => {
        console.log(res, "res getAchievementOwners");
        dispatch(getAchievementOwnersSuccess((res.data), actualRequestId));
      })
      .catch(err => {
        console.log(err, "err getAchievementOwners");
        dispatch(getAchievementOwnersFailure(err));
      });
};

const getAllAchievementsForAdmin = api => (filter = {}) => dispatch => {
  let formattedFilter = {
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    limit: filter.offset || 999999,
    offset: filter.offset || 0,
    name: filter.name || null,
  };
  // generate actualRequestId
  const actualRequestId = Date.now().toString();
  dispatch(getAllAchievementsRequest(actualRequestId));
  api
    .getAllAdminAchievements(formattedFilter)
    .then(res => {
      console.log(res, "res getAllAchievements");
      dispatch(getAllAchievementsSuccess(res.data, actualRequestId));
    })
    .catch(err => {
      console.log(err, "getAllAchievements");
      dispatch(getAllAchievementsFailure(err));
    });
};

const getOwnAchievements = api => (filter = {}) => dispatch => {
  dispatch(getOwnAchievementsRequest());
  api
    .getOwnAchievements(filter)
    .then(res => {
      console.log(res, "res getOwnAchievements");
      dispatch(getOwnAchievementsSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "getOwnAchievements");
      dispatch(getOwnAchievementsFailure(err));
    });
};

const getUserAchievements = api => (userId, filter = {}) => dispatch => {
  let formattedFilter = {
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    limit: filter.limit || 999999,
    offset: filter.offset || 0,
  };

  dispatch(getUserAchievementsRequest());
  api
    .getUserAchievements(userId, formattedFilter)
    .then(res => {
      console.log(res, "res getUserAchievements");
      dispatch(getUserAchievementsSuccess(res.data));
    })
    .catch(err => {
      console.log(err, "getUserAchievements");
      dispatch(getUserAchievementsFailure(err));
    });
};

const handleCreateAchievement = api => (
  achievementData,
  filter = {},
) => dispatch => {
  api
    .createAchievement(achievementData)
    .then(res => {
      console.log(res, "res createAchievement");
      dispatch(createAchievement());
      getAllAchievementsForAdmin(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "createAchievement");
    });
};

const handleRemoveAchievement = api => (achievementId, filter) => dispatch => {
  api
    .removeAchievement(achievementId)
    .then(res => {
      console.log(res, "res removeAchievement");
      getAllAchievementsForAdmin(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err removeAchievement");
    });
};

const handleEditAchievement = api => (
  achievementData,
  filter = {},
) => dispatch => {
  api
    .editAchievement(achievementData)
    .then(res => {
      console.log(res, "res editAchievement");
      dispatch(editAchievement());
      getAllAchievementsForAdmin(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "editAchievement");
    });
};

export {
  getAllAchievements,
  getAchievement,
  getAchievementOwners,
  getAllAchievementsForAdmin,
  getOwnAchievements,
  getUserAchievements,
  handleCreateAchievement,
  handleEditAchievement,
  handleRemoveAchievement,
};
