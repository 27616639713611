const FETCH_LOGIN_REQUEST = "FETCH_LOGIN_REQUEST";
const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS";
const FETCH_LOGIN_FAILURE = "FETCH_LOGIN_FAILURE";

const FETCH_USER_PROFILE_REQUEST = "FETCH_USER_PROFILE_REQUEST";
const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";
const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";

const LOGOUT = "LOGOUT";

export {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_FAILURE,
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_PROFILE_SUCCESS,
  LOGOUT,
};
