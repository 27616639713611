import React, { useState, useEffect } from "react";
import styles from "./EditProjectForm.module.scss";

import {SelectFilter, FilledTextInput} from "../../FormItems";
import showMessage from "../../showMessage";

const mapUserPartIdToName = {
  true: "Основной участник",
  false: "Не основной участник",
  null: "Не участвует",
};

const UserRowEditForm = ({ onSave, userData}) => {

  useEffect(() => {
    if (userData.role !== undefined) {
      setProjectRole(userData.role);
    }
    if (userData.isMain !== undefined) {
      setProjectPart({
          id: userData.isMain,
          name: mapUserPartIdToName[userData.isMain],
      });
    } else {
        setProjectPart({
            id: null,
            name: mapUserPartIdToName.null,
        })
    }

  }, [userData]);

    const [projectRole, setProjectRole] = useState("");
    const [projectPart, setProjectPart] = useState({});

  return (
    <div className={styles.projectUserForm}>
      <div className={styles.selectors}>
        <div style={{ marginBottom: 20}}>
            <FilledTextInput
                placeholder={"Роль в проекте"}
                value={projectRole}
                onChange={value => {
                    setProjectRole(value);
                }}
                onBlur={value => {
                    setProjectRole(value);
                    if (projectPart.id !== null) {
                            onSave({
                            ...userData,
                            role: value,
                        });
                    }
                }
                }
            />
        </div>
        <div>
          <SelectFilter
              width={"100%"}
              placeholder='Доля участия'
              onSelect={value => {
                setProjectPart(value);
                      onSave({
                      ...userData,
                      role: projectRole,
                      isMain: value.id
                  });
              }}
              selectedValue={projectPart}
              clearSelect={() => {
                setProjectPart({});
              }}
              options={[
                { id: null, name: "Не участвует" },
                { id: true, name: "Основной участник" },
                { id: false, name: "Не основной участник" },
              ]}
              className={styles.projectUserFormIsMain}
          />
        </div>
      </div>
    </div>
  );
};

export default UserRowEditForm;
