import {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_FAILURE,
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
  LOGOUT,
} from "./types";

import Cookies from "js-cookie";

const initialState = {
  jwtToken: Cookies.get("jwtToken") || null,
  refreshJwtToken: null,
  userId: null,
  username: null,
  firstName: null,
  lastName: null,
  points: 0,
  coins: 0,
  isSuperuser: false,
  avatar: null,
  loading: false,
  error: null,
  sex: 1,
  placeType: 1
};
const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...initialState,
        jwtToken: null,
      };
    case FETCH_LOGIN_REQUEST:
    case FETCH_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        points: action.payload.points,
        userId: action.payload.id,
        username: action.payload.username,
        jwtToken: action.payload.tokens.jwtToken,
        refreshJwtToken: action.payload.tokens.refreshJwtToken,
        isSuperuser: action.payload.isSuperuser,
        placeType: action.payload.placeType,
      };
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        points: action.payload.points,
        coins: action.payload.coins,
        userId: action.payload.id,
        username: action.payload.nickname,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        isSuperuser: action.payload.isSuperuser,
        avatar: action.payload.avatar,
        sex: action.payload.sex,
        placeType: action.payload.placeType,
      };
    case FETCH_LOGIN_FAILURE:
    case FETCH_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        jwtToken: null,
      };

    default:
      return {
        ...state,
      };
  }
};

export default auth;
