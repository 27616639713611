import React from "react";
import "./loadScreen.scss";

const LoadScreen = () => {
  return (
    <div className='LoadScreen'>
      <div className='mainFountainG'>
        <div className='fountainG fountainG_1'></div>
        <div className='fountainG fountainG_2'></div>
        <div className='fountainG fountainG_3'></div>
        <div className='fountainG fountainG_4'></div>
        <div className='fountainG fountainG_5'></div>
        <div className='fountainG fountainG_6'></div>
        <div className='fountainG fountainG_7'></div>
        <div className='fountainG fountainG_8'></div>
      </div>
    </div>
  );
};
export default LoadScreen;
