import React from "react";
import styles from "./textarea.module.scss";

const Textarea = ({
  label,
  placeholder,
  value = "",
  onChange = () => {},
  minHeight = "64px",
}) => {
  return (
    <div className={styles.Textarea}>
      <label>
        {label}
      </label>
        <div style={{ minHeight: minHeight }} className={styles.inputBlock}>
          <textarea
            style={{ minHeight: minHeight }}
            value={value ? value : ''}
            placeholder={placeholder}
            onChange={e => {
              onChange(e.target.value);
            }}
          />
        </div>

    </div>
  );
};

export default Textarea;
