const FETCH_PROJECTS_ALL_REQUEST = "FETCH_PROJECTS_ALL_REQUEST";
const FETCH_PROJECTS_ALL_SUCCESS = "FETCH_PROJECTS_ALL_SUCCESS";
const FETCH_MORE_PROJECTS_ALL_SUCCESS = "FETCH_MORE_PROJECTS_ALL_SUCCESS";
const FETCH_PROJECTS_ALL_FAILURE = "FETCH_PROJECTS_ALL_FAILURE";

const FETCH_PROJECT_REQUEST = "FETCH_PROJECT_REQUEST";
const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
const FETCH_PROJECT_FAILURE = "FETCH_PROJECT_FAILURE";

const FETCH_PROJECT_USER_MAIN_REQUEST = "FETCH_PROJECT_USER_MAIN_REQUEST";
const FETCH_PROJECT_USER_MAIN_SUCCESS = "FETCH_PROJECT_USER_MAIN_SUCCESS";
const FETCH_PROJECT_USER_MAIN_FAILURE = "FETCH_PROJECT_USER_MAIN_FAILURE";

const FETCH_PROJECT_USER_MINOR_REQUEST = "FETCH_PROJECT_USER_MINOR_REQUEST";
const FETCH_PROJECT_USER_MINOR_SUCCESS = "FETCH_PROJECT_USER_MINOR_SUCCESS";
const FETCH_PROJECT_USER_MINOR_FAILURE = "FETCH_PROJECT_USER_MINOR_FAILURE";

const FETCH_PROJECTS_OWN_REQUEST = "FETCH_PROJECTS_OWN_REQUEST";
const FETCH_PROJECTS_OWN_SUCCESS = "FETCH_PROJECTS_OWN_SUCCESS";
const FETCH_PROJECTS_OWN_FAILURE = "FETCH_PROJECTS_OWN_FAILURE";

const FETCH_PROJECTS_MAIN_USER_REQUEST = "FETCH_PROJECTS_MAIN_USER_REQUEST";
const FETCH_PROJECTS_MAIN_USER_SUCCESS = "FETCH_PROJECTS_MAIN_USER_SUCCESS";
const FETCH_PROJECTS_MAIN_USER_FAILURE = "FETCH_PROJECTS_MAIN_USER_FAILURE";

const FETCH_PROJECTS_MINOR_USER_REQUEST = "FETCH_PROJECTS_MINOR_USER_REQUEST";
const FETCH_PROJECTS_MINOR_USER_SUCCESS = "FETCH_PROJECTS_MINOR_USER_SUCCESS";
const FETCH_PROJECTS_MINOR_USER_FAILURE = "FETCH_PROJECTS_MINOR_USER_FAILURE";

const CREATE_PROJECT = "CREATE_PROJECT";
const EDIT_PROJECT = "EDIT_PROJECT";

export {
  FETCH_PROJECTS_ALL_REQUEST,
  FETCH_PROJECTS_ALL_SUCCESS,
  FETCH_MORE_PROJECTS_ALL_SUCCESS,
  FETCH_PROJECTS_ALL_FAILURE,
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_FAILURE,
  FETCH_PROJECT_USER_MAIN_REQUEST,
  FETCH_PROJECT_USER_MAIN_SUCCESS,
  FETCH_PROJECT_USER_MAIN_FAILURE,
  FETCH_PROJECT_USER_MINOR_REQUEST,
  FETCH_PROJECT_USER_MINOR_SUCCESS,
  FETCH_PROJECT_USER_MINOR_FAILURE,
  FETCH_PROJECTS_OWN_REQUEST,
  FETCH_PROJECTS_OWN_SUCCESS,
  FETCH_PROJECTS_OWN_FAILURE,
  FETCH_PROJECTS_MAIN_USER_REQUEST,
  FETCH_PROJECTS_MAIN_USER_SUCCESS,
  FETCH_PROJECTS_MAIN_USER_FAILURE,
  FETCH_PROJECTS_MINOR_USER_REQUEST,
  FETCH_PROJECTS_MINOR_USER_SUCCESS,
  FETCH_PROJECTS_MINOR_USER_FAILURE,
  CREATE_PROJECT,
  EDIT_PROJECT,
};
