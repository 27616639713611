import React from "react";
import {Link} from "react-router-dom";
import styles from "./productCard.module.scss";
import PlaceholderCardImage from "../../../assets/images/PlaceholderCardImage.svg";
import {ReactComponent as MoneyIcon} from "../../../assets/images/moneyIcon.svg";

const ProductCard = ({cardData}) => {
    return (
        <div className={styles.card}>
            <Link to={`/store/${cardData.type}/${cardData.id}`} style={{display: 'flex'}}>
                <div className={styles.cardAvatar} style={{
                    backgroundImage: `url(${cardData.photo ? window.location.origin + cardData.photo.image : PlaceholderCardImage})`
                }}
                >
                </div>
                {cardData.placeType === 0 ? <div className={styles.cardLabel}>Для всех</div> : ''}
                {cardData.placeType === 1 ? <div className={styles.cardLabel}>Офис</div> : ''}
                {cardData.placeType === 2 ? <div className={styles.cardLabel}>Удаленка</div> : ''}
                <div className={styles.cardBlock}>
                    <div
                        className={styles.type}>{cardData.type === 'products' ? 'Личная покупка' : 'Краудфандинг'}</div>
                    <div className={styles.offerBlock}>
                        <div className={styles.offerTitle}>{cardData.title}</div>
                        {cardData.type === 'products' ?
                            <div className={styles.offerPrice}>
                                {cardData.totalPrice}
                                <MoneyIcon
                                    style={{marginBottom: -5, marginLeft: 5}}
                                    width={20}
                                    height={20}
                                />
                            </div> : ''}
                    </div>
                    <div
                        className={cardData.type === 'products' ? styles.descriptionProducts : styles.descriptionCrowdfunding}>{cardData.description}</div>
                    {cardData.type !== 'products' ?
                        <div className={styles.offerCount}>
                            <div className={styles.offerCountProgress}>
                                <div className={styles.offerCountProgressBar}
                                     style={{
                                         width: (cardData.totalPrice - cardData.left) * 100 / cardData.totalPrice <= 1 ?
                                             Math.ceil((cardData.totalPrice - cardData.left) * 100 / cardData.totalPrice) + '%' :
                                             Math.floor((cardData.totalPrice - cardData.left) * 100 / cardData.totalPrice) + '%'
                                     }}
                                >

                                </div>
                            </div>
                            <div className={styles.offerCountProgressDesc}>
                                <div className={styles.offerCountProgressDescLeft}>
                                    <div className={styles.offerCountProgressPersent}>
                                        {
                                            (cardData.totalPrice - cardData.left) * 100 / cardData.totalPrice <= 1 ?
                                                Math.ceil((cardData.totalPrice - cardData.left) * 100 / cardData.totalPrice) + '%' :
                                                Math.floor((cardData.totalPrice - cardData.left) * 100 / cardData.totalPrice) + '%'
                                        }
                                    </div>
                                    <div
                                        className={styles.offerCountProgressPersentSubtitle}>{(cardData.totalPrice - cardData.left) * 100 / cardData.totalPrice === 100 ? 'сбор закончен' : 'идет сбор'}</div>
                                </div>
                                <div className={styles.offerCountProgressDescRight}>
                                    <div className={styles.offerCountProgressCoin}>
                                        {cardData.left}
                                        <MoneyIcon
                                            style={{marginBottom: -5, marginLeft: 5}}
                                            width={20}
                                            height={20}
                                        />
                                    </div>
                                    <div className={styles.offerCountProgressCoinSubtitle}>осталось</div>
                                </div>
                            </div>
                        </div> : ''
                    }
                </div>
            </Link>
        </div>
    );
};

export default ProductCard;
