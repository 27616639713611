import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../hoc";
import compose from "../../../utils/compose";
import styles from "./PurchaseListTab.module.scss";
import { Table } from "../../Tables";
import getUrlParam from "../../../utils/getUrlParam";
import Pagination from "../../Tables/Pagination";
import {Button, DateInput} from "../../FormItems";
import CardTableCell from "../../CardTableCell";
import { getPurchaseForAdmin } from "../../../redux/purchase/actions";
import getFormattedDate from "../../../utils/getFormattedDate";

const PurchaseListTab = ({
  purchase,
  loading,
  purchaseCount,
  getPurchaseForAdmin,
}) => {

  const desktop = window.innerWidth < 480 ? false : true;
  let urlParam = getUrlParam('page');

  const [purchaseFilter, setPurchaseFilter] = useState({
        limit: desktop ? 10 : 4,
        offset: (urlParam ? (urlParam - 1) : 0) * (desktop ? 10 : 4),
        dateFrom: localStorage.getItem('datePurchaseFilterFrom') ? localStorage.getItem('datePurchaseFilterFrom') : getFormattedDate(new Date().setMonth(new Date().getMonth() - 1)).split('.').reverse().join('-'),
        dateTo: localStorage.getItem('datePurchaseFilterTo') ? localStorage.getItem('datePurchaseFilterTo') :  getFormattedDate(new Date().setMonth(new Date().getMonth() + 1)).split('.').reverse().join('-'),
      }
  );

  useEffect(() => {
    getPurchaseForAdmin(purchaseFilter);
  }, []);

  const updatePurchaseFilter = (fieldName, fieldValue) => {
    if(!desktop){ window.history.pushState(null, null, `?page=1`)};
    setPurchaseFilter(prevState => ({
      ...prevState,
      [fieldName]: fieldValue
    }));
    if (fieldName == 'dateFrom'){localStorage.setItem('datePurchaseFilterFrom', fieldValue)}
    if (fieldName == 'dateTo'){localStorage.setItem('datePurchaseFilterTo', fieldValue)}

    getPurchaseForAdmin(
        { ...purchaseFilter, [fieldName]: fieldValue}
    );
  };
  const columns = [
    {
       title: "#",
       dataIndex: "id",
       width: "40px",
    },
    {
      title: "Название",
      dataIndex: "title",
      width: "210px",
    },
    {
      title: "Тип товара",
      dataIndex: "type",
      width: "130px",
      render: rowData => {
        return  rowData.type === 'common' ? 'Личная покупка' : 'Краудфантинг'
      }
    },
    {
      title: "Стоимость",
      dataIndex: "price",
      width: "90px",
    },
    {
      title: "Покупатель",
      dataIndex: "client",
      width: "170px",
    },
    {
      title: "Комментарий",
      dataIndex: "commentary",
      width: "170px",
    },
    {
      title: "Завершено",
      dataIndex: "createdAt",
      width: "100px",
      render: rowData => {
        return  getFormattedDate(rowData.createdAt)
      }
    }
  ];

  return (
      <div className={styles.PurchaseListTab}>
        <div className={styles.pageContent}>
          <div className='titleH1'>Список покупок ({purchaseCount})</div>
          <div className="mb1"></div>
          <div className={styles.filterBlock}>
            <div className={styles.filterCell}>
              <DateInput
                  label='Начальная дата'
                  labelPosition={"left"}
                  value={purchaseFilter.dateFrom}
                  placeholder={1}
                  maxValue={9999999}
                  minValue={0}
                  width={"154px"}
                  onChange={value => {
                    updatePurchaseFilter("dateFrom", value);
                  }}
              />
              <div className="ml1"></div>
              <DateInput
                  label='Конечная дата'
                  labelPosition={"left"}
                  value={purchaseFilter.dateTo}
                  placeholder={1}
                  maxValue={9999999}
                  minValue={0}
                  width={"154px"}
                  onChange={value => {
                    updatePurchaseFilter("dateTo", value);
                  }}
              />
            </div>
          </div>
          <div className="mb1"></div>
          {desktop ?
            <Table
              loading={loading}
              tableWidth={"100%"}
              maxRowInCol={10}
              minHeight={'80%'}
              maxHeight={'620px'}
              dataSource={purchase}
              columns={columns}
              onClickRow={() => {
              }}
              highlightRow={rowData => {

              }}
              onChange={(pagination, sorter) => {
                let changedFields = {
                  ...pagination,
                  ...sorter,
                };
                updatePurchaseFilter(changedFields);
              }}
            /> :
          <>
            {purchase.map(cardData => {
              return (
                <CardTableCell
                  cardData={cardData}
                  key={`${cardData.id}_mobile`}
                />
              );
            })}
          </>
        }
        </div>
        {purchaseCount > purchaseFilter.limit ? (
          <Pagination
            total={purchaseCount}
            offset={(urlParam ? (urlParam - 1) : purchaseFilter.offset) * purchaseFilter.limit}
            limit={purchaseFilter.limit}
            onChange={newPage => {
              setPurchaseFilter({ ...purchaseFilter, offset: (newPage - 1) * purchaseFilter.limit });
              updatePurchaseFilter({ ...purchaseFilter, offset: (newPage - 1) * purchaseFilter.limit })
            }}
          />
        ) : (
            ""
        )}
      </div>
  );
};

const mapStateToProps = ({ purchase }) => ({
  purchase: purchase.purchases,
  purchaseCount: purchase.purchaseCount,
  loading: purchase.loading,
});
const mapDispatchToProps = (dispatch, { apiService, filter }) => {
  return bindActionCreators(
      {
        getPurchaseForAdmin: getPurchaseForAdmin(apiService, filter),
      },
      dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(PurchaseListTab);
