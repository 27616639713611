import React, {useEffect, useState} from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../hoc";
import compose from "../../utils/compose";
import { getProject, getProjectUsersMain, getProjectUsersMinor } from "../../redux/projects/actions";

import styles from "./ProjectsDetail.module.scss";
import LoadScreen from "../LoadScreen";
import NotFoundPage from "../NotFoundPage";
import { ReactComponent as PlaceholderCardImage } from "../../assets/images/PlaceholderCardImage.svg";
import { ReactComponent as UserPlaceholder } from "../../assets/images/userPlaceholder.svg";
import { ReactComponent as BreadcrumbsBlockArrow } from "../../assets/images/breadcrumbsBlockArrow.svg";
import PaginationTwo from "../Tables/PaginationTwo";
import Pagination from "../Tables/Pagination";
import getUrlParam from "../../utils/getUrlParam";

const RowItem = ({ userData }) => {
  return (
    <Link to={`/user/${userData.id}`} className={styles.rowItemLink}>
      <div className={styles.rowItem}>
        <div className={styles.imgBlock}>
          {userData.avatar ? (
            <img src={userData.avatar} alt='achievement' style={{}} />
          ) : (
            <UserPlaceholder
              width='30px'
              height='30px'
              style={{
                borderRadius: 8,
                backgroundColor: "#EBEEF1",
                padding: "0.2rem",
              }}
            />
          )}
        </div>
        <div className={styles.infoBlock}>
          <div className={styles.name}>
            {userData.firstName + " " + userData.lastName}
          </div>
          {userData.role ? (
            <div className={styles.role}>{userData.role}</div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Link>
  );
};
const ProjectsDetail = ({
                          projects,
                          loading,
                          error,
                          getProject,
                          getProjectUsersMain,
                          getProjectUsersMinor,
                          match,
                          mainUsers,
                          minorUsers,
                          mainUsersCount,
                          minorUsersCount,
}) => {
    const desktop = window.innerWidth < 768 ? false : true;
    let urlParam = getUrlParam('page') == 0 ? 1 : getUrlParam('page');
    let urlParamTwo = getUrlParam('pageTwo') == 0 ? 1 : getUrlParam('pageTwo');

    const [projectUsersMainFilter, setProjectUsersMainFilter] = useState({
            limit: desktop ? 20 : 4,
            offset: (urlParam ? (urlParam - 1) : 0) * (desktop ? 20 : 4)
        }
    );
    const [projectUsersMinorFilter, setProjectUsersMinorFilter] = useState({
            limit: desktop ? 20 : 4,
            offset: (urlParamTwo ? (urlParamTwo - 1) : 0) * (desktop ? 20 : 4)
        }
    );

  useEffect(() => {
    getProject(match.params.id);
    getProjectUsersMain(match.params.id, projectUsersMainFilter);
    getProjectUsersMinor(match.params.id, projectUsersMinorFilter);
  }, []);

  let projectInfo = projects[0] ? projects[0] : null;

  if (loading) {
    return <LoadScreen />;
  }

  if (!projectInfo && !loading) {
    return <NotFoundPage />;
  }

  return (
    <div className={styles.ProjectsDetail}>
      <div className='mt4'></div>
      <div className={styles.breadcrumbsBlock}>
        <Link to={"/projects"} className={styles.breadcrumb}>
          Проекты
        </Link>
        <span className={styles.breadcrumbsArrow}>
          <BreadcrumbsBlockArrow
              style={{ marginLeft: 5, marginBottom: -2 }}
              width={8}
              height={12}
          />
        </span>
        <div className={styles.breadcrumbActive}>Описание проекта</div>
      </div>
      <div className='mb4'></div>
      <div className={styles.projectInfo}>
        <div className={styles.cardPicture}>
          {projectInfo.image ? (
              <img
                  className={styles.cardIcon}
                  src={window.origin + projectInfo.image.image}
                  alt={"project icon"}
              />
          ) : (
              <PlaceholderCardImage
                  width='256px'
                  height='256px'
                  style={{ borderRadius: 8 }}
              />
          )}
        </div>
        <div className={styles.projectText}>
          <div className={styles.projectTitle}>{projectInfo.name}</div>
          <div className={styles.projectDescription}>
            {projectInfo.description}
          </div>
        </div>
      </div>
      <div className={styles.projectTables}>
        <div className={styles.projectTablesBlock}>
          <div className='titleH1'>Основные участники</div>
          <div className='mb2'></div>
          <div className={styles.table}>
            <div className={mainUsers.length > 1 ? styles.twoCol : mainUsers.length === 1 ? styles.oneCol : ''}>
              {mainUsers.map(item => (
                <RowItem userData={item} />
              ))}
            </div>
          </div>
        {!desktop && mainUsersCount > projectUsersMainFilter.limit ?
          (<Pagination
            total={mainUsersCount}
            offset={(urlParam ? (urlParam - 1) : projectUsersMainFilter.offset) * projectUsersMainFilter.limit}
            limit={projectUsersMainFilter.limit}
            onChange={newPage => {
              setProjectUsersMainFilter( { ...projectUsersMainFilter, offset: (newPage - 1) * projectUsersMainFilter.limit})
              getProjectUsersMain(match.params.id, { ...projectUsersMainFilter, offset: (newPage - 1) * projectUsersMainFilter.limit});
            }}
          />
          ) : ''
        }
        </div>
        {minorUsers.length > 0 ? (
          <div className={styles.projectTablesBlock}>
            <div className='titleH1'>Принимали участие</div>
            <div className='mb2'></div>
            <div className={styles.table + ' ' + styles.tableNotMain}>
              {minorUsers.map(item => (
                <RowItem userData={item} />
              ))}
            </div>
            {!desktop && minorUsersCount > projectUsersMinorFilter.limit ?
              (<PaginationTwo
                total={minorUsersCount}
                offset={(urlParamTwo ? (urlParamTwo - 1) : projectUsersMinorFilter.offset) * projectUsersMinorFilter.limit}
                limit={projectUsersMinorFilter.limit}
                onChange={newPage => {
                  setProjectUsersMinorFilter( { ...projectUsersMinorFilter, offset: (newPage - 1) * projectUsersMinorFilter.limit})
                  getProjectUsersMinor(match.params.id, { ...projectUsersMinorFilter, offset: (newPage - 1) * projectUsersMinorFilter.limit});
                }}
               />
              ) : ''
            }
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ projects }) => ({
  projects: projects.projects,
  mainUsers: projects.mainUsers,
  minorUsers: projects.minorUsers,
  mainUsersCount: projects.mainUsersCount,
  minorUsersCount: projects.minorUsersCount,
  loading: projects.loading,
  error: projects.error,
});
const mapDispatchToProps = (dispatch, { apiService, projectId, filter }) => {
  return bindActionCreators(
    {
      getProject: getProject(apiService, projectId),
      getProjectUsersMain: getProjectUsersMain(apiService, projectId, filter),
      getProjectUsersMinor: getProjectUsersMinor(apiService, projectId, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(ProjectsDetail));
