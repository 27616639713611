import React, {useEffect, useState} from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../hoc";
import compose from "../../utils/compose";
import {  buyProduct, buyCrowdfundingProduct } from "../../redux/store/actions";
import Modal from "../Modal";
import styles from "./storeDetail.module.scss";
import LoadScreen from "../LoadScreen";
import PlaceholderCardImage  from "../../assets/images/PlaceholderCardImage.svg";
import { ReactComponent as BreadcrumbsBlockArrow } from "../../assets/images/breadcrumbsBlockArrow.svg";
import { ReactComponent as MoneyIcon } from "../../assets/images/moneyIcon.svg";
import { Button, NumInput, AlertMessage } from "../FormItems";
import showMessage from "../showMessage";
import getFormattedDate from "../../utils/getFormattedDate";
import { Table } from "../Tables";
import { getPurchaseCustomersCommon } from "../../redux/purchase/actions";
import getUrlParam from "../../utils/getUrlParam";
import Pagination from "../Tables/Pagination";

const StoreDetail = ({
                         match,
                         history,
                         apiService,
                         loading,
                         buyProduct,
                         buyCrowdfundingProduct,
                         customers,
                         customersCount,
                         customersloading,
                         getPurchaseCustomersCommon
}) => {

  const [showCrowdfundingAlertMessage, setShowCrowdfundingAlertMessage] = useState(false);
  const [showProductAlertMessage, setShowProductAlertMessage] = useState(false);
  const [crowdfundingPrice, setCrowdfundingPrice] = useState(0);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [price, setPrice] = useState('');
  const [totalAmountPrice, setTotalAmountPrice] = useState('');
  const [crowdfundingLeft, setCrowdfundingLeft] = useState('');
  const [crowdfundingCollected, setCrowdfundingCollected] = useState('');
  const [crowdfundingDeletedAt, setCrowdfundingDeletedAt] = useState('');
  const desktop = window.innerWidth < 768 ? false : true;
  let urlParam = getUrlParam('page');
  const [customersFilter, setCustomersFilter] = useState({
       limit: desktop ? 10 : 4,
       offset: (urlParam ? (urlParam - 1) : 0) * (desktop ? 10 : 4),
    }
  );

  useEffect(() => {
      if (match.params.type === 'products') {
          apiService
              .getProductById(match.params.id)
              .then(({ data }) => {
                  setTitle(data.title);
                  setDescription(data.description);
                  setPrice(data.total_price);
                  setImage(data.photo.image);
              })
              .catch(err => {
                  console.error(err);

              });
      } else {
          apiService
              .getCrowdfundingById(match.params.id)
              .then(({ data }) => {
                  setTitle(data.title);
                  setDescription(data.description);
                  setImage(data.photo.image);
                  setTotalAmountPrice(data.total_price);
                  setCrowdfundingLeft(data.left);
                  setCrowdfundingCollected(data.collected);
                  setCrowdfundingDeletedAt(getFormattedDate(Date.parse(new Date(data.collecting_until_dt))));
              })
              .catch(err => {
                  console.error(err);

              });
          getPurchaseCustomersCommon(match.params.id, customersFilter);
      }
  }, []);

  if (loading) {
    return <LoadScreen />;
  }

  const columns = [
    {
      title: "#",
      dataIndex: "realPoisition",
      width: "40px",
    },
    {
      title: "Покупатель",
      dataIndex: "firstName",
      width: "170px",
      render: rowData => {
        return  rowData.firstName + ' ' + rowData.lastName
      }
    },
    {
      title: "Количество баллов",
      dataIndex: "amount",
      width: "100px",
    },
  ];
  return (
    <div className={styles.StoreDetail}>
      <Modal
        maxWidth={288}
        minWidth={288}
        displayModal={showCrowdfundingAlertMessage}
        onCloseModal={() => setShowCrowdfundingAlertMessage(false)}
      >
        <AlertMessage
          key={'CrowdfundingAlertMessage'}
          text={'Подтвердите перевод монет'}
          buttonText={'Пожертвовать'}
          onProcess={() => {
              if (crowdfundingPrice !== 0) {
                  buyCrowdfundingProduct(match.params.id, crowdfundingPrice, () => history.push("/store"))
              } else {
                  showMessage({status: 'error', text: 'Сумма не должна быть равна нулю!'})
              }
          }}
        />
      </Modal>
      <Modal
         maxWidth={288}
         minWidth={288}
         displayModal={showProductAlertMessage}
         onCloseModal={() => setShowProductAlertMessage(false)}
      >
        <AlertMessage
          key={'ProductAlertMessage'}
          text={'Подтвердите оплату товара'}
          type={'products'}
          buttonText={'Купить'}
          onProcess={(productCommentary) => {
              buyProduct(match.params.id, productCommentary, () => history.push("/store"))
          }}
        />
      </Modal>
      <div className='mt4'></div>
      <div className={styles.breadcrumbsBlock}>
        <Link to={"/store"} className={styles.breadcrumb}>
          Магазин
        </Link>
        <span className={styles.breadcrumbsArrow}>
          <BreadcrumbsBlockArrow
              style={{ marginLeft: 5, marginBottom: -2 }}
              width={8}
              height={12}
          />
        </span>
        <div className={styles.breadcrumbActive}>Описание товара</div>
      </div>
      <div className='mb4'></div>
      <div className={styles.storeInfo}>
        <div className={styles.cardPicture} style={{
           backgroundImage: `url(${image ? window.location.origin + image :  PlaceholderCardImage })`
        }}>
        </div>
        <div className={styles.storeText}>
          <div className={styles.storeTitle}>{title}</div>
          <div className={styles.storeDescription}>{description}</div>
          {match.params.type === 'products' ?
          <div style={{display: "flex", flexDirection: 'column'}}>
              <div className={styles.storeDescriptionPrice}>
                  {price}
                  <MoneyIcon
                      style={{ marginBottom: -2, marginLeft: 5 }}
                      width={20}
                      height={20}
                  />
              </div>
              <div className="mb15"></div>
              <Button
                  style={{height: 48, width: 144 }}
                  label='Оплатить'
                  handleClick={() => {
                      setShowProductAlertMessage(true)
                  }}
              />
          </div> :
          <div className={styles.offerCountProgressDesc}>
            <div className={styles.offerCountProgressDesc}>
              <div className={styles.offerCountProgressCoin}>
                {crowdfundingCollected}
                <MoneyIcon
                  style={{ marginBottom: -2, marginLeft: 5 }}
                  width={20}
                  height={20}
                />
              </div>
              <div className={styles.offerCountProgressCoinSubtitle}>собрано из {totalAmountPrice} {crowdfundingDeletedAt !== null ? ' (срок до ' + crowdfundingDeletedAt + ')' : ''}</div>
            </div>
            <div className={styles.offerCountProgress}>
              <div className={styles.offerCountProgressBar}
                   style={{
                       width: (totalAmountPrice - crowdfundingLeft)*100/totalAmountPrice <= 1 ?
                           Math.ceil((totalAmountPrice - crowdfundingLeft)*100/totalAmountPrice) + '%' :
                           Math.floor((totalAmountPrice - crowdfundingLeft)*100/totalAmountPrice) + '%'
                   }}
              >
                  <div className={styles.offerCountProgressBarText}>
                      {
                          (totalAmountPrice - crowdfundingLeft)*100/totalAmountPrice <= 1 ?
                              Math.ceil((totalAmountPrice - crowdfundingLeft)*100/totalAmountPrice) + '%' :
                              Math.floor((totalAmountPrice - crowdfundingLeft)*100/totalAmountPrice) + '%'
                      }
                  </div>
              </div>
            </div>
            <div className={styles.NumInputContainer}>
              {crowdfundingLeft !== 0 ?
                <>
                  <NumInput
                      label='Сумма'
                      labelPosition={"left"}
                      value={crowdfundingPrice}
                      maxValue={9999999}
                      minValue={0}
                      width={"110px"}
                      onChange={value => {
                          setCrowdfundingPrice(value)
                      }}
                      icon = {<MoneyIcon style={{ marginLeft: 5, marginTop: 1 }} width={20} height={20} />}
                  />
                  <Button
                    disabled={crowdfundingPrice == 0}
                    style={{height: 48, marginLeft: 20 }}
                    label='Пожертвовать'
                    handleClick={() => {
                        if (crowdfundingPrice > crowdfundingLeft) {
                            showMessage({status: 'error', text: "Величина пожертвования не должна превышать стоимость товара!"})
                        } else {
                            setShowCrowdfundingAlertMessage(true)
                        }
                    }}
                  />
                </>
              : '' }
            </div>
          </div>
          }
        </div>
      </div>
        { match.params.type === 'crowdfunding' && customersCount !==0 ?
          <div className={styles.storeHistory}>
            <div className={styles.storeTitle}>Кто помогает собрать заветную сумму:</div>
            <div className='mb2'></div>
            <Table
              loading={customersloading}
              tableWidth={"100%"}
              maxRowInCol={10}
              minHeight={'80%'}
              maxHeight={'500px'}
              dataSource={customers}
              columns={columns}
            />
            {customersCount > customersFilter.limit ? (
              <Pagination
                total={customersCount}
                offset={(urlParam ? (urlParam - 1) : customersFilter.offset) * customersFilter.limit}
                limit={customersFilter.limit}
                onChange={newPage => {
                setCustomersFilter({ ...customersFilter, offset: (newPage - 1) * customersFilter.limit });
                    getPurchaseCustomersCommon( match.params.id,
                    { ...customersFilter, offset: (newPage - 1) * customersFilter.limit }
                    );
                }}
              />
              ) : (
                ""
              )
            }
          </div> : ""
        }
    </div>
  );
};

const mapStateToProps = ({ store, purchase }) => ({
    loading: store.loading,
    error: store.error,
    customers: purchase.customers,
    customersCount: purchase.customersCount,
    customersloading: purchase.loading,
});
const mapDispatchToProps = (
    dispatch, {
        apiService, productId, productCost, productCommentary, callback, customersFilter
    }
    ) => {
  return bindActionCreators(
    {
        buyProduct: buyProduct(apiService, productId, productCommentary, callback),
        buyCrowdfundingProduct: buyCrowdfundingProduct(apiService, productId, productCost, callback),
        getPurchaseCustomersCommon: getPurchaseCustomersCommon(apiService, productId, customersFilter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(StoreDetail));
