import {
  FETCH_GRATITUDE_CATEGORIES_REQUEST,
  FETCH_GRATITUDE_CATEGORIES_FAILURE,
  FETCH_GRATITUDE_CATEGORIES_SUCCESS,
  FETCH_OWN_RECEIVED_GRATITUDE_REQUEST,
  FETCH_OWN_RECEIVED_GRATITUDE_FAILURE,
  FETCH_OWN_RECEIVED_GRATITUDE_SUCCESS,
  FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_REQUEST,
  FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_FAILURE,
  FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_SUCCESS,
  FETCH_RECEIVED_GRATITUDE_BY_USERID_REQUEST,
  FETCH_RECEIVED_GRATITUDE_BY_USERID_FAILURE,
  FETCH_RECEIVED_GRATITUDE_BY_USERID_SUCCESS,
  FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_REQUEST,
  FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_FAILURE,
  FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_SUCCESS,
  FETCH_OWN_SENDED_GRATITUDE_REQUEST,
  FETCH_OWN_SENDED_GRATITUDE_FAILURE,
  FETCH_OWN_SENDED_GRATITUDE_SUCCESS,
  FETCH_SENDED_GRATITUDE_BY_USERID_REQUEST,
  FETCH_SENDED_GRATITUDE_BY_USERID_FAILURE,
  FETCH_SENDED_GRATITUDE_BY_USERID_SUCCESS,
  FETCH_GIVE_POINTS_TO_USER_REQUEST,
  FETCH_GIVE_POINTS_TO_USER_FAILURE,
  FETCH_GIVE_POINTS_TO_USER_SUCCESS,
} from "./types";

const initialState = {
  points: [],
  pointsCount: null,
  pointsTotal: null,
  pointsCategories: [],
  loading: false,
  error: null,
};
const points = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GRATITUDE_CATEGORIES_REQUEST:
    case FETCH_OWN_RECEIVED_GRATITUDE_REQUEST:
    case FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_REQUEST:
    case FETCH_RECEIVED_GRATITUDE_BY_USERID_REQUEST:
    case FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_REQUEST:
    case FETCH_OWN_SENDED_GRATITUDE_REQUEST:
    case FETCH_SENDED_GRATITUDE_BY_USERID_REQUEST:
    case FETCH_GIVE_POINTS_TO_USER_REQUEST:
      return {
        points: [],
        pointsCount: null,
        pointsTotal: null,
        pointsCategories: [],
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_GRATITUDE_CATEGORIES_FAILURE:
    case FETCH_OWN_RECEIVED_GRATITUDE_FAILURE:
    case FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_FAILURE:
    case FETCH_RECEIVED_GRATITUDE_BY_USERID_FAILURE:
    case FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_FAILURE:
    case FETCH_OWN_SENDED_GRATITUDE_FAILURE:
    case FETCH_SENDED_GRATITUDE_BY_USERID_FAILURE:
    case FETCH_GIVE_POINTS_TO_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FETCH_GRATITUDE_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        pointsCategories: action.payload,
      };
    case FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_SUCCESS:
    case FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_SUCCESS:
      return {
        ...state,
        loading: false,
        pointsTotal: action.payload.count,
      };
    case FETCH_OWN_RECEIVED_GRATITUDE_SUCCESS:
    case FETCH_RECEIVED_GRATITUDE_BY_USERID_SUCCESS:
    case FETCH_OWN_SENDED_GRATITUDE_SUCCESS:
    case FETCH_SENDED_GRATITUDE_BY_USERID_SUCCESS:
      return {
        ...state,
        loading: false,
        points: action.payload.entities,
        pointsCount: action.payload.count
      };

    case FETCH_GIVE_POINTS_TO_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default points;
