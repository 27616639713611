import {
  FETCH_GRATITUDE_CATEGORIES_REQUEST,
  FETCH_GRATITUDE_CATEGORIES_FAILURE,
  FETCH_GRATITUDE_CATEGORIES_SUCCESS,
  FETCH_OWN_RECEIVED_GRATITUDE_REQUEST,
  FETCH_OWN_RECEIVED_GRATITUDE_FAILURE,
  FETCH_OWN_RECEIVED_GRATITUDE_SUCCESS,
  FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_REQUEST,
  FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_FAILURE,
  FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_SUCCESS,
  FETCH_RECEIVED_GRATITUDE_BY_USERID_REQUEST,
  FETCH_RECEIVED_GRATITUDE_BY_USERID_FAILURE,
  FETCH_RECEIVED_GRATITUDE_BY_USERID_SUCCESS,
  FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_REQUEST,
  FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_FAILURE,
  FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_SUCCESS,
  FETCH_OWN_SENDED_GRATITUDE_REQUEST,
  FETCH_OWN_SENDED_GRATITUDE_FAILURE,
  FETCH_OWN_SENDED_GRATITUDE_SUCCESS,
  FETCH_SENDED_GRATITUDE_BY_USERID_REQUEST,
  FETCH_SENDED_GRATITUDE_BY_USERID_FAILURE,
  FETCH_SENDED_GRATITUDE_BY_USERID_SUCCESS,
  FETCH_GIVE_POINTS_TO_USER_REQUEST,
  FETCH_GIVE_POINTS_TO_USER_FAILURE,
  FETCH_GIVE_POINTS_TO_USER_SUCCESS,
} from "./types";

const getGratitudeCategoriesRequest = () => {
  return { type: FETCH_GRATITUDE_CATEGORIES_REQUEST };
};
const getGratitudeCategoriesFailure = err => {
  return { type: FETCH_GRATITUDE_CATEGORIES_FAILURE, payload: err };
};
const getGratitudeCategoriesSuccess = categories => {
  return { type: FETCH_GRATITUDE_CATEGORIES_SUCCESS, payload: categories };
};

const getOwnReceivedGratitudeRequest = () => {
  return { type: FETCH_OWN_RECEIVED_GRATITUDE_REQUEST };
};
const getOwnReceivedGratitudeFailure = err => {
  return { type: FETCH_OWN_RECEIVED_GRATITUDE_FAILURE, payload: err };
};
const getOwnReceivedGratitudeSuccess = points => {
  return { type: FETCH_OWN_RECEIVED_GRATITUDE_SUCCESS, payload: points };
};

const getOwnTotalCountReceivedGratitudeRequest = () => {
  return { type: FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_REQUEST };
};
const getOwnTotalCountReceivedGratitudeFailure = err => {
  return { type: FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_FAILURE, payload: err };
};
const getOwnTotalCountReceivedGratitudeSuccess = points => {
  return { type: FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_SUCCESS, payload: points };
};

const getReceivedGratitudeTotalCountRequest = () => {
  return { type: FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_REQUEST };
};
const getReceivedGratitudeTotalCountFailure = err => {
  return { type: FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_FAILURE, payload: err };
};
const getReceivedGratitudeTotalCountSuccess = points => {
  return { type: FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_SUCCESS, payload: points };
};

const getReceivedGratitudeByUserIdRequest = () => {
  return { type: FETCH_RECEIVED_GRATITUDE_BY_USERID_REQUEST };
};
const getReceivedGratitudeByUserIdFailure = err => {
  return { type: FETCH_RECEIVED_GRATITUDE_BY_USERID_FAILURE, payload: err };
};
const getReceivedGratitudeByUserIdSuccess = points => {
  return { type: FETCH_RECEIVED_GRATITUDE_BY_USERID_SUCCESS, payload: points };
};
const getOwnSendedGratitudeRequest = () => {
  return { type: FETCH_OWN_SENDED_GRATITUDE_REQUEST };
};
const getOwnSendedGratitudeFailure = err => {
  return { type: FETCH_OWN_SENDED_GRATITUDE_FAILURE, payload: err };
};
const getOwnSendedGratitudeSuccess = points => {
  return { type: FETCH_OWN_SENDED_GRATITUDE_SUCCESS, payload: points };
};

const getSendedGratitudeByUserIdRequest = () => {
  return { type: FETCH_SENDED_GRATITUDE_BY_USERID_REQUEST };
};
const getSendedGratitudeByUserIdFailure = err => {
  return { type: FETCH_SENDED_GRATITUDE_BY_USERID_FAILURE, payload: err };
};
const getSendedGratitudeByUserIdSuccess = points => {
  return { type: FETCH_SENDED_GRATITUDE_BY_USERID_SUCCESS, payload: points };
};

const givePointsToUserRequest = () => {
  return { type: FETCH_GIVE_POINTS_TO_USER_REQUEST };
};
const givePointsToUserFailure = err => {
  return { type: FETCH_GIVE_POINTS_TO_USER_FAILURE, payload: err };
};
const givePointsToUserSuccess = points => {
  return { type: FETCH_GIVE_POINTS_TO_USER_SUCCESS, payload: points };
};

export {
  getGratitudeCategoriesRequest,
  getGratitudeCategoriesFailure,
  getGratitudeCategoriesSuccess,
  getOwnReceivedGratitudeRequest,
  getOwnReceivedGratitudeFailure,
  getOwnReceivedGratitudeSuccess,
  getOwnTotalCountReceivedGratitudeRequest,
  getOwnTotalCountReceivedGratitudeFailure,
  getOwnTotalCountReceivedGratitudeSuccess,
  getReceivedGratitudeByUserIdRequest,
  getReceivedGratitudeByUserIdFailure,
  getReceivedGratitudeByUserIdSuccess,
  getReceivedGratitudeTotalCountRequest,
  getReceivedGratitudeTotalCountFailure,
  getReceivedGratitudeTotalCountSuccess,
  getOwnSendedGratitudeRequest,
  getOwnSendedGratitudeFailure,
  getOwnSendedGratitudeSuccess,
  getSendedGratitudeByUserIdRequest,
  getSendedGratitudeByUserIdFailure,
  getSendedGratitudeByUserIdSuccess,
  givePointsToUserRequest,
  givePointsToUserFailure,
  givePointsToUserSuccess,
};
