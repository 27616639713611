import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../hoc";
import compose from "../../utils/compose";
import { getPurchase } from "../../redux/purchase/actions";

import InfiniteScroll from "react-infinite-scroller";
import PurchaseCard from "./PurchaseCard";
import LoadScreen from "../LoadScreen";
import styles from "./purchase.module.scss";
import Pagination from "../Tables/Pagination";
import getUrlParam from "../../utils/getUrlParam";
import {ReactComponent as EmptyTable} from "../../assets/images/EmptyTable.svg";
const Purchase = ({
  getPurchase,
  purchases,
  purchaseCount,
  loading,
}) => {

  const desktop = window.innerWidth < 768 ? false : true;
  let urlParam = getUrlParam('page');
  const [productsFilter, setProductsFilter] = useState({
    limit: desktop ? 20 : 4,
    offset: (urlParam ? (urlParam - 1) : 0) * (desktop ? 20 : 4),
  }
  );

  const [page, setPage] = useState(1);

  useEffect(() => {
    localStorage.removeItem('allAchievementsVisibleBlock');
    localStorage.removeItem('achievementsHistoryFilterTime');

    localStorage.removeItem('pointsRatingFilterTime');
    localStorage.removeItem('pointsRatingFilterCategory');
    localStorage.removeItem('achievementsVisibleBlock');
    localStorage.removeItem('achievementsRatingFilterTime');

    localStorage.removeItem('productsFilterType');
    localStorage.removeItem('coinFilterFrom');
    localStorage.removeItem('coinFilterTo');

    localStorage.removeItem('homePointsFilter');
    localStorage.removeItem('otherUserPointsFilter');

    getPurchase({...productsFilter, page: productsFilter.offset/productsFilter.limit}, true);
  }, []);

  const loadData = () => {
    getPurchase({ ...productsFilter, page });
    setPage(page + 1);
  };

  if (purchases.length <= 0 && loading) {
    return <div className={styles.PurchaseLoadScreen}><LoadScreen /></div>;
  }
  return (
    <div className={styles.Purchase}>
      <div className='mt1'></div>
      <div className={styles.filtersRow}>
        <div className="titleH1">Мои покупки</div>
      </div>
      {purchases.length === 0 ?
          <div className={styles.PurchaseTableEmptyScreen}>
            <EmptyTable />
            <div className='Text'>Нет данных</div>
          </div> :
          !desktop ? (
        <>
          <div
              className={styles.CardsTable}
          >
            {purchases.map(cardData => {
                return <PurchaseCard cardData={cardData} key={'PurchaseCard' + ' ' + cardData.id + ' ' + cardData.purchaseDate}/>
            })}
          </div>
          {purchaseCount > productsFilter.limit ?
            (<Pagination
              total={purchaseCount}
              offset={(urlParam ? (urlParam - 1) : productsFilter.offset) * productsFilter.limit}
              limit={productsFilter.limit}
              onChange={newPage => {
                setProductsFilter( { ...productsFilter, offset: (newPage - 1) * productsFilter.limit, page: newPage - 1 })
                getPurchase({ ...productsFilter, offset: (newPage - 1) * productsFilter.limit, page: newPage - 1},true);
              }}
             />
            ) : ''
          }
        </>
      ) : (
          <InfiniteScroll
              className={styles.CardsTable}
              loadMore={loadData}
              initialLoad={false}
              hasMore={!loading && purchases.length < purchaseCount}
          >
            {purchases.map(cardData => {
                return <PurchaseCard cardData={cardData} key={'PurchaseCardInfiniteScroll' + cardData.title + cardData.purchaseDate}/>
            })}
          </InfiniteScroll>
      )}
    </div>
  );
};

const mapStateToProps = ({ purchase }) => ({
  purchases: purchase.purchases,
  purchaseCount: purchase.purchaseCount,
  loading: purchase.loading,
});
const mapDispatchToProps = (dispatch, { apiService, filter }) => {
  return bindActionCreators(
    {
      getPurchase: getPurchase(apiService, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(Purchase);
