import React from "react";
import styles from "./FilledTextInput.module.scss";

const FilledTextInput = ({ label, placeholder, onChange, onBlur, value, className }) => {
  return (
    <div className={styles.TextInput}>
      {label ? <label>{label}</label> : ""}
      <div className={styles.inputBlock}>
        <input
          value={value}
          placeholder={placeholder}
          onChange={e => {
            onChange(e.target.value);
          }}
          onBlur={e => typeof onBlur === "function" && onBlur(e.target.value)}
          className={className}
        />
      </div>
    </div>
  );
};

export default FilledTextInput;
