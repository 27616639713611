import React from "react";

import styles from "./pointsReceivedBackCard.module.scss";

const PointsReceivedBackCard = ({ cardData }) => {
  return (
    <>
      <div className={styles.title}>{cardData.category.name}</div>
      <div className='mt1'></div>
      <div className={styles.subTitle}>{cardData.commentary}</div>
    </>
  );
};

export default PointsReceivedBackCard;
