import React, {useState, useEffect} from "react";
import styles from "./editProductsForm.module.scss";
import {NumInput, DateInput, Form, SelectFilter, Button, Textarea, ImageInput} from "../../FormItems";
import {ReactComponent as MoneyIcon} from "../../../assets/images/moneyIcon.svg";
import {TextInput} from "../../FormItems";
import CheckBox from "../../lib/CheckBox";
import {ReactComponent as BreadcrumbsBlockArrow} from "../../../assets/images/breadcrumbsBlockArrow.svg";

const EditProductsForm = ({onSave, onRemove, onHistory, productData = {}}) => {
    const [editProductForm, setEditProductForm] = useState({});
    useEffect(() => {
        setEditProductForm({
            ...productData,
            collectingUntilDt: productData.collectingUntilDt ? productData.collectingUntilDt.slice(0, 10) : '',
            type: productData.type ? productData.type : 'products'
        });
    }, [productData]);

    const updateEditProductForm = (fieldName, fieldValue) => {
        setEditProductForm(prevState => ({...prevState, [fieldName]: fieldValue}));
    };
    return (
        <div className={styles.userCard}>
            <div className={styles.header}>
                <ImageInput
                    className={styles.projectFormRowImageInput}
                    value={
                        editProductForm.photo
                            ? editProductForm.photo.image
                            ? window.location.origin + editProductForm.photo.image
                            : URL.createObjectURL(editProductForm.photo)
                            : ""
                    }
                    onChange={value => {
                        updateEditProductForm("photo", value);
                    }}
                />
                <div className={styles.userRoleChanger}>
                    {productData.type ?
                        <button className={styles.userRoleChangerCount}>
                            {productData.type === 'crowdfunding' ? "Краудфантинг" : "Личная покупка"}
                        </button> :
                        <SelectFilter
                            onSelect={value => {
                                updateEditProductForm("type", value.id);
                            }}
                            selectedValue={
                                editProductForm.type === 'crowdfunding'
                                    ? {id: 'crowdfunding', name: "Краудфантинг"}
                                    : {id: 'products', name: "Личная покупка"}
                            }
                            options={[
                                {id: 'products', name: "Личная покупка"},
                                {id: 'crowdfunding', name: "Краудфантинг"}
                            ]}
                        />
                    }
                    <div className="mb1"></div>
                    {editProductForm.id ?
                        <button
                            className={styles.userRoleChangerCount}
                            onClick={() => {
                                onHistory()
                            }}
                        >
                            История покупок
                            <BreadcrumbsBlockArrow
                                style={{marginLeft: 5, marginBottom: -2, fill: '#96A0B0'}}
                                width={12}
                                height={12}
                            />
                        </button> : ""
                    }
                </div>
            </div>
            <Form onSubmit={e => {
            }}>
                <div className={styles.userDataBlock}>
                    <label>Название товара</label>
                    <TextInput
                        placeholder={"Название товара"}
                        value={editProductForm.title}
                        onChange={value => {
                            updateEditProductForm("title", value)
                        }}
                    />
                    <Textarea
                        label={'Описание товара'}
                        placeholder={"Описание товара"}
                        value={editProductForm.description}
                        onChange={value => {
                            updateEditProductForm("description", value);
                        }}
                        minHeight={"80px"}
                    />
                </div>
                <div className="mb15"></div>
                <div className={styles.flex}>
                    <NumInput
                        label='Стоимость'
                        labelPosition={"left"}
                        value={editProductForm.totalPrice}
                        placeholder={1}
                        maxValue={9999999}
                        minValue={0}
                        width={"110px"}
                        onChange={value => {
                            updateEditProductForm("totalPrice", value);
                        }}
                        icon={<MoneyIcon style={{marginLeft: 5, marginTop: 1}} width={20} height={20}/>}
                    />
                    <div className="ml1"></div>
                    {editProductForm.type === 'crowdfunding' ?
                        <DateInput
                            label='Дата завершения'
                            labelPosition={"left"}
                            value={editProductForm.collectingUntilDt}
                            placeholder={1}
                            width={"160px"}
                            onChange={value => {
                                updateEditProductForm("collectingUntilDt", value);
                            }}
                        /> :
                        <NumInput
                            label='Количество'
                            labelPosition={"left"}
                            value={editProductForm.inStock}
                            placeholder={1}
                            maxValue={9999999}
                            minValue={0}
                            width={"84px"}
                            onChange={value => {
                                updateEditProductForm("inStock", value);
                            }}
                            icon={<img src=""/>}
                        />
                    }
                </div>
                <div className='mt2'></div>
                <div className={styles.checkboxRow}>
                    <CheckBox
                        id={`${editProductForm.id}`}
                        label='Доступен'
                        checked={editProductForm.available == null || editProductForm.available === true}
                        onChange={({target: {checked}}) =>
                            updateEditProductForm("available", checked)
                        }
                    />
                    <div className="mr2"></div>
                    <CheckBox
                        id={`office_place`}
                        label='Только офис'
                        checked={editProductForm.placeType === 1}
                        onChange={({target: {checked}}) => {
                            updateEditProductForm("placeType", checked ? 1 : 0)
                        }}
                    />
                    <div className="mr2"></div>
                    <CheckBox
                        id={`remote_place`}
                        label='Только удаленка'
                        checked={editProductForm.placeType === 2}
                        onChange={({target: {checked}}) => {
                            updateEditProductForm("placeType", checked ? 2 : 0)
                        }}
                    />
                </div>
                <div className="mb2"></div>
                <div
                    className={styles.editUserFormButton}
                >
                    {onRemove ? (
                        <button
                            type='button'
                            className={styles.removeBtn}
                            onClick={() => {
                                onRemove(editProductForm);
                            }}
                        >
                            Удалить
                        </button>
                    ) : (
                        ""
                    )}
                    <Button
                        style={{}}
                        label='Сохранить'
                        handleClick={() => {
                            onSave(editProductForm);
                        }}
                    />
                </div>
            </Form>
        </div>
    );
};

export default EditProductsForm;
