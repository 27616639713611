import {
  FETCH_ACHIEVEMENTS_RATING_REQUEST,
  FETCH_ACHIEVEMENTS_RATING_FAILURE,
  FETCH_ACHIEVEMENTS_RATING_SUCCESS,
  FETCH_POINTS_RATING_REQUEST,
  FETCH_POINTS_RATING_FAILURE,
  FETCH_POINTS_RATING_SUCCESS,
} from "./types";

const initialState = {
  achievementsRatingCount: null,
  achievementsRating: [],
  pointsRatingCount: null,
  pointsRating: [],
  loading: false,
  loadingPoints: false,
  loadingAchievements: false,
  error: null,
};

const ratings = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_ACHIEVEMENTS_RATING_REQUEST:
      return {
        ...state,
        loading: true,
        loadingAchievements: true,
        achievementsRatingCount: null,
        achievementsRating: [],
        error: null,
      };
    case FETCH_POINTS_RATING_REQUEST:
      return {
        ...state,
        loading: true,
        loadingPoints: true,
        pointsRatingCount: null,
        pointsRating: [],
        error: null,
      };
    case FETCH_ACHIEVEMENTS_RATING_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingAchievements: false,

        achievementsRatingCount: action.payload.count,
        achievementsRating: action.payload.entities,
      };
    case FETCH_POINTS_RATING_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingPoints: false,

        pointsRatingCount: action.payload.count,
        pointsRating: action.payload.entities,
      };

    case FETCH_ACHIEVEMENTS_RATING_FAILURE:
      return {
        ...state,
        loading: false,
        loadingAchievements: false,

        error: action.payload,
      };
    case FETCH_POINTS_RATING_FAILURE:
      return {
        ...state,
        loading: false,
        loadingPoints: false,

        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default ratings;
