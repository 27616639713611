import {
  FETCH_HISTORY_GRATITUDE_REQUEST,
  FETCH_HISTORY_GRATITUDE_FAILURE,
  FETCH_HISTORY_GRATITUDE_SUCCESS,
  FETCH_HISTORY_ACHIEVEMENTS_REQUEST,
  FETCH_HISTORY_ACHIEVEMENTS_FAILURE,
  FETCH_HISTORY_ACHIEVEMENTS_SUCCESS,
  FETCH_HISTORY_EVENTS_REQUEST,
  FETCH_HISTORY_EVENTS_SUCCESS,
  FETCH_MORE_HISTORY_EVENTS_SUCCESS,
} from "./types";

const initialState = {
  historyAchievements: [],
  historyGratitude: [],
  historyEvents: [],
  eventsCount: 0,
  loading: false,
  error: null,
};
const history = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HISTORY_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_HISTORY_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        historyEvents: action.payload.data,
        eventsCount: action.payload.count,
      };
    case FETCH_MORE_HISTORY_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        historyEvents: [
          ...state.historyEvents,
          ...action.payload.data.sort((a, b) => b.createdAt - a.createdAt),
        ],
      };
    case FETCH_HISTORY_GRATITUDE_REQUEST:
    case FETCH_HISTORY_ACHIEVEMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_HISTORY_GRATITUDE_SUCCESS:
      return {
        ...state,
        loading: false,
        historyGratitude: action.payload,
      };

    case FETCH_HISTORY_ACHIEVEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        historyAchievements: action.payload.entities,
        eventsCount: action.payload.count,
      };
    case FETCH_HISTORY_GRATITUDE_FAILURE:
    case FETCH_HISTORY_ACHIEVEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default history;
