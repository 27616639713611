import {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_FAILURE,
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_PROFILE_SUCCESS,
  LOGOUT,
} from "./types";

const loginRequest = () => {
  return { type: FETCH_LOGIN_REQUEST };
};

const loginFailure = err => {
  return { type: FETCH_LOGIN_FAILURE, payload: err };
};

const loginSuccess = userData => {
  return { type: FETCH_LOGIN_SUCCESS, payload: userData };
};

const getUserProfileRequest = () => {
  return { type: FETCH_USER_PROFILE_REQUEST };
};
const getUserProfileFailure = error => {
  return { type: FETCH_USER_PROFILE_FAILURE, payload: error };
};
const getUserProfileSuccess = users => {
  return { type: FETCH_USER_PROFILE_SUCCESS, payload: users };
};
const logout = () => {
  return { type: LOGOUT };
};
export {
  loginRequest,
  loginFailure,
  loginSuccess,
  getUserProfileRequest,
  getUserProfileFailure,
  getUserProfileSuccess,
  logout,
};
