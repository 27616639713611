import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../../hoc";
import compose from "../../../../utils/compose";
import styles from "./ViewProductsHistoryForm.module.scss";
import { Table } from "../../../Tables";
import getUrlParam from "../../../../utils/getUrlParam";
import Pagination from "../../../Tables/Pagination";
import { DateInput } from "../../../FormItems";
import CardTableCell from "../../../CardTableCell";
import { getPurchaseCustomers } from "../../../../redux/purchase/actions";
import getFormattedDate from "../../../../utils/getFormattedDate";

const ViewProductsHistoryForm = ({
  productType,
  productId,
  customers,
  loading,
  customersCount,
  getPurchaseCustomers,
}) => {

  const desktop = window.innerWidth < 768 ? false : true;
  let urlParam = getUrlParam('page');

  const [customersFilter, setCustomersFilter] = useState({
        limit: desktop ? 10 : 4,
        offset: (urlParam ? (urlParam - 1) : 0) * (desktop ? 10 : 4),
        dataFrom: localStorage.getItem('dataCustomersFilterFrom') ? localStorage.getItem('dataCustomersFilterFrom') : getFormattedDate(new Date().setMonth(new Date().getMonth() - 1)).split('.').reverse().join('-'),
        dataTo: localStorage.getItem('dataCustomersFilterTo') ? localStorage.getItem('dataCustomersFilterTo') : getFormattedDate(new Date().setMonth(new Date().getMonth() + 1)).split('.').reverse().join('-'),
      }
  );

  useEffect(() => {
    getPurchaseCustomers(productType, productId, customersFilter);
  }, []);

  const updatePurchaseFilter = (fieldName, fieldValue) => {

      if (fieldName == 'dataFrom'){localStorage.setItem('dataCustomersFilterFrom', fieldValue)}
      if (fieldName == 'dataTo'){localStorage.setItem('dataCustomersFilterTo', fieldValue)}

      if(!desktop){ window.history.pushState(null, null, `?page=1`)};
      setCustomersFilter(prevState => ({
          ...prevState,
          [fieldName]: fieldValue
      }));
      getPurchaseCustomers(productType, productId,
        { ...customersFilter, [fieldName]: fieldValue}
    );
  };

  const columns = [
    {
       title: "#",
       dataIndex: "id",
       width: "40px",
    },
    {
      title: "Покупатель",
      dataIndex: "client",
      width: "170px",
    },
    {
      title: "Завершено",
      dataIndex: "createdAt",
      width: "100px",
      render: rowData => {
          return  getFormattedDate(rowData.createdAt)
      }
    },
    productType !== 'crowdfunding' ? '' :
      {
         title: "Монеты",
         dataIndex: "amount",
         width: "120px",
      }
  ];

  return (
      <div className={styles.PurchaseListTab}>
        <div className={styles.pageContent}>
          <div className='titleH1'>История покупок</div>
          <div className="mb2"></div>
          <div className={styles.filterBlock}>
            <div className={styles.filterCell}>
              <DateInput
                  label='Начальная дата'
                  labelPosition={"left"}
                  value={customersFilter.dataFrom}
                  placeholder={1}
                  width={"160px"}
                  onChange={value => {
                      updatePurchaseFilter("dataFrom", value);
                  }}
              />
              <div className="ml1"></div>
              <DateInput
                  label='Конечная дата'
                  labelPosition={"left"}
                  value={customersFilter.dataTo}
                  placeholder={1}
                  width={"160px"}
                  onChange={value => {
                      updatePurchaseFilter("dataTo", value);
                  }}
              />
            </div>
          </div>
          <div className="mb1"></div>
          {desktop ?
            <Table
              loading={loading}
              tableWidth={"100%"}
              maxRowInCol={10}
              minHeight={'80%'}
              maxHeight={'500px'}
              dataSource={customers}
              columns={columns}
              onClickRow={() => {
              }}
              highlightRow={rowData => {

              }}
              onChange={(pagination, sorter) => {
                let changedFields = {
                  ...pagination,
                  ...sorter,
                };
                  updatePurchaseFilter(changedFields);
              }}
            /> :
          <>
            {customers.map(cardData => {
              return (
                <CardTableCell
                  cardData={cardData}
                  key={`${cardData.id}_mobile`}
                />
              );
            })}
          </>
        }
        </div>
        {customersCount > customersFilter.limit ? (
            <Pagination
                total={customersCount}
                offset={(urlParam ? (urlParam - 1) : customersFilter.offset) * customersFilter.limit}
                limit={customersFilter.limit}
                onChange={newPage => {
                  setCustomersFilter({ ...customersFilter, offset: (newPage - 1) * customersFilter.limit });
                  getPurchaseCustomers(productType, productId,{ ...customersFilter, offset: (newPage - 1) * customersFilter.limit })
                }}
            />
        ) : (
            ""
        )}
      </div>
  );
};

const mapStateToProps = ({ purchase }) => ({
  customers: purchase.customers,
  customersCount: purchase.customersCount,
  loading: purchase.loading,
});
const mapDispatchToProps = (dispatch, { apiService, productType, productId, customersFilter }) => {
  return bindActionCreators(
      {
        getPurchaseCustomers: getPurchaseCustomers(apiService, productType, productId, customersFilter),
      },
      dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(ViewProductsHistoryForm);
