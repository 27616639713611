import {
  getAllProjectsRequest,
  getAllProjectsFailure,
  getAllProjectsSuccess,
  getAllProjectsMoreSuccess,
  getProjectRequest,
  getProjectFailure,
  getProjectSuccess,
  getProjectUsersMainRequest,
  getProjectUsersMainFailure,
  getProjectUsersMainSuccess,
  getProjectUsersMinorRequest,
  getProjectUsersMinorFailure,
  getProjectUsersMinorSuccess,
  getUserMainProjectsRequest,
  getUserMainProjectsFailure,
  getUserMainProjectsSuccess,
  getUserMinorProjectsRequest,
  getUserMinorProjectsFailure,
  getUserMinorProjectsSuccess,
  createProject,
  editProject,
} from "./actionCreators";

import getTimestampDateXDaysAgo from "../../utils/getTimestampDateXDaysAgo";

const mapTimeFilterIdToTimestamp = {
  1: 0, // За все время
  2: getTimestampDateXDaysAgo(1), // За сегодня
  3: getTimestampDateXDaysAgo(7), // За неделю
  4: getTimestampDateXDaysAgo(30), // За месяц
};

const getAllProjects = api => (filter = {}, force) => dispatch => {
  let formattedFilter = {
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    limit: filter.limit || 999999,
    offset: filter.offset || 0,
    name: filter.name || null,
  };

  // generate actualRequestId
  const actualRequestId = Date.now().toString();

  dispatch(getAllProjectsRequest(actualRequestId));
  api
    .getAllProjects(formattedFilter)
    .then(res => {
      console.log(res, "res getAllProjects");
        console.log(force, "res force");
      if (force) {
        dispatch(
            getAllProjectsSuccess(
                res.data,
                actualRequestId,
            ),
        );
      } else {
        dispatch(
            getAllProjectsMoreSuccess(
                res.data,
                actualRequestId,
            ),
        );
      }


    })
    .catch(err => {
      console.log(err, "getAllProjects");
      dispatch(getAllProjectsFailure(err, actualRequestId));
    });
};

const getProject = api => projectId => dispatch => {
  // generate actualRequestId
  const actualRequestId = Date.now().toString();

  dispatch(getProjectRequest(actualRequestId));
  api
    .getProject(projectId)
    .then(res => {
      console.log(res, "res getProject");
      dispatch(getProjectSuccess([res.data], actualRequestId));
    })
    .catch(err => {
      console.log(err, "getProject");
      dispatch(getProjectFailure(err, actualRequestId));
    });
};

const getProjectUsersMain = api => (projectId, filter) => dispatch => {
    dispatch(getProjectUsersMainRequest());
    api
        .getProjectUsersMain(projectId, filter)
        .then(res => {
            console.log(res, "res getProject");
            dispatch(getProjectUsersMainSuccess(res.data));
        })
        .catch(err => {
            console.log(err, "getProject");
            dispatch(getProjectUsersMainFailure(err));
        });
};

const getProjectUsersMinor = api => (projectId, filter) => dispatch => {
    dispatch(getProjectUsersMinorRequest());
    api
        .getProjectUsersMinor(projectId, filter)
        .then(res => {
            console.log(res, "res getProject");
            dispatch(getProjectUsersMinorSuccess(res.data));
        })
        .catch(err => {
            console.log(err, "getProject");
            dispatch(getProjectUsersMinorFailure(err));
        });
};

const getAllProjectsForAdmin = api => (filter = {}) => dispatch => {
  let formattedFilter = {
    limit: filter.limit || 999999,
    offset: filter.offset || 0,
    name: filter.name || null,
  };
  // generate actualRequestId
  const actualRequestId = Date.now().toString();
  dispatch(getAllProjectsRequest(actualRequestId));
  api
    .getAllAdminProjects(formattedFilter)
    .then(res => {
      console.log(res, "res getAllAdminProjects");
      dispatch(
        getAllProjectsSuccess(res.data, actualRequestId),
      );
    })
    .catch(err => {
      console.log(err, "getAllAdminProjects");
      dispatch(getAllProjectsFailure(err, actualRequestId));
    });
};

const getUserMainProjects = api => (userId, mainProjectFilter = {}) => dispatch => {
  dispatch(getUserMainProjectsRequest());
  api
    .getUserMainProjects(userId, mainProjectFilter)
    .then(res => {
      console.log(res, "res getUserProjects");
      dispatch(
        getUserMainProjectsSuccess(res.data),
      );
    })
    .catch(err => {
      console.log(err, "getUserProjects");
      dispatch(getUserMainProjectsFailure(err));
    });
};
const getUserMinorProjects = api => (userId, mainProjectFilter = {}) => dispatch => {

    dispatch(getUserMinorProjectsRequest());
    api
        .getUserMinorProjects(userId, mainProjectFilter)
        .then(res => {
            console.log(res, "res getUserProjects");
            dispatch(
                getUserMinorProjectsSuccess(res.data),
            );
        })
        .catch(err => {
            console.log(err, "getUserProjects");
            dispatch(getUserMinorProjectsFailure(err));
        });
};
const handleCreateProject = api => (projectData, filter = {}) => dispatch => {
  api
    .createProject(projectData)
    .then(res => {
      console.log(res, "res createProject");
      dispatch(createProject());
      getAllProjectsForAdmin(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "createProject");
    });
};

const handleRemoveProject = api => (projectId, filter) => dispatch => {
  api
    .removeProject(projectId)
    .then(res => {
      console.log(res, "res removeProject");
      getAllProjectsForAdmin(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err removeProject");
    });
};

const handleEditProject = api => (projectData, filter = {}) => dispatch => {
  console.log(projectData, 'projectDataввсаываыв')
  api
    .editProject(projectData)
    .then(res => {
      console.log(res, "res editProject");
      dispatch(editProject());
      getAllProjectsForAdmin(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "editProject");
    });
};

export {
  getAllProjects,
  getProject,
  getProjectUsersMain,
  getProjectUsersMinor,
  getAllProjectsForAdmin,
  getUserMainProjects,
  getUserMinorProjects,
  handleCreateProject,
  handleEditProject,
  handleRemoveProject,
};
