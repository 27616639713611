import {
  searchUserRequest,
  searchUserFailure,
  searchUserSuccess,
  searchUserInHeaderRequest,
  searchUserInHeaderFailure,
  searchUserInHeaderSuccess,
} from './actionCreators';

const searchUsersByName = api => (filter) => dispatch => {
  dispatch(searchUserRequest());
  api
    .getUsers(filter)
    .then(res => {
      console.log(res, 'res getUsers');
      dispatch(searchUserSuccess(res.data));
    })
    .catch(err => {
      dispatch(searchUserFailure(err));
      console.log(err, 'err getUsers');
    });
};

const searchUsersByNameWithSort = api => (filter, projectData) => dispatch => {
  dispatch(searchUserRequest());
  api
      .getUsers(filter)
      .then(res => {
        console.log(res, 'res getUsers');
        let resData =[...res.data.entities.sort((a, b) => a.firstName.localeCompare(b.firstName))];
        if (projectData.users && projectData.users.length > 0) {
          // Сортируем searchResults по наличию проектов
          resData = [
            ...resData.sort((a, b) => {
              return projectData.users.find(item => item.id === a.id) ? -1 : 1;
            }),
          ];
        }
        let response = {entities: resData, count: res.data.count};
        dispatch(searchUserSuccess(response));
      })
      .catch(err => {
        dispatch(searchUserFailure(err));
        console.log(err, 'err getUsers');
      });
};

const searchUsersInHeader = api => filter => dispatch => {
  dispatch(searchUserInHeaderRequest());
  api
    .getUsers(filter)
    .then(res => {
      console.log(res, 'res getUsers');
      dispatch(searchUserInHeaderSuccess(res.data));
    })
    .catch(err => {
      dispatch(searchUserInHeaderFailure(err));
      console.log(err, 'err getUsers');
    });
};
export { searchUsersByName, searchUsersInHeader,  searchUsersByNameWithSort};
