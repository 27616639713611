// TODO: Что нужно сделать для этого компонента
//

import React, { useEffect } from "react";
import styles from "./RatingsSecretScreen.module.scss";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { GridTable } from "..//Tables";

import { withApiService } from "../hoc";
import compose from "../../utils/compose";
import { getRatingsWithPosition, getAchievementsWithPosition } from "../../utils/ratings-utils";
import {
  getPointsRating,
  getAchievementsRating,
} from "../../redux/ratings/actions";

import { ReactComponent as UserPlaceholder } from "../../assets/images/userPlaceholder.svg";
import { ReactComponent as BcoinIcon } from "../../assets/images/bcoin.svg";

const RatingsSecretScreen = ({
  pointsRating = [],
  loadingPoints = false,
  achievementsRating = [],
  loadingAchievements = false,
  getPointsRating,
  getAchievementsRating,
}) => {
  useEffect(() => {
    getPointsRating({
      limit: 10,
      time: 4,
    }, true);
    getAchievementsRating({
      limit: 10,
      time: 4,
    }, true);
  }, []);

  return (
    <div className={styles.RatingsSecretScreen}>
      <div className={styles.tableContainer}>
        <div className='titleH1' style={{ marginBottom: "2rem" }}>
          Достижения за 30 дней
        </div>
        <GridTable
          maxHeight={"1500px"}
          loading={loadingAchievements}
          dataSource={getAchievementsWithPosition(achievementsRating)}
          columns={[
            {
              title: "#",
              dataIndex: "position",
              width: "15%",
              textAlign: 'center',
              render: (rowData, rowIndex) => (
                <span style={{ fontSize: 24 }}>{rowIndex + 1}</span>
              ),
            },
            {
              title: "Имя",
              dataIndex: "nickname",
              render: rowData => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContentL: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                      fontSize: 24,
                    }}
                  >
                    {rowData.user.avatar ? (
                      <img
                        style={{
                          width: "42px",
                          height: "42px",
                          borderRadius: "12px",
                          marginRight: "20px",
                        }}
                        src={rowData.user.avatar}
                        alt='avatar'
                      />
                    ) : (
                      <UserPlaceholder
                        style={{
                          width: "20px",
                          height: "20px",
                          padding: "0.5rem",
                          backgroundColor: "#EBEEF1",
                          borderRadius: "12px",
                          marginRight: "20px",
                        }}
                      />
                    )}
                    {rowData.user.firstName + " " + rowData.user.lastName ||
                      rowData.user.nickname}
                  </div>
                );
              },
            },
            {
              title: "Сколько",
              dataIndex: "achievementsCount",
              width: "10%",
              render: rowData => (
                <span style={{ fontSize: 24 }}>
                  {rowData.achievementsCount}
                </span>
              ),
            },
          ]}
        />
      </div>
      <div className={styles.tableContainer}>
        <div className='titleH1' style={{ marginBottom: "2rem" }}>
          Топ благодаренных за 30 дней
        </div>
        <GridTable
          maxHeight={"1500px"}
          loading={loadingPoints}
          dataSource={getRatingsWithPosition(pointsRating)}
          columns={[
            {
              title: "#",
              dataIndex: "position",
              width: "10%",
              textAlign: 'center',
              render: (rowData) => (
                <span style={{ fontSize: 24 }}>{rowData.position}</span>
              ),
            },
            {
              title: "Имя",
              dataIndex: "nickname",
              width: "60%",
              render: rowData => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContentL: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                      fontSize: 24,
                    }}
                  >
                    {rowData.user.avatar ? (
                      <img
                        style={{
                          width: "42px",
                          height: "42px",
                          borderRadius: "12px",
                          marginRight: "20px",
                        }}
                        src={rowData.user.avatar}
                        alt='avatar'
                      />
                    ) : (
                      <UserPlaceholder
                        style={{
                          width: "20px",
                          height: "20px",
                          padding: "0.5rem",
                          backgroundColor: "#EBEEF1",
                          borderRadius: "12px",
                          marginRight: "20px",
                        }}
                      />
                    )}
                    {rowData.user.firstName + " " + rowData.user.lastName ||
                      rowData.user.nickname}
                  </div>
                );
              },
            },
            {
              title: "",
              dataIndex: "gratitudeAmount",
              render: rowData => {
                return (
                  <div style={{ textAlign: "end", fontSize: 24 }}>
                    {rowData.gratitudeAmount}
                    <BcoinIcon
                      style={{ marginBottom: -3, marginLeft: 5 }}
                      width={20}
                      height={20}
                    />
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ ratings }) => ({
  pointsRating: ratings.pointsRating,
  loadingPoints: ratings.loadingPoints,
  achievementsRating: ratings.achievementsRating,
  loadingAchievements: ratings.loadingAchievements,
});
const mapDispatchToProps = (dispatch, { apiService, filter }) => {
  return bindActionCreators(
    {
      getAchievementsRating: getAchievementsRating(apiService, filter),
      getPointsRating: getPointsRating(apiService),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(RatingsSecretScreen));
