import {
  FETCH_PURCHASE_REQUEST,
  FETCH_PURCHASE_FAILURE,
  FETCH_PURCHASE_SUCCESS,
  FETCH_MORE_PURCHASE_SUCCESS,
  FETCH_PURCHASE_CUSTOMERS_REQUEST,
  FETCH_PURCHASE_CUSTOMERS_FAILURE,
  FETCH_PURCHASE_CUSTOMERS_SUCCESS,
} from "./types";

const initialState = {
  purchases: [],
  purchaseCount: 0,
  customers: [],
  customersCount: 0,
  loading: false,
  error: null,
};
const purchase = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PURCHASE_CUSTOMERS_REQUEST:
    case FETCH_PURCHASE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PURCHASE_SUCCESS:
      return {
        ...state,
        loading: false,
        purchases: action.payload.data,
        purchaseCount: action.payload.count,
      };
    case FETCH_PURCHASE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: action.payload.data,
        customersCount: action.payload.count,
      };
    case FETCH_MORE_PURCHASE_SUCCESS:
      return {
        ...state,
        loading: false,
        purchases: [
          ...state.purchases,
          ...action.payload.data.sort((a, b) => b.createdAt - a.createdAt),
        ],
      };
    case FETCH_PURCHASE_FAILURE:
    case FETCH_PURCHASE_CUSTOMERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default purchase;
