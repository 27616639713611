import React, { useEffect, useRef } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../hoc";
import compose from "../../utils/compose";
import {
  getAchievementsHistory,
  getGratitudeHistory,
} from "../../redux/tvscreen/actions";

import AchievementCard from "./AchievementCard";
import PointsCard from "./PointsCard";
import LoadScreen from "../LoadScreen";
import styles from "./eventsSecretScreen.module.scss";

const EventsSecretScreen = ({
  getGratitudeHistory,
  getAchievementsHistory,
  achievementsList,
  pointsList,
  loading,
}) => {
  const eventsBlock = useRef(null);

  useEffect(() => {
    getGratitudeHistory({ limit: 35 });
    getAchievementsHistory({ limit: 35 });
  }, []);

  // Авто-скролл вниз страницы экранами
  useEffect(() => {
    if (pointsList && achievementsList && eventsBlock.current) {
      // Узнаем высоту экрана в px
      let screenHeight = window.innerHeight;
      // Узнаем высоту 1 карточки
      let oneStepHeight = (475 + 30);
      // Узнаем высоту блока с событиями
      let fullEventsBlockHeight = eventsBlock.current.clientHeight;
      // Высчитываем сколько нам нужно блоков
      let countOfAnimations = Math.ceil(fullEventsBlockHeight / oneStepHeight);

      // Запускаем анимацию
      let animationCounter = 0;
      let animationInterval = setInterval(() => {
        if (animationCounter <= countOfAnimations) {
          window.scrollTo({
            top: animationCounter * oneStepHeight,
            behavior: "smooth",
          });
          animationCounter++;
        } else {
          clearInterval(animationInterval);
        }
      }, 4000);
    }
  }, [pointsList, achievementsList]);

  // Обьединяем в ачивки и баллы в 1 массив отсортированный по дате создания записи
  let sortedArray = [...achievementsList, ...pointsList].sort((a, b) => {
    return b.createdAt - a.createdAt;
  });

  if (achievementsList.length <= 0 || pointsList.length <= 0) {
    return <LoadScreen />;
  }
  return (
    <div className={styles.EventsSecretScreen} ref={eventsBlock}>
      <div className={styles.CardsTable}>
        {sortedArray.map(cardData => {
          if (cardData.achievement) {
            return <AchievementCard key={'AchievementCard' + cardData.id} cardData={cardData} />;
          } else {
            return <PointsCard key={'PointsCard' + cardData.id} cardData={cardData} />;
          }
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ tvscreen }) => ({
  achievementsList: tvscreen.historyAchievements,
  pointsList: tvscreen.historyGratitude,
  loading: tvscreen.loading,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, filter, giveFormData, callback },
) => {
  return bindActionCreators(
    {
      getAchievementsHistory: getAchievementsHistory(apiService, filter),
      getGratitudeHistory: getGratitudeHistory(apiService, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(EventsSecretScreen);
