import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import compose from "../../../utils/compose";
import { withApiService } from "../../hoc";

import styles from "./nav.module.scss";

const Nav = ({ history, location, isSuperuser }) => {
  return (
    <div className={styles.Nav}>
      <ul>
        <li style={location.pathname === "/events" ? { color: "#5f37ff" } : {}}>
          <Link to={"/events"}>События</Link>
        </li>
        <li style={location.pathname === "/store" ? { color: "#5f37ff" } : {}}>
          <Link to={"/store"}>Магазин</Link>
        </li>
        <li
          style={location.pathname === "/projects" ? { color: "#5f37ff" } : {}}
        >
          <Link to={"/projects"}>Проекты</Link>
        </li>
        <li
          style={location.pathname === "/ratings" ? { color: "#5f37ff" } : {}}
        >
          <Link to={"/ratings"}>Рейтинги</Link>
        </li>
        <li
          style={
            location.pathname === "/achievements" ? { color: "#5f37ff" } : {}
          }
        >
          <Link to={"/achievements"}>Достижения</Link>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  isSuperuser: auth.isSuperuser,
});
const mapDispatchToProps = (dispatch, { apiService }) => {
  return bindActionCreators({}, dispatch);
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Nav));
