import React, {useEffect, useState} from "react";

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withApiService} from "../hoc";
import compose from "../../utils/compose";
import {getProducts} from "../../redux/store/actions";
import {getProfile} from "../../redux/auth/actions";

import InfiniteScroll from "react-infinite-scroller";
import ProductCard from "./ProductCard";
import LoadScreen from "../LoadScreen";
import styles from "./store.module.scss";
import {ReactComponent as MoneyIcon} from "../../assets/images/moneyIcon.svg";
import {NumInput, SelectFilter} from "../FormItems";
import Pagination from "../Tables/Pagination";
import getUrlParam from "../../utils/getUrlParam";
import {ReactComponent as EmptyTable} from "../../assets/images/EmptyTable.svg";

const Store = ({
                   getProducts,
                   getProfile,
                   coins,
                   products,
                   productsCount,
                   loading,
               }) => {
    const desktop = window.innerWidth < 768 ? false : true;
    let urlParam = getUrlParam('page');

    const [productsFilter, setProductsFilter] = useState({
        limit: desktop ? 20 : 4,
        offset: (urlParam ? (urlParam - 1) : 0) * (desktop ? 20 : 4),
        productsType: localStorage.getItem('productsFilterType') ? localStorage.getItem('productsFilterType') : "all",
        placeType: localStorage.getItem('placeFilterType') ? localStorage.getItem('placeFilterType') : null,
        coinFrom: localStorage.getItem('coinFilterFrom') ? localStorage.getItem('coinFilterFrom') : '0',
        coinTo: localStorage.getItem('coinFilterTo') ? localStorage.getItem('coinFilterTo') : 100000,
    });


    const [page, setPage] = useState(1);

    useEffect(() => {
        localStorage.removeItem('allAchievementsVisibleBlock');
        localStorage.removeItem('achievementsHistoryFilterTime');

        localStorage.removeItem('pointsRatingFilterTime');
        localStorage.removeItem('pointsRatingFilterCategory');
        localStorage.removeItem('achievementsVisibleBlock');
        localStorage.removeItem('achievementsRatingFilterTime');

        localStorage.removeItem('homePointsFilter');
        localStorage.removeItem('otherUserPointsFilter');

        getProducts({...productsFilter, page: productsFilter.offset / productsFilter.limit}, true);

        getProfile()
    }, []);

    const updateProductsFilter = (fieldName, fieldValue) => {
        if (!desktop) {
            window.history.pushState(null, null, `?page=1`)
        }
        setProductsFilter(prevState => ({
            ...prevState,
            [fieldName]: fieldValue.id ? fieldValue.id : fieldValue, offset: 0, page: 0
        }));
        if (fieldName === 'productsType') {
            localStorage.setItem('productsFilterType', fieldValue.id);
        }
        if (fieldName === 'coinFrom') {
            localStorage.setItem('coinFilterFrom', fieldValue);
        }
        if (fieldName === 'coinTo') {
            localStorage.setItem('coinFilterTo', fieldValue);
        }
        if (fieldName === 'placeType') {
            console.log(fieldValue.id);
            localStorage.setItem('placeFilterType', fieldValue.id ? fieldValue.id : null);
        }

        setPage(1);
        setTimeout(getProducts, 500, {
            ...productsFilter,
            [fieldName]: fieldValue.id ? fieldValue.id : fieldValue,
            page: 0,
            offset: 0
        }, true)
    };

    const loadData = () => {
        getProducts({...productsFilter, page});
        setPage(page + 1);
    };

    const placeTypeOptions = [
        {id: "0", name: "Для всех"},
        {id: "1", name: "Офис"},
        {id: "2", name: "Удаленка"},
    ]

    const productsFilterOptions = [
        {id: "all", name: "Все товары"},
        {id: "products", name: "Личные товары"},
        {id: "crowdfunding", name: "Краудфандинговые"},
        {id: "completed", name: "Завершенные"},
    ];

    if (products.length <= 0 && loading) {
        return <div className={styles.StoreLoadScreen}><LoadScreen/></div>;
    }

    return (
        <div className={styles.Store}>
            <div className='mt1'></div>
            <div className={styles.filtersRow}>
                <div className="titleH1">Магазин</div>

                <div className={styles.filterContainer}>
                    <SelectFilter
                        width={212}
                        placeholder='Все товары'
                        onSelect={value => {
                            updateProductsFilter('placeType', value)
                        }}
                        selectedValue={placeTypeOptions.find(
                            item => item.id === productsFilter.placeType,
                        )}
                        clearSelect={() => {
                            updateProductsFilter('placeType', null)
                        }}
                        options={placeTypeOptions}
                    />
                    <SelectFilter
                        width={212}
                        placeholder='Все товары'
                        onSelect={value => {
                            updateProductsFilter("productsType", value);
                        }}
                        selectedValue={productsFilterOptions.find(
                            item => item.id === productsFilter.productsType,
                        )}
                        clearSelect={() => {
                            updateProductsFilter("productsType", null);
                        }}
                        options={productsFilterOptions}
                    />
                    <div className={styles.NumInputContainer}>
                        <NumInput
                            label='Стоимость'
                            labelPosition={"left"}
                            value={productsFilter.coinFrom}
                            maxValue={9999999}
                            minValue={0}
                            width={"110px"}
                            onChange={value => {
                                updateProductsFilter("coinFrom", +value);
                            }}
                            icon={<MoneyIcon style={{marginLeft: 5, marginTop: 1}} width={20} height={20}/>}
                        />
                        <div className={styles.NumInputContainerDefis}></div>
                        <NumInput
                            label=''
                            labelPosition={"left"}
                            value={productsFilter.coinTo}
                            maxValue={9999999}
                            minValue={0}
                            width={"110px"}
                            onChange={value => {
                                updateProductsFilter("coinTo", +value);
                            }}
                            icon={<MoneyIcon style={{marginLeft: 5, marginTop: 1}} width={20} height={20}/>}
                        />
                    </div>
                </div>
            </div>
            {products.length === 0 ?
                <div className={styles.StoreTableEmptyScreen}>
                    <EmptyTable/>
                    <div className='Text'>Нет данных</div>
                </div> :
                !desktop ? (
                    <>
                        <div
                            className={styles.CardsTable}
                        >
                            {products.map(cardData => {
                                return <ProductCard cardData={cardData} key={'ProductCard' + cardData.id}/>
                            })}
                        </div>
                        {productsCount > productsFilter.limit ?
                            (<Pagination
                                    total={productsCount}
                                    offset={(urlParam ? (urlParam - 1) : productsFilter.offset) * productsFilter.limit}
                                    limit={productsFilter.limit}
                                    onChange={newPage => {
                                        setProductsFilter({
                                            ...productsFilter,
                                            offset: (newPage - 1) * productsFilter.limit,
                                            page: newPage - 1
                                        })
                                        getProducts({
                                            ...productsFilter,
                                            offset: (newPage - 1) * productsFilter.limit,
                                            page: newPage - 1
                                        }, true);
                                    }}
                                />
                            ) : ''
                        }
                    </>
                ) : (
                    <InfiniteScroll
                        className={styles.CardsTable}
                        loadMore={loadData}
                        initialLoad={false}
                        hasMore={!loading && products.length < productsCount}
                    >
                        {products.map(cardData => {
                            return <ProductCard cardData={cardData}
                                                key={'ProductCardInfiniteScroll ' + cardData.title}/>
                        })}
                    </InfiniteScroll>
                )}
        </div>
    );
};

const mapStateToProps = ({store, auth}) => ({
    products: store.products,
    productsCount: store.productsCount,
    loading: store.loading,
    coins: auth.coins,
});
const mapDispatchToProps = (dispatch, {apiService, filter}) => {
    return bindActionCreators(
        {
            getProfile: getProfile(apiService),
            getProducts: getProducts(apiService, filter),
        },
        dispatch,
    );
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps),
)(Store);
