export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_MORE_PRODUCTS_SUCCESS = "FETCH_MORE_PRODUCTS_SUCCESS";

export const FETCH_PRODUCTS_BY_ID_REQUEST = "FETCH_PRODUCTS_BY_ID_REQUEST";
export const FETCH_PRODUCTS_BY_ID_FAILURE = "FETCH_PRODUCTS_BY_ID_FAILURE";
export const FETCH_PRODUCTS_BY_ID_SUCCESS = "FETCH_PRODUCTS_BY_ID_SUCCESS";

export const FETCH_CROWDFUNDING_BY_ID_REQUEST = "FETCH_CROWDFUNDING_BY_ID_REQUEST";
export const FETCH_CROWDFUNDING_BY_ID_FAILURE = "FETCH_CROWDFUNDING_BY_ID_FAILURE";
export const FETCH_CROWDFUNDING_BY_ID_SUCCESS = "FETCH_CROWDFUNDING_BY_ID_SUCCESS";

export const FETCH_BUY_PRODUCT_REQUEST = "FETCH_BUY_PRODUCT_REQUEST";
export const FETCH_BUY_PRODUCT_FAILURE = "FETCH_BUY_PRODUCT_FAILURE";
export const FETCH_BUY_PRODUCT_SUCCESS = "FETCH_BUY_PRODUCT_SUCCESS";

export const FETCH_CREATE_PRODUCT_REQUEST = "FETCH_CREATE_PRODUCT_REQUEST";
export const FETCH_CREATE_PRODUCT_FAILURE = "FETCH_CREATE_PRODUCT_FAILURE";
export const FETCH_CREATE_PRODUCT_SUCCESS = "FETCH_CREATE_PRODUCT_SUCCESS";

export const FETCH_UPDATE_PRODUCT_REQUEST = "FETCH_UPDATE_PRODUCT_REQUEST";
export const FETCH_UPDATE_PRODUCT_FAILURE = "FETCH_UPDATE_PRODUCT_FAILURE";
export const FETCH_UPDATE_PRODUCT_SUCCESS = "FETCH_UPDATE_PRODUCT_SUCCESS";

export const FETCH_REMOVE_PRODUCT_REQUEST = "FETCH_REMOVE_PRODUCT_REQUEST";
export const FETCH_REMOVE_PRODUCT_FAILURE = "FETCH_REMOVE_PRODUCT_FAILURE";
export const FETCH_REMOVE_PRODUCT_SUCCESS = "FETCH_REMOVE_PRODUCT_SUCCESS";