import React from 'react';
import styles from './checkbox.module.scss';

const CheckBox = ({ id, label, checked, onChange }) => {
  return (
    <div className={styles.Checkbox}>
      <input id={id} onChange={onChange} checked={checked} type='checkbox' />
      <label htmlFor={id}></label>
      <label htmlFor={id} className={styles.checkBoxLabel}>{label}</label>
    </div>
  );
};

export default CheckBox;
