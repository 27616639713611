import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../hoc";
import compose from "../../../utils/compose";
import { searchUsersByNameWithSort } from "../../../redux/search/actions";

import styles from "./EditProjectForm.module.scss";

import {
    TextInput,
    Textarea,
    ImageInput,
    Button,
    Search,
    CheckBox,
} from "../../FormItems";

import { GridTableWithToogleRows } from "../../Tables";
import { ReactComponent as UserPlaceholder } from "../../../assets/images/userPlaceholder.svg";

import UserRowEditForm from "./UserRowEditForm";
import showMessage from "../../showMessage";
import getUrlParam from "../../../utils/getUrlParam";
import Pagination from "../../Tables/Pagination";

const updateProjectOwnersList = (prevValuesList = [], value) => {
  let valueIndex = prevValuesList.findIndex(item => item.id === value.id);
  // Если в массиве есть value и нужно удалить его
  if (valueIndex > -1 && value.isMain == null) {
      return [
          ...prevValuesList.slice(0, valueIndex),
          ...prevValuesList.slice(valueIndex + 1),
      ];
  }
  if (valueIndex < 1 && value.isMain == null) {
      return [...prevValuesList];
  }
  // Если в массиве есть value заменяем его
  if (valueIndex > -1) {
    return [
      ...prevValuesList.slice(0, valueIndex),
      value,
      ...prevValuesList.slice(valueIndex + 1),
    ];
  } else {
    // Если в массиве нет value добавляем его
    return [...prevValuesList, value];
  }
};

const EditProjectForm = ({
  onSave,
  onClose,
  onRemove,
  searchResultsUsersCount,
  projectData = {},
  searchResults = [],
  searchUsersByNameWithSort
}) => {
  const [editProjectForm, setEditProjectForm] = useState(projectData);
  const [flag, setFlag] = useState(false);
    const desktop = window.innerWidth < 768 ? false : true;
    let urlParam = getUrlParam('page');
    const [filter, setFilter] = useState({
        limit: desktop ? 9999 : 8,
        offset: (urlParam ? urlParam - 1 : 0) * (desktop ? 9999 : 8),
    });

  useEffect(() => {
    setEditProjectForm(projectData);
    searchUsersByNameWithSort(filter, projectData);
  }, [projectData]);

  const updateProjectData = (fieldName, fieldValue) => {
    setEditProjectForm(prevState => {
      return { ...prevState, [fieldName]: fieldValue };
    });
  };

  return (
    <div
      key={"projectEditBlock "}
      className={styles.projectCard}
    >
      <div className={styles.projectFormRow}>
        <div className={styles.imageInputWrapper}>
          <ImageInput
            className={styles.projectFormRowImageInput}
            value={
              editProjectForm.image
                ? editProjectForm.image.image
                  ? window.location.origin + editProjectForm.image.image
                  : URL.createObjectURL(editProjectForm.image)
                : ""
            }
            onChange={value => {
              updateProjectData("image", value);
            }}
          />
        </div>
        <div className={styles.projectFormCol}>
          <TextInput
            placeholder={"Название проекта"}
            value={editProjectForm.name}
            onChange={value => {
              updateProjectData("name", value);
            }}
          />
          <Textarea
            placeholder={"Описание проекта"}
            value={editProjectForm.description ? editProjectForm.description : ''}
            onChange={value => {
              updateProjectData("description", value);
            }}
            minHeight={"80px"}
          />
        </div>
      </div>
      <div className='m1'></div>

      <div className={styles.projectUsersBlock} key={"projectUsersBlock"}>
          <div className={styles.projectsUsersFilter}>
            <Search
              label='Имя'
              placeholder='Имя пользователя'
              onSelect={value => {}}
              searchResults={[]}
              requestStartSumbolIndex={0}
              searchRequest={val => {
                  searchUsersByNameWithSort({ name: val, limit: 99999 }, editProjectForm);
              }}
              selectedValue={{}}
            />

            <div className={styles.projectsUsersCount}>
              Участников: {" "}
              {editProjectForm.users ? editProjectForm.users.length : "0"}
            </div>
          </div>
          <GridTableWithToogleRows
              maxHeight={320}
              differentRowColor={true}
              onRowClick={(rowData, rowIndex) => {
                  if (rowIndex > searchResultsUsersCount - 4) {
                      let tbody = document.querySelectorAll("tbody")[1];
                       function scroll(){tbody.scrollBy(0, 130)}
                      setTimeout(scroll, 200)
                  }
                }
              }
              openRowComponent={({ rowData, rowIndex }) => {
                  return (
                      <UserRowEditForm
                          onSave={newUserData => {
                              updateProjectData(
                                  "users",
                                  updateProjectOwnersList(
                                      editProjectForm.users,
                                      newUserData,
                                  ),
                              );
                          }}
                          isRole={ value => {setFlag(value)}}
                          userData={(() => {
                              let projectUser = editProjectForm.users
                                  ? editProjectForm.users.find(
                                      item => item.id === rowData.id,
                                  )
                                  : {};
                              projectUser = projectUser ? projectUser : {};
                              return { ...rowData, ...projectUser };
                          })()}
                      />
                  );
              }}
              dataSource={searchResults}
            columns={[
              {
                title: "",
                dataIndex: "nickname",
                render: userRowData => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {userRowData.avatar ? (
                          <img
                            style={{
                              width: 36,
                              height: 36,
                              borderRadius: 8,
                            }}
                            src={userRowData.avatar}
                            alt='project'
                          />
                        ) : (
                          <UserPlaceholder
                            width='30px'
                            height='30px'
                            style={{
                              borderRadius: 8,
                              backgroundColor: "#EBEEF1",
                              padding: "0.2rem",
                            }}
                          />
                        )}
                        <div
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            marginLeft: 10,
                          }}
                        >
                          {userRowData.firstName + " " + userRowData.lastName ||
                            userRowData.nickname}
                        </div>
                      </div>
                      <CheckBox
                        onChange={activeValues => {}}
                        prevValuesList={editProjectForm.users}
                        value={userRowData}
                        defValue={
                          editProjectForm.users &&
                          editProjectForm.users.find(
                            item => item.id === userRowData.id,
                          )
                            ? editProjectForm.users.find(
                                item => item.id === userRowData.id,
                              ).role
                            : false
                        }
                      />
                    </div>
                  );
                },
              },
            ]}
          />
          {!desktop &&  searchResultsUsersCount > filter.limit ? (
              <Pagination
                  total={searchResultsUsersCount}
                  offset={(urlParam ? (urlParam - 1) : filter.offset) * filter.limit}
                  limit={filter.limit}
                  onChange={newPage => {
                      setFilter({...filter, offset: (newPage - 1) * filter.limit })
                      searchUsersByNameWithSort({...filter, offset: (newPage - 1) * filter.limit, limit: filter.limit }, projectData)
                  }}
              />
          ) : (
              ""
          )}
      </div>
      <div className='mt1'></div>
      <div className={styles.EditProjectFormButton}>
        {onRemove ? (
          <button
            type='button'
            className={styles.removeBtn}
            onClick={() => {
              onRemove(editProjectForm);
            }}
          >
            Удалить
          </button>
        ) : (
          ""
        )}
        {onClose ? (
          <button
            type='button'
            className={styles.removeBtn}
            onClick={() => {
              onClose();
            }}
          >
            Отменить
          </button>
        ) : (
          ""
        )}
        {onSave ? (
               <Button
                label='Сохранить'
                handleClick={() => {
                    let check = null;
                    if (editProjectForm.users && editProjectForm.users.length > 0) {
                        for (let i = 0; i < editProjectForm.users.length; i++) {
                            if (!editProjectForm.users[i].role || editProjectForm.users[i].isMain === undefined) {
                                setTimeout(function() {
                                    showMessage({
                                        status: "error",
                                        text: `Пожалуйста, укажите роль и долю участия ${editProjectForm.users[i].nickname}`,
                                    });
                                }, 500);
                                check = false
                            } else {
                                check = true
                            }
                        }
                    } else {
                        check = true
                    }
                    if (check) {
                        onSave(editProjectForm);
                    }
                }}
              />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ search }) => ({
  searchResults: search.users,
  searchResultsUsersCount: search.usersCount
});
const mapDispatchToProps = (dispatch, { apiService, filter, data }) => {
  return bindActionCreators(
    {
      searchUsersByNameWithSort: searchUsersByNameWithSort(apiService, filter, data),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(EditProjectForm);
