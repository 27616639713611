import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../hoc";
import compose from "../../../utils/compose";

import {
  getUsersForAdmin,
  updateUser,
  syncUsersWithSlack,
} from "../../../redux/users/actions";

import styles from "./UsersTab.module.scss";
import { GridTable } from "../../Tables";
import { Button, Search } from "../../FormItems";
import EditUserForm from "../EditUserForm";
import { ReactComponent as SyncIcon } from "../../../assets/images/SyncIcon.svg";
import { ReactComponent as UserPlaceholder } from "../../../assets/images/userPlaceholder.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/editIcon.svg";
import Pagination from "../../Tables/Pagination";

import {ReactComponent as BreadcrumbsBlockArrow} from "../../../assets/images/breadcrumbsBlockArrow.svg";
import getUrlParam from "../../../utils/getUrlParam";

const UsersTab = ({
  users,
  usersLoading,
  usersCount,
  getUsersForAdmin,
  syncUsersWithSlack,
  updateUser,
}) => {
  useEffect(() => {
    getUsersForAdmin(filter);
  }, []);

  const desktop = window.innerWidth < 768 ? false : true;
  let urlParam = getUrlParam('page');
  const [filter, setFilter] = useState({
    limit: desktop ? 9999 : 8,
    offset: (urlParam ? urlParam - 1 : 0) * (desktop ? 9999 : 8),
  });
  const [selectedRow, setSelectedRow] = useState("");

  return (
    <div className={styles.UsersTab}>
      { window.innerWidth > 768 || !selectedRow.id ?
          <div className={styles.tableBlock}>
            <div className='titleH1'>Список пользователей ({usersCount})</div>
            <div className='mt2'></div>
            <div className={styles.twoFlexColumns}>
              <div style={{ width: '100%'}}>
                <Search
                  label='Имя'
                  placeholder='Имя пользователя'
                  onSelect={value => {}}
                  searchResults={[]}
                  requestStartSumbolIndex={0}
                  searchRequest={val => {
                    getUsersForAdmin({ name: val });
                    setFilter({ name: val });
                  }}
                  selectedValue={{}}
                />
              </div>
            </div>
            <GridTable
                  loading={usersLoading}
                  differentRowColor={true}
                  onClickRow={rowData => {
                    setSelectedRow(rowData);
                  }}
                  highlightRow={rowData => rowData.id === selectedRow.id}
                  dataSource={users}
                  columns={[
                    {
                      title: "",
                      dataIndex: "nickname",
                      render: rowData => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {rowData.avatar ? (
                                <img
                                  style={{ width: 36, height: 36, borderRadius: 8 }}
                                  src={rowData.avatar}
                                  alt='achievement'
                                />
                              ) : (
                                <UserPlaceholder
                                  width='30px'
                                  height='30px'
                                  style={{
                                    borderRadius: 8,
                                    backgroundColor: "#EBEEF1",
                                    padding: "0.2rem",
                                  }}
                                />
                              )}
                              <div
                                style={{
                                  fontSize: 14,
                                  fontWeight: 500,
                                  marginLeft: 10,
                                }}
                              >
                                {rowData.firstName + " " + rowData.lastName ||
                                  rowData.nickname}
                              </div>
                            </div>
                            <EditIcon
                              style={{
                                borderRadius: 6,
                                boxShadow: "0px 5px 19px -10px rgba(0, 0, 0, 0.75)",
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  ]}
            />
            { !desktop && usersCount > filter.limit ? (
                <Pagination
                    total={usersCount}
                    offset={(urlParam ? (urlParam - 1) : filter.offset) * filter.limit}
                    limit={filter.limit}
                    onChange={newPage => {
                      setFilter({ ...filter, offset: (newPage - 1) * filter.limit });
                      getUsersForAdmin( { ...filter, offset: (newPage - 1) * filter.limit, limit: filter.limit })
                    }}
                />
            ) : (
                ""
            )}
          </div>
        : ''
      }

      {selectedRow.id ? (
        <>
          {   !desktop ?
          <div className={styles.breadcrumbsBlock}>
            <span className={styles.breadcrumbsArrow}>
              <BreadcrumbsBlockArrow
                  style={{ marginLeft: 5, width: '12px', height: '12px', fill: '#5f37ff', transform: 'rotate(180deg)' }}
                  width={8}
                  height={12}
              />
            </span>
            <div
                className={styles.breadcrumb}
                onClick={() => {setSelectedRow(""); setFilter({...filter,  name: ''});  getUsersForAdmin({...filter,  name: ''})}}
            >
              Настройки
            </div>
          </div> : ''
          }
          <div className={styles.formBlock}>
            <EditUserForm
              onSave={editUserForm => {
                updateUser(editUserForm, filter);
                setSelectedRow("");
                setFilter({...filter,  name: ''});
                getUsersForAdmin({...filter,  name: ''})
              }}
              userData={selectedRow || {}}
            />
          </div>
        </>
      ) : (
          ""
      )}
    </div>
  );
};

const mapStateToProps = ({ users }) => ({
  users: users.users,
  usersLoading: users.loading,
  usersCount: users.usersCount,
});
const mapDispatchToProps = (dispatch, { apiService, filter, userData }) => {
  return bindActionCreators(
    {
      getUsersForAdmin: getUsersForAdmin(apiService, filter),
      updateUser: updateUser(apiService, userData, filter),
      syncUsersWithSlack: syncUsersWithSlack(apiService, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(UsersTab);
