import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../hoc";
import compose from "../../../utils/compose";
import { searchUsersByName } from "../../../redux/search/actions";

import styles from "./editAchievementForm.module.scss";

import {
  TextInput,
  Textarea,
  ImageInput,
  Button,
  Search,
  CheckBox,
} from "../../FormItems";
import { GridTable } from "../../Tables";
import { ReactComponent as UserPlaceholder } from "../../../assets/images/userPlaceholder.svg";
import Pagination from "../../Tables/Pagination";
import getUrlParam from "../../../utils/getUrlParam";

const EditAchievementForm = ({
  onSave,
  onClose,
  onRemove,
  searchResultsUsersCount,
  achievementData = {},
  searchResults = [],
  searchUsersByName,
}) => {
  const [editAchievementForm, setEditAchievementForm] = useState(
    achievementData
  );
  const desktop = window.innerWidth < 768 ? false : true;
  let urlParam = getUrlParam('page');
  const [filter, setFilter] = useState({
    limit: desktop ? 9999 : 8,
    offset: (urlParam ? urlParam - 1 : 0) * (desktop ? 9999 : 8),
  });
  useEffect(() => {
    setEditAchievementForm({...achievementData, isActive:true});
  }, [achievementData]);

  useEffect(() => {
    searchUsersByName(filter);
  }, []);

  const [isShowAllUsers, setIsShowAllUsers] = useState(false);

  const updateAchievementData = (fieldName, fieldValue) => {
    setEditAchievementForm(prevState => {
      return { ...prevState, [fieldName]: fieldValue };
    });
  };

  const updateAchievementOwnersList = (prevValuesList = [], value) => {
    let valueIndex = prevValuesList.findIndex(item => item.id === value.id);

    // Если в массиве есть value удаляем его
    if (valueIndex > -1) {
      return [
        ...prevValuesList.slice(0, valueIndex),
        ...prevValuesList.slice(valueIndex + 1),
      ];
    } else {
      // Если в массиве нет value добавляем его
      return [...prevValuesList, value];
    }
  };

  // Сортируем searchResults по алфавиту
  searchResults = searchResults.sort((a, b) =>
    a.firstName > b.firstName ? 1 : -1,
  );

  if (editAchievementForm.owners && editAchievementForm.owners.length > 0) {
    // Сортируем searchResults по наличию ачивок
    searchResults = searchResults.sort((a, b) => {
      return editAchievementForm.owners.find(item => item.id === a.id) ? -1 : 1;
    });
  }

  return (
    <div
      key={"achievmentEditBlock" + editAchievementForm.id}
      className={styles.achievementCard}
    >
      <div className={styles.achievementFormRow}>
        <div className={styles.imageInputWrapper}>
          <ImageInput
            className={styles.achievementImageInput}
            value={
              editAchievementForm.image
                ? editAchievementForm.image.image
                  ? window.location.origin + editAchievementForm.image.image
                  : URL.createObjectURL(editAchievementForm.image)
                : ""
            }
            onChange={value => {
              updateAchievementData("image", value);
            }}
          />
        </div>
        <div className={styles.achievementFormCol}>
          <TextInput
            placeholder={"Название достижения"}
            value={editAchievementForm.name}
            onChange={value => {
              updateAchievementData("name", value);
            }}
          />
          <Textarea
            placeholder={"Описание достижения"}
            minHeight={"60px"}
            value={editAchievementForm.description}
            onChange={value => {
              updateAchievementData("description", value);
            }}
          />
        </div>
      </div>
      <div className='mt1'></div>
      <div className={styles.achievementOwnersBlock}>
          <div className={styles.achievementsOwnersFilter}>
            <Search
              label='Имя'
              placeholder='Имя пользователя'
              onSelect={value => {}}
              searchResults={[]}
              requestStartSumbolIndex={0}
              searchRequest={val => {
                searchUsersByName({ name: val, limit: 99999 });
              }}
              selectedValue={{}}
            />

            <div className={styles.achievementsOwnersCount}>
              Награждено{" "}
              {editAchievementForm.owners
                ? editAchievementForm.owners.length
                : "0"}
            </div>
          </div>
          <GridTable
            maxHeight={!desktop ? 390 : 320}
            differentRowColor={false}
            onClickRow={rowData => {
              updateAchievementData(
                "owners",
                updateAchievementOwnersList(
                  editAchievementForm.owners,
                  rowData,
                ),
              );
            }}
            dataSource={searchResults}
            columns={[
              {
                title: "",
                dataIndex: "nickname",
                render: userRowData => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {userRowData.avatar ? (
                          <img
                            style={{
                              width: 36,
                              height: 36,
                              borderRadius: 8,
                            }}
                            src={userRowData.avatar}
                            alt='achievement'
                          />
                        ) : (
                          <UserPlaceholder
                            width='30px'
                            height='30px'
                            style={{
                              borderRadius: 8,
                              backgroundColor: "#EBEEF1",
                              padding: "0.2rem",
                            }}
                          />
                        )}
                        <div
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            marginLeft: 10,
                          }}
                        >
                          {userRowData.firstName + " " + userRowData.lastName ||
                            userRowData.nickname}
                        </div>
                      </div>
                      <CheckBox
                        onChange={activeValues => {
                          updateAchievementData("owners", activeValues);
                        }}
                        prevValuesList={editAchievementForm.owners}
                        value={userRowData}
                        defValue={
                          editAchievementForm.owners
                            ? editAchievementForm.owners.find(
                                item => item.id === userRowData.id,
                              )
                            : false
                        }
                      />
                    </div>
                  );
                },
              },
            ]}
          />
        { !desktop && searchResultsUsersCount > filter.limit ? (
            <Pagination
                total={searchResultsUsersCount}
                offset={(urlParam ? (urlParam - 1) : filter.offset) * filter.limit}
                limit={filter.limit}
                onChange={newPage => {
                  setFilter({ ...filter, offset: (newPage - 1) * filter.limit });
                  searchUsersByName( {  ...filter, offset: (newPage - 1) * filter.limit, limit: filter.limit })
                }}
            />
        ) : (
            ""
        )}
      </div>
      <div className='mt1'></div>
      <div className={styles.EditAchievementFormButton}>
        {onRemove ? (
          <button
            type='button'
            className={styles.removeBtn}
            onClick={() => {
              onRemove(editAchievementForm);
            }}
          >
            Удалить
          </button>
        ) : (
          ""
        )}
        {onClose ? (
          <button
            type='button'
            className={styles.removeBtn}
            onClick={() => {
              onClose();
            }}
          >
            Отменить
          </button>
        ) : (
          ""
        )}
        {onSave ? (
            <Button
                label='Сохранить'
                handleClick={() => {
                  console.log(editAchievementForm);
                  onSave(editAchievementForm);
                }}
            />
        ) : (
          ""
        )}

      </div>
    </div>
  );
};

const mapStateToProps = ({ search }) => ({
  searchResults: search.users,
  searchResultsUsersCount: search.usersCount
});
const mapDispatchToProps = (dispatch, { apiService, filter }) => {
  return bindActionCreators(
    {
      searchUsersByName: searchUsersByName(apiService, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(EditAchievementForm);
