import {
  getGratitudeCategoriesRequest,
  getGratitudeCategoriesFailure,
  getGratitudeCategoriesSuccess,
  getOwnReceivedGratitudeRequest,
  getOwnReceivedGratitudeFailure,
  getOwnReceivedGratitudeSuccess,
  getOwnTotalCountReceivedGratitudeRequest,
  getOwnTotalCountReceivedGratitudeFailure,
  getOwnTotalCountReceivedGratitudeSuccess,
  getOwnSendedGratitudeRequest,
  getOwnSendedGratitudeFailure,
  getOwnSendedGratitudeSuccess,
  getReceivedGratitudeByUserIdRequest,
  getReceivedGratitudeByUserIdFailure,
  getReceivedGratitudeByUserIdSuccess,
  getReceivedGratitudeTotalCountRequest,
  getReceivedGratitudeTotalCountFailure,
  getReceivedGratitudeTotalCountSuccess,
  getSendedGratitudeByUserIdRequest,
  getSendedGratitudeByUserIdFailure,
  getSendedGratitudeByUserIdSuccess,
  givePointsToUserRequest,
  givePointsToUserFailure,
  givePointsToUserSuccess,
} from "./actionCreators";

import getTimestampDateXDaysAgo from "../../utils/getTimestampDateXDaysAgo";
import showMessage from "../../components/showMessage";
import { getProfile } from "../auth/actions";

const mapTimeFilterIdToTimestamp = {
  1: 0, // За все время
  2: getTimestampDateXDaysAgo(1), // За сегодня
  3: getTimestampDateXDaysAgo(7), // За неделю
  4: getTimestampDateXDaysAgo(30), // За месяц
};
const getGratitudeCategories = api => () => dispatch => {
  dispatch(getGratitudeCategoriesRequest());
  api
    .getGratitudeCategories()
    .then(res => {
      dispatch(getGratitudeCategoriesSuccess(res.data));
      console.log(res, "res getGratitudeCategories");
    })
    .catch(err => {
      dispatch(getGratitudeCategoriesFailure(err));
      console.log(err, "err getGratitudeCategories");
    });
};

const getOwnReceivedGratitude = api => (filter = {}) => dispatch => {
  let formattedFilter = {
    gratitudeCategoryId: filter.category || null,
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    grantedById: filter.grantedById || null,
    limit: filter.limit,
    offset: filter.offset
  };

  dispatch(getOwnReceivedGratitudeRequest());
  api
    .getReceivedOwnGratitude(formattedFilter)
    .then(res => {
      dispatch(getOwnReceivedGratitudeSuccess(res.data));
      console.log(res, "res getReceivedOwnGratitude");
    })
    .catch(err => {
      dispatch(getOwnReceivedGratitudeFailure(err));
      console.log(err, "err getReceivedOwnGratitude");
    });
};

const getOwnTotalCountReceivedGratitude = api => (filter) => dispatch => {
  dispatch(getOwnTotalCountReceivedGratitudeRequest());
  api
    .getReceivedOwnGratitude(filter)
    .then(res => {
      dispatch(getOwnTotalCountReceivedGratitudeSuccess(res.data));
      console.log(res, "res getReceivedOwnGratitude");
    })
    .catch(err => {
      dispatch(getOwnTotalCountReceivedGratitudeFailure(err));
      console.log(err, "err getReceivedOwnGratitude");
    });
};

const getOwnSendedGratitude = api => filter => dispatch => {

  let formattedFilter = {
    gratitudeCategoryId: filter.category || null,
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    grantedById: filter.grantedById || null,
    limit: filter.limit,
    offset: filter.offset
  };
  dispatch(getOwnSendedGratitudeRequest());
  api
    .getSendedOwnGratitude(formattedFilter)
    .then(res => {
      dispatch(getOwnSendedGratitudeSuccess(res.data));
      console.log(res, "res getReceivedOwnGratitude");
    })
    .catch(err => {
      dispatch(getOwnSendedGratitudeFailure(err));
      console.log(err, "err getReceivedOwnGratitude");
    });
};

const getReceivedGratitudeByUserId = api => (
  userId,
  filter = {},
) => dispatch => {
  let formattedFilter = {
    gratitudeCategoryId: filter.category || null,
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    grantedById: filter.grantedById || null,
    limit: filter.limit || 999999,
    offset: filter.offset || 0,
  };

  dispatch(getReceivedGratitudeByUserIdRequest());
  api
    .getReceivedGratitudeByUserId(userId, formattedFilter)
    .then(res => {
      dispatch(getReceivedGratitudeByUserIdSuccess(res.data));
      console.log(res, "res getReceivedGratitudeByUserId");
    })
    .catch(err => {
      dispatch(getReceivedGratitudeByUserIdFailure(err));
      console.log(err, "err getReceivedGratitudeByUserId");
    });
};

const getReceivedGratitudeTotalCount = api => (
  userId,
  filter
) => dispatch => {
  dispatch(getReceivedGratitudeTotalCountRequest());
  api
    .getReceivedGratitudeByUserId(userId, filter)
    .then(res => {
      dispatch(getReceivedGratitudeTotalCountSuccess(res.data));
      console.log(res, "res getReceivedGratitudeTotalCount");
    })
    .catch(err => {
      dispatch(getReceivedGratitudeTotalCountFailure(err));
      console.log(err, "err getReceivedGratitudeTotalCount");
    });
};
const getSendedGratitudeByUserId = api => (userId, filter) => dispatch => {

  let formattedFilter = {
    gratitudeCategoryId: filter.category || null,
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    grantedById: filter.grantedById || null,
    limit: filter.limit || 999999,
    offset: filter.offset || 0,
  };

  dispatch(getSendedGratitudeByUserIdRequest());
  api
    .getSendedGratitudeByUserId(userId, formattedFilter)
    .then(res => {
      dispatch(getSendedGratitudeByUserIdSuccess(res.data));
      console.log(res, "res getSendedGratitudeByUserId");
    })
    .catch(err => {
      dispatch(getSendedGratitudeByUserIdFailure(err));
      console.log(err, "err getSendedGratitudeByUserId");
    });
};

const givePointsToUser = api => (
  { amount, commentary, gratitudeCategoryId, byAppCraft, userId },
  callback,
) => dispatch => {
  console.log('amount: ' + amount);
  dispatch(givePointsToUserRequest());
  // TODO: Проверка полей на заполненность
  if (!userId || !userId.id) {
    showMessage({
      text: "Пожалуйста, выберите пользователя",
      status: "error",
    });
    dispatch(givePointsToUserFailure({ userId: "Пользователь не выбран" }));
  } else if (!gratitudeCategoryId || !gratitudeCategoryId.id) {
    showMessage({
      text: "Пожалуйста, укажите за что вы благодарите",
      status: "error",
    });
    dispatch(
      givePointsToUserFailure({ gratitudeCategoryId: "категория не выбрана" }),
    );
  } else if (!commentary) {
    showMessage({
      text: "Пожалуйста, оставьте свой комментарий",
      status: "error",
    });
    dispatch(givePointsToUserFailure({ commentary: "Комментарий не введен" }));
  } else if (!amount) {
    showMessage({
      text: "Пожалуйста, укажите количество баллов",
      status: "error",
    });
    dispatch(givePointsToUserFailure({ amount: "Баллы не заполнены" }));
  } else {
    api
      .givePointsByUserId(userId.id, amount, gratitudeCategoryId.id, commentary, byAppCraft)
      .then(res => {
        dispatch(givePointsToUserSuccess());
        console.log(res, "res givePointsByUserId");
        showMessage({ text: "Баллы успешно начислены", status: "success" });
        getProfile(api)()(dispatch);
        callback();
      })
      .catch(err => {
        if (err.response.status === 400) {
          showMessage({
            text: "Вы не можете отправить благодарности самому себе",
            status: "error",
          });
          dispatch(givePointsToUserFailure(err));
        } else {
          dispatch(givePointsToUserFailure(err));
          showMessage({
            text: "Возникла неизвестная ошибка",
            status: "error",
          });
        }

        console.log(err, "err givePointsByUserId");
      });
  }
};

const givePointsToAllUser = api => (
  { amount, commentary, gratitudeCategoryId, byAppCraft, userIds },
  callback,
) => dispatch => {
  dispatch(givePointsToUserRequest());
  // TODO: Проверка полей на заполненность
  if (userIds.length == 0) {
    showMessage({
      text: "Пожалуйста, выберите пользователя",
      status: "error",
    });
    dispatch(givePointsToUserFailure({ userId: "Пользователь не выбран" }));
  } else if (!gratitudeCategoryId || !gratitudeCategoryId.id) {
    showMessage({
      text: "Пожалуйста, укажите за что вы благодарите",
      status: "error",
    });
    dispatch(
      givePointsToUserFailure({ gratitudeCategoryId: "категория не выбрана" }),
    );
  } else if (!commentary) {
    showMessage({
      text: "Пожалуйста, оставьте свой комментарий",
      status: "error",
    });
    dispatch(givePointsToUserFailure({ commentary: "Комментарий не введен" }));
  } else if (!amount) {
    showMessage({
      text: "Пожалуйста, укажите количество баллов",
      status: "error",
    });
    dispatch(givePointsToUserFailure({ amount: "Баллы не заполнены" }));
  } else {
    api
      .givePointsToAllUser(userIds, amount, gratitudeCategoryId.id, commentary, byAppCraft)
      .then(res => {
        dispatch(givePointsToUserSuccess());
        console.log(res, "res givePointsByUserId");
        showMessage({ text: "Баллы успешно начислены", status: "success" });
        getProfile(api)()(dispatch);
        callback();
      })
      .catch(err => {
        if (err.response.status === 400) {
          showMessage({
            text: "Вы не можете отправить благодарности самому себе",
            status: "error",
          });
          dispatch(givePointsToUserFailure(err));
        } else {
          dispatch(givePointsToUserFailure(err));
          showMessage({
            text: "Возникла неизвестная ошибка",
            status: "error",
          });
        }

        console.log(err, "err givePointsByUserId");
      });
  }
};

export {
  getGratitudeCategories,
  getOwnReceivedGratitude,
  getOwnTotalCountReceivedGratitude,
  getOwnSendedGratitude,
  getReceivedGratitudeByUserId,
  getReceivedGratitudeTotalCount,
  getSendedGratitudeByUserId,
  givePointsToUser,
  givePointsToAllUser
};
