import React, {useState, useEffect} from "react";
import styles from "./Tabs.module.scss";
import {Button} from "../FormItems";
import Slider from "react-slick";

const Tabs = ({blocks = []}) => {
    const [activeBlockIndex, setActiveBlockIndex] = useState(parseInt(localStorage.getItem('currentAdminTab')) ? localStorage.getItem('currentAdminTab') : 0);

    return (
        <div className={styles.tabsContainer}>
            <div className={styles.buttonsBlock}>
                <Slider
                    dots={false}
                    arrows={false}
                    className={"center"}
                    centerMode={true}
                    centerPadding={"70px"}
                    slidesToShow={5}
                    responsive={[
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]}
                >
                    {blocks.map(({title}, index) => {
                        return (
                            <div
                                key={"Tabsbutton" + index}
                                className={`${styles.button} ${
                                    index === parseInt(localStorage.getItem('currentAdminTab')) ? styles.buttonActive : ""
                                }`}
                            >
                                <Button
                                    style={{width: '90%', margin: '0 auto', maxWidth: '90%', whiteSpace: 'nowrap'}}
                                    label={title}
                                    handleClick={() => {
                                        localStorage.setItem('currentAdminTab', index);
                                        setActiveBlockIndex(index);
                                        window.history.pushState(null, null, `?page=1`);
                                    }}
                                />
                            </div>
                        );
                    })}
                </Slider>
            </div>
            <div className={styles.contentContainer}>
                {blocks[activeBlockIndex].htmlBlock}
            </div>
        </div>
    );
};

export default Tabs;
