import React from "react";
import styles from "./textInput.module.scss";

const TextInput = ({ label, placeholder, onChange, onBlur, value }) => {
  return (
    <div className={styles.TextInput}>
      {label ? <label>{label}</label> : ""}
      <div className={styles.inputBlock}>
        <input
          value={value ? value : ''}
          placeholder={placeholder}
          onChange={e => {
            onChange(e.target.value);
          }}

          onBlur={e => {typeof onBlur === 'function' && onBlur(e.target.value)}}
        />
      </div>
    </div>
  );
};

export default TextInput;
