import React from "react";
import styles from "./cardsTable.module.scss";
import { ReactComponent as EmptyTable } from "../../../assets/images/EmptyTable.svg";

const Card = ({ cardData, FrontSideComponent, BackSideComponent }) => {
  return (
    <button className={styles.Card}>
      <div className={styles.frontSide}>
        {<FrontSideComponent cardData={cardData} />}
      </div>
      <div className={styles.backSide}>
        {<BackSideComponent cardData={cardData} />}
      </div>
    </button>
  );
};

const CardsTable = ({
  cards = [],
  FrontSideComponent,
  BackSideComponent,
  loading = false,
}) => {
  if (loading) {
    return (
      <div className={styles.LoadScreen}>
        <div className='mainFountainG'>
          <div className='fountainG fountainG_1'></div>
          <div className='fountainG fountainG_2'></div>
          <div className='fountainG fountainG_3'></div>
          <div className='fountainG fountainG_4'></div>
          <div className='fountainG fountainG_5'></div>
          <div className='fountainG fountainG_6'></div>
          <div className='fountainG fountainG_7'></div>
          <div className='fountainG fountainG_8'></div>
        </div>
      </div>
    );
  }
  if (!loading && cards.length === 0) {
    return (
      <div className={styles.EmptyTableBlock}>
        <EmptyTable />
        <div className={styles.Text}>Нет данных</div>
      </div>
    );
  }

  return (
    <div className={styles.CardsTable}>
      {cards.map((item, index) => {
        return (
          <Card
            cardData={item}
            key={"cardItem-" + index}
            FrontSideComponent={FrontSideComponent}
            BackSideComponent={BackSideComponent}
          />
        );
      })}
    </div>
  );
};

export default CardsTable;
