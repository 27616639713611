import {
  FETCH_HISTORY_TV_GRATITUDE_REQUEST,
  FETCH_HISTORY_TV_GRATITUDE_FAILURE,
  FETCH_HISTORY_TV_GRATITUDE_SUCCESS,
  FETCH_HISTORY_TV_ACHIEVEMENTS_REQUEST,
  FETCH_HISTORY_TV_ACHIEVEMENTS_FAILURE,
  FETCH_HISTORY_TV_ACHIEVEMENTS_SUCCESS,
} from "./types";

const getGratitudeHistoryRequest = () => {
  return { type: FETCH_HISTORY_TV_GRATITUDE_REQUEST };
};
const getGratitudeHistoryFailure = err => {
  return { type: FETCH_HISTORY_TV_GRATITUDE_FAILURE, payload: err };
};
const getGratitudeHistorySuccess = historyGratitude => {
  return {
    type: FETCH_HISTORY_TV_GRATITUDE_SUCCESS,
    payload: historyGratitude,
  };
};

const getAchievementsHistoryRequest = () => {
  return { type: FETCH_HISTORY_TV_ACHIEVEMENTS_REQUEST };
};
const getAchievementsHistoryFailure = err => {
  return { type: FETCH_HISTORY_TV_ACHIEVEMENTS_FAILURE, payload: err };
};
const getAchievementsHistorySuccess = historyAchievements => {
  return {
    type: FETCH_HISTORY_TV_ACHIEVEMENTS_SUCCESS,
    payload: historyAchievements,
  };
};

export {
  getGratitudeHistoryRequest,
  getGratitudeHistoryFailure,
  getGratitudeHistorySuccess,
  getAchievementsHistoryRequest,
  getAchievementsHistoryFailure,
  getAchievementsHistorySuccess,
};
