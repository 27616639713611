import React from "react";
import styles from "./gridTable.module.scss";
import { ReactComponent as EmptyTable } from "../../../assets/images/EmptyTable.svg";

const GridTable = ({
  dataSource = [],
  columns = [],
  onClickRow = () => {},
  highlightRow = () => {},
  differentRowColor = true,
  loading = false,
  maxHeight = "auto",
}) => {
  if (loading) {
    return (
      <div className={styles.LoadScreen}>
        <div className='mainFountainG'>
          <div className='fountainG fountainG_1'></div>
          <div className='fountainG fountainG_2'></div>
          <div className='fountainG fountainG_3'></div>
          <div className='fountainG fountainG_4'></div>
          <div className='fountainG fountainG_5'></div>
          <div className='fountainG fountainG_6'></div>
          <div className='fountainG fountainG_7'></div>
          <div className='fountainG fountainG_8'></div>
        </div>
      </div>
    );
  }
  if (!loading && dataSource.length === 0) {
    return (
      <div className={styles.EmptyTableBlock}>
        <EmptyTable />
        <div className={styles.Text}>Нет данных</div>
      </div>
    );
  }

  return (
    <table className={styles.GridTable}>
      <thead>
        <tr>
          {columns.map(headerRowItem => {
            return (
              <th
                key={"headerRowItem" + headerRowItem.title}
                style={
                  headerRowItem.width ? { width: headerRowItem.width } : {}
                }
              >
                {headerRowItem.title}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody style={{ maxHeight: maxHeight }}>
        {dataSource.map((rowItem, rowIndex) => {
          return (
            <tr
              key={"gridTableTr" + rowIndex + rowItem.id}
              onClick={() => onClickRow(rowItem)}
              className={
                rowIndex % 2 === 0 && differentRowColor ? styles.gray : ""
              }
              style={
                highlightRow(rowItem)
                  ? { backgroundColor: "#5f37ff", color: "#ffffff" }
                  : {}
              }
            >
              {columns.map(headerRowItem => {
                return (
                  <td
                    key={
                      "gridTableTd" +
                      rowIndex +
                      rowItem.id +
                      headerRowItem.title
                    }
                    style={
                      headerRowItem.width
                        ? {
                            width: headerRowItem.width,
                            textAlign: headerRowItem.textAlign
                              ? headerRowItem.textAlign
                              : "left",
                          }
                        : {}
                    }
                  >
                    {headerRowItem.render
                      ? headerRowItem.render(rowItem, rowIndex)
                      : rowItem[headerRowItem.dataIndex]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default GridTable;
