import React, {useState, useEffect} from "react";
import { withRouter, Link  } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from "./ratings.module.scss";

import { withApiService } from "../hoc";
import compose from "../../utils/compose";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SelectFilter } from "../FormItems";
import { GridTable } from "../Tables";
import {getAchievementsWithPosition, getRatingsWithPosition} from "../../utils/ratings-utils";
import { getGratitudeCategories } from "../../redux/points/actions";
import {getAchievementsRating, getPointsRating} from "../../redux/ratings/actions";

import { ReactComponent as UserPlaceholder } from "../../assets/images/userPlaceholder.svg";
import { ReactComponent as BcoinIcon } from "../../assets/images/bcoin.svg";
import { ReactComponent as BcoinIconWhite } from "../../assets/images/bcoinWhite.svg";
import getUrlParam from "../../utils/getUrlParam";

const Ratings = ({   ownId,
                     ratingLoading = false,
                     pointsRating = [],
                     pointsCategories = [],
                     getPointsRating,
                     getGratitudeCategories,
                     achievementsRating,
                     history,
                     getAchievementsRating,}) => {
    const VisBlock = localStorage.getItem('achievementsVisibleBlock') ? localStorage.getItem('achievementsVisibleBlock') : 'achievements';
    const desktop = window.innerWidth < 991 ? false : true;
    let urlParam = getUrlParam('page');
    const [activeButton, setActiveButton] = useState('achievements');

    const [pointsRatingFilter, setPointsRatingFilter] = useState(
        {
            time: localStorage.getItem("pointsRatingFilterTime") ? localStorage.getItem("pointsRatingFilterTime") : 4,
            category: localStorage.getItem("pointsRatingFilterCategory") ? localStorage.getItem("pointsRatingFilterCategory") : '',
            limit: desktop ? 20 : 10,
            offset: (urlParam ? urlParam - 1 : 0) * (desktop ? 20 : 10)
        },
    );

    const [achievementsRatingFilter, setAchievementsRatingFilter] = useState(
        {
            time: localStorage.getItem("achievementsRatingFilterTime") ? localStorage.getItem("achievementsRatingFilterTime") : 1,
            limit: 20,
            offset: (urlParam ? urlParam - 1 : 0) * 20
        },
    );

    useEffect(() => {
        localStorage.removeItem('allAchievementsVisibleBlock');
        localStorage.removeItem('achievementsHistoryFilterTime');

        localStorage.removeItem('eventsFilterTime');
        localStorage.removeItem('eventsFilterType');
        localStorage.removeItem('eventsFilterCategory');

        localStorage.removeItem('homePointsFilter');
        localStorage.removeItem('otherUserPointsFilter');

        localStorage.removeItem('productsFilterType');
        localStorage.removeItem('coinFilterFrom');
        localStorage.removeItem('coinFilterTo');

        getGratitudeCategories();
        if(desktop) {
            getPointsRating(pointsRatingFilter);
            getAchievementsRating(achievementsRatingFilter);
        } else {
            if (localStorage.getItem('achievementsVisibleBlock') === 'points') {
                getPointsRating(pointsRatingFilter);
            } else if (localStorage.getItem('achievementsVisibleBlock') === 'achievements' || !localStorage.getItem('achievementsVisibleBlock')) {
                getAchievementsRating(achievementsRatingFilter);
            }
        }

    }, []);


    const updatePointsRatingFilter = (fieldName, fieldValue) => {
        if(!desktop){ window.history.pushState(null, null, `?page=1`)};
        setPointsRatingFilter(prevState => ({
            ...prevState, offset: 0,
            [fieldName]: fieldValue.id,
        }));

        if (fieldName === 'time'){localStorage.setItem("pointsRatingFilterTime", fieldValue.id)}
        if(fieldName === 'category'){localStorage.setItem("pointsRatingFilterCategory", fieldValue.id)};
        getPointsRating({
            ...pointsRatingFilter, offset: 0,
            [fieldName]: fieldValue.id,
        });
    };
    const updateAchievementsRatingFilter = (fieldName, fieldValue) => {
        if(!desktop){ window.history.pushState(null, null, `?page=1`)};
        setAchievementsRatingFilter(prevState => ({
            ...prevState, offset: 0,
            [fieldName]: fieldValue.id,
        }));

        if (fieldName === 'time'){localStorage.setItem("achievementsRatingFilterTime", fieldValue.id)};
        getAchievementsRating({
            ...achievementsRatingFilter, offset: 0,
            [fieldName]: fieldValue.id,
        });
    };

    const timeFilterOptions = [
        { id: 1, name: "За все время" },
        { id: 2, name: "За 24 часа" },
        { id: 3, name: "За 7 дней" },
        { id: 4, name: "За 30 дней" },
    ];


    // Для мобильных устройств выводим только 10 запесей включая пользователя
    let modifiedAchievementsRatingsList = achievementsRating;
    if (achievementsRating && achievementsRating.length > 20) {
        let ownIndex = achievementsRating.findIndex(item => item.user.id === ownId);
        if (ownIndex < 10) {
            modifiedAchievementsRatingsList = achievementsRating.slice(0, 10);
        } else {
            modifiedAchievementsRatingsList = [
                ...achievementsRating.slice(0, 9),
                achievementsRating[ownIndex],
            ];
        }
    }


    return (
        <>
            <div className='mt3'></div>
            <div className={styles.infoContainerControls}>
                <div className={styles.slidersContainer}>
                    <button
                        className={activeButton === 'achievements' ? styles.slidersContainerButtonActive : styles.slidersContainerButton}
                        onClick={() => {
                            setActiveButton('achievements');
                            setAchievementsRatingFilter({...achievementsRatingFilter, offset: 0})
                            localStorage.setItem('achievementsVisibleBlock', 'achievements');
                            window.history.pushState(null, null, `?page=1`);
                            getAchievementsRating({...achievementsRatingFilter, offset: 0});
                        }}
                    >
                        Достижений
                    </button>
                </div>
                <div className={styles.slidersContainer}>
                    <button
                        className={activeButton === 'points' ? styles.slidersContainerButtonActive : styles.slidersContainerButton}
                        onClick={() => {
                            setActiveButton('points');
                            setPointsRatingFilter({...pointsRatingFilter, offset: 0})
                            localStorage.setItem('achievementsVisibleBlock', 'points');
                            window.history.pushState(null, null, `?page=1`);
                            getPointsRating({...pointsRatingFilter, offset: 0});
                        }}
                    >
                        Благодаренных
                    </button>
                </div>
            </div>
            <div className={styles.twoColumns}>
                    <div style={{display: desktop ? 'block' : VisBlock === 'achievements' ? 'block' : 'none'}}>
                        <div className={styles.Achievements}>
                            <div className={styles.AchievementsHeader}>
                                <div className='titleH1 mb1'>Достижения</div>
                                <div></div>
                                <SelectFilter
                                    placeholder='За все время'
                                    onSelect={value => {
                                        updateAchievementsRatingFilter("time", value);
                                    }}
                                    options={timeFilterOptions}
                                    selectedValue={
                                        timeFilterOptions.find(
                                            item => item.id === achievementsRatingFilter.time,
                                        ) || {}
                                    }
                                    clearSelect={() => {
                                        updateAchievementsRatingFilter("time", {});
                                    }}
                                />
                            </div>
                            <div className='mt1'></div>
                            <GridTable
                                maxHeight={"960px"}
                                loading={ratingLoading}
                                onClickRow={rowItem => {
                                    history.push(
                                        ownId === rowItem.user.id ? "/" : `/user/${rowItem.user.id}`,
                                    );
                                }}
                                highlightRow={rowItem => {
                                    if (rowItem.user.id === ownId) {
                                        return true;
                                    }
                                    return false;
                                }}
                                dataSource={getAchievementsWithPosition(desktop ? achievementsRating : modifiedAchievementsRatingsList)}
                                columns={[
                                    {
                                        title: "#",
                                        dataIndex: "position",
                                        width: "15%",
                                        textAlign: "center",
                                        render: rowData => {
                                            return rowData.realPosition > 20
                                                ? rowData.realPosition
                                                : rowData.position;
                                        },
                                    },
                                    {
                                        title: "Имя",
                                        dataIndex: "nickname",
                                        render: rowData => {
                                            return (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContentL: "space-between",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className={styles.AchievementsUserAvatar}>
                                                        {rowData.user.avatar ? (
                                                            <img
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    display: "block",
                                                                    objectFit: "cover",
                                                                }}
                                                                src={rowData.user.avatar}
                                                                alt='avatar'
                                                            />
                                                        ) : (
                                                            <UserPlaceholder
                                                                style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    padding: "0.5rem",
                                                                    backgroundColor: "#EBEEF1",
                                                                    borderRadius: "12px",
                                                                    marginRight: "20px",
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    {rowData.user.firstName + " " + rowData.user.lastName ||
                                                    rowData.user.nickname}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        title: "Сколько",
                                        dataIndex: "achievementsCount",
                                        width: "10%",
                                    },
                                ]}
                            />
                        </div>
                    </div>
                <div style={{display: desktop ? 'block' : VisBlock === 'points' ? 'block' : 'none'}}>
                    <div className={styles.Achievements}>
                        <div className='titleH1'>Топ благодаренных</div>
                        <div className='mt1'></div>
                        <div className={styles.AchievementsHeader}>
                            <div>
                                <SelectFilter
                                    placeholder='За все время'
                                    onSelect={value => {
                                        updatePointsRatingFilter("time", value);
                                    }}
                                    options={timeFilterOptions}
                                    selectedValue={
                                        timeFilterOptions.find(
                                            item => item.id === +pointsRatingFilter.time,
                                        ) || {}
                                    }
                                    clearSelect={() => {
                                        updatePointsRatingFilter("time", {});
                                    }}
                                />
                            </div>
                            {pointsCategories.length > 0 ? (
                                <div>
                                    <SelectFilter
                                        placeholder='За что благодарили'
                                        onSelect={value => {
                                            updatePointsRatingFilter("category", value);
                                        }}
                                        options={pointsCategories}
                                        selectedValue={
                                            pointsCategories.find(
                                                item => item.id === +pointsRatingFilter.category,
                                            ) || {}
                                        }
                                        clearSelect={() => {
                                            updatePointsRatingFilter("category", {});
                                        }}
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="mb1"></div>
                        <GridTable
                            maxHeight={"960px"}
                            loading={ratingLoading}
                            highlightRow={rowItem => {
                                if (rowItem.user.id === ownId) {
                                    return true;
                                }
                                return false;
                            }}
                            dataSource={getRatingsWithPosition(pointsRating)}
                            columns={[
                                {
                                    title: "#",
                                    dataIndex: "position",
                                    width: "15%",
                                    textAlign: "center",
                                    render: rowData => {
                                        return (
                                            <Link
                                                to={
                                                    ownId === rowData.user.id ? "/" : `/user/${rowData.user.id}`
                                                }
                                            >
                                                {rowData.realPosition > 20
                                                    ? rowData.realPosition
                                                    : rowData.position}
                                            </Link>
                                        );
                                    },
                                },
                                {
                                    title: "Имя",
                                    dataIndex: "nickname",
                                    width: "60%",
                                    render: rowData => {
                                        return (
                                            <Link
                                                to={
                                                    ownId === rowData.user.id ? "/" : `/user/${rowData.user.id}`
                                                }
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContentL: "space-between",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className={styles.AchievementsUserAvatar}>
                                                        {rowData.user.avatar ? (
                                                            <img
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    display: "block",
                                                                    objectFit: "cover",
                                                                }}
                                                                src={rowData.user.avatar}
                                                                alt='avatar'
                                                            />
                                                        ) : (
                                                            <UserPlaceholder
                                                                style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    padding: "0.5rem",
                                                                    backgroundColor: "#EBEEF1",
                                                                    borderRadius: "12px",
                                                                    marginRight: "20px",
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    {rowData.user.firstName + " " + rowData.user.lastName ||
                                                    rowData.user.nickname}
                                                </div>
                                            </Link>
                                        );
                                    },
                                },
                                {
                                    title: "",
                                    dataIndex: "gratitudeAmount",
                                    render: rowData => {
                                        return (
                                            <Link
                                                to={
                                                    ownId === rowData.user.id ? "/" : `/user/${rowData.user.id}`
                                                }
                                            >
                                                <div style={{ textAlign: "end" }}>
                                                    {rowData.gratitudeAmount}
                                                    {rowData.user.id === ownId ? (
                                                        <BcoinIconWhite
                                                            style={{ marginBottom: -2, marginLeft: 5, display: 'inline-block', verticalAlign: 'bottom' }}
                                                            width={18}
                                                            height={18}
                                                        />
                                                    ) : (
                                                        <BcoinIcon
                                                            style={{ marginBottom: -2, marginLeft: 5, display: 'inline-block', verticalAlign: 'bottom' }}
                                                            width={18}
                                                            height={18}
                                                        />
                                                    )}
                                                </div>
                                            </Link>
                                        );
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ ratings, points, auth }) => ({
    pointsCategories: points.pointsCategories,
    pointsRating: ratings.pointsRating,
    ratingLoading: ratings.loadingPoints,
    ownId: auth.userId,
    achievementsRating: ratings.achievementsRating,
    achievementsRatingCount: ratings.achievementsRatingCount,
});
const mapDispatchToProps = (dispatch, { apiService, filter }) => {
    return bindActionCreators(
        {
            getGratitudeCategories: getGratitudeCategories(apiService, filter),
            getPointsRating: getPointsRating(apiService, filter),
            getAchievementsRating: getAchievementsRating(apiService, filter),
        },
        dispatch,
    );
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Ratings));