import React from "react";
import styles from "./CardTableCell.module.scss";

import { ReactComponent as MoneyIcon } from "../../assets/images/moneyIcon.svg";
import {ReactComponent as DownIcon} from "../../assets/images/down-arrow.svg";
const CardTableCell = ({ cardData = {} }) => {
  return (
      <div className={styles.card}>
         <div className={styles.cardHead}>
             <div className={styles.cardHeadTitle}>{cardData.nickname}</div>
             <div className={styles.cardHeadIcon}>
                 <DownIcon
                     style={{fill: '#5F37FF'}}
                     onClick={() => {}}
                 />
             </div>
         </div>
         <div className={styles.cardBody}>
             <div className={styles.cardBodyRow}>
                 <div className={styles.cardBodyTitle}>Тип товара</div>
                 <div className={styles.cardBodyText}>{cardData.nickname}</div>
             </div>
             <div className={styles.cardBodyRow}>
                 <div className={styles.cardBodyTitle}>Стоимость</div>
                 <div className={styles.cardBodyText}>
                     {cardData.points}
                     <MoneyIcon
                         style={{marginLeft: 4}}
                         width={14}
                         height={14}
                     />
                 </div>
             </div>
             <div className={styles.cardBodyRow}>
                 <div className={styles.cardBodyTitle}>Покупатель</div>
                 <div className={styles.cardBodyText}>{cardData.nickname}</div>
             </div>
             <div className={styles.cardBodyRow}>
                 <div className={styles.cardBodyTitle}>Завершено</div>
                 <div className={styles.cardBodyText}>{cardData.points}</div>
             </div>
         </div>
      </div>
  );
};

export default CardTableCell;
