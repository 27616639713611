import React, {useState, useEffect} from "react";
import styles from "./editUserForm.module.scss";
import {NumInput, Form, SelectFilter, Button} from "../../FormItems";
import {ReactComponent as BlockIcon} from "../../../assets/images/blockIcon.svg";
import {ReactComponent as UserPlaceholder} from "../../../assets/images/userPlaceholder.svg";
import {ReactComponent as MoneyIcon} from "../../../assets/images/moneyIcon.svg";
import {TextInput} from "../../FormItems";
import CheckBox from "../../lib/CheckBox";

const EditUserForm = ({onSave, onRemove, onBackward, userData = {}}) => {
    const [editUserForm, setEditUserForm] = useState({...userData});
    const [fullName, setFullName] = useState(null);
    const [fullNameGenitive, setFullNameGenitive] = useState(null);

    useEffect(() => {
        setEditUserForm({...userData});
    }, [userData]);

    const updateEditUserForm = (fieldName, fieldValue) => {
        setEditUserForm(prevState => ({...prevState, [fieldName]: fieldValue}));
    };

    const onSexChange = sex => {
        updateEditUserForm("sex", sex === "man" ? 1 : 2);
    };

    const onPlaceTypeChange = placeType => {
        updateEditUserForm("placeType", placeType === "office" ? 1 : 2);
    };


    const onNameChange = (value, isGenitive) => {
        isGenitive ? setFullNameGenitive(value) : setFullName(value);
    };

    return (
        <div className={styles.userCard}>
            <div className={styles.header}>
                {editUserForm.avatar ? (
                    <img src={editUserForm.avatar} alt='avatar'/>
                ) : (
                    <UserPlaceholder
                        width='40px'
                        height='40px'
                        style={{
                            borderRadius: 8,
                            backgroundColor: "#EBEEF1",
                            padding: "0.3rem",
                        }}
                    />
                )}
                <div className={styles.userRoleChanger}>
                    <SelectFilter
                        onSelect={value => {
                            updateEditUserForm("isSuperuser", value.id);
                        }}
                        selectedValue={
                            editUserForm.isSuperuser
                                ? {id: true, name: "Администратор"}
                                : {id: false, name: "Пользователь"}
                        }
                        options={[
                            {id: false, name: "Пользователь"},
                            {id: true, name: "Администратор"},
                        ]}
                    />
                </div>
            </div>
            <Form onSubmit={e => {
            }}>
                <div className={styles.userDataBlock}>
                    <label>Именительный падеж</label>
                    <TextInput
                        placeholder={"Именительнный падеж"}
                        value={fullName !== null ? fullName : (editUserForm.firstName && editUserForm.lastName) ? editUserForm.firstName + ' ' + editUserForm.lastName : ''}
                        onChange={value => onNameChange(value, false)}
                        onBlur={value => {
                            const [firstName, lastName] = value.split(" ");
                            updateEditUserForm("firstName", firstName);
                            updateEditUserForm("lastName", lastName);
                        }}
                    />
                    <label>Родительный падеж</label>
                    <TextInput
                        placeholder={"Родительный падеж"}
                        value={fullNameGenitive !== null ? fullNameGenitive : (editUserForm.firstNameGenitive && editUserForm.lastNameGenitive) ? editUserForm.firstNameGenitive + ' ' + editUserForm.lastNameGenitive : ''}
                        onChange={value => onNameChange(value, true)}
                        onBlur={value => {
                            const [firstNameGenitive, lastNameGenitive] = value.split(" ");
                            updateEditUserForm("firstNameGenitive", firstNameGenitive);
                            updateEditUserForm("lastNameGenitive", lastNameGenitive);
                        }}
                    />
                    <div className={styles.checkboxRow}>
                        <CheckBox
                            id={`${editUserForm.id}-sex_checkbox-1MALE`}
                            label='Мужчина'
                            checked={editUserForm.sex === 1}
                            onChange={({target: {checked}}) =>
                                onSexChange("man", checked)
                            }
                        />
                        <CheckBox
                            id={`${editUserForm.id}-sex_checkbox-2FEMALE`}
                            label='Женщина'
                            checked={editUserForm.sex === 2}
                            onChange={({target: {checked}}) =>
                                onSexChange("woman", checked)
                            }
                        />
                        <CheckBox
                            id={`${editUserForm.id}-placeType_checkbox-OFFICE`}
                            label='Офис'
                            checked={editUserForm.placeType === 1}
                            onChange={({target: {checked}}) =>
                                onPlaceTypeChange("office", checked)
                            }
                        />
                        <CheckBox
                            id={`${editUserForm.id}-placeType_checkbox-REMOTE`}
                            label='Удаленка'
                            checked={editUserForm.placeType === 2}
                            onChange={({target: {checked}}) =>
                                onPlaceTypeChange("remote", checked)
                            }
                        />
                    </div>
                </div>
                <div className={styles.flex}>
                    <NumInput
                        label='Баллов в неделю'
                        labelPosition={"left"}
                        value={editUserForm.maxPoints}
                        placeholder={1}
                        maxValue={9999999}
                        minValue={0}
                        width={"84px"}
                        onChange={value => {
                            updateEditUserForm("maxPoints", value);
                        }}
                    />
                    <div className="ml1"></div>
                    <NumInput
                        label='Текущий баланс'
                        labelPosition={"left"}
                        value={editUserForm.points}
                        placeholder={1}
                        maxValue={9999999}
                        minValue={0}
                        width={"84px"}
                        onChange={value => {
                            updateEditUserForm("points", value);
                        }}
                    />
                </div>
                <div className='mt2'></div>
                <div
                    className={styles.editUserFormButton}
                    onClick={() => {
                        updateEditUserForm("isBlocked", !editUserForm.isBlocked);
                    }}
                >
                    {!editUserForm.isBlocked ? (
                        <div className={styles.block}>
                            <BlockIcon/> Заблокировать
                        </div>
                    ) : (
                        <div className={styles.unblock}>
                            <BlockIcon/> Разблокировать
                        </div>
                    )}
                    <Button
                        style={{}}
                        label='Применить'
                        handleClick={() => {
                            onSave(editUserForm);
                        }}
                    />
                </div>
            </Form>
        </div>
    );
};

export default EditUserForm;
