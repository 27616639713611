import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../hoc";
import compose from "../../../utils/compose";
import {
  getAllProjectsForAdmin,
  handleCreateProject,
  handleEditProject,
  handleRemoveProject,
} from "../../../redux/projects/actions";
import { searchUsersByName } from "../../../redux/search/actions";

import styles from "./ProjectsTab.module.scss";
import { GridTable } from "../../Tables";
import { Button, Search } from "../../FormItems";
import EditProjectForm from "../EditProjectForm";
import { ReactComponent as PlaceholderCardImage } from "../../../assets/images/PlaceholderCardImage.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/editIcon.svg";
import { ReactComponent as AddIcon } from "../../../assets/images/addIcon.svg";
import Pagination from "../../Tables/Pagination";
import getUrlParam from "../../../utils/getUrlParam";
import {ReactComponent as BreadcrumbsBlockArrow} from "../../../assets/images/breadcrumbsBlockArrow.svg";


const ProjectsTab = ({
  projects,
  projectsLoading,
  projectsCount,
  getAllProjectsForAdmin,
  handleCreateProject,
  handleEditProject,
  handleRemoveProject,
  searchUsersByName,
}) => {
    const desktop = window.innerWidth < 768 ? false : true;
    let urlParam = getUrlParam('page');
    const [filter, setFilter] = useState({
        limit: desktop ? 9999 : 8,
        offset: (urlParam ? urlParam - 1 : 0) * (desktop ? 9999 : 8),
    });
  const [selectedRow, setSelectedRow] = useState("");
  useEffect(() => {
    getAllProjectsForAdmin(filter);
  }, []);


  return (
    <div className={styles.ProjectsTab}>
      { desktop || typeof selectedRow !== "object" ?
          <div className={styles.tableBlock}>
            <div className='titleH1'>Список проектов ({projectsCount})</div>
            <div className='mt2'></div>
            <div className={styles.twoFlexColumns}>
              <div style={{ width: '100%' }}>
                <Search
                  label='Название проекта'
                  placeholder='Название проекта'
                  onSelect={value => {}}
                  searchResults={[]}
                  requestStartSumbolIndex={0}
                  searchRequest={val => {
                    getAllProjectsForAdmin({ name: val });

                    setFilter(oldFilter => ({ ...oldFilter, name: val }));
                  }}
                  selectedValue={{}}
                />
              </div>
              <Button
                style={{ height: "48px", width: " 48px", marginTop: 18,  marginLeft: 18, padding: 10 }}
                label={<AddIcon />}
                handleClick={() => {
                  searchUsersByName({ limit: 99999 });
                  setSelectedRow({});
                }}
              />
            </div>
            <GridTable
              loading={projectsLoading}
              differentRowColor={true}
              onClickRow={rowData => {
                window.history.pushState(null, null, `?page=1`);
                setSelectedRow(rowData);
              }}
              highlightRow={rowData => rowData.id === selectedRow.id}
              dataSource={projects}
              columns={[
                {
                  title: "",
                  dataIndex: "name",
                  render: rowData => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {rowData.image ? (
                            <div
                              style={{
                                width: 36,
                                height: 36,
                                borderRadius: 8,
                                display: "flex",
                                overflow: "hidden",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "auto",
                                }}
                                src={window.location.origin + rowData.image.image}
                                alt='project'
                              />
                            </div>
                          ) : (
                            <PlaceholderCardImage
                              width='30px'
                              height='30px'
                              style={{
                                borderRadius: 8,
                              }}
                            />
                          )}
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: 500,
                              marginLeft: 10,
                            }}
                          >
                            {rowData.name}
                          </div>
                        </div>
                        <EditIcon
                          onClick={() => {
                            setSelectedRow(rowData);
                          }}
                          style={{
                            borderRadius: 6,
                            boxShadow: "0px 5px 19px -10px rgba(0, 0, 0, 0.75)",
                          }}
                        />
                      </div>
                    );
                  },
                },
              ]}
            />
              {!desktop &&  projectsCount > filter.limit ? (
                  <Pagination
                      total={projectsCount}
                      offset={(urlParam ? (urlParam - 1) : filter.offset) * filter.limit}
                      limit={filter.limit}
                      onChange={newPage => {
                          setFilter({...filter, offset: (newPage - 1) * filter.limit })
                          getAllProjectsForAdmin( { ...filter, offset: (newPage - 1) * filter.limit, limit: filter.limit })
                      }}
                  />
              ) : (
                  ""
              )}
          </div>
          : ''
      }
      {typeof selectedRow === "object" ? (
        <>
          {!desktop ?
              <div className={styles.breadcrumbsBlock}>
            <span className={styles.breadcrumbsArrow}>
              <BreadcrumbsBlockArrow
                  style={{ marginLeft: 5, width: '12px', height: '12px', fill: '#5f37ff', transform: 'rotate(180deg)' }}
                  width={8}
                  height={12}
              />
            </span>
                  <div
                      className={styles.breadcrumb}
                      onClick={() => {
                          setSelectedRow("");
                          setFilter({...filter,  name: ''});
                          getAllProjectsForAdmin({...filter,  name: ''});
                          window.history.pushState(null, null, `?page=1`);
                        }
                      }
                  >
                      Настройки
                  </div>
              </div> : ''
          }
          <div className={styles.formBlock}>
            <div className='mt1'></div>
            <EditProjectForm
              projectData={selectedRow || {}}
              onSave={projectData => {
                selectedRow.id
                  ? handleEditProject(projectData, filter)
                  : handleCreateProject(projectData, filter);
                setSelectedRow("");
                setFilter({...filter,  name: ''});
               }}
              onRemove={
                selectedRow.id
                  ? projectData => {
                      handleRemoveProject(projectData.id, filter);
                      setSelectedRow("");
                      setFilter({...filter,  name: ''});
                    }
                  : ""
              }
            />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = ({ projects }) => ({
  projects: projects.projects,
  projectsLoading: projects.loading,
  projectsCount: projects.projectsCount,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, filter, projectData, projectFilter, projectId },
) => {
  return bindActionCreators(
    {
      getAllProjectsForAdmin: getAllProjectsForAdmin(apiService, projectFilter),
      handleRemoveProject: handleRemoveProject(
        apiService,
        projectId,
        projectFilter,
      ),
      handleCreateProject: handleCreateProject(
        apiService,
        projectData,
        projectFilter,
      ),
      handleEditProject: handleEditProject(
        apiService,
        projectData,
        projectFilter,
      ),

      searchUsersByName: searchUsersByName(apiService, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(ProjectsTab);
