import React from "react";

import "./PaginationOne.scss";
import { ReactComponent as ArrowLeft } from "../../../assets/images/ArrowIcon.svg";
import { ReactComponent as ArrowRight} from "../../../assets/images/ArrowIcon.svg";

const getPaginationButtonsByCurrent = (
    currentPage,
    lastPageNumber,
    maxElements,
) => {
    let pagCount = 0;
    let pagination = [];

    // Добавляем элементы идущие перед текущим
    for (
        let i = currentPage - Math.floor(maxElements / 2);
        i < currentPage;
        i++
    ) {
        if (i >= 1 && i < lastPageNumber) {
            pagination.push(i);
        }
    }
    pagCount = pagination.length;

    // Добавляем текущий элемент если он не выводится последним
    if (lastPageNumber !== currentPage) {
        pagination.push(currentPage);
        pagCount = pagination.length;
    }

    // Добавляем элементы идущие после текущего
    for (
        let i = currentPage + 1;
        i < currentPage + maxElements - pagCount + 1;
        i++
    ) {
        if (i < lastPageNumber && i >= 1) {
            pagination.push(i);
        }
    }
    pagCount = pagination.length;

    // Если нехватает страниц добавляем еще в начало
    if (pagination.length < maxElements) {
        // Высчитываем какой элемент должен быть первым
        let firstItem = pagination[0] - (maxElements - pagCount);
        for (let i = pagination[0] - 1; i >= firstItem; i--) {
            if (i >= 1) {
                pagination.unshift(i);
            }
        }
        pagCount = pagination.length - pagCount;
    }

    return pagination;
};

const PaginationOne = ({
                        total,
                        offset,
                        limit,
                        maxElements = 2,
                        onChange = () => {},
                    }) => {
    let lastPageNumber = Math.ceil(total / limit);
    let currentPage = Math.ceil(offset / limit) + 1;
    let pagItems = getPaginationButtonsByCurrent(
        currentPage,
        lastPageNumber,
        maxElements,
    );

    return (
        <div className='Pagination'>
            <button
                className='pagButtonArrow pagButtonArrow__prev'
                onClick={(e) =>
                {
                    if ((currentPage - 1) < 1) {
                        e.preventDefault();
                    } else {
                        onChange(currentPage - 1);
                        window.history.pushState(null, null, `?pageOne=${currentPage - 1}`);
                    }
                }
                }
            >
                <ArrowLeft />
            </button>
            { currentPage > 2 && lastPageNumber > 5 ?
                <>
                    <button
                        key={"pagButton" + 1}
                        onClick={() => {
                            onChange(1);
                            window.history.pushState(null, null, `?pageOne=1`);
                        }
                        }
                        className={1 === currentPage ? "active" : ""}
                    >
                        1
                    </button>
                    {currentPage > 3 && lastPageNumber > 5? <div className='pagButtonDot'>...</div> : ''}
                </>
                : ''
            }
            {pagItems.map(pagPageNum => {
                return (
                    <button
                        key={"pagButton" + pagPageNum}
                        onClick={() => {
                            onChange(pagPageNum);
                            window.history.pushState(null, null, `?pageOne=${pagPageNum}`);
                        }
                        }
                        className={pagPageNum === currentPage ? "active" : ""}
                    >
                        {pagPageNum}
                    </button>
                );
            })}
            {(lastPageNumber > 4) && (currentPage < (lastPageNumber - 1)) ? <div className='pagButtonDot'>...</div> : ''}
            <button
                className={lastPageNumber === currentPage ? "active" : ""}
                onClick={() => {
                    onChange(lastPageNumber);
                    window.history.pushState(null, null, `?pageOne=${lastPageNumber}`);
                }
                }
            >
                {lastPageNumber}
            </button>
            <button
                className='pagButtonArrow pagButtonArrow__next'
                onClick={(e) =>
                {
                    if (currentPage  === lastPageNumber) {
                        e.preventDefault();
                    } else {
                        onChange(currentPage + 1);
                        window.history.pushState(null, null, `?pageOne=${currentPage + 1}`);
                    }
                }
                }
            >
                <ArrowRight />
            </button>
        </div>
    );
};

export default PaginationOne;
