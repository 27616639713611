import React, { useState, useEffect } from "react";
import styles from "./search.module.scss";
import { ReactComponent as SearchIcon } from "../../../assets/images/searchIcon.svg";

import debounce from 'lodash.debounce';

import {
    ReactComponent as PlaceholderProfileIcon
} from "../../../assets/images/userPlaceholder.svg";


const Search = ({
    minWidth = "auto",
    label,
    placeholder,
    onSelect,
    selectedValue = {},
    searchResults = [],
    searchRequest,
    requestStartSumbolIndex = 0,
}) => {
    const [searchValue, setSearchValue] = useState(
        selectedValue.name ? selectedValue.name : "",
    );

    const [visibleList, setVisibleList] = useState(false)

    useEffect(() => {
        if (selectedValue.name === "null") {
            onSelect({});
            setSearchValue("");
        }
    }, [selectedValue]);

    useEffect(() => {
        setSearchValue("");
    }, []);

    const onSearchChange = debounce((value) => {
        searchRequest(value)
    }, 300);

    return (
        <div className={styles.Search} style={{ minWidth }}>
            {label ? <label>{label}</label> : ""}
            <div className={styles.inputBlock}>
                <input
                    value={searchValue}
                    placeholder={placeholder}
                    onChange={e => {
                        if (e.target.value.length >= requestStartSumbolIndex) {
                            onSearchChange(e.target.value)
                        }
                        setSearchValue(e.target.value);
                    }}
                    onFocus={() => {
                        onSearchChange('')
                        setVisibleList(true)
                    }}
                    onBlur={() => {
                        setTimeout(() => {
                            setVisibleList(false)
                        }, 300);
                    }}
                />
                <SearchIcon width='20px' height='20px' />
            </div>
            <ul
                style={
                    visibleList
                        ? {}
                        : { visibility: "hidden", opacity: 0 }
                }
            >
                {searchResults.map(item => {
                    return (
                        <li
                            key={"searchUserList" + item.id}
                            onClick={() => {
                                onSelect(item);
                                // setSearchValue(
                                //     item.firstName + " " + item.lastName || item.nickname,
                                // );
                                setSearchValue('')
                                // Костыль что бы очистить список
                                searchRequest("awdawdawdawldawdawdawdawdawdawdawd");
                            }}
                        >
                            {item.avatar ? (
                                <div className={styles.searchUserImg}>
                                    <img src={item.avatar} alt='avatar' />
                                </div>
                            ) : (
                                <div className={styles.searchUserImg}>
                                    <PlaceholderProfileIcon />
                                </div>
                            )}
                            {item.firstName + " " + item.lastName || item.nickname}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Search;

