const FETCH_ACHIEVEMENTS_RATING_REQUEST = "FETCH_ACHIEVEMENTS_RATING_REQUEST";
const FETCH_ACHIEVEMENTS_RATING_FAILURE = "FETCH_ACHIEVEMENTS_RATING_FAILURE";
const FETCH_ACHIEVEMENTS_RATING_SUCCESS = "FETCH_ACHIEVEMENTS_RATING_SUCCESS";

const FETCH_POINTS_RATING_REQUEST = "FETCH_POINTS_RATING_REQUEST";
const FETCH_POINTS_RATING_FAILURE = "FETCH_POINTS_RATING_FAILURE";
const FETCH_POINTS_RATING_SUCCESS = "FETCH_POINTS_RATING_SUCCESS";

export {
  FETCH_ACHIEVEMENTS_RATING_REQUEST,
  FETCH_ACHIEVEMENTS_RATING_FAILURE,
  FETCH_ACHIEVEMENTS_RATING_SUCCESS,
  FETCH_POINTS_RATING_REQUEST,
  FETCH_POINTS_RATING_FAILURE,
  FETCH_POINTS_RATING_SUCCESS,
};
