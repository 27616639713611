import React from "react";
import styles from "./button.module.scss";

const Button = ({
  label = "",
  bgColor = "",
  size = "",
  disabled = false,
  style = {},
  handleClick = () => {},
}) => {
  return (
    <button
      disabled={disabled}
      type='submit'
      style={style}
      className={styles.Button}
      onClick={() => {
        handleClick();
      }}
    >
      {label}
    </button>
  );
};

export default Button;
