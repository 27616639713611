import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../hoc";
import compose from "../../../utils/compose";

import { getProfile, handleLogout } from "../../../redux/auth/actions";
import styles from "./userInfo.module.scss";
import { ReactComponent as PlaceholderProfileIcon } from "../../../assets/images/userPlaceholder.svg";
import { ReactComponent as BcoinIcon } from "../../../assets/images/bcoin.svg";
import { ReactComponent as MoneyIcon } from "../../../assets/images/moneyIcon.svg";
import { ReactComponent as StoreIcon } from "../../../assets/images/storeIcon.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/images/logout.svg";
import { ReactComponent as SettingsPageMobileNav } from "../../../assets/images/SettingsPageMobileNav.svg";

const UserInfo = ({
  history,
  getProfile,
  handleLogout,
  username,
  points,
  coins,
  avatar,
  isSuperuser,
}) => {

  useEffect(() => {
    getProfile();
  }, []);
  const [focus, setFocus] = useState(false);

  const dropdown = useRef();
  return (
    <div className={styles.UserInfo}>
      <div className={styles.avatar}>
        {avatar ? (
          <img src={avatar} alt='user avatar' />
        ) : (
          <PlaceholderProfileIcon
            style={{ padding: 5 }}
            width={26}
            height={26}
          />
        )}
      </div>
      <div className={styles.userInfoBlock}>
        <div className={styles.username}>@{username}</div>
        <div className={styles.balance}>
          {points}
          <BcoinIcon
            style={{ marginBottom: -5, marginLeft: 5, marginRight: 10 }}
            width={20}
            height={20}
          />
          {coins == null ? 0 : coins}
          <MoneyIcon
            style={{ marginBottom: -5, marginLeft: 5 }}
            width={20}
            height={20}
          />
        </div>
      </div>
      <div
        ref={dropdown}
        tabIndex='1'
        onClick={() => {
          if (focus) {
            dropdown.current.blur();
          } else {
            setFocus(true);
          }
        }}
        onBlur={e => {
          e.preventDefault();
          setFocus(false);
        }}
        className={styles.dropdownWrapper}
      >
        <ul className={styles.dropdown}>
          <li
            onClick={() => {
              history.push("/purchase");
            }}
          >
            <StoreIcon
              style={{ marginBottom: -5, marginRight: 20 }}
              width={20}
              height={20}
            />
            Мои покупки
          </li>
          {isSuperuser ? (
            <li
              onClick={() => {
                history.push("/settings");
              }}
            >
              <SettingsPageMobileNav
                width={20}
                height={20}
              />
              Настройки
            </li>
          ) : (
            ""
          )}
          <li
            onClick={() => {
              handleLogout();
            }}
          >
            <LogoutIcon width={20} height={20} />
            Выход
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({ ...auth });
const mapDispatchToProps = (dispatch, { apiService }) => {
  return bindActionCreators(
    {
      getProfile: getProfile(apiService),
      handleLogout: handleLogout(),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(UserInfo));
