import React, {useEffect, useState} from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../hoc";
import compose from "../../utils/compose";

import styles from "./purchaseDetail.module.scss";
import LoadScreen from "../LoadScreen";
import PlaceholderCardImage  from "../../assets/images/PlaceholderCardImage.svg";
import { ReactComponent as BreadcrumbsBlockArrow } from "../../assets/images/breadcrumbsBlockArrow.svg";
import { ReactComponent as MoneyIcon } from "../../assets/images/moneyIcon.svg";
import {AlertMessage, Button, NumInput} from "../FormItems";
import Modal from "../Modal";
import {buyCrowdfundingProduct, buyProduct, getCrowdfundingById, getProductById} from "../../redux/store/actions";
import getFormattedDate from "../../utils/getFormattedDate";
import showMessage from "../showMessage";
import getUrlParam from "../../utils/getUrlParam";

const PurchaseDetail = ({
                            match,
                            history,
                            product,
                            crowdfunding,
                            loading,
                            getProduct,
                            getCrowdfunding,
                            buyProduct,
                            buyCrowdfundingProduct
}) => {
    const [showCrowdfundingAlertMessage, setShowCrowdfundingAlertMessage] = useState(false);
    const [showProductAlertMessage, setShowProductAlertMessage] = useState(false);
    const [crowdfundingPrice, setCrowdfundingPrice] = useState(0);

    useEffect(() => {
        if (match.params.type === 'products') {
            getProduct(match.params.id);
        } else {
            getCrowdfunding(match.params.id);
        }

    }, []);
  if (loading) {
    return <LoadScreen />;
  }

  return (
    <div className={styles.PurchaseDetail}>
        <Modal
            maxWidth={288}
            minWidth={288}
            displayModal={showCrowdfundingAlertMessage}
            onCloseModal={() => setShowCrowdfundingAlertMessage(false)}
        >
            <AlertMessage
                key={'CrowdfundingAlertMessage'}
                text={'Подтвердите перевод'}
                buttonText={'Пожертвовать'}
                onProcess={() => {
                    if (crowdfundingPrice !== 0) {
                        buyCrowdfundingProduct(match.params.id, crowdfundingPrice, () => history.push("/purchase"))
                    } else {
                        showMessage({status: 'error', text: 'Нужно пожертововать хоть сколько-нибудь Т_Т!'})
                    }
                }}
            />
        </Modal>
        <Modal
            maxWidth={288}
            minWidth={288}
            displayModal={showProductAlertMessage}
            onCloseModal={() => setShowProductAlertMessage(false)}
        >
            <AlertMessage
                key={'ProductAlertMessage'}
                text={'Подтвердите оплату товара'}
                type={'products'}
                buttonText={'Купить'}
                onProcess={(productCommentary) => {
                    buyProduct(match.params.id, productCommentary, () => history.push("/store"))
                }}
            />
        </Modal>
      <div className='mt4'></div>
      <div className={styles.breadcrumbsBlock}>
        <Link to={"/purchase"} className={styles.breadcrumb}>
          Список покупок
        </Link>
        <span className={styles.breadcrumbsArrow}>
          <BreadcrumbsBlockArrow
              style={{ marginLeft: 5, marginBottom: -2 }}
              width={8}
              height={12}
          />
        </span>
        <div className={styles.breadcrumbActive}>Товар</div>
      </div>
      <div className='mb4'></div>
      <div className={styles.purchaseInfo}>
        <div className={styles.cardPicture} style={{
          backgroundImage: match.params.type === 'products' ?
            `url(${product.photo !== undefined && product.photo !== null ? window.location.origin + product.photo.image :  PlaceholderCardImage })` :
            `url(${crowdfunding.photo !== undefined && crowdfunding.photo !== null ? window.location.origin + crowdfunding.photo.image : PlaceholderCardImage })`
        }}>
          <div className={styles.cardPictureLabel}>{match.params.type === 'products' ? 'Приобретено' : 'Пожертвовано'}
            <br/>
            {match.params.type === 'products' ?
                (( getUrlParam('data') !==null && getUrlParam('data') !== 0 ) ? getFormattedDate(getUrlParam('data')) : '--') :
                ((getUrlParam('data') !==null && getUrlParam('data') !== 0 ) ? getFormattedDate(getUrlParam('data')) : '--')
            }
          </div>
        </div>
        <div className={styles.purchaseText}>
          <div className={styles.purchaseTitle}>{product.title || crowdfunding.title}</div>
          <div className={styles.purchaseDescription}>
            {product.description || crowdfunding.description}
          </div>
          {match.params.type == 'products' ?
            <>
              <div className={styles.purchaseDescriptionPrice}>
                {product.total_price}
                <MoneyIcon
                  style={{ marginBottom: -2, marginLeft: 5 }}
                  width={20}
                  height={20}
                />
              </div>
              {/*<div className="mb15"></div>*/}
              {/*<Button*/}
              {/*  style={{height: 48, width: 144 }}*/}
              {/*  label='Повторить'*/}
              {/*  handleClick={() => {*/}
              {/*      setShowProductAlertMessage(true)*/}
              {/*  }}*/}
              {/*/>*/}
            </>  :
              <div className={styles.offerCountProgressDesc}>
                <div className={styles.offerCountProgressDesc}>
                  <div className={styles.offerCountProgressCoin}>
                    {crowdfunding.collected}
                      <MoneyIcon
                          style={{ marginBottom: -2, marginLeft: 5 }}
                          width={20}
                          height={20}
                      />
                  </div>
                  <div className={styles.offerCountProgressCoinSubtitle}>собрано из {crowdfunding.total_price} {crowdfunding.collecting_until_dt !== null ? ' (срок до ' + getFormattedDate(Date.parse(new Date(crowdfunding.collecting_until_dt))) + ')' : ''}</div>
                </div>
                <div className={styles.offerCountProgress}>
                  <div className={styles.offerCountProgressBar}
                       style={{
                           width: (crowdfunding.total_price - crowdfunding.left)*100/crowdfunding.total_price <= 1 ?
                               Math.ceil((crowdfunding.total_price - crowdfunding.left)*100/crowdfunding.total_price) + '%' :
                               Math.floor((crowdfunding.total_price - crowdfunding.left)*100/crowdfunding.total_price) + '%'
                       }}
                  >
                      <div className={styles.offerCountProgressBarText}>
                          {
                              (crowdfunding.total_price - crowdfunding.left)*100/crowdfunding.total_price <= 1 ?
                                  Math.ceil((crowdfunding.total_price - crowdfunding.left)*100/crowdfunding.total_price) + '%' :
                                  Math.floor((crowdfunding.total_price - crowdfunding.left)*100/crowdfunding.total_price) + '%'
                          }
                      </div>
                  </div>
                </div>
                <div className={styles.NumInputContainer}>
                  {crowdfunding.left !== 0 ?
                    <>
                      <NumInput
                          label='Сумма'
                          labelPosition={"left"}
                          value={crowdfundingPrice}
                          maxValue={9999999}
                          minValue={0}
                          width={"110px"}
                          onChange={value => {
                              setCrowdfundingPrice(value);
                          }}
                          icon = {<MoneyIcon style={{ marginLeft: 5, marginTop: 1 }} width={20} height={20} />}
                      />
                      <Button
                        disabled={crowdfundingPrice == 0}
                        style={{height: 48, marginLeft: 20 }}
                        label='Пожертвовать'
                        handleClick={() => {
                            if (crowdfundingPrice > crowdfunding.left) {
                                showMessage({status: 'error', text: "Величина пожертвования не должна превышать стоимость товара!"})
                            } else {
                                setShowCrowdfundingAlertMessage(true)
                            }
                        }}
                      />
                    </>
                        : ''
                  }
                </div>
              </div>
            }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ store }) => ({
    product: store.product,
    crowdfunding: store.crowdfunding,
    loading: store.loading,
    error: store.error,
});
const mapDispatchToProps = (dispatch, { apiService, productId, productCost, productCommentary, callback }) => {
    return bindActionCreators(
        {
            getCrowdfunding: getCrowdfundingById(apiService, productId),
            getProduct: getProductById(apiService, productId),
            buyProduct: buyProduct(apiService, productId, productCommentary, callback),
            buyCrowdfundingProduct: buyCrowdfundingProduct(apiService, productId, productCost, callback),
        },
        dispatch,
    );
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(PurchaseDetail));