import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_MORE_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_BY_ID_REQUEST,
  FETCH_PRODUCTS_BY_ID_FAILURE,
  FETCH_PRODUCTS_BY_ID_SUCCESS,
  FETCH_CROWDFUNDING_BY_ID_REQUEST,
  FETCH_CROWDFUNDING_BY_ID_FAILURE,
  FETCH_CROWDFUNDING_BY_ID_SUCCESS,
  FETCH_BUY_PRODUCT_REQUEST,
  FETCH_BUY_PRODUCT_FAILURE,
  FETCH_BUY_PRODUCT_SUCCESS,
  FETCH_CREATE_PRODUCT_REQUEST,
  FETCH_CREATE_PRODUCT_FAILURE,
  FETCH_CREATE_PRODUCT_SUCCESS,
  FETCH_UPDATE_PRODUCT_REQUEST,
  FETCH_UPDATE_PRODUCT_FAILURE,
  FETCH_UPDATE_PRODUCT_SUCCESS,
  FETCH_REMOVE_PRODUCT_REQUEST,
  FETCH_REMOVE_PRODUCT_FAILURE,
  FETCH_REMOVE_PRODUCT_SUCCESS,
} from './types';

export const getProductsRequest = () => {
  return { type: FETCH_PRODUCTS_REQUEST };
};
export const getProductsFailure = err => {
  return { type: FETCH_PRODUCTS_FAILURE, payload: err };
};
export const getProductsSuccess = products => {
  return { type: FETCH_PRODUCTS_SUCCESS, payload: products };
};
export const getProductsMoreSuccess = payload => {
  return { type: FETCH_MORE_PRODUCTS_SUCCESS, payload }
}

export const getProductByIdRequest = () => {
  return { type: FETCH_PRODUCTS_BY_ID_REQUEST };
};
export const getProductByIdFailure = err => {
  return { type: FETCH_PRODUCTS_BY_ID_FAILURE, payload: err };
};
export const getProductByIdSuccess = product => {
  return { type: FETCH_PRODUCTS_BY_ID_SUCCESS, payload: product };
};

export const getCrowdfundingByIdRequest = () => {
  return { type: FETCH_CROWDFUNDING_BY_ID_REQUEST };
};
export const getCrowdfundingByIdFailure = err => {
  return { type: FETCH_CROWDFUNDING_BY_ID_FAILURE, payload: err };
};
export const getCrowdfundingByIdSuccess = crowdfunding => {
  return { type: FETCH_CROWDFUNDING_BY_ID_SUCCESS, payload: crowdfunding };
};

export const buyProductRequest = () => {
  return { type: FETCH_BUY_PRODUCT_REQUEST };
};
export const buyProductFailure = err => {
  return { type: FETCH_BUY_PRODUCT_FAILURE, payload: err };
};
export const buyProductSuccess = () => {
  return { type: FETCH_BUY_PRODUCT_SUCCESS };
};

export const createProductRequest = () => {
  return { type: FETCH_CREATE_PRODUCT_REQUEST };
};
export const createProductFailure = err => {
  return { type: FETCH_CREATE_PRODUCT_FAILURE, payload: err };
};
export const createProductSuccess = () => {
  return { type: FETCH_CREATE_PRODUCT_SUCCESS };
};
export const updateProductRequest = () => {
  return { type: FETCH_UPDATE_PRODUCT_REQUEST };
};
export const updateProductFailure = err => {
  return { type: FETCH_UPDATE_PRODUCT_FAILURE, payload: err };
};
export const updateProductSuccess = () => {
  return { type: FETCH_UPDATE_PRODUCT_SUCCESS };
};
export const removeProductRequest = () => {
  return { type: FETCH_REMOVE_PRODUCT_REQUEST };
};
export const removeProductFailure = err => {
  return { type: FETCH_REMOVE_PRODUCT_FAILURE, payload: err };
};
export const removeProductSuccess = () => {
  return { type: FETCH_REMOVE_PRODUCT_SUCCESS };
};