import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withApiService } from '../hoc';
import compose from '../../utils/compose';
import { searchUsersInHeader } from '../../redux/search/actions';

import styles from './header.module.scss';
import Nav from './Nav';
import UserInfo from './UserInfo';
import NavMobile from './NavMobile';
import { Search } from '../FormItems';

const Header = ({ history, searchUsersList, searchUsersInHeader }) => {
  const [headerSearchVal, setHeaderSearchVal] = useState({});

  return (
  <>
     <div className={styles.Header}>
      <div className={styles.logo}>
        <Link to={'/'}>
          The best
          <br /> of Us
        </Link>
      </div>

      <Nav />
      <NavMobile />
      <div className={styles.UserInfoBlock}>
        <div className={styles.searchUsers}>
          <Search
            placeholder='Поиск пользователей'
            onSelect={value => {
              if (value.id) {
                history.push(`/user/${value.id}`);
                setTimeout(() => {
                  setHeaderSearchVal({ name: 'null' });
                }, 200);
              }
            }}
            searchResults={searchUsersList}
            searchRequest={val => {
              searchUsersInHeader({ name: val });
            }}
            selectedValue={headerSearchVal}
          />
        </div>
        <UserInfo />
      </div>
    </div>
  </>
  );
};

const mapStateToProps = ({ search }) => ({
  searchUsersList: search.usersInHeader,
});
const mapDispatchToProps = (dispatch, { apiService, filter }) => {
  return bindActionCreators(
    {
      searchUsersInHeader: searchUsersInHeader(apiService, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Header));
