import React, {useEffect} from "react";

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {demoLogin} from "../../redux/auth/actions";
import {withApiService} from "../hoc";
import compose from "../../utils/compose";
import {withRouter} from "react-router-dom";
import styles from "./auth.module.scss";
import {ReactComponent as AuthImage} from "../../assets/images/AuthImage.svg";
import LoadScreen from "../LoadScreen";

const Auth = ({demoLogin, location, loading}) => {
    useEffect(() => {
        demoLogin();
    }, []);

    if (loading) {
        return <LoadScreen/>;
    }

    return (
        <div className={styles.Auth}>
            <div className={styles.AuthForm}>
                <div className={styles.titleBlock}>
                    <div className={styles.title}>
                        The Best <br/> of Us
                    </div>
                    <div className={styles.subTitle}>Онлайн-сервис достижений</div>
                </div>
                <div className='mb3'></div>
                <div className={styles.AuthFooter}>
                    <div className={styles.footerLinks}>
                        <a className={styles.link} href='https://appcraft.pro' alt=''>
                            appcraft.pro
                        </a>
                        <span className={styles.separator}>|</span>
                        <a className={styles.link} href='https://appcraft.team' alt=''>
                            appcraft.team
                        </a>
                    </div>
                    <div className={styles.copyright}>
                        Copyright &copy; 2011-2020,&nbsp;AppCraft LCC
                    </div>
                </div>
            </div>
            <AuthImage width={"30%"} height={"30%"}/>
        </div>
    );
};

const mapStateToProps = ({auth}) => ({...auth});
const mapDispatchToProps = (dispatch, {apiService, slackCode}) => {
    return bindActionCreators(
        {
            demoLogin: demoLogin(apiService, slackCode),
        },
        dispatch,
    );
};
export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Auth));
