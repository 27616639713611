import React from "react";
import styles from "./dateInput.module.scss";

const DateInput = ({
  label,
  placeholder,
  value,
  labelPosition = "top",
  width = "60px",
  onChange = () => {},
}) => {
  return (
    <div className={styles[labelPosition + "DateInput"]}>
      <label className={styles.label}>{label}</label>
      <div style={{ maxWidth: width }} className={styles.inputBlock}>
        <input
          type='date'
          onChange={e => {
              onChange(e.target.value);
          }}
          placeholder={placeholder}
          value={value ? value : ''}
        />
      </div>
    </div>
  );
};

export default DateInput;
