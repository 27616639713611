import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { withApiService } from "../hoc";
import compose from "../../utils/compose";
import { getAllProjects } from "../../redux/projects/actions";

import { ReactComponent as PlaceholderCardImage } from "../../assets/images/PlaceholderCardImage.svg";
import styles from "./projects.module.scss";
import { Search } from "../FormItems";
import LoadScreen from "../LoadScreen";
import Pagination from "../Tables/Pagination";
import InfiniteScroll from "react-infinite-scroller";
import getUrlParam from "../../utils/getUrlParam";

const Projects = ({
                      projects,
                      loading,
                      getAllProjects,
                      projectsCount,
                }) => {
    const desktop = window.innerWidth < 768 ? false : true;
    let urlParam = getUrlParam('page');
    const [filter, setFilter] = useState({
        limit: desktop ? 20 : 4,
        offset: (urlParam ? urlParam - 1 : 0) * (desktop ? 20 : 4),
    });

    useEffect(() => {
        localStorage.removeItem('allAchievementsVisibleBlock');
        localStorage.removeItem('achievementsHistoryFilterTime');

        localStorage.removeItem('pointsRatingFilterTime');
        localStorage.removeItem('pointsRatingFilterCategory');
        localStorage.removeItem('achievementsVisibleBlock');
        localStorage.removeItem('achievementsRatingFilterTime');

        localStorage.removeItem('eventsFilterTime');
        localStorage.removeItem('eventsFilterType');
        localStorage.removeItem('eventsFilterCategory');

        localStorage.removeItem('homePointsFilter');
        localStorage.removeItem('otherUserPointsFilter');

        localStorage.removeItem('productsFilterType');
        localStorage.removeItem('coinFilterFrom');
        localStorage.removeItem('coinFilterTo');

            getAllProjects(filter, true);
    }, []);

    const loadData = () => {
        getAllProjects({ ...filter, offset: filter.offset + filter.limit });
        setFilter({...filter,  offset: filter.offset + filter.limit})
    };

    if (projects.length <= 0 && loading) {
        return <div className={styles.ProjectsLoadScreen}><LoadScreen /></div>;
    }
    return (
        <div className={styles.Projects}>
            <div className='mt1'></div>
            <div className={styles.filtersContainer}>
                <Search
                    minWidth={300}
                    label='Проект'
                    placeholder='Название проекта'
                    onSelect={value => {}}
                    searchResults={[]}
                    requestStartSumbolIndex={0}
                    searchRequest={val => {
                        getAllProjects({ ...filter, offset: 0, name: val }, true);
                        setFilter(prevFilter => {
                            return { ...prevFilter, offset: 0, name: val };
                        });
                    }}
                    selectedValue={{}}
                />
            </div>
            {!desktop ? (
                <>
                    <div className={styles.CardsTable}>
                        {projects.map(item => {
                              return (
                                  <Link to={`/projects/${item.id}`} key={"projects" + item.id}>
                                      <div className={styles.projectCard}>
                                          <div className={styles.cardTitle}>{item.name}</div>
                                          <div className={styles.cardImg}>
                                              {item.image ? (
                                                  <img
                                                      className={styles.cardIcon}
                                                      src={window.origin + item.image.image}
                                                      alt={"project icon"}
                                                  />
                                              ) : (
                                                  <PlaceholderCardImage
                                                      width='180px'
                                                      height='180px'
                                                      style={{ borderRadius: 8 }}
                                                  />
                                              )}
                                          </div>
                                      </div>
                                  </Link>
                              );
                          })}
                    </div>
                    {projectsCount > filter.limit ? (
                        <Pagination
                            total={projectsCount}
                            offset={(urlParam ? (urlParam - 1) : filter.offset) * filter.limit}
                            limit={filter.limit}
                            onChange={newPage => {
                                setFilter({ ...filter, offset: (newPage - 1) * filter.limit });
                                getAllProjects({ ...filter, offset: (newPage - 1) * filter.limit }, true)
                            }}
                        />
                    ) : (
                        ""
                    )}
                </>
            ) : (
                <InfiniteScroll
                    className={styles.CardsTable}
                    loadMore={loadData}
                    initialLoad={false}
                    hasMore={!loading && projects.length < projectsCount}
                >
                    {projects.map(item => {
                        return (
                            <Link to={`/projects/${item.id}`} key={"projects" + item.id}>
                                <div className={styles.projectCard}>
                                    <div className={styles.cardTitle}>{item.name}</div>
                                    <div className={styles.cardImg}>
                                        {item.image ? (
                                            <img
                                                className={styles.cardIcon}
                                                src={window.origin + item.image.image}
                                                alt={"project icon"}
                                            />
                                        ) : (
                                            <PlaceholderCardImage
                                                width='180px'
                                                height='180px'
                                                style={{ borderRadius: 8 }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </InfiniteScroll>
            )}
        </div>
    );
};

const mapStateToProps = ({ projects }) => ({
    projects: projects.projects,
    projectsCount: projects.projectsCount,
    loading: projects.loading,
});
const mapDispatchToProps = (dispatch, { apiService, filter }) => {
    return bindActionCreators(
        {
            getAllProjects: getAllProjects(apiService, filter),
        },
        dispatch,
    );
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps),
)(Projects);
