import {
  getAchievementsRatingRequest,
  getAchievementsRatingFailure,
  getAchievementsRatingSuccess,
  getPointsRatingRequest,
  getPointsRatingFailure,
  getPointsRatingSuccess,
} from "./actionCreators";
import getTimestampDateXDaysAgo from "../../utils/getTimestampDateXDaysAgo";

const mapTimeFilterIdToTimestamp = {
  1: 0, // За все время
  2: getTimestampDateXDaysAgo(1), // За сегодня
  3: getTimestampDateXDaysAgo(7), // За неделю
  4: getTimestampDateXDaysAgo(30), // За месяц
};

const getAchievementsRating = api => (filter = {}, isTV = false) => dispatch => {

  let formattedFilter = {
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    gratitudeCategoryId: filter.category || null,
    limit: filter.limit || 999999,
    offset: filter.offset || 0,
  };
  dispatch(getAchievementsRatingRequest());
  api
    .getAchievementsRatings(formattedFilter, isTV)
    .then(res => {
      console.log(res, "res getAchievementsRatings");
      dispatch(getAchievementsRatingSuccess(res.data));
    })
    .catch(err => {
      dispatch(getAchievementsRatingFailure(err));
      console.log(err, "err getAchievementsRatings");
    });
};

const getPointsRating = api => (filter = {}, isTV = false) => dispatch => {

  let formattedFilter = {
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    gratitudeCategoryId: filter.category || null,
    limit: filter.limit || 999999,
    offset: filter.offset || 0,
  };

  dispatch(getPointsRatingRequest());
  api
    .getGratitudeRatings(formattedFilter, isTV)
    .then(res => {
      console.log(res, "res getPointsRating");
      dispatch(getPointsRatingSuccess(res.data));
    })
    .catch(err => {
      dispatch(getPointsRatingFailure(err));
      console.log(err, "err getPointsRating");
    });
};

export { getAchievementsRating, getPointsRating };
