import React, { useState, useEffect } from "react";
import styles from "./SearchOnChangeReload.module.scss";
import { ReactComponent as SearchIcon } from "../../../assets/images/searchIcon.svg";

import {
    ReactComponent as UserPlaceholderImage,
    ReactComponent as PlaceholderProfileIcon
} from "../../../assets/images/userPlaceholder.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeIcon.svg";

const SearchOnChangeReload = ({
                    minWidth = "auto",
                    label,
                    placeholder,
                    onSelect,
                    onKeyPress,
                    selectedValue = {},
                    searchResults = [],
                    searchRequest,
                    requestStartSumbolIndex = 3,
                }) => {
    const [searchValue, setSearchValue] = useState(
        selectedValue.name ? selectedValue.name : "",
    );

    useEffect(() => {
        if (selectedValue.name === "null") {
            onSelect({});
            onKeyPress({});
            setSearchValue("");
        }
    }, [selectedValue]);
    return (
        <div className={styles.Search} style={{ minWidth }}>
            {label ? <label>{label}</label> : ""}
            <div className={styles.inputBlock}>
                <input
                    value={searchValue}
                    placeholder={placeholder}
                    onChange={e => {
                        if (e.target.value.length >= requestStartSumbolIndex) {
                            searchRequest(e.target.value);
                        }
                        setSearchValue(e.target.value);
                        onKeyPress(searchValue)
                    }}
                />
                <SearchIcon width='20px' height='20px' />
            </div>
            <ul
                style={
                    searchResults.length > 0 &&
                    searchValue.length >= requestStartSumbolIndex
                        ? {}
                        : { display: "none" }
                }
            >
                {searchResults.map(item => {
                    return (
                        <li
                            key={"searchUserList" + item.id}
                            onClick={() => {
                                onSelect(item);
                                setSearchValue(
                                    item.firstName + " " + item.lastName || item.nickname,
                                );
                                // Костыль что бы очистить список
                                searchRequest("awdawdawdawldawdawdawdawdawdawdawd");
                            }}
                        >
                            {item.avatar ? (
                                <div className={styles.searchUserImg}>
                                    <img src={item.avatar} alt='avatar' />
                                </div>
                            ) : (
                                <div className={styles.searchUserImg}>
                                    <PlaceholderProfileIcon />
                                </div>
                            )}
                            {item.firstName + " " + item.lastName || item.nickname}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default SearchOnChangeReload;

