const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";

const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";

const FETCH_SYNC_SLACK_USERS_REQUEST = "FETCH_SYNC_SLACK_USERS_REQUEST";
const FETCH_SYNC_SLACK_USERS_FAILURE = "FETCH_SYNC_SLACK_USERS_FAILURE";
const FETCH_SYNC_SLACK_USERS_SUCCESS = "FETCH_SYNC_SLACK_USERS_SUCCESS";

const FETCH_USER_UPDATE_REQUEST = "FETCH_USER_UPDATE_REQUEST";
const FETCH_USER_UPDATE_FAILURE = "FETCH_USER_UPDATE_FAILURE";
const FETCH_USER_UPDATE_SUCCESS = "FETCH_USER_UPDATE_SUCCESS";
export {
  FETCH_USERS_REQUEST,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  FETCH_USER_UPDATE_REQUEST,
  FETCH_USER_UPDATE_FAILURE,
  FETCH_USER_UPDATE_SUCCESS,
  FETCH_SYNC_SLACK_USERS_REQUEST,
  FETCH_SYNC_SLACK_USERS_FAILURE,
  FETCH_SYNC_SLACK_USERS_SUCCESS,
};
