import {
  FETCH_HISTORY_TV_GRATITUDE_REQUEST,
  FETCH_HISTORY_TV_GRATITUDE_FAILURE,
  FETCH_HISTORY_TV_GRATITUDE_SUCCESS,
  FETCH_HISTORY_TV_ACHIEVEMENTS_REQUEST,
  FETCH_HISTORY_TV_ACHIEVEMENTS_FAILURE,
  FETCH_HISTORY_TV_ACHIEVEMENTS_SUCCESS,
} from "./types";

const initialState = {
  historyAchievements: [],
  historyGratitude: [],
  loading: false,
  error: null,
};
const tvscreen = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HISTORY_TV_GRATITUDE_REQUEST:
    case FETCH_HISTORY_TV_ACHIEVEMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_HISTORY_TV_GRATITUDE_SUCCESS:
      return {
        ...state,
        loading: false,
        historyGratitude: action.payload,
      };

    case FETCH_HISTORY_TV_ACHIEVEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        historyAchievements: action.payload,
      };
    case FETCH_HISTORY_TV_GRATITUDE_FAILURE:
    case FETCH_HISTORY_TV_ACHIEVEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default tvscreen;
