import React, {useState} from "react";
import  Button from "../Button";
import "./AlertMessage.scss";
import {Textarea} from "../index";

const AlertMessage = ({onProcess, onCloseModal, text='Вы точно хотите удалить эту запись?', type = '', buttonText='Да' }) => {
  const [commentary, setCommentary] = useState(null)
  return (
      <div className='remove-form'>
          <div className='remove-form__title'>{text}</div>
          {type === 'products' ?
          <div className='remove-form__textarea'>
              <Textarea
                  label={'Пожелания или замечания для менеджера'}
                  placeholder={"Комментарий"}
                  value={commentary}
                  onChange={value => {
                      setCommentary(value);
                  }}
                  minHeight={"80px"}
              />
          </div> : ''}
          <div className='remove-form__btn'>
              <Button
                  label={buttonText}
                  style={{width: 128, height: 48, padding: '12px 12px 12px 12px'}}
                  handleClick={() => {
                      onProcess(commentary !== null ? commentary : '');
                      onCloseModal()
                    }
                  }
              />
              <Button
                  label={'Отмена'}
                  style={{width: 128, height: 48, padding: '12px 12px 12px 12px'}}
                  handleClick={() => onCloseModal()}
              />
          </div>
      </div>
  );
};
export default AlertMessage

