import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  FETCH_USER_UPDATE_REQUEST,
  FETCH_USER_UPDATE_FAILURE,
  FETCH_USER_UPDATE_SUCCESS,
  FETCH_SYNC_SLACK_USERS_REQUEST,
  FETCH_SYNC_SLACK_USERS_FAILURE,
  FETCH_SYNC_SLACK_USERS_SUCCESS,
} from "./types";

const getUsersRequest = actualRequestId => {
  return { type: FETCH_USERS_REQUEST, payload: actualRequestId };
};
const getUsersFailure = (error, actualRequestId) => {
  return { type: FETCH_USERS_FAILURE, payload: { error, actualRequestId } };
};
const getUsersSuccess = (usersData, actualRequestId) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: { ...usersData, actualRequestId },
  };
};

const getUserRequest = () => {
  return { type: FETCH_USER_REQUEST };
};
const getUserFailure = error => {
  return { type: FETCH_USER_FAILURE, payload: error };
};
const getUserSuccess = users => {
  return { type: FETCH_USER_SUCCESS, payload: users };
};

const updateUserRequest = () => {
  return { type: FETCH_USER_UPDATE_REQUEST };
};
const updateUserFailure = error => {
  return { type: FETCH_USER_UPDATE_FAILURE, payload: error };
};
const updateUserSuccess = () => {
  return { type: FETCH_USER_UPDATE_SUCCESS };
};

const syncSlackUsersRequest = () => {
  return { type: FETCH_SYNC_SLACK_USERS_REQUEST };
};
const syncSlackUsersFailure = err => {
  return { type: FETCH_SYNC_SLACK_USERS_FAILURE, payload: err };
};
const syncSlackUsersSuccess = () => {
  return { type: FETCH_SYNC_SLACK_USERS_SUCCESS };
};

export {
  getUsersRequest,
  getUsersFailure,
  getUsersSuccess,
  getUserRequest,
  getUserFailure,
  getUserSuccess,
  updateUserRequest,
  updateUserFailure,
  updateUserSuccess,
  syncSlackUsersRequest,
  syncSlackUsersFailure,
  syncSlackUsersSuccess,
};
