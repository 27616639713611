import {
  FETCH_SEARCH_USER_REQUEST,
  FETCH_SEARCH_USER_SUCCESS,
  FETCH_SEARCH_USER_FAILURE,
  FETCH_SEARCH_USERS_IN_HEADER_REQUEST,
  FETCH_SEARCH_USERS_IN_HEADER_SUCCESS,
  FETCH_SEARCH_USERS_IN_HEADER_FAILURE,
} from "./types";

const initialState = {
  users: [],
  usersCount: null,
  usersInHeader: [],
  usersCountInHeader: null,
  loading: false,
  error: null,
};

const search = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEARCH_USERS_IN_HEADER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        usersInHeader: [],
        usersCountInHeader: null,
      };
    case FETCH_SEARCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        users: [],
        usersCount: null,
      };
    case FETCH_SEARCH_USER_SUCCESS:
      return {
        ...state,
        users: action.payload.entities,
        usersCount: action.payload.count,
        loading: false,
      };
    case FETCH_SEARCH_USERS_IN_HEADER_SUCCESS:
      return {
        ...state,
        usersInHeader: action.payload.entities,
        usersCountInHeader: action.payload.count,
        loading: false,
      };

    case FETCH_SEARCH_USERS_IN_HEADER_FAILURE:
    case FETCH_SEARCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default search;
