import React, { useRef, useState } from 'react';
import styles from './select.module.scss';
import { ReactComponent as ArrowIcon } from "../../../assets/images/arrowDown.svg";
import { ReactComponent as CloseIcon } from '../../../assets/images/closeIcon.svg';

const Select = ({
  label,
  placeholder,
  onSelect,
  selectedValue = {},
  options = [],
  clearSelect = '',
}) => {
  const Select = useRef();
  const [focus, setFocus] = useState(false);

  return (
    <div
      tabIndex='-1'
      onClick={e => {
        e.preventDefault();
        if (focus) {
          Select.current.blur();
        } else {
          setFocus(true);
        }
      }}
      onBlur={e => {
        e.preventDefault();
        setFocus(false);
      }}
      onFocus={e => {
        e.preventDefault();
      }}
      ref={Select}
      className={styles.Select}
    >
      <label>
        {label}
        <div className={styles.inputBlock}>
          <input
            disabled
            value={selectedValue.name || ''}
            placeholder={placeholder}
            readOnly
            onChange={e => {}}
          />
          {selectedValue.name && clearSelect ? (
            <CloseIcon
              className={styles.clearBtn}
              onClick={() => {
                clearSelect();
              }}
            />
          ) : (
            <ArrowIcon className={styles.arrow} />
          )}
        </div>
      </label>
      <ul>
        {options.map(item => {
          return (
            <li
              key={item.id}
              onClick={() => {
                onSelect(item);
                Select.current.blur();
              }}
            >
              {item.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Select;
