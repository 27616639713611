import axios from "axios";
import Cookies from "js-cookie";
import getTimestampDateXDaysAgo from "./utils/getTimestampDateXDaysAgo";

export default class API {
    constructor(props) {
        this.api = axios.create({
            baseURL: window.location.origin + "/api",
            responseType: "json",
            ContentType: "application/json",
        });

        this.api.interceptors.response.use(
            response => response,
            error => {
                if (
                    error.response &&
                    error.response.status === 401 &&
                    error.config.url !== "/token/refresh/"
                ) {
                    // Пытаемся восстановить токен если у нас есть refreshToken
                    if (Cookies.get("refreshJwtToken")) {
                        return this.refreshJWT()
                            .then(res => {
                                if (res.data && res.data.access) {
                                    Cookies.set("jwtToken", res.data.access);
                                    // Если у нас получилось достать обновленный токен, дергаем метод который вызвал ошибку еще раз
                                    error.config.headers.Authorization =
                                        "Bearer " + res.data.access;
                                    error.config.__isRetryRequest = true;
                                    return axios(error.config);
                                } else {
                                    Cookies.remove("jwtToken");
                                    Cookies.remove("refreshJwtToken");
                                    console.log(res, "refreshJWT");
                                }
                            })
                            .catch(err => {
                                console.log(err, "err");
                                Cookies.remove("jwtToken");
                                Cookies.remove("refreshJwtToken");
                            });
                    } else {
                        Cookies.remove("jwtToken");
                        Cookies.remove("refreshJwtToken");
                    }
                } else if (
                    error.response &&
                    error.response.status === 401 &&
                    error.config.url === "/token/refresh/"
                ) {
                    // Если сам refresh токен  не валидный
                    Cookies.remove("jwtToken");
                    Cookies.remove("refreshJwtToken");
                    window.location.reload();
                }
                return Promise.reject(error);
            },
        );
    }

    // demo
    demo_login = async () => {
        return await this.api.post(
            "/authorization/demo",
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
    }

    // Auth
    login = async slackCode => {
        const form = new FormData();
        form.append("client_id", "93841683266.893325203922");
        form.append("client_secret", "22680d6df632cff56595233a3a20a0eb");
        form.append("code", slackCode);
        form.append(
            "redirect_uri",
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname,
        );

        let responseData = await axios.post(
            "https://slack.com/api/oauth.access",
            form,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        );

        if (responseData.data.access_token) {
            Cookies.set("slackAccessToken", responseData.data.access_token);
            // Cookies.set("slackAccessToken", "xoxp-93841683266-350528008054-896092087521-3577c891f813d57bf4fbaf37d136a91b");

            responseData = await this.api.post(
                "/authorization/slack",
                {
                    slackAccessToken: responseData.data.access_token,
                    // slackAccessToken: "xoxp-93841683266-350528008054-896092087521-3577c891f813d57bf4fbaf37d136a91b",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
        }

        return responseData;
    };

    refreshJWT = async () => {
        let responseData = await this.api.post(
            "/token/refresh/",
            {
                refresh: Cookies.get("refreshJwtToken"),
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
        console.log(responseData, 'responseDatarefreshJWT')
        return responseData;
    };
    // Achievements

    checkUserVisit = async () => {
        await this.api.get("/visit", {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
        });
    };

    getAllAchievements = async filter => {
        let responseData = await this.api.get("/achievements", {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };

    getAchievement = async achievementId => {
        let responseData = await this.api.get(`/achievements/${achievementId}`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
        });
        return responseData;
    };
    getAchievementOwners = async (achievementId, filter) => {
        let responseData = await this.api.get(`/achievements/${achievementId}/owners/`, {
            params: filter,
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
        });
        return responseData;
    };
    getAllAdminAchievements = async filter => {
        let responseData = await this.api.get("/admin/achievements", {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };
    getOwnAchievements = async filter => {
        let responseData = await this.api.get("/user_achievements/own", {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };

    getUserAchievements = async (userId, filter) => {
        let responseData = await this.api.get(
            `/user_achievements/by_user/${userId}`,
            {
                headers: {
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
                params: filter,
            },
        );
        return responseData;
    };

    removeAchievement = async achievementId => {
        let responseData = await this.api.delete(
            `/admin/achievements/${achievementId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );
        return responseData;
    };
    createAchievement = async ({
                                   name,
                                   description,
                                   image,
                                   owners = [],
                                   isActive,
                               }) => {
        let responseData = await this.api.post(
            "/admin/achievements",
            {
                name: name,
                description: description,
                isActive: isActive,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );
        let createdAchievementId = responseData.data.id;
        // Добавляем картинку
        if (createdAchievementId && image) {
            const form = new FormData();
            form.append("image", image);
            await this.api.post(
                `/admin/media/achievement_image/${createdAchievementId}`,
                form,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + Cookies.get("jwtToken"),
                    },
                },
            );
        }

        // Обновляем список владельцев ачивки
        await this.api.patch(
            `/admin/achievements/${createdAchievementId}/owners`,
            {
                users: owners.map(item => item.id),
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );

        return responseData;
    };

    editAchievement = async ({
                                 name,
                                 description,
                                 isActive,
                                 image,
                                 id,
                                 owners = [],
                             }) => {
        let responseData = await this.api.patch(
            `/admin/achievements/${id}`,
            {
                name: name,
                description: description,
                isActive: isActive,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );
        // Добавляем картинку если она была обновлена

        if (id && image && !image.id) {
            // Удаляем предидущую картинку если она была
            if (
                responseData.data &&
                responseData.data.image &&
                responseData.data.image.id
            ) {
                await this.api.delete(
                    `/admin/media/images/${responseData.data.image.id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + Cookies.get("jwtToken"),
                        },
                    },
                );
            }
            // Добавляем новую картинку
            const form = new FormData();
            form.append("image", image);
            await this.api.post(`/admin/media/achievement_image/${id}`, form, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            });
        }
        // Обновляем список владельцев ачивки

        await this.api.patch(
            `/admin/achievements/${id}/owners`,
            {
                users: owners.map(item => item.id),
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );
        return responseData;
    };

    // User
    syncUsersFromSlack = async () => {
        let responseData = await this.api.post(
            "/admin/sync_slack_users",
            {
                slackAccessToken: Cookies.get("slackAccessToken"),
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );
        return responseData;
    };

    updateUser = async userData => {
        console.log(userData, "userData updateUser api");

        let responseData = await this.api.patch(
            `/admin/profile/${userData.id}`,
            {
                isBlocked: userData.isBlocked,
                isSuperuser: userData.isSuperuser,
                maxPoints: userData.maxPoints,
                points: userData.points,
                firstName: userData.firstName,
                lastName: userData.lastName,
                firstNameGenitive: userData.firstNameGenitive,
                lastNameGenitive: userData.lastNameGenitive,
                sex: userData.sex,
                placeType: userData.placeType,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );

        return responseData;
    };
    getUsers = async filter => {
        let responseData = await this.api.get("/users", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };
    getUsersForAdmin = async filter => {
        let responseData = await this.api.get("/admin/users", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };

    getUser = async userId => {
        let responseData = await this.api.get(`/profile/${userId}`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
        });
        return responseData;
    };
    getProfile = async () => {
        let responseData = await this.api.get("/profile", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
        });
        return responseData;
    };
    blockUser = async userId => {
        let responseData = await this.api.patch(
            `/admin/profile/${userId}/block`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );
        return responseData;
    };

    unblockUser = async userId => {
        let responseData = await this.api.patch(
            `/admin/profile/${userId}/unblock`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );
        return responseData;
    };

    setMaxPoints = async (userId, maxPoints) => {
        let responseData = await this.api.patch(
            `/admin/profile/${userId}/max_points`,
            {maxPoints},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );
        return responseData;
    };

    addAchievementToUser = async (userId, achievementId) => {
        let responseData = await this.api.post(
            "/admin/user_achievements",
            {
                userId: userId,
                achievementId: achievementId,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );

        return responseData;
    };

    // history
    getAchievementsHistory = async filter => {
        let responseData = await this.api.get("/user_achievements/history", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };

    getGratitudeHistory = async filter => {
        let responseData = await this.api.get("/gratitude/history", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };

    getEventsHistory = async filter => {
        const responseData = await this.api.get("/events/history", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });

        return responseData;
    };

    // purchase
    getPurchase = async filter => {
        let responseData = await this.api.get("/all-user-products", {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };
    getPurchaseForAdmin = async filter => {
        let responseData = await this.api.get("/admin/all-purchases", {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };

    getPurchaseСustomers = async (productType, productId, customersFilter) => {
        let responseData = await this.api.get(`/admin/purchase/${productType}/${productId}`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: customersFilter,
        });
        return responseData;
    };

    getPurchaseCustomersCommon = async (productId, customersFilter) => {
        let responseData = await this.api.get(`/crowdfunding_products/${productId}/lead_board`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: customersFilter,
        });
        return responseData;
    };

    // store
    getProducts = async filter => {
        let responseData = await this.api.get("/all-products", {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };
    getProductsByAdmin = async filter => {
        let responseData = await this.api.get("/admin/all-products", {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };
    getProductById = async productId => {
        let responseData = await this.api.get(`/products/${productId}/`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
        });
        return responseData;
    };
    getCrowdfundingById = async productId => {
        let responseData = await this.api.get(`/crowdfunding_products/${productId}/`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
        });
        return responseData;
    };
    buyProduct = async (productId, productCommentary) => {
        let responseData = await this.api.post(`/products/${productId}/buy/ `, {
            commentary: productCommentary ? productCommentary : ''
        }, {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
        });
        return responseData;
    };
    buyCrowdfundingProduct = async (productId, productCost) => {
        let responseData = await this.api.post(`/products/${productId}/invest/${productCost}`, null, {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
        });

        return responseData;
    };

    createProduct = async productData => {

        if (productData.type === "products") {
            let responseData = await this.api.post(`/products/ `, {
                title: productData.title,
                description: productData.description,
                totalPrice: productData.totalPrice,
                inStock: productData.inStock,
                available: productData.available ? productData.available : true,
                placeType: productData.placeType !== null ? productData.placeType : 0
            }, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            });
            let createdProjectId = responseData.data.id;
            // Добавляем картинку
            if (productData.photo) {
                const form = new FormData();
                form.append("image", productData.photo);
                await this.api.post(
                    `/admin/media/images/common_product/${createdProjectId}`,
                    form,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + Cookies.get("jwtToken"),
                        },
                    },
                );
            }

            return responseData;
        } else {
            let responseData = await this.api.post(`/crowdfunding_products/`, {
                title: productData.title,
                description: productData.description,
                totalPrice: productData.totalPrice,
                collecting_until_dt: Date.parse(productData.collectingUntilDt + 'T12:59:59'),
                available: productData.available ? productData.available : true,
                placeType: productData.placeType !== null ? productData.placeType : 0
            }, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            });
            let createdProjectId = responseData.data.id;
            // Добавляем картинку
            if (productData.photo) {
                const form = new FormData();
                form.append("image", productData.photo);
                await this.api.post(
                    `/admin/media/images/crowdfunding_products/${createdProjectId}`,
                    form,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + Cookies.get("jwtToken"),
                        },
                    },
                );
            }

            return responseData;
        }
    };
    updateProduct = async productData => {

        if (productData.type === "products") {
            let responseData = await this.api.put(`/products/${productData.id}/ `, {
                title: productData.title,
                description: productData.description,
                totalPrice: productData.totalPrice,
                inStock: productData.inStock,
                available: productData.available,
                placeType: productData.placeType !== null ? productData.placeType : 0
            }, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            });
            // Добавляем картинку
            if (productData.photo && !productData.photo.id) {
                const form = new FormData();
                form.append("image", productData.photo);
                await this.api.post(
                    `/admin/media/images/common_product/${productData.id}`,
                    form,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + Cookies.get("jwtToken"),
                        },
                    },
                );
            }

            return responseData;
        } else {
            let responseData = await this.api.put(`/crowdfunding_products/${productData.id}/ `, {
                title: productData.title,
                description: productData.description,
                totalPrice: productData.totalPrice,
                collecting_until_dt: Date.parse(productData.collectingUntilDt + 'T12:59:59'),
                available: productData.available,
                placeType: productData.placeType !== null ? productData.placeType : 0
            }, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            });
            // Добавляем картинку
            if (productData.photo && !productData.photo.id) {
                const form = new FormData();
                form.append("image", productData.photo);
                await this.api.post(
                    `/admin/media/images/crowdfunding_products/${productData.id}`,
                    form,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + Cookies.get("jwtToken"),
                        },
                    },
                );
            }

            return responseData;
        }
    };
    removeProduct = async (productDataType, productDataId) => {
        if (productDataType === "products") {
            let responseData = await this.api.delete(`/products/${productDataId}/`, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            });
            return responseData;
        } else {
            let responseData = await this.api.delete(`/crowdfunding_products/${productDataId}/`, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            });
            return responseData;
        }

    };


    // TV Events
    getAchievementsHistoryTV = async filter => {
        let responseData = await this.api.get("/user_achievements/history-for-tv", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            params: filter,
        });
        return responseData;
    };

    getGratitudeHistoryTV = async filter => {
        let responseData = await this.api.get("/gratitude/history-for-tv", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            params: filter,
        });
        return responseData;
    };

    // Points
    // Получить категории для благодарности
    getGratitudeCategories = async () => {
        let responseData = await this.api.get("/gratitude/categories", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
        return responseData;
    };
    // Получить свои полученные благодарности
    getReceivedOwnGratitude = async filter => {
        let responseData = await this.api.get(
            `/gratitude/get_received_gratitudes/own`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
                params: filter,
            },
        );
        return responseData;
    };

    // Получить чужие полученные благодарности
    getReceivedGratitudeByUserId = async (userId, filter) => {
        let responseData = await this.api.get(
            `/gratitude/get_received_gratitudes/${userId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
                params: filter,
            },
        );
        return responseData;
    };

    // Получить свои отправленные благодарности
    getSendedOwnGratitude = async filter => {
        let responseData = await this.api.get(
            `/gratitude/get_sent_gratitudes/own`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
                params: filter,
            },
        );
        return responseData;
    };
    // Получить чужие отправленные благодарности
    getSendedGratitudeByUserId = async (userId, filter) => {
        let responseData = await this.api.get(
            `/gratitude/get_sent_gratitudes/${userId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
                params: filter,
            },
        );
        return responseData;
    };

    // Выдать баллы пользователю
    givePointsByUserId = async (
        userId,
        amountPoints,
        gratitudeCategoryId,
        comment,
        byAppCraft,
    ) => {
        let responseData = await this.api.post(
            `/gratitude/give_to/${userId}`,
            {
                amount: amountPoints,
                commentary: comment,
                gratitudeCategoryId,
                byAppCraft,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );
        return responseData;
    };

    // Выдать баллы пользователю
    givePointsToAllUser = async (
        userIds,
        amountPoints,
        gratitudeCategoryId,
        comment,
        byAppCraft,
    ) => {
        let responseData = await this.api.post(
            `/gratitude/bulk_transfer`,
            {
                userIds: userIds,
                amount: amountPoints,
                commentary: comment,
                gratitudeCategoryId,
                byAppCraft,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );
        return responseData;
    };
    // Raitings
    // Получить список людей с благодарностями
    getGratitudeRatings = async (filter, isTV) => {
        let responseData = await this.api.get(
            `/ratings/${isTV ? "tv/" : ""}gratitude`,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
                params: filter,
            },
        );
        return responseData;
    };
    // Получить список людей с ачивками
    getAchievementsRatings = async (filter, isTV) => {
        let responseData = await this.api.get(
            `/ratings/${isTV ? "tv/" : ""}achievements`,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
                params: filter,
            },
        );
        return responseData;
    };

    // Projects
    getAllProjects = async filter => {
        let responseData = await this.api.get("/projects", {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };

    getProject = async projectId => {
        let responseData = await this.api.get(`/projects/${projectId}`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
        });
        return responseData;
    };
    getProjectUsersMain = async (projectId, mainProjectFilter) => {
        let responseData = await this.api.get(`/projects/${projectId}/users/main/`, {
            params: mainProjectFilter,
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
        });
        return responseData;
    };
    getProjectUsersMinor = async (projectId, minorProjectFilter) => {
        let responseData = await this.api.get(`/projects/${projectId}/users/minor/`, {
            params: minorProjectFilter,
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
        });
        return responseData;
    };
    getAllAdminProjects = async filter => {
        let responseData = await this.api.get("/projects", {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };

    getUserMainProjects = async (userId, filter) => {
        let responseData = await this.api.get(`/projects/by_user/${userId}/main/`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };
    getUserMinorProjects = async (userId, filter) => {
        let responseData = await this.api.get(`/projects/by_user/${userId}/minor/`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
            params: filter,
        });
        return responseData;
    };

    removeProject = async projectId => {
        let responseData = await this.api.delete(`/admin/projects/${projectId}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("jwtToken"),
            },
        });
        return responseData;
    };
    createProject = async ({name, description, image, users = []}) => {
        let responseData = await this.api.post(
            "/admin/projects",
            {
                name: name,
                description: description,
                startedAt: getTimestampDateXDaysAgo(0),
                users: users.map(item => {
                    return {
                        user: item.id,
                        join_date: getTimestampDateXDaysAgo(0),
                        role: item.role || null,
                        isMain: item.isMain ? item.isMain.id : false,
                    };
                }),
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );
        let createdProjectId = responseData.data.id;
        // Добавляем картинку
        if (createdProjectId && image) {
            const form = new FormData();
            form.append("image", image);
            await this.api.post(
                `/admin/media/images/projects/${createdProjectId}`,
                form,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + Cookies.get("jwtToken"),
                    },
                },
            );
        }

        return responseData;
    };

    editProject = async ({name, description, image, id, users = []}) => {

        let responseData = await this.api.patch(
            `/admin/projects/${id}`,
            {
                name: name,
                description: description,
                image: image,
                startedAt: getTimestampDateXDaysAgo(0),
                users: users.map(item => {
                    return {
                        user: item.id,
                        join_date: getTimestampDateXDaysAgo(0),
                        role: item.role || null,
                        isMain: item.isMain ? item.isMain.id : false,
                    };
                }),
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            },
        );
        // Добавляем картинку если она была обновлена

        if (id && image && !image.id) {
            const form = new FormData();
            form.append("image", image);
            await this.api.post(`/admin/media/images/projects/${id}`, form, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwtToken"),
                },
            });
        }
        return responseData;
    };
}
