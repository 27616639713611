import {
  getPurchaseRequest,
  getPurchaseFailure,
  getPurchaseSuccess,
  getPurchaseMoreSuccess,
  getPurchaseСustomersRequest,
  getPurchaseСustomersFailure,
  getPurchaseСustomersSuccess,
} from "./actionCreators";

const getPurchase = api => (filter = {}, force) => dispatch => {

  dispatch(getPurchaseRequest());
  api
    .getPurchase(filter)
    .then(res => {
      console.log(res, 'getPurchase')
      if (force) {
        dispatch(getPurchaseSuccess(res.data));
      } else {
        dispatch(getPurchaseMoreSuccess(res.data));
      }
    })
    .catch(err => {
      console.log(err, 'err getProducts')
      dispatch(getPurchaseFailure());
    });
};
const getPurchaseForAdmin = api => (filter) => dispatch => {
    let formatedFilter = {
        dt_from: Date.parse(filter.dateFrom + 'T12:59:59'),
        dt_to: Date.parse(filter.dateTo + 'T12:59:59'),
        offset: filter.offset,
        limit: filter.limit
    }

    dispatch(getPurchaseRequest());
    api
        .getPurchaseForAdmin(formatedFilter)
        .then(res => {
            console.log(res, 'getPurchase')
            dispatch(getPurchaseSuccess(res.data));
        })
        .catch(err => {
            console.log(err, 'err getProducts')
            dispatch(getPurchaseFailure());
        });
};

const getPurchaseCustomers = api => (productType, productId, customersFilter) => dispatch => {
    let formatedFilter = {
        dt_from: customersFilter.dataFrom ? Date.parse(customersFilter.dataFrom + 'T12:59:59') : null,
        dt_to: customersFilter.dataTo ? Date.parse(customersFilter.dataTo + 'T12:59:59') : null,
        offset: customersFilter.offset,
        limit: customersFilter.limit
    }

    dispatch(getPurchaseСustomersRequest());
    api
        .getPurchaseСustomers(productType, productId, formatedFilter)
        .then(res => {
            console.log(res, 'getPurchase')
            dispatch(getPurchaseСustomersSuccess(res.data));
        })
        .catch(err => {
            console.log(err, 'err getProducts')
            dispatch(getPurchaseСustomersFailure());
        });
};

const getPurchaseCustomersCommon = api => (productId, customersFilter) => dispatch => {
    console.log('ldflk')
    let formatedFilter = {
        offset: customersFilter.offset,
        limit: customersFilter.limit
    }

    dispatch(getPurchaseСustomersRequest());
    api
        .getPurchaseCustomersCommon(productId, formatedFilter)
        .then(res => {
            console.log(res, 'getPurchase')
            dispatch(getPurchaseСustomersSuccess(res.data));
        })
        .catch(err => {
            console.log(err, 'err getProducts')
            dispatch(getPurchaseСustomersFailure());
        });
};

export { getPurchase, getPurchaseForAdmin, getPurchaseCustomers, getPurchaseCustomersCommon };
