import React, {useEffect} from "react";
import {Switch, Route} from "react-router-dom";
import Cookies from "js-cookie";

import {connect} from "react-redux";
import {withApiService} from "./hoc";
import compose from "../utils/compose";

import Footer from "./Footer";
import Header from "./Header";
import Auth from "./Auth";
import Home from "./Home";
import Ratings from "./Ratings";
import Achievements from "./Achievements";
import Achievement from "./Achievement";
import Store from "./Store";
import StoreDetail from "./StoreDetail";
import Purchase from "./Purchase";
import PurchaseDetail from "./PurchaseDetail";
import Settings from "./Settings";
import User from "./User";
import EventsSecretScreen from "./EventsSecretScreen";
import RatingsSecretScreen from "./RatingsSecretScreen";
import Events from "./Events";
import Projects from "./Projects";
import ProjectsDetail from "./ProjectsDetail";

import NotFoundPage from "./NotFoundPage";

const App = ({jwtToken, apiService}) => {
    useEffect(() => {
        apiService.checkUserVisit();
    }, [jwtToken, apiService]);

    if (window.location.pathname === "/tvevents") {
        return <EventsSecretScreen/>;
    }
    if (window.location.pathname === "/tvratings") {
        return <RatingsSecretScreen/>;
    }

    if (!Cookies.get("jwtToken")) {
        return <Auth/>;
    }

    return (
        <>
            <Header/>
            <main className='main'>
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route exact path='/ratings' component={Ratings}/>
                    <Route exact path='/achievements' component={Achievements}/>
                    <Route exact path='/achievements/:id' component={Achievement}/>
                    <Route exact path='/settings' component={Settings}/>
                    <Route exact path='/user/:id' component={User}/>
                    <Route exact path='/events' component={Events}/>
                    <Route exact path='/store' component={Store}/>
                    <Route exact path='/store/:type/:id' component={StoreDetail}/>
                    <Route exact path='/purchase' component={Purchase}/>
                    <Route exact path='/purchase/:type/:id' component={PurchaseDetail}/>
                    <Route exact path='/projects' component={Projects}/>
                    <Route exact path='/projects/:id' component={ProjectsDetail}/>

                    <Route path='*' component={NotFoundPage}/>
                </Switch>
            </main>
            <Footer/>
        </>
    );
};

const mapStateToProps = ({auth}) => ({...auth});
const mapDispatchToProps = ({apiService}) => ({});

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps),
)(App);
