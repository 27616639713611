import React, { useState } from "react";

import "./Table.scss";
import TableLoadingScreen from "../TableLoadingScreen";
import TableEmptyScreen from "../TableEmptyScreen";
import TableRow from "../TableRow";
import Sorter from "../Sorter";

const Table = ({
  dataSource = [],
  columns = [],
  onClickRow = () => {},
  highlightRow = () => {},
  loading = false,
  maxHeight = "75%",
  pagination = false,
  withHeader = true,
  tableWidth = "100%",
  onChange = () => {},
}) => {
  const [sort, setSort] = useState({});

  if (loading) {
    return <TableLoadingScreen maxHeight={maxHeight}/>;
  }
  if (!loading && dataSource.length === 0) {
    return <TableEmptyScreen maxHeight={maxHeight} />;
  }

  return (
  <>
    <div style={{ maxHeight: maxHeight }} className='GridTable'>
      <table style={{ width: tableWidth }}>
        {withHeader ? (
          <thead>
            <tr>
              {columns.map((headerRowItem, headerRowIndex) => {
                return (
                  <th
                    key={"tableHeadThI" + headerRowIndex + headerRowItem.title}
                    style={
                      headerRowItem.width ? { width: headerRowItem.width } : {}
                    }
                  >
                    {headerRowItem.title}
                    {headerRowItem.sorter ? (
                      <Sorter
                        sortValue={
                          headerRowItem.dataIndex === sort.orderBy
                            ? sort.order
                            : "desc"
                        }
                        onChange={sortValue => {
                          let sorter = {
                            orderBy: headerRowItem.dataIndex,
                            order: sortValue,
                          };
                          setSort(sorter);
                          onChange({ offset: 0 }, sorter);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
        ) : (
          ""
        )}

        <tbody>
          {dataSource.map((rowItem, rowIndex) => {
            return (
              <TableRow
                key={"gridTableTrI" + rowIndex + "Id" + rowItem.id}
                rowIndex={rowIndex}
                rowItem={rowItem}
                highlightRow={highlightRow}
                columns={columns}
                onClickRow={onClickRow}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  </>
  );
};
export default Table;
