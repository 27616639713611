import {
  getUsersRequest,
  getUsersFailure,
  getUsersSuccess,
  getUserRequest,
  getUserFailure,
  getUserSuccess,
  updateUserRequest,
  updateUserFailure,
  updateUserSuccess,
  syncSlackUsersRequest,
  syncSlackUsersFailure,
  syncSlackUsersSuccess,
} from "./actionCreators";

const getUsers = api => (filter = {}) => dispatch => {
  let formattedFilter = {
    name: filter.name || null,
    limit: filter.limit || 999999,
  };

  // generate actualRequestId
  const actualRequestId = Date.now().toString();

  dispatch(getUsersRequest(actualRequestId));
  api
    .getUsers(formattedFilter)
    .then(res => {
      console.log(res, "res getUsers");
      dispatch(getUsersSuccess(res.data, actualRequestId));
    })
    .catch(err => {
      console.log(err, "err getUsers");
      dispatch(getUsersFailure(err, actualRequestId));
    });
};
const getUsersForAdmin = api => (filter = {}) => dispatch => {
  let formattedFilter = {
    name: filter.name || null,
    limit: filter.limit || 999999,
    offset: filter.offset || 0
  };

  // generate actualRequestId
  const actualRequestId = Date.now().toString();

  dispatch(getUsersRequest(actualRequestId));
  api
    .getUsersForAdmin(formattedFilter)
    .then(res => {
      console.log(res, "res getUsersForAdmin");
      dispatch(getUsersSuccess(res.data, actualRequestId));
    })
    .catch(err => {
      console.log(err, "err getUsersForAdmin");
      dispatch(getUsersFailure(err, actualRequestId));
    });
};
const getUser = api => userId => dispatch => {
  dispatch(getUserRequest());
  api
    .getUser(userId)
    .then(res => {
      console.log(res, "res getUser");
      dispatch(getUserSuccess([res.data]));
    })
    .catch(err => {
      console.log(err, "err getUser");
      dispatch(getUserFailure(err));
    });
};

const updateUser = api => (userData, filter) => dispatch => {
  dispatch(updateUserRequest());
  api
    .updateUser(userData)
    .then(res => {
      console.log(res, "res updateUser");
      dispatch(updateUserSuccess());
      // запрашиваем обновленный список пользователей
      getUsersForAdmin(api)(filter)(dispatch);
    })
    .catch(err => {
      console.log(err, "err updateUser");
      dispatch(updateUserFailure(err));
      getUsersForAdmin(api)(filter)(dispatch);
    });
};

const syncUsersWithSlack = api => filter => dispatch => {
  dispatch(syncSlackUsersRequest());
  api
    .syncUsersFromSlack()
    .then(res => {
      console.log(res, "res syncUsersFromSlack");
      dispatch(syncSlackUsersSuccess());

      getUsers(api)(filter)(dispatch);
    })
    .catch(err => {
      dispatch(syncSlackUsersFailure());

      console.log(err, "err syncUsersFromSlack");
    });
};

export { getUsers, getUsersForAdmin, getUser, updateUser, syncUsersWithSlack };
