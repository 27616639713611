import React, { useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import compose from "../../../utils/compose";
import { withApiService } from "../../hoc";
import { handleLogout } from "../../../redux/auth/actions";
import styles from "./navMobile.module.scss";

import { ReactComponent as BurgerMenuIcon } from "../../../assets/images/burgerMenuIcon.svg";

import { ReactComponent as AchievementsPageMobileNav } from "../../../assets/images/AchievementsPageMobileNav.svg";
import { ReactComponent as RatingsPageMobileNav } from "../../../assets/images/RatingsPageMobileNav.svg";
import { ReactComponent as SettingsPageMobileNav } from "../../../assets/images/SettingsPageMobileNav.svg";
import { ReactComponent as EventsPageMobileNav } from "../../../assets/images/EventsPageMobileNav.svg";
import { ReactComponent as StorePageMobileNav } from "../../../assets/images/EventsPageMobileNav.svg";
import { ReactComponent as ExitPageMobileNav } from "../../../assets/images/ExitPageMobileNav.svg";
import {ReactComponent as BcoinIcon} from "../../../assets/images/bcoin.svg";
import { ReactComponent as MoneyIcon } from "../../../assets/images/moneyIcon.svg";
import { ReactComponent as StoreIcon } from "../../../assets/images/storeIcon.svg";

const NavMobile = ({
  history,
  location,
  isSuperuser,
  handleLogout,
  username,
  points,
  coins
}) => {
  const mobileNavBlock = useRef();
  const [focus, setFocus] = useState(false);

  return (
    <div
      ref={mobileNavBlock}
      tabIndex='-1'
      onBlur={e => {
        e.preventDefault();
        setFocus(false);
      }}
      onFocus={e => {
        e.preventDefault();
      }}
      onClick={e => {
        e.preventDefault();
        if (focus) {
          mobileNavBlock.current.blur();
        } else {
          setFocus(true);
        }
        // mobileNavBlock.current.focus();
      }}
      className={styles.NavMobile}
    >
      <div className={styles.balance}>
        {points}
        <BcoinIcon
          style={{ marginBottom: -3, marginLeft: 5, marginRight: 10 }}
          width={20}
          height={20}
        />
        {coins == null ? 0 : coins}
        <MoneyIcon
            style={{ marginBottom: -3, marginLeft: 5 }}
            width={20}
            height={20}
        />
      </div>
      <div className="mr1"></div>
      <div className={styles.navMobileTitle}>
        <BurgerMenuIcon />
      </div>
      <ul className={styles.navMobileList}>
        <li>
          <div className={styles.username}>{username}</div>
        </li>
        <li
          style={location.pathname === "/events" ? { color: "#5f37ff" } : {}}
          onClick={() => {
            history.push("/events");
            mobileNavBlock.current.blur();
          }}
        >
          <EventsPageMobileNav /> События
        </li>
        <li
          style={location.pathname === "/store" ? { color: "#5f37ff" } : {}}
          onClick={() => {
             history.push("/store");
             mobileNavBlock.current.blur();
          }}
        >
          <StorePageMobileNav /> Магазин
        </li>
        <li
          style={location.pathname === "/projects" ? { color: "#5f37ff" } : {}}
          onClick={() => {
            history.push("/projects");
            mobileNavBlock.current.blur();
          }}
        >
          <EventsPageMobileNav /> Проекты
        </li>
        <li
          style={location.pathname === "/ratings" ? { color: "#5f37ff" } : {}}
          onClick={() => {
            history.push("/ratings");
            mobileNavBlock.current.blur();
          }}
        >
          <RatingsPageMobileNav /> Рейтинг
        </li>
        <li
          style={
            location.pathname === "/achievements" ? { color: "#5f37ff" } : {}
          }
          onClick={() => {
            history.push("/achievements");
            mobileNavBlock.current.blur();
          }}
        >
          <AchievementsPageMobileNav /> Достижения
        </li>
        <li
          style={
            location.pathname === "/purchase" ? { color: "#5f37ff" } : {}
          }
          onClick={() => {
            history.push("/purchase");
            mobileNavBlock.current.blur();
          }}
        >
          <StoreIcon
              style={{ marginBottom: -5, marginRight: 20 }}
              width={20}
              height={20}
          />
        Мои покупки
        </li>
        {isSuperuser ? (
          <li
            style={
              location.pathname === "/settings" ? { color: "#5f37ff" } : {}
            }
            onClick={() => {
              history.push("/settings");
              mobileNavBlock.current.blur();
            }}
          >
            <SettingsPageMobileNav /> Настройки
          </li>
        ) : (
          ""
        )}
        <li
          style={{ paddingTop: 10 }}
          onClick={() => {
            handleLogout();
            mobileNavBlock.current.blur();
          }}
        >
          <ExitPageMobileNav /> Выход
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  isSuperuser: auth.isSuperuser,
  username: auth.username,
  points: auth.points,
  coins: auth.coins,
});
const mapDispatchToProps = (dispatch, { apiService }) => {
  return bindActionCreators(
    {
      handleLogout: handleLogout(),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(NavMobile));
