import {
  FETCH_ACHIEVEMENTS_RATING_REQUEST,
  FETCH_ACHIEVEMENTS_RATING_FAILURE,
  FETCH_ACHIEVEMENTS_RATING_SUCCESS,
  FETCH_POINTS_RATING_REQUEST,
  FETCH_POINTS_RATING_FAILURE,
  FETCH_POINTS_RATING_SUCCESS,
} from "./types";

const getAchievementsRatingRequest = () => {
  return { type: FETCH_ACHIEVEMENTS_RATING_REQUEST };
};
const getAchievementsRatingFailure = err => {
  return { type: FETCH_ACHIEVEMENTS_RATING_FAILURE, payload: err };
};
const getAchievementsRatingSuccess = achievementsRating => {
  return {
    type: FETCH_ACHIEVEMENTS_RATING_SUCCESS,
    payload: achievementsRating,
  };
};

const getPointsRatingRequest = () => {
  return { type: FETCH_POINTS_RATING_REQUEST };
};
const getPointsRatingFailure = err => {
  return { type: FETCH_POINTS_RATING_FAILURE, payload: err };
};
const getPointsRatingSuccess = pointsRating => {
  return { type: FETCH_POINTS_RATING_SUCCESS, payload: pointsRating };
};

export {
  getAchievementsRatingRequest,
  getAchievementsRatingFailure,
  getAchievementsRatingSuccess,
  getPointsRatingRequest,
  getPointsRatingFailure,
  getPointsRatingSuccess,
};
