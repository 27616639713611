import {
  FETCH_ACHIEVEMENTS_ALL_REQUEST,
  FETCH_ACHIEVEMENTS_ALL_SUCCESS,
  FETCH_ACHIEVEMENTS_ALL_FAILURE,
  FETCH_ACHIEVEMENT_REQUEST,
  FETCH_ACHIEVEMENT_SUCCESS,
  FETCH_ACHIEVEMENT_FAILURE,
  FETCH_ACHIEVEMENT_OWNERS_REQUEST,
  FETCH_ACHIEVEMENT_OWNERS_SUCCESS,
  FETCH_ACHIEVEMENT_OWNERS_FAILURE,
  FETCH_ACHIEVEMENTS_OWN_REQUEST,
  FETCH_ACHIEVEMENTS_OWN_SUCCESS,
  FETCH_ACHIEVEMENTS_OWN_FAILURE,
  FETCH_ACHIEVEMENTS_USER_REQUEST,
  FETCH_ACHIEVEMENTS_USER_SUCCESS,
  FETCH_ACHIEVEMENTS_USER_FAILURE,
  CREATE_ACHIEVEMENT,
  EDIT_ACHIEVEMENT,
} from "./types";

const getAllAchievementsRequest = actualRequestId => {
  return { type: FETCH_ACHIEVEMENTS_ALL_REQUEST, payload: actualRequestId };
};

const getAllAchievementsFailure = (error, actualRequestId) => {
  return {
    type: FETCH_ACHIEVEMENTS_ALL_FAILURE,
    payload: { error, actualRequestId },
  };
};

const getAllAchievementsSuccess = (achievementsData, actualRequestId) => {
  return {
    type: FETCH_ACHIEVEMENTS_ALL_SUCCESS,
    payload: { ...achievementsData, actualRequestId },
  };
};

const getAchievementRequest = actualRequestId => {
  return { type: FETCH_ACHIEVEMENT_REQUEST, payload: actualRequestId };
};
const getAchievementFailure = err => {
  return { type: FETCH_ACHIEVEMENT_FAILURE, payload: err };
};
const getAchievementSuccess = (achievements, actualRequestId) => {
  return {
    type: FETCH_ACHIEVEMENT_SUCCESS,
    payload: { achievements, actualRequestId },
  };
};

const getAchievementOwnersRequest = actualRequestId => {
  return { type: FETCH_ACHIEVEMENT_OWNERS_REQUEST, payload: actualRequestId };
};
const getAchievementOwnersFailure = err => {
  return { type: FETCH_ACHIEVEMENT_OWNERS_FAILURE, payload: err };
};
const getAchievementOwnersSuccess = (owners, actualRequestId) => {
  return {
    type: FETCH_ACHIEVEMENT_OWNERS_SUCCESS,
    payload: { ...owners, actualRequestId },
  };
};

const getOwnAchievementsRequest = actualRequestId => {
  return { type: FETCH_ACHIEVEMENTS_OWN_REQUEST, payload: actualRequestId };
};

const getOwnAchievementsFailure = err => {
  return { type: FETCH_ACHIEVEMENTS_OWN_FAILURE, payload: err };
};

const getOwnAchievementsSuccess = (achievementsData, actualRequestId) => {
  return {
    type: FETCH_ACHIEVEMENTS_OWN_SUCCESS,
    payload: { ...achievementsData, actualRequestId },
  };
};

const getUserAchievementsRequest = actualRequestId => {
  return { type: FETCH_ACHIEVEMENTS_USER_REQUEST, payload: actualRequestId };
};

const getUserAchievementsFailure = err => {
  return { type: FETCH_ACHIEVEMENTS_USER_FAILURE, payload: err };
};

const getUserAchievementsSuccess = (achievementsData, actualRequestId) => {
  return {
    type: FETCH_ACHIEVEMENTS_USER_SUCCESS,
    payload: { ...achievementsData, actualRequestId },
  };
};

const createAchievement = () => {
  return { type: CREATE_ACHIEVEMENT };
};
const editAchievement = () => {
  return { type: EDIT_ACHIEVEMENT };
};

export {
  getAllAchievementsRequest,
  getAllAchievementsFailure,
  getAllAchievementsSuccess,
  getAchievementRequest,
  getAchievementFailure,
  getAchievementSuccess,
  getAchievementOwnersRequest,
  getAchievementOwnersFailure,
  getAchievementOwnersSuccess,
  getOwnAchievementsRequest,
  getOwnAchievementsFailure,
  getOwnAchievementsSuccess,
  getUserAchievementsRequest,
  getUserAchievementsFailure,
  getUserAchievementsSuccess,
  createAchievement,
  editAchievement,
};
