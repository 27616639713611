import {
  getGratitudeHistoryRequest,
  getGratitudeHistoryFailure,
  getGratitudeHistorySuccess,
  getAchievementsHistoryRequest,
  getAchievementsHistoryFailure,
  getAchievementsHistorySuccess,
} from "./actionCreators";

import getTimestampDateXDaysAgo from "../../utils/getTimestampDateXDaysAgo";

const mapTimeFilterIdToTimestamp = {
  1: 0, // За все время
  2: getTimestampDateXDaysAgo(1), // За сегодня
  3: getTimestampDateXDaysAgo(7), // За неделю
  4: getTimestampDateXDaysAgo(30), // За месяц
};

const getGratitudeHistory = api => (filter = {}) => dispatch => {
  let formattedFilter = {
    gratitudeCategoryId: filter.category || null,
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    limit: filter.limit || 999999,
  };

  dispatch(getGratitudeHistoryRequest());
  api
    .getGratitudeHistoryTV(formattedFilter)
    .then(res => {
      dispatch(getGratitudeHistorySuccess(res.data.entities));
      console.log(res, "res getGratitudeHistory");
    })
    .catch(err => {
      dispatch(getGratitudeHistoryFailure());
      console.log(err, "err getAchievementsHistory");
    });
};

const getAchievementsHistory = api => (filter = {}) => dispatch => {
  let formattedFilter = {
    gratitudeCategoryId: filter.category || null,
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    limit: filter.limit || 999999,
  };
  dispatch(getAchievementsHistoryRequest());

  api
    .getAchievementsHistoryTV(formattedFilter)
    .then(res => {
      dispatch(getAchievementsHistorySuccess(res.data.entities));
      console.log(res, "res getAchievementsHistory");
    })
    .catch(err => {
      dispatch(getAchievementsHistoryFailure());
      console.log(err, "err getAchievementsHistory");
    });
};

export { getGratitudeHistory, getAchievementsHistory };
