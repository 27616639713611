import {
  FETCH_PROJECTS_ALL_REQUEST,
  FETCH_PROJECTS_ALL_SUCCESS,
  FETCH_MORE_PROJECTS_ALL_SUCCESS,
  FETCH_PROJECTS_ALL_FAILURE,
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_FAILURE,
  FETCH_PROJECT_USER_MAIN_REQUEST,
  FETCH_PROJECT_USER_MAIN_SUCCESS,
  FETCH_PROJECT_USER_MAIN_FAILURE,
  FETCH_PROJECT_USER_MINOR_REQUEST,
  FETCH_PROJECT_USER_MINOR_SUCCESS,
  FETCH_PROJECT_USER_MINOR_FAILURE,
  FETCH_PROJECTS_OWN_REQUEST,
  FETCH_PROJECTS_OWN_SUCCESS,
  FETCH_PROJECTS_OWN_FAILURE,
  FETCH_PROJECTS_MAIN_USER_REQUEST,
  FETCH_PROJECTS_MAIN_USER_SUCCESS,
  FETCH_PROJECTS_MAIN_USER_FAILURE,
  FETCH_PROJECTS_MINOR_USER_REQUEST,
  FETCH_PROJECTS_MINOR_USER_SUCCESS,
  FETCH_PROJECTS_MINOR_USER_FAILURE,
  CREATE_PROJECT,
  EDIT_PROJECT,
} from "./types";

const getAllProjectsRequest = actualRequestId => {
  return { type: FETCH_PROJECTS_ALL_REQUEST, payload: actualRequestId };
};

const getAllProjectsFailure = (error, actualRequestId) => {
  return {
    type: FETCH_PROJECTS_ALL_FAILURE,
    payload: { error, actualRequestId },
  };
};

const getAllProjectsSuccess = (projectsData, actualRequestId) => {
  return {
    type: FETCH_PROJECTS_ALL_SUCCESS,
    payload: {...projectsData, actualRequestId },
  };
};
const getAllProjectsMoreSuccess = (projectsData, actualRequestId) => {
  return { type: FETCH_MORE_PROJECTS_ALL_SUCCESS, payload: { ...projectsData, actualRequestId } }
}

const getProjectRequest = actualRequestId => {
  return { type: FETCH_PROJECT_REQUEST, payload: actualRequestId };
};
const getProjectFailure = (error, actualRequestId) => {
  return { type: FETCH_PROJECT_FAILURE, payload: { error, actualRequestId } };
};
const getProjectSuccess = (projects, actualRequestId) => {
  return {
    type: FETCH_PROJECT_SUCCESS,
    payload: { projects, actualRequestId },
  };
};
const getProjectUsersMainRequest = () => {
  return { type: FETCH_PROJECT_USER_MAIN_REQUEST};
};
const getProjectUsersMainFailure = error => {
  return { type: FETCH_PROJECT_USER_MAIN_FAILURE, payload: error };
};
const getProjectUsersMainSuccess = projects => {
  return {
    type: FETCH_PROJECT_USER_MAIN_SUCCESS,
    payload: projects
  };
};
const getProjectUsersMinorRequest = () => {
  return { type: FETCH_PROJECT_USER_MINOR_REQUEST };
};
const getProjectUsersMinorFailure = error => {
  return { type: FETCH_PROJECT_USER_MINOR_FAILURE, payload: error};
};
const getProjectUsersMinorSuccess = (projects) => {
  return {
    type: FETCH_PROJECT_USER_MINOR_SUCCESS,
    payload: projects,
  };
};

const getOwnProjectsRequest = actualRequestId => {
  return { type: FETCH_PROJECTS_OWN_REQUEST, payload: actualRequestId };
};

const getOwnProjectsFailure = (error, actualRequestId) => {
  return {
    type: FETCH_PROJECTS_OWN_FAILURE,
    payload: { error, actualRequestId },
  };
};

const getOwnProjectsSuccess = (projectsData, actualRequestId) => {
  return {
    type: FETCH_PROJECTS_OWN_SUCCESS,
    payload: { ...projectsData, actualRequestId },
  };
};

const getUserMainProjectsRequest = actualRequestId => {
  return { type: FETCH_PROJECTS_MAIN_USER_REQUEST, payload: actualRequestId };
};

const getUserMainProjectsFailure = (error, actualRequestId) => {
  return {
    type: FETCH_PROJECTS_MAIN_USER_FAILURE,
    payload: { error, actualRequestId },
  };
};

const getUserMainProjectsSuccess = (projectsData, actualRequestId) => {
  return {
    type: FETCH_PROJECTS_MAIN_USER_SUCCESS,
    payload: { ...projectsData, actualRequestId },
  };
};

const getUserMinorProjectsRequest = actualRequestId => {
  return { type: FETCH_PROJECTS_MINOR_USER_REQUEST, payload: actualRequestId };
};

const getUserMinorProjectsFailure = (error, actualRequestId) => {
  return {
    type: FETCH_PROJECTS_MINOR_USER_FAILURE,
    payload: { error, actualRequestId },
  };
};

const getUserMinorProjectsSuccess = (projectsData, actualRequestId) => {
  return {
    type: FETCH_PROJECTS_MINOR_USER_SUCCESS,
    payload: { ...projectsData, actualRequestId },
  };
};


const createProject = () => {
  return { type: CREATE_PROJECT };
};
const editProject = () => {
  return { type: EDIT_PROJECT };
};

export {
  getAllProjectsRequest,
  getAllProjectsFailure,
  getAllProjectsSuccess,
  getAllProjectsMoreSuccess,
  getProjectRequest,
  getProjectFailure,
  getProjectSuccess,
  getProjectUsersMainRequest,
  getProjectUsersMainFailure,
  getProjectUsersMainSuccess,
  getProjectUsersMinorRequest,
  getProjectUsersMinorFailure,
  getProjectUsersMinorSuccess,
  getOwnProjectsRequest,
  getOwnProjectsFailure,
  getOwnProjectsSuccess,
  getUserMainProjectsRequest,
  getUserMainProjectsFailure,
  getUserMainProjectsSuccess,
  getUserMinorProjectsRequest,
  getUserMinorProjectsFailure,
  getUserMinorProjectsSuccess,
  createProject,
  editProject,
};
