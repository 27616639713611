import React from "react";
import { withRouter } from "react-router-dom";

import styles from "./notFoundPage.module.scss";

import { Button } from "../FormItems";

const NotFoundPage = ({ history }) => {
  return (
    <div className={styles.NotFoundPage}>
      <div className='mt3'></div>

      <div className='titleH1' style={{ color: "#5f37ff" }}>
        404
      </div>
      <div className='mt2'></div>
      <div className='titleH1'>Кажется, что-то пошло не так…</div>
      <div className='mt2'></div>
      <Button
        label='Вернуться назад'
        handleClick={() => {
          history.push("/");
        }}
      />
      <div className='mt3'></div>
    </div>
  );
};

export default withRouter(NotFoundPage);
