import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../hoc";
import compose from "../../utils/compose";
import { getEventsHistory } from "../../redux/history/actions";
import { getGratitudeCategories } from "../../redux/points/actions";

import InfiniteScroll from "react-infinite-scroller";

import AchievementCard from "./AchievementCard";
import PointsCard from "./PointsCard";
import LoadScreen from "../LoadScreen";
import styles from "./events.module.scss";

import { SelectFilter } from "../FormItems";
import Pagination from "../Tables/Pagination";
import getUrlParam from "../../utils/getUrlParam";

const Events = ({
  getGratitudeCategories,
  getEventsHistory,
  eventsHistory,
  eventsCount,
  pointsCategories = [],
  loading,
}) => {
  const desktop = window.innerWidth < 768 ? false : true;
  let urlParam = getUrlParam('page');
  const [eventsFilter, setEventsFilter] = useState({
    limit: desktop ? 20 : 4,
    offset: (urlParam ? (urlParam - 1) : 0) * (desktop ? 20 : 4),
    time: localStorage.getItem('eventsFilterTime') ? localStorage.getItem('eventsFilterTime') : 4,
    eventType: localStorage.getItem('eventsFilterType') ? localStorage.getItem('eventsFilterType') : "all",
    gratitudeCategoryId: localStorage.getItem('eventsFilterCategory') ? localStorage.getItem('eventsFilterCategory') : null,
  }
  );

  const [page, setPage] = useState(1);
  const [showEventsType, setShowEventsType] = useState(
      localStorage.getItem('eventsFilterType') == 'achievement' ? { id: "achievement", name: "Только достижения" } :
          localStorage.getItem('eventsFilterType') == 'points' ? { id: "points", name: "Только начисления"} :
          localStorage.getItem('eventsFilterType') == 'all' ? { id: "all", name: "Все события"} : {id: "all", name: "Все события"}
  );

  useEffect(() => {
    localStorage.removeItem('allAchievementsVisibleBlock');
    localStorage.removeItem('achievementsHistoryFilterTime');

    localStorage.removeItem('pointsRatingFilterTime');
    localStorage.removeItem('pointsRatingFilterCategory');
    localStorage.removeItem('achievementsVisibleBlock');
    localStorage.removeItem('achievementsRatingFilterTime');

    localStorage.removeItem('homePointsFilter');
    localStorage.removeItem('otherUserPointsFilter');

    localStorage.removeItem('productsFilterType');
    localStorage.removeItem('coinFilterFrom');
    localStorage.removeItem('coinFilterTo');

    getEventsHistory({...eventsFilter, page: eventsFilter.offset/eventsFilter.limit}, true);
    getGratitudeCategories();
  }, []);

  const updateEventsFilter = (fieldName, fieldValue) => {
    if(!desktop){ window.history.pushState(null, null, `?page=1`)};
    setEventsFilter(prevState => ({
      ...prevState,
      [fieldName]: fieldValue.id, offset: 0, page: 0
    }));
    if (fieldName == 'time'){localStorage.setItem('eventsFilterTime', fieldValue.id)}
    if (fieldName == 'eventType'){localStorage.setItem('eventsFilterType', fieldValue.id); setShowEventsType({id: fieldValue.id, name: fieldValue.name})}
    if (fieldName == 'gratitudeCategoryId'){localStorage.setItem('eventsFilterCategory', fieldValue.id)}

    setPage(1);
    getEventsHistory(
      { ...eventsFilter, [fieldName]: fieldValue.id, page: 0, offset: 0 },
      true,
    );
  };
  const loadData = () => {
    getEventsHistory({ ...eventsFilter, page });
    setPage(page + 1);
  };

  // const sortedArray = eventsHistory.sort((a, b) => b.createdAt - a.createdAt );

  const timeFilterOptions = [
    { id: 1, name: "За все время" },
    { id: 2, name: "За 24 часа" },
    { id: 3, name: "За 7 дней" },
    { id: 4, name: "За 30 дней" },
  ];

  if (eventsHistory.length <= 0 && loading) {
    return <LoadScreen />;
  }
  return (
    <div className={styles.Events}>
      <div className='mt1'></div>
      <div className={styles.filtersRow}>
        <div className={styles.filterContainer}>
          <SelectFilter
            width={212}
            placeholder='Все события'
            onSelect={value => {
              setShowEventsType(value);
              updateEventsFilter("eventType", value);
            }}
            selectedValue={showEventsType}
            clearSelect={() => {
              setShowEventsType({ id: "all", name: "Все события" });
              updateEventsFilter("eventType", { id: "all", name: "Все события" });
            }}
            options={[
              { id: "all", name: "Все события" },
              { id: "achievement", name: "Только достижения" },
              { id: "points", name: "Только начисления" },
            ]}
          />
        </div>
        <div className={styles.filterContainer}>
          <SelectFilter
            width={180}
            placeholder='Время событий'
            onSelect={value => {
              updateEventsFilter("time", value);
            }}
            selectedValue={timeFilterOptions.find(
              item => item.id === +eventsFilter.time,
            )}
            clearSelect={() => {
              updateEventsFilter("time", {});
            }}
            options={timeFilterOptions}
          />
        </div>
        <div className={styles.filterContainer}>
          <SelectFilter
              width={245}
              placeholder='Категория начислений'
              onSelect={value => {
                updateEventsFilter("gratitudeCategoryId", value);
              }}
              selectedValue={pointsCategories.find(
                  item => item.id === +eventsFilter.gratitudeCategoryId,
              )}
              options={pointsCategories}
              clearSelect={() => {
                updateEventsFilter("gratitudeCategoryId", {});
              }}
          />
        </div>
      </div>
      <div></div>
      {!desktop ? (
        <>
          <div
              className={styles.CardsTable}
          >
            {eventsHistory.map(cardData => {
              if (cardData.achievement) {
                return (
                    <AchievementCard
                        cardData={cardData}
                        key={`${cardData.id}-${cardData.achievement.name}_mobile`}
                    />
                );
              } else {
                return (
                    <PointsCard
                        cardData={cardData}
                        key={`${cardData.id}- ${cardData.value} mobile`}
                    />
                );
              }
            })}
          </div>
          {eventsCount > eventsFilter.limit ?
            (<Pagination
              total={eventsCount}
              offset={(urlParam ? (urlParam - 1) : eventsFilter.offset) * eventsFilter.limit}
              limit={eventsFilter.limit}
              onChange={newPage => {
                setEventsFilter( { ...eventsFilter, offset: (newPage - 1) * eventsFilter.limit, page: newPage - 1 })
                getEventsHistory({ ...eventsFilter, offset: (newPage - 1) * eventsFilter.limit, page: newPage - 1},true);

              }}
             />
            ) : ''
          }
        </>
      ) : (
          <InfiniteScroll
              className={styles.CardsTable}
              loadMore={loadData}
              initialLoad={false}
              hasMore={!loading && eventsHistory.length < eventsCount}
          >
            {eventsHistory.map(cardData => {
              if (cardData.achievement) {
                return (
                    <AchievementCard
                        cardData={cardData}
                        key={`${cardData.id} - ${cardData.achievement.name}`}
                    />
                );
              } else {
                return (
                    <PointsCard
                        cardData={cardData}
                        key={`${cardData.id} - ${cardData.value}`}
                    />
                );
              }
            })}
          </InfiniteScroll>
      )}
    </div>
  );
};

const mapStateToProps = ({ history, points }) => ({
  pointsCategories: points.pointsCategories,
  eventsHistory: history.historyEvents,
  eventsCount: history.eventsCount,
  loading: history.loading,
});
const mapDispatchToProps = (dispatch, { apiService, filter }) => {
  return bindActionCreators(
    {
      getGratitudeCategories: getGratitudeCategories(apiService),
      getEventsHistory: getEventsHistory(apiService, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(Events);
