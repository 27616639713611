import {
  getGratitudeHistoryRequest,
  getGratitudeHistoryFailure,
  getGratitudeHistorySuccess,
  getAchievementsHistoryRequest,
  getAchievementsHistoryFailure,
  getAchievementsHistorySuccess,
  getEventsHistoryRequest,
  getEventsHistorySuccess,
  getEventsHistoryMoreSuccess,
} from "./actionCreators";

import { eventTypes } from "./history-utils";

import getTimestampDateXDaysAgo from "../../utils/getTimestampDateXDaysAgo";

const mapTimeFilterIdToTimestamp = {
  1: 0, // За все время
  2: getTimestampDateXDaysAgo(1), // За сегодня
  3: getTimestampDateXDaysAgo(7), // За неделю
  4: getTimestampDateXDaysAgo(30), // За месяц
};

const getGratitudeHistory = api => (filter = {}) => dispatch => {
  let formattedFilter = {
    gratitudeCategoryId: filter.category || null,
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    limit: filter.limit || 999999,
  };

  dispatch(getGratitudeHistoryRequest());
  api
    .getGratitudeHistory(formattedFilter)
    .then(res => {
      dispatch(getGratitudeHistorySuccess(res.data.entities));
      console.log(res, "res getGratitudeHistory");
    })
    .catch(err => {
      dispatch(getGratitudeHistoryFailure());
      console.log(err, "err getAchievementsHistory");
    });
};

const getAchievementsHistory = api => (filter = {}) => dispatch => {
  let formattedFilter = {
    gratitudeCategoryId: filter.category || null,
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    limit: filter.limit || 999999,
    offset: filter.offset || 0,
  };
  dispatch(getAchievementsHistoryRequest());

  api
    .getAchievementsHistory(formattedFilter)
    .then(res => {
      dispatch(getAchievementsHistorySuccess(res.data));
      console.log(res, "res getAchievementsHistory");
    })
    .catch(err => {
      dispatch(getAchievementsHistoryFailure());
      console.log(err, "err getAchievementsHistory");
    });
};

const getEventsHistory = api => (filter = {}, force) => dispatch => {
  console.log(filter, 'filter')
  const formattedFilter = {
    dtFrom: mapTimeFilterIdToTimestamp[filter.time] || null,
    eventType: filter.gratitudeCategoryId
      ? eventTypes.EVENT_TYPE_GRATITUDE
      : filter.eventType || eventTypes.EVENT_TYPE_ALL,
    gratitudeCategoryId: filter.gratitudeCategoryId || null,
    page: filter.page || 0,
    limit: filter.limit || 20,
    offset: filter.offset || 0
  };
  console.log(formattedFilter, 'formattedFilter')
  dispatch(getEventsHistoryRequest());

  api
    .getEventsHistory(formattedFilter)
    .then(res => {
      if (force) {
        dispatch(getEventsHistorySuccess(res.data));
      } else {
        dispatch(getEventsHistoryMoreSuccess(res.data));
      }
    })
    .catch(err => {
      dispatch(getAchievementsHistoryFailure());
    });
};

export { getGratitudeHistory, getAchievementsHistory, getEventsHistory };
