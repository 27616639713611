import {
  FETCH_HISTORY_GRATITUDE_REQUEST,
  FETCH_HISTORY_GRATITUDE_FAILURE,
  FETCH_HISTORY_GRATITUDE_SUCCESS,
  FETCH_HISTORY_ACHIEVEMENTS_REQUEST,
  FETCH_HISTORY_ACHIEVEMENTS_FAILURE,
  FETCH_HISTORY_ACHIEVEMENTS_SUCCESS,
  FETCH_HISTORY_EVENTS_REQUEST,
  FETCH_HISTORY_EVENTS_SUCCESS,
  FETCH_MORE_HISTORY_EVENTS_SUCCESS,
} from './types';

const getGratitudeHistoryRequest = () => {
  return { type: FETCH_HISTORY_GRATITUDE_REQUEST };
};
const getGratitudeHistoryFailure = err => {
  return { type: FETCH_HISTORY_GRATITUDE_FAILURE, payload: err };
};
const getGratitudeHistorySuccess = historyGratitude => {
  return { type: FETCH_HISTORY_GRATITUDE_SUCCESS, payload: historyGratitude };
};

const getAchievementsHistoryRequest = () => {
  return { type: FETCH_HISTORY_ACHIEVEMENTS_REQUEST };
};
const getAchievementsHistoryFailure = err => {
  return { type: FETCH_HISTORY_ACHIEVEMENTS_FAILURE, payload: err };
};
const getAchievementsHistorySuccess = historyAchievements => {
  return {
    type: FETCH_HISTORY_ACHIEVEMENTS_SUCCESS,
    payload: historyAchievements
  };
};

const getEventsHistoryRequest = () => {
  return { type: FETCH_HISTORY_EVENTS_REQUEST }
}

const getEventsHistoryMoreSuccess = (payload) => {
  return { type: FETCH_MORE_HISTORY_EVENTS_SUCCESS, payload }
}

const getEventsHistorySuccess = (payload) => {
  return { type: FETCH_HISTORY_EVENTS_SUCCESS, payload }
}

export {
  getGratitudeHistoryRequest,
  getGratitudeHistoryFailure,
  getGratitudeHistorySuccess,
  getAchievementsHistoryRequest,
  getAchievementsHistoryFailure,
  getAchievementsHistorySuccess,
  getEventsHistoryRequest,
  getEventsHistorySuccess,
  getEventsHistoryMoreSuccess,
};
