import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
import styles from "./achievements.module.scss";

import {SelectFilter, CheckBox, Button} from "../FormItems";
import { GridTable } from "../Tables";
import  getUrlParam from "../../utils/getUrlParam";
import { withApiService } from "../hoc";
import compose from "../../utils/compose";

import { getAllAchievements } from "../../redux/achievements/actions";
import { getAchievementsHistory } from "../../redux/history/actions";

import { ReactComponent as PlaceholderCardImage } from "../../assets/images/PlaceholderCardImage.svg";
import Pagination from "../Tables/Pagination";


const Achievements = ({
  history,
  achievements = [],
  achievementsCount,
  eventsCount,
  achievementsLoading = true,
  historyAchievements = [],
  historyAchievementsLoading = true,
  ownId,
  getAllAchievements,
  getAchievementsHistory,
}) => {

  const VisBlock = localStorage.getItem('allAchievementsVisibleBlock') ? localStorage.getItem('allAchievementsVisibleBlock') : 'allAchievements';
  const desktop = window.innerWidth < 991 ? false : true;
  let urlParam = getUrlParam('page');

  const [activeButton, setActiveButton] = useState('allAchievements')
  const [achievementsFilter, setAchievementsFilter] = useState({
        time: 4,
        limit: desktop ? 9999 : 8,
        offset: (urlParam ? urlParam - 1 : 0) * (desktop ? 9999 : 8)
      },
  );
  const [achievementsHistoryFilter, setAchievementsHistoryFilter] = useState(
    {
      time: localStorage.getItem("achievementsHistoryFilterTime") ? +localStorage.getItem("achievementsHistoryFilterTime") : 4,
      limit: desktop ? 9999 : 8,
      offset: (urlParam ? urlParam - 1 : 0) * (desktop ? 50 : 8)
    },
  );

  useEffect(() => {
      localStorage.removeItem('pointsRatingFilterTime');
      localStorage.removeItem('pointsRatingFilterCategory');
      localStorage.removeItem('achievementsVisibleBlock');
      localStorage.removeItem('achievementsRatingFilterTime');

      localStorage.removeItem('eventsFilterTime');
      localStorage.removeItem('eventsFilterType');
      localStorage.removeItem('eventsFilterCategory');

      localStorage.removeItem('homePointsFilter');
      localStorage.removeItem('otherUserPointsFilter');

      localStorage.removeItem('productsFilterType');
      localStorage.removeItem('coinFilterFrom');
      localStorage.removeItem('coinFilterTo');

      if(desktop) {
          getAllAchievements(achievementsFilter);
          getAchievementsHistory(achievementsHistoryFilter);
      } else {
          if (localStorage.getItem('allAchievementsVisibleBlock') == 'allAchievements' || !localStorage.getItem('allAchievementsVisibleBlock')) {
              getAllAchievements(achievementsFilter)
          } else if (localStorage.getItem('allAchievementsVisibleBlock') == 'recentAchievements') {
              getAchievementsHistory(achievementsHistoryFilter);
          }
      }

  }, []);

  const updateAchievementsHistoryFilter = (fieldName, fieldValue) => {
    if(!desktop){ window.history.pushState(null, null, `?page=1`)};
    setAchievementsHistoryFilter(prevState => ({
      ...prevState, offset: 0,
      [fieldName]: fieldValue.id,
    }));

    localStorage.setItem("achievementsHistoryFilterTime", fieldValue.id);
    getAchievementsHistory({
      ...achievementsHistoryFilter, offset: 0,
      [fieldName]: fieldValue.id,
    });
  };
  const timeFilterOptions = [
    { id: 1, name: "За все время" },
    { id: 2, name: "За 24 часа" },
    { id: 3, name: "За 7 дней" },
    { id: 4, name: "За 30 дней" },
  ];


  return (
    <>
      <div className='mt3'></div>
      <div className={styles.infoContainerControls}>
        <div className={styles.slidersContainer}>
          <button
            className={activeButton == 'allAchievements' ? styles.slidersContainerButtonActive : styles.slidersContainerButton}
            onClick={() => {
              setActiveButton('allAchievements');
              localStorage.setItem('allAchievementsVisibleBlock', 'allAchievements');
              setAchievementsFilter({...achievementsFilter, offset: 0 });
              window.history.pushState(null, null, `?page=1`);
              getAllAchievements({ limit:8, offset: 0 });
            }}
          >
            Все достижения
          </button>
        </div>
        <div className={styles.slidersContainer}>
          <button
            className={activeButton == 'recentAchievements' ? styles.slidersContainerButtonActive : styles.slidersContainerButton}
            onClick={() => {
              setActiveButton('recentAchievements');
              setAchievementsHistoryFilter({ ...achievementsHistoryFilter, offset: 0 });
              localStorage.setItem('allAchievementsVisibleBlock', 'recentAchievements');
              window.history.pushState(null, null, `?page=1`);
              getAchievementsHistory({ ...achievementsHistoryFilter, offset: 0});
            }}
          >
              Недавние
          </button>
        </div>
      </div>
      <div className={styles.twoColumns}>
        <div style={{display: desktop ? 'block' : VisBlock == 'allAchievements' ? 'block' : 'none'}}>
            <div className={styles.Achievements} >
              <div className={styles.AchievementsHeader}>
                <div className='titleH1'>Достижения</div>
              </div>
              <div className='mt1'></div>
              <GridTable
                onClickRow={rowData => {
                  history.push(`/achievements/${rowData.id}`);
                }}
                loading={achievementsLoading}
                dataSource={achievements}
                columns={[
                  {
                    title: "Достижение",
                    dataIndex: "name",
                    render: ({ name, image, id }) => {
                      return (
                        <Link to={`/achievements/${id}`}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {image ? (
                              <div
                                style={{
                                  width: "36px",
                                  height: "36px",
                                  overflow: "hidden",
                                  borderRadius: 8,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  style={{
                                    width: "auto",
                                    height: "100%",
                                  }}
                                  src={window.location.origin + image.image}
                                  alt='achievement'
                                />
                              </div>
                            ) : (
                              <PlaceholderCardImage
                                width='36px'
                                height='36px'
                                style={{ borderRadius: 8 }}
                              />
                            )}
                            <div
                              style={{
                                fontSize: 14,
                                fontWeight: 500,
                                marginLeft: 10,
                              }}
                            >
                              {name}
                            </div>
                          </div>
                        </Link>
                      );
                    },
                  },
                  {
                    title: "Уникальность",
                    dataIndex: "holdersPercent",
                    width: "25%",
                    render: rowData => {
                      return (
                        <Link to={`/achievements/${rowData.id}`}>
                          Есть у {rowData.holders_percent}%
                        </Link>
                      );
                    },
                  },
                  {
                    title: "Наличие",
                    dataIndex: "totalAchievements",
                    width: "13%",
                    render: rowData => {
                      return (
                        <Link to={`/achievements/${rowData.id}`}>
                          <CheckBox
                            prevValuesList={rowData.owners}
                            value={{ id: ownId }}
                            defValue={
                              rowData.owners
                                ? rowData.owners.find(item => item.id === ownId)
                                : false
                            }
                          />
                        </Link>
                      );
                    },
                  },
                ]}
                maxHeight={'734px'}
              />
            </div>
            {!desktop &&  achievementsCount > achievementsFilter.limit ? (
                <Pagination
                    total={achievementsCount}
                    offset={(urlParam ? (urlParam - 1) : achievementsFilter.offset) * achievementsFilter.limit}
                    limit={achievementsFilter.limit}
                    onChange={newPage => {
                        setAchievementsFilter({...achievementsFilter,  offset: (newPage - 1) * achievementsFilter.limit})
                        getAllAchievements({...achievementsFilter,  offset: (newPage - 1) * achievementsFilter.limit})
                    }}
                />
            ) : (
                ""
            )}
        </div>
        <div style={{display: desktop ? 'block' : VisBlock == 'recentAchievements' ? 'block' : 'none'}}>
            <div className={styles.Achievements} >
              <div className={styles.achievementsHistoryTitle}>Недавние</div>
              <div className='mt1'></div>

              <SelectFilter
                placeholder='За все время'
                onSelect={value => {
                  updateAchievementsHistoryFilter("time", value);
                }}
                options={timeFilterOptions}
                selectedValue={
                  timeFilterOptions.find(
                    item => item.id === achievementsHistoryFilter.time,
                  ) || {}
                }
                clearSelect={() => {
                  updateAchievementsHistoryFilter("time", {});
                }}
              />

              <GridTable
                loading={historyAchievementsLoading}
                dataSource={historyAchievements}
                columns={[
                  {
                    title: "",
                    dataIndex: "name",
                    render: rowData => {
                      return (
                        <Link to={`/user/${rowData.user.id}`}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {rowData.achievement.image ? (
                              <div
                                style={{
                                  width: "36px",
                                  height: "36px",
                                  overflow: "hidden",
                                  borderRadius: 8,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                  }}
                                  src={
                                    window.location.origin +
                                    rowData.achievement.image.image
                                  }
                                  alt='achievement'
                                />
                              </div>
                            ) : (
                              <PlaceholderCardImage
                                width='36px'
                                height='36px'
                                style={{ borderRadius: 8 }}
                              />
                            )}
                            <div
                              style={{
                                fontSize: 14,
                                fontWeight: 500,
                                marginLeft: 10,
                              }}
                            >
                              {rowData.user.firstName +
                                " " +
                                rowData.user.lastName || rowData.user.nickname}
                            </div>
                          </div>
                        </Link>
                      );
                    },
                  },
                ]}
              />
            </div>
            {!desktop &&  eventsCount > achievementsHistoryFilter.limit ? (
                <Pagination
                    total={eventsCount}
                    offset={(urlParam ? (urlParam - 1) : achievementsHistoryFilter.offset) * achievementsHistoryFilter.limit}
                    limit={achievementsHistoryFilter.limit}
                    onChange={newPage => {
                        setAchievementsHistoryFilter({ ...achievementsHistoryFilter, offset: (newPage - 1) * achievementsHistoryFilter.limit})
                        getAchievementsHistory({ ...achievementsHistoryFilter, offset: (newPage - 1) * achievementsHistoryFilter.limit})
                    }}
                />
            ) : (
                ""
            )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = ({ achievements, history, auth }) => ({
  achievements: achievements.achievements,
  achievementsCount: achievements.achievementsCount,
  historyAchievements: history.historyAchievements,
  eventsCount: history.eventsCount,
  achievementsLoading: achievements.loading,
  historyAchievementsLoading: history.loading,
  ownId: auth.userId,
});
const mapDispatchToProps = (dispatch, { apiService, filter }) => {
  return bindActionCreators(
    {
      getAllAchievements: getAllAchievements(apiService, filter),
      getAchievementsHistory: getAchievementsHistory(apiService, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Achievements));
