import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../../hoc";
import compose from "../../../utils/compose";
import {
  getAllAchievements,
  handleCreateAchievement,
  handleEditAchievement,
  handleRemoveAchievement,
} from "../../../redux/achievements/actions";
import { searchUsersByName } from "../../../redux/search/actions";

import styles from "./AchievementTab.module.scss";
import { GridTable } from "../../Tables";
import { Button, Search } from "../../FormItems";
import EditAchievementForm from "../EditAchievementForm";
import { ReactComponent as PlaceholderCardImage } from "../../../assets/images/PlaceholderCardImage.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/editIcon.svg";
import { ReactComponent as AddIcon } from "../../../assets/images/addIcon.svg";
import Pagination from "../../Tables/Pagination";
import {ReactComponent as BreadcrumbsBlockArrow} from "../../../assets/images/breadcrumbsBlockArrow.svg";
import getUrlParam from "../../../utils/getUrlParam";

const AchievementTab = ({
  achievements,
  achievementsLoading,
  achievementsCount,
  getAllAchievementsForAdmin,
  handleCreateAchievement,
  handleEditAchievement,
  handleRemoveAchievement,
  searchUsersByName,
}) => {
    const desktop = window.innerWidth < 768 ? false : true;
    let urlParam = getUrlParam('page');
    const [filter, setFilter] = useState({
        limit: desktop ? 9999 : 8,
        offset: (urlParam ? urlParam - 1 : 0) * (desktop ? 9999 : 8),
    });

  const [selectedRow, setSelectedRow] = useState("");
  useEffect(() => {
    getAllAchievementsForAdmin(filter);
  }, []);
  return (
    <div className={styles.AchievementTab}>
      { window.innerWidth > 768 || typeof selectedRow !== "object" ?
          <div className={styles.tableBlock}>
              <div className='titleH1'>
                  Список достижений ({achievementsCount})
              </div>
              <div className='mt2'></div>
              <div className={styles.twoFlexColumns}>
              <div style={{ width: '100%' }}>
                <Search
                  label='Название достижения'
                  placeholder='Название достижения'
                  onSelect={value => {}}
                  searchResults={[]}
                  requestStartSumbolIndex={0}
                  searchRequest={val => {
                    getAllAchievementsForAdmin({ name: val });

                    setFilter(oldFilter => ({ ...oldFilter, name: val }));
                  }}
                  selectedValue={{}}
                />
              </div>
              <Button
                style={{ height: "48px", width: " 48px", marginTop: 18,  marginLeft: 18, padding: 10 }}
                label={<AddIcon />}
                handleClick={() => {
                  searchUsersByName({ limit: 99999 });
                  setSelectedRow({});
                }}
              />
            </div>
            <GridTable
              loading={achievementsLoading}
              differentRowColor={true}
              onClickRow={rowData => {
                setSelectedRow(rowData);
                  window.history.pushState(null, null, `?page=1`);
              }}
              highlightRow={rowData => rowData.id === selectedRow.id}
              dataSource={achievements}
              columns={[
                {
                  title: "",
                  dataIndex: "name",
                  render: rowData => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {rowData.image ? (
                            <div
                              style={{
                                width: 36,
                                height: 36,
                                borderRadius: 8,
                                display: "flex",
                                overflow: "hidden",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "auto",
                                }}
                                src={window.location.origin + rowData.image.image}
                                alt='achievement'
                              />
                            </div>
                          ) : (
                            <PlaceholderCardImage
                              width='30px'
                              height='30px'
                              style={{
                                borderRadius: 8,
                              }}
                            />
                          )}
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: 500,
                              marginLeft: 10,
                            }}
                          >
                            {rowData.name}
                          </div>
                        </div>
                        <EditIcon
                          onClick={() => {
                            setSelectedRow(rowData);
                          }}
                          style={{
                            borderRadius: 6,
                            boxShadow: "0px 5px 19px -10px rgba(0, 0, 0, 0.75)",
                          }}
                        />
                      </div>
                    );
                  },
                },
              ]}
            />
              {!desktop &&  achievementsCount > filter.limit ? (
                  <Pagination
                      total={achievementsCount}
                      offset={(urlParam ? (urlParam - 1) : filter.offset) * filter.limit}
                      limit={filter.limit}
                      onChange={newPage => {
                          setFilter({...filter, offset: (newPage - 1) * filter.limit })
                          getAllAchievementsForAdmin( { ...filter, offset: (newPage - 1) * filter.limit, limit: filter.limit })
                      }}
                  />
              ) : (
                  ""
              )}
          </div>
          : ''
      }
      {typeof selectedRow === "object" ? (
        <>
          {!desktop ?
              <div className={styles.breadcrumbsBlock}>
            <span className={styles.breadcrumbsArrow}>
              <BreadcrumbsBlockArrow
                  style={{ marginLeft: 5, width: '12px', height: '12px', fill: '#5f37ff', transform: 'rotate(180deg)' }}
                  width={8}
                  height={12}
              />
            </span>
                  <div
                      className={styles.breadcrumb}
                      onClick={() => {
                          setSelectedRow("");
                          setFilter({...filter,  name: ''});
                          getAllAchievementsForAdmin({...filter,  name: ''});
                          window.history.pushState(null, null, `?page=1`);
                        }
                      }
                  >
                      Настройки
                  </div>
              </div> : ''
          }
          <div className={styles.formBlock}>
            <div className="mt1"></div>
            <EditAchievementForm
              achievementData={selectedRow || {}}
              onSave={achievementData => {
                selectedRow.id
                  ? handleEditAchievement(achievementData, filter)
                  : handleCreateAchievement(achievementData, filter);

                setSelectedRow("");
                setFilter({...filter,  name: ''});
                getAllAchievementsForAdmin({...filter,  name: ''});
              }}
              onRemove={
                selectedRow.id
                  ? achievementData => {
                      handleRemoveAchievement(achievementData.id, filter);
                      setSelectedRow("");
                      setFilter({...filter,  name: ''});
                      getAllAchievementsForAdmin({...filter,  name: ''});
                    }
                  : ""
              }
            />
          </div>
        </>
      ) : (
          ""
      )}
    </div>
  );
};

const mapStateToProps = ({ achievements }) => ({
  achievements: achievements.achievements,
  achievementsLoading: achievements.loading,
  achievementsCount: achievements.achievementsCount,
});
const mapDispatchToProps = (
  dispatch,
  { apiService, filter, achievementData, achievementFilter, achievementId },
) => {
  return bindActionCreators(
    {
      // getAllAchievementsForAdmin: getAllAchievementsForAdmin(
      //   apiService,
      //   achievementFilter,
      // ),
        getAllAchievementsForAdmin: getAllAchievements(
            apiService,
            achievementFilter,
        ),
      handleRemoveAchievement: handleRemoveAchievement(
        apiService,
        achievementId,
        achievementFilter,
      ),
      handleCreateAchievement: handleCreateAchievement(
        apiService,
        achievementData,
        achievementFilter,
      ),
      handleEditAchievement: handleEditAchievement(
        apiService,
        achievementData,
        achievementFilter,
      ),

      searchUsersByName: searchUsersByName(apiService, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(AchievementTab);
