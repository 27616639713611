const FETCH_HISTORY_TV_GRATITUDE_REQUEST = "FETCH_HISTORY_TV_GRATITUDE_REQUEST";
const FETCH_HISTORY_TV_GRATITUDE_FAILURE = "FETCH_HISTORY_TV_GRATITUDE_FAILURE";
const FETCH_HISTORY_TV_GRATITUDE_SUCCESS = "FETCH_HISTORY_TV_GRATITUDE_SUCCESS";

const FETCH_HISTORY_TV_ACHIEVEMENTS_REQUEST =
  "FETCH_HISTORY_TV_ACHIEVEMENTS_REQUEST";
const FETCH_HISTORY_TV_ACHIEVEMENTS_FAILURE =
  "FETCH_HISTORY_TV_ACHIEVEMENTS_FAILURE";
const FETCH_HISTORY_TV_ACHIEVEMENTS_SUCCESS =
  "FETCH_HISTORY_TV_ACHIEVEMENTS_SUCCESS";

export {
  FETCH_HISTORY_TV_GRATITUDE_REQUEST,
  FETCH_HISTORY_TV_GRATITUDE_FAILURE,
  FETCH_HISTORY_TV_GRATITUDE_SUCCESS,
  FETCH_HISTORY_TV_ACHIEVEMENTS_REQUEST,
  FETCH_HISTORY_TV_ACHIEVEMENTS_FAILURE,
  FETCH_HISTORY_TV_ACHIEVEMENTS_SUCCESS,
};
