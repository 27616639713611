import React from "react";
import styles from "./form.module.scss";

const Form = ({ children, onSubmit }) => {
  return (
    <form
      className={styles.Form}
      onSubmit={e => {
        e.preventDefault();
        onSubmit(e);
        return false;
      }}
    >
      {children}
    </form>
  );
};

export default Form;
