import React from "react";
import styles from "./numInput.module.scss";
import { ReactComponent as BcoinIcon } from "../../../assets/images/bcoin.svg";

const NumInput = ({
  label,
  placeholder,
  maxValue,
  minValue,
  value,
  labelPosition = "top",
  width = "60px",
  onChange = () => {},
  icon = <BcoinIcon style={{ marginLeft: 5, marginTop: 1 }} width={20} height={20} />
}) => {
  return (
    <div className={styles[labelPosition + "NumInput"]}>
      <label className={styles.label}>{label}</label>
      <div style={{ maxWidth: width }} className={styles.inputBlock}>
        <input
          onChange={e => {
            if (+e.target.value <= +maxValue && +e.target.value >= +minValue) {
              onChange(+e.target.value);
            }
          }}
          placeholder={placeholder}
          value={value ? value : ''}
        />
          {icon}
      </div>
    </div>
  );
};

export default NumInput;
