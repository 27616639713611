import React from "react";
import { Link } from "react-router-dom";
import styles from "./purchaseCard.module.scss";
import { ReactComponent as MoneyIcon } from "../../../assets/images/moneyIcon.svg";
import PlaceholderCardImage from "../../../assets/images/PlaceholderCardImage.svg";
import getFormattedDate from "../../../utils/getFormattedDate";
const PurchaseCard = ({ cardData }) => {
    return (
        <div className={styles.card}>
            <Link to={`/purchase/${cardData.type}/${cardData.id}?data=${cardData.purchaseDate}`}>
                <div className={styles.cardAvatar} style={{
                    backgroundImage: `url(${
                    cardData.photo ?
                        window.location.origin + cardData.photo.image :
                        PlaceholderCardImage})`
                }}
                >
                </div>
                <div className={styles.cardBlock}>
                    <div className={styles.type}>{cardData.type === 'products' ? 'Личная покупка' : 'Краудфандинг'}</div>
                    <div className={styles.offerBlock}>
                        <div className={styles.offerTitle}>{cardData.title}</div>
                        <div className={styles.offerPrice}>
                            {cardData.totalPrice}
                            <MoneyIcon
                                style={{ marginBottom: -5, marginLeft: 5 }}
                                width={20}
                                height={20}
                            />
                        </div>
                    </div>
                    <div className={styles.description}>
                        {(cardData.type !== 'products'  ? 'Пожертвовано: ' : "Приобретено: " ) + ((cardData.purchaseDate !==null && cardData.purchaseDate !== 0 )? getFormattedDate(cardData.purchaseDate) : '--') }
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default PurchaseCard;
