import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_MORE_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_BY_ID_REQUEST,
  FETCH_PRODUCTS_BY_ID_FAILURE,
  FETCH_PRODUCTS_BY_ID_SUCCESS,
  FETCH_CROWDFUNDING_BY_ID_REQUEST,
  FETCH_CROWDFUNDING_BY_ID_FAILURE,
  FETCH_CROWDFUNDING_BY_ID_SUCCESS,
  FETCH_BUY_PRODUCT_REQUEST,
  FETCH_BUY_PRODUCT_FAILURE,
  FETCH_BUY_PRODUCT_SUCCESS,
  FETCH_CREATE_PRODUCT_REQUEST,
  FETCH_CREATE_PRODUCT_FAILURE,
  FETCH_CREATE_PRODUCT_SUCCESS,
  FETCH_UPDATE_PRODUCT_REQUEST,
  FETCH_UPDATE_PRODUCT_FAILURE,
  FETCH_UPDATE_PRODUCT_SUCCESS,
  FETCH_REMOVE_PRODUCT_REQUEST,
  FETCH_REMOVE_PRODUCT_FAILURE,
  FETCH_REMOVE_PRODUCT_SUCCESS,
} from "./types";

const initialState = {
  products: [],
  product: {},
  crowdfunding: {},
  productsCount: 0,
  loading: false,
  error: null,
};
const store = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
    case FETCH_PRODUCTS_BY_ID_REQUEST:
    case FETCH_CROWDFUNDING_BY_ID_REQUEST:
    case FETCH_BUY_PRODUCT_REQUEST:
    case FETCH_CREATE_PRODUCT_REQUEST:
    case FETCH_UPDATE_PRODUCT_REQUEST:
    case FETCH_REMOVE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_BUY_PRODUCT_SUCCESS:
    case FETCH_CREATE_PRODUCT_SUCCESS:
    case FETCH_UPDATE_PRODUCT_SUCCESS:
    case FETCH_REMOVE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PRODUCTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload,
      };
    case FETCH_CROWDFUNDING_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        crowdfunding: action.payload,
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload.data,
        productsCount: action.payload.count,
      };
    case FETCH_MORE_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: [
          ...state.products,
          ...action.payload.data.sort((a, b) => b.createdAt - a.createdAt),
        ],
      };
    case FETCH_PRODUCTS_FAILURE:
    case FETCH_PRODUCTS_BY_ID_FAILURE:
    case FETCH_CROWDFUNDING_BY_ID_FAILURE:
    case FETCH_BUY_PRODUCT_FAILURE:
    case FETCH_CREATE_PRODUCT_FAILURE:
    case FETCH_UPDATE_PRODUCT_FAILURE:
    case FETCH_REMOVE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default store;
