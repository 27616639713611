const FETCH_ACHIEVEMENTS_ALL_REQUEST = 'FETCH_ACHIEVEMENTS_ALL_REQUEST';
const FETCH_ACHIEVEMENTS_ALL_SUCCESS = 'FETCH_ACHIEVEMENTS_ALL_SUCCESS';
const FETCH_ACHIEVEMENTS_ALL_FAILURE = 'FETCH_ACHIEVEMENTS_ALL_FAILURE';

const FETCH_ACHIEVEMENT_REQUEST = 'FETCH_ACHIEVEMENT_REQUEST';
const FETCH_ACHIEVEMENT_SUCCESS = 'FETCH_ACHIEVEMENT_SUCCESS';
const FETCH_ACHIEVEMENT_FAILURE = 'FETCH_ACHIEVEMENT_FAILURE';

const FETCH_ACHIEVEMENT_OWNERS_REQUEST = 'FETCH_ACHIEVEMENT_OWNERS_REQUEST';
const FETCH_ACHIEVEMENT_OWNERS_SUCCESS = 'FETCH_ACHIEVEMENT_OWNERS_SUCCESS';
const FETCH_ACHIEVEMENT_OWNERS_FAILURE = 'FETCH_ACHIEVEMENT_OWNERS_FAILURE';

const FETCH_ACHIEVEMENTS_OWN_REQUEST = 'FETCH_ACHIEVEMENTS_OWN_REQUEST';
const FETCH_ACHIEVEMENTS_OWN_SUCCESS = 'FETCH_ACHIEVEMENTS_OWN_SUCCESS';
const FETCH_ACHIEVEMENTS_OWN_FAILURE = 'FETCH_ACHIEVEMENTS_OWN_FAILURE';

const FETCH_ACHIEVEMENTS_USER_REQUEST = 'FETCH_ACHIEVEMENTS_USER_REQUEST';
const FETCH_ACHIEVEMENTS_USER_SUCCESS = 'FETCH_ACHIEVEMENTS_USER_SUCCESS';
const FETCH_ACHIEVEMENTS_USER_FAILURE = 'FETCH_ACHIEVEMENTS_USER_FAILURE';

const CREATE_ACHIEVEMENT = 'CREATE_ACHIEVEMENT';
const EDIT_ACHIEVEMENT = 'EDIT_ACHIEVEMENT';

export {
  FETCH_ACHIEVEMENTS_ALL_REQUEST,
  FETCH_ACHIEVEMENTS_ALL_SUCCESS,
  FETCH_ACHIEVEMENTS_ALL_FAILURE,
  FETCH_ACHIEVEMENT_REQUEST,
  FETCH_ACHIEVEMENT_SUCCESS,
  FETCH_ACHIEVEMENT_FAILURE,
  FETCH_ACHIEVEMENT_OWNERS_REQUEST,
  FETCH_ACHIEVEMENT_OWNERS_SUCCESS,
  FETCH_ACHIEVEMENT_OWNERS_FAILURE,
  FETCH_ACHIEVEMENTS_OWN_REQUEST,
  FETCH_ACHIEVEMENTS_OWN_SUCCESS,
  FETCH_ACHIEVEMENTS_OWN_FAILURE,
  FETCH_ACHIEVEMENTS_USER_REQUEST,
  FETCH_ACHIEVEMENTS_USER_SUCCESS,
  FETCH_ACHIEVEMENTS_USER_FAILURE,
  CREATE_ACHIEVEMENT,
  EDIT_ACHIEVEMENT,
};
