import React from "react";

import styles from "./Modal.module.scss";

const Modal = ({
  children,
  onCloseModal = () => {},
  displayModal = false,
  maxWidth = "480px",
  minWidth = "480px",
}) => {
  const modifiedChild = [];
  React.Children.forEach(children, child => {
    modifiedChild.push(
      React.cloneElement(child, {
        onCloseModal,
      }),
    );
  });

  const modalShowStyle = { display: displayModal ? "block" : "none" };
  return (
    <div
      style={modalShowStyle}
      onClick={e => {
        e.stopPropagation();
        onCloseModal();
      }}
      className={styles.modal}
    >
      <div
        style={{ maxWidth: maxWidth, minWidth: minWidth }}
        className={styles["modal-content"]}
        onClick={e => e.stopPropagation()}
      >
        {modifiedChild}
      </div>
    </div>
  );
};

export default Modal;
