import React from "react";
import "./TableLoadingScreen.scss";

const TableLoadingScreen = ({ maxHeight }) => {
  return (
    <div style={{ maxHeight, minHeight: maxHeight }} className='LoadScreen'>
      <div className='ldsSpinner'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default TableLoadingScreen;
