import {
    getUserProfileFailure,
    getUserProfileRequest,
    getUserProfileSuccess,
    loginFailure,
    loginRequest,
    loginSuccess,
    logout,
} from './actionCreators';

import Cookies from 'js-cookie';

const demoLogin = api => () => dispatch => {
    dispatch(loginRequest());
    api
        .demo_login()
        .then(res => {
            console.log(res, 'res login');
            if (res.data.tokens) {
                // записываем токены в куки
                Cookies.set('jwtToken', res.data.tokens.access, {expires: 5});
                Cookies.set('refreshJwtToken', res.data.tokens.refresh, {expires: 7});
                api.checkUserVisit();
                dispatch(loginSuccess(res.data));
            } else {
                dispatch(loginFailure('Произошла ошибочка ;('));
            }
        })
        .catch(err => {
            dispatch(loginFailure('Произошла ошибочка ;('));
            console.log(err, 'err login');
        });
}

const login = api => slackCode => dispatch => {
    dispatch(loginRequest());
    api
        .login(slackCode)
        .then(res => {
            console.log(res, 'res login');
            if (res.data.tokens) {
                // записываем токены в куки
                Cookies.set('jwtToken', res.data.tokens.access, {expires: 5});
                Cookies.set('refreshJwtToken', res.data.tokens.refresh, {expires: 7});
                api.checkUserVisit();
                dispatch(loginSuccess(res.data));
            } else {
                dispatch(loginFailure('Произошла ошибочка ;('));
            }
        })
        .catch(err => {
            dispatch(loginFailure('Произошла ошибочка ;('));
            console.log(err, 'err login');
        });
};

const getProfile = api => () => dispatch => {
    dispatch(getUserProfileRequest());
    api
        .getProfile()
        .then(res => {
            console.log(res, 'res getProfile');
            if (localStorage.getItem('placeFilterType') === null) {
                localStorage.setItem('placeFilterType', res.data.placeType);
            }
            dispatch(getUserProfileSuccess(res.data));
        })
        .catch(err => {
            console.log(err, 'err getProfile');
            dispatch(getUserProfileFailure(err));
        });
};

const handleLogout = () => () => dispatch => {
    console.log('handleLogout')
    Cookies.remove('jwtToken');
    Cookies.remove('refreshJwtToken');
    Cookies.remove('slackAccessToken');
    localStorage.removeItem('currentAdminTab');
    localStorage.removeItem('placeFilterType');
    dispatch(logout());
};

export {demoLogin, login, getProfile, handleLogout};
