import {
  FETCH_SEARCH_USER_REQUEST,
  FETCH_SEARCH_USER_SUCCESS,
  FETCH_SEARCH_USER_FAILURE,
  FETCH_SEARCH_USERS_IN_HEADER_REQUEST,
  FETCH_SEARCH_USERS_IN_HEADER_SUCCESS,
  FETCH_SEARCH_USERS_IN_HEADER_FAILURE,
} from './types';

const searchUserRequest = () => {
  return { type: FETCH_SEARCH_USER_REQUEST };
};

const searchUserFailure = err => {
  return { type: FETCH_SEARCH_USER_FAILURE, payload: err };
};

const searchUserSuccess = users => {
  return { type: FETCH_SEARCH_USER_SUCCESS, payload: users };
};

const searchUserInHeaderRequest = () => {
  return { type: FETCH_SEARCH_USERS_IN_HEADER_REQUEST };
};

const searchUserInHeaderFailure = err => {
  return { type: FETCH_SEARCH_USERS_IN_HEADER_FAILURE, payload: err };
};

const searchUserInHeaderSuccess = users => {
  return { type: FETCH_SEARCH_USERS_IN_HEADER_SUCCESS, payload: users };
};

export {
  searchUserRequest,
  searchUserFailure,
  searchUserSuccess,
  searchUserInHeaderRequest,
  searchUserInHeaderFailure,
  searchUserInHeaderSuccess,
};
