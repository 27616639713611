import React, { useEffect, useState  } from "react";
import CountUp from "react-countup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { withApiService } from "../hoc";
import compose from "../../utils/compose";
import toDeclension from '../../utils/toDeclension';
import { getUserAchievements } from "../../redux/achievements/actions";
import {
    getGratitudeCategories,
    getReceivedGratitudeByUserId,
    getReceivedGratitudeTotalCount,
    getSendedGratitudeByUserId,
    givePointsToUser,
} from "../../redux/points/actions";

import { getUser } from "../../redux/users/actions";
import { searchUsersByName } from "../../redux/search/actions";
import {getUserMainProjects, getUserMinorProjects} from "../../redux/projects/actions";

import styles from "./user.module.scss";
import { ReactComponent as BcoinIcon } from "../../assets/images/bcoin.svg";
import { ReactComponent as PlaceholderCardImage } from "../../assets/images/PlaceholderCardImage.svg";
import { ReactComponent as UserPlaceholderImage } from "../../assets/images/userPlaceholder.svg";
import CardsList from "../CardsList";
import {
    Form,
    SearchOnChangeReload,
    Select,
    Textarea,
    Button,
    SliderCoin,
    SelectFilter,
    TextInput,
} from "../FormItems";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CheckBox from "../lib/CheckBox";

import NotFoundPage from "../NotFoundPage";

import { CardsTable } from "../Tables";

import PointsReceivedBackCard from "./PointsReceivedBackCard";
import PointsReceivedFrontCard from "./PointsReceivedFrontCard";

import LoadScreen from "../LoadScreen";
import Pagination from "../Tables/Pagination";
import PaginationTwo from "../Tables/PaginationTwo";
import PaginationOne from "../Tables/PaginationOne";
import getUrlParam from "../../utils/getUrlParam";
const findUserRoleInProject = (projectUsers = [], currentUserId) => {
    let userRole = "";
    if (projectUsers.length > 0 && currentUserId) {
        let findendUser = projectUsers.find(user => user.id == currentUserId);
        userRole = findendUser ? findendUser.role : "";
    }
    return userRole;
};

const RowProjectsTableItem = ({
                                  projectData,
                                  currentUserId,
                                  transparentColor = false,
                              }) => {
    return (
        <Link to={`/projects/${projectData.id}`} key={"RowProjectsTableItem" + projectData.id}>
            <div
                className={styles.rowItem}
                style={{ backgroundColor: transparentColor ? "transparent" : "" }}
            >
                <div className={styles.imgBlock}>
                    {projectData.image ? (
                        <img
                            src={window.origin + projectData.image.image}
                            alt='project'
                            style={{}}
                        />
                    ) : (
                        <PlaceholderCardImage
                            width='40px'
                            height='40px'
                            style={{
                                marginRight: 15,
                                borderRadius: 8,
                                backgroundColor: "#EBEEF1",
                                padding: "0.2rem",
                            }}
                        />
                    )}
                </div>
                <div className={styles.infoBlock}>
                    <div className={styles.name}>{projectData.name}</div>
                    <div className={styles.role}>
                        {findUserRoleInProject(projectData.users, currentUserId)}
                    </div>
                </div>
            </div>
        </Link>
    );
};


const User = ({
                  achievementsList = [],
                  achievementsLoading = false,
                  achievementsCount,
                  pointsList = [],
                  pointsTotal,
                  pointsCategories = [],
                  searchUsersList = [],
                  getUserAchievements,
                  userInfo = [],
                  isSuperuser,
                  usersErrors = null,
                  maxGivePoints = 0,
                  pointsLoading = false,
                  getUser,
                  getGratitudeCategories,
                  getReceivedGratitudeByUserId,
                  getReceivedGratitudeTotalCount,
                  getUserMainProjects,
                  getUserMinorProjects,
                  getSendedGratitudeByUserId,
                  searchUsers,
                  givePointsToUser,
                  match,
                  mainProjects = [],
                  mainProjectsCount,
                  minorProjectsCount,
                  minorProjects = [],
                  projectsLoading,
              }) => {

    const desktop = window.innerWidth < 768 ? false : true;
    let urlParamOne = getUrlParam('pageOne') === 0 ? 1 : getUrlParam('pageOne');
    let urlParamTwo = getUrlParam('pageTwo') === 0 ? 1 : getUrlParam('pageTwo');
    const [filter, setFilter] = useState(
        JSON.parse(localStorage.getItem("homePointsFilter")) || {
            time: 4, limit: desktop ? 9999 :  4, offset: 0
        },
    );
    const [mainProjectFilter, setMainProjectFilter] = useState({
            limit: desktop ? 20 : 8,
            offset: (urlParamOne ? (urlParamOne - 1) : 0) * (desktop ? 20 : 8),
            order: 'desc'
        }
    );
    const [minorProjectFilter, setMinorProjectFilter] = useState({
            limit: desktop ? 20 : 8,
            offset: (urlParamTwo ? (urlParamTwo - 1) : 0) * (desktop ? 20 : 8),
            order: 'desc'
        }
    );
    const [type, setType] =  useState(1);
    const [givePointsForm, setGivePointsForm] = useState({ amount: 1 });
    const [baseFilter, setBaseFilter] = useState({ limit: 10, offset: 0 });

    useEffect(() => {
        localStorage.removeItem('allAchievementsVisibleBlock');
        localStorage.removeItem('achievementsHistoryFilterTime');

        localStorage.removeItem('pointsRatingFilterTime');
        localStorage.removeItem('pointsRatingFilterCategory');
        localStorage.removeItem('achievementsVisibleBlock');
        localStorage.removeItem('achievementsRatingFilterTime');

        localStorage.removeItem('eventsFilterTime');
        localStorage.removeItem('eventsFilterType');
        localStorage.removeItem('eventsFilterCategory');

        localStorage.removeItem('homePointsFilter');
        localStorage.removeItem('HomeVisibleBlock');

        localStorage.removeItem('productsFilterType');
        localStorage.removeItem('coinFilterFrom');
        localStorage.removeItem('coinFilterTo');

        localStorage.setItem('UserVisibleBlock', 'project')
        getUserAchievements(match.params.id, {limit: window.innerWidth > 768 ? 9999 : 4, offset: 0 });
        getReceivedGratitudeTotalCount(match.params.id, {time: 1});
        getReceivedGratitudeByUserId(match.params.id, {limit: window.innerWidth > 768 ? 9999 : 4, offset: 0 });
        getGratitudeCategories();
        getUser(match.params.id);
        getUserMainProjects(match.params.id, mainProjectFilter);
        getUserMinorProjects(match.params.id, minorProjectFilter)
    }, [match.params.id]);

    const updateGivePointsForm = (fieldName, fieldValue) => {
        setGivePointsForm(prevState => ({
            ...prevState,
            [fieldName]: fieldValue,
            userId: userInfo[0] ? userInfo[0] : null,
        }));
    };

    const updatePointsFilter = (fieldName, fieldValue) => {
        setFilter(prevState => ({ ...prevState, [fieldName]: fieldValue.id }));

        if (fieldName !== "grantedById") {
            localStorage.setItem(
                "otherUserPointsFilter",
                JSON.stringify({
                    ...filter,
                    [fieldName]: fieldValue.id,
                }),
            )
        }

        // Если в фильтре выбран пользователь, то ищем только по нему
        type === 1
            ? getReceivedGratitudeByUserId(match.params.id, {
                ...filter,
                [fieldName]: fieldValue.id,
            })
            : getSendedGratitudeByUserId(match.params.id, {
                ...filter,
                [fieldName]: fieldValue.id,
            });
    };

    let totalPointsReceive = pointsList.reduce((accumulator, item) => {
        return accumulator.value
            ? accumulator.value + +item.value
            : accumulator + +item.value;
    }, 0);

    if (!userInfo[0] && usersErrors) {
        return <NotFoundPage />;
    }

    const timeFilterOptions = [
        { id: 1, name: "За все время" },
        { id: 2, name: "За 24 часа" },
        { id: 3, name: "За 7 дней" },
        { id: 4, name: "За 30 дней" },
    ];
    const pointsTypes = [
        { id: 1, name: "Полученные благодарности" },
        { id: 2, name: "Отправленные благодарности" },
    ];

    if (!userInfo[0]) {
        return <LoadScreen />;
    }

    const {
        avatar,
        firstName,
        lastName,
        sex,
    } = userInfo[0];

    const basicName = `${firstName} ${lastName}`;

    let gotText = `${
        sex === 1
            ? type === 1
            ? "получил"
            : "отправил"
            : type === 1
            ? "получила"
            : "отправила"
    }`;

    return (
        <>
            <div className='mt3'></div>
            <div className={styles.userContainer}>
                <div className={styles.imgContainer}>
                    {avatar ? (
                        <img src={avatar} alt='user avatar' />
                    ) : (
                        <UserPlaceholderImage />
                    )}
                </div>
                <div className={styles.infoContainer}>
                    <div className={styles.name}>{`${firstName || ""} ${lastName ||
                    ""}`}</div>
                    <div className={styles.counters}>
                        <div className={styles.counterBlock}>
                            <CountUp end={mainProjects && mainProjects.length} />
                            <div className={styles.counterText}>{toDeclension(+mainProjects.length, ['Проект', 'Проекта', 'Проектов'])}</div>
                        </div>
                        <div className={styles.counterBlock}>
                            <CountUp end={achievementsList && achievementsList.length} />
                            <div className={styles.counterText}>{toDeclension(+achievementsList.length, ['Достижение', 'Достижения', 'Достижений'])}</div>
                        </div>
                        <div className={styles.counterBlock}>
                            <CountUp end={pointsTotal} />
                            <div className={styles.counterText}>{toDeclension(+pointsTotal, ['Благодарность', 'Благодарности', 'Благодарностей'])}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt3'></div>
            <div className={styles.infoContainerControls}>
                <Slider
                    dots={false}
                    arrows={false}
                    className={"center"}
                    centerMode={true}
                    centerPadding={"70px"}
                    slidesToShow={1}
                    responsive={[
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]}
                >
                    <div className={styles.slidersContainer}>
                        <Button
                            style={{
                                width: '90%',
                                margin: '0 auto',
                                maxWidth: '90%',
                                background: localStorage.getItem('UserVisibleBlock') ==='project' ? '#5f37ff': '#ffffff',
                                color: localStorage.getItem('UserVisibleBlock') ==='project' ? '#ffffff' : '#5f37ff',
                            }}
                            label='Проекты'
                            handleClick={() => {
                                localStorage.setItem('UserVisibleBlock', 'project');
                                setBaseFilter({ limit: 8, offset: 0 });
                                window.history.pushState(null, null, `?page=1`);
                                getUserMainProjects(match.params.id, {limit: window.innerWidth > 768 ? 9999 : 8, offset: 0 });
                                getUserMinorProjects(match.params.id, {limit: window.innerWidth > 768 ? 9999 : 8, offset: 0 })
                            }}
                        />
                    </div>
                    <div className={styles.slidersContainer}>
                        <Button
                            style={{
                                width: '90%',
                                margin: '0 auto',
                                maxWidth: '90%',
                                background: localStorage.getItem('UserVisibleBlock') ==='points' ? '#5f37ff': '#ffffff',
                                color: localStorage.getItem('UserVisibleBlock') ==='points' ? '#ffffff' : '#5f37ff',
                            }}
                            label='Благодарности'
                            handleClick={() => {
                                localStorage.setItem('UserVisibleBlock', 'points');
                                setBaseFilter({ limit: 4, offset: 0 });
                                window.history.pushState(null, null, `?page=1`);
                                getReceivedGratitudeByUserId(match.params.id, {limit: 4, offset: 0 });
                            }}
                        />
                    </div>
                    <div className={styles.slidersContainer}>
                        <Button
                            style={{
                                width: '90%',
                                margin: '0 auto',
                                maxWidth: '90%',
                                background: localStorage.getItem('UserVisibleBlock') ==='achievements' ? '#5f37ff': '#ffffff',
                                color: localStorage.getItem('UserVisibleBlock') ==='achievements' ? '#ffffff' : '#5f37ff',
                            }}
                            label='Достижения'
                            handleClick={() => {
                                localStorage.setItem('UserVisibleBlock', 'achievements');
                                setBaseFilter({ limit: 8, offset: 0 });
                                window.history.pushState(null, null, `?page=1`);
                                getUserAchievements(match.params.id, {limit: 8, offset: 0 });
                            }}
                        />
                    </div>
                </Slider>
            </div>
            <div className={styles.projectsContainer} style={{display: window.innerWidth > 768 ? 'grid' : localStorage.getItem('UserVisibleBlock') === 'project' ? 'block' : 'none'}}>
                <div className={styles.achievementsCardsListTitle} style={{display: window.innerWidth > 768 ? 'block' : 'none'}}>Проекты</div>
                <div className='mt2'></div>
                        {projectsLoading ? (
                            <LoadScreen />
                        ) : mainProjects.length > 0 ? (
                            <div>
                                <div className={styles.achievementsCardsListTitle}>Основное участие</div>
                                <div className='mt2'></div>
                                <div  className={styles.allMyProjectsContainer}>
                                    {mainProjects.map(item => {
                                        return (
                                            <Link to={`/projects/${item.id}`} key={item.id} style={{display: 'flex'}}>
                                                <div className={styles.projectCard}>
                                                    <div className={styles.cardImg}>
                                                        {item.image ? (
                                                            <img
                                                                className={styles.cardIcon}
                                                                src={window.origin + item.image.image}
                                                                alt={"project icon"}
                                                            />
                                                        ) : (
                                                            <PlaceholderCardImage
                                                                width='180px'
                                                                height='180px'
                                                                style={{ borderRadius: 8 }}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className={styles.cardTitle}>{item.name}</div>
                                                    <div className={styles.cardRole}>
                                                        {findUserRoleInProject(
                                                            item.users,
                                                            match.params.id,
                                                        )}
                                                    </div>
                                                </div>
                                            </Link>
                                        );
                                    })}
                                </div>
                                <div className='mt2'></div>
                                {!desktop &&  mainProjectsCount > mainProjectFilter.limit ? (
                                    <>
                                        <PaginationOne
                                            total={mainProjectsCount}
                                            offset={(urlParamOne ? (urlParamOne - 1) : mainProjectFilter.offset) * mainProjectFilter.limit}
                                            limit={mainProjectFilter.limit}
                                            onChange={newPage => {
                                                setMainProjectFilter( { ...mainProjectFilter, offset: (newPage - 1) * mainProjectFilter.limit})
                                                getUserMainProjects(match.params.id, { ...mainProjectFilter, offset: (newPage - 1) * mainProjectFilter.limit });
                                            }}
                                        />
                                        <div className='mt2'></div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : (
                            <div className={styles.projectsContainerText}>Проектов не обнаружено</div>
                        )}
                        {projectsLoading ? (
                            <LoadScreen />
                        ) : minorProjects.length > 0 ? (
                            <div>
                                <div className={styles.achievementsCardsListTitle}>
                                    {sex === 1 ? "Принимал" : "Принимала "} участие
                                </div>
                                <div className='mt2'></div>
                                <div className={styles.table}>
                                    {minorProjects.length > 0 ? (
                                        minorProjects.map((project, projectIndex) => (
                                            <RowProjectsTableItem
                                                key={project.id}
                                                projectData={project}
                                                currentUserId={match.params.id}
                                                transparentColor={projectIndex % 2 > 0}
                                            />
                                        ))
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className='mt2'></div>
                                {!desktop < 768 && minorProjectsCount > minorProjectFilter.limit ?
                                    (<PaginationTwo
                                            total={minorProjectsCount}
                                            offset={(urlParamTwo ? (urlParamTwo - 1) : minorProjectFilter.offset) * minorProjectFilter.limit}
                                            limit={minorProjectFilter.limit}
                                            onChange={newPage => {
                                                setMinorProjectFilter( { ...minorProjectFilter, offset: (newPage - 1) * minorProjectFilter.limit})
                                                getUserMinorProjects(match.params.id, {  ...minorProjectFilter, offset: (newPage - 1) * minorProjectFilter.limit });
                                            }}
                                        />
                                    ) : ''
                                }
                            </div>
                        ) : (
                            ""
                        )}
            </div>
            <div className={styles.achievementsContainer} style={{display: window.innerWidth > 768 ? 'block' :  localStorage.getItem('UserVisibleBlock') === 'achievements' ? 'block' : 'none'}}>
                <div className='mt1'></div>
                <div className={styles.achievementsCardsListTitle}>Достижения</div>
                <div className='mt2'></div>
                <div className={styles.achievementsList}>
                    <CardsList
                        loading={achievementsLoading}
                        cards={achievementsList}
                    />
                    {window.innerWidth < 768 && achievementsCount > baseFilter.limit ? (
                        <Pagination
                            total={achievementsCount}
                            offset={baseFilter.offset}
                            limit={baseFilter.limit}
                            onChange={newPage => {
                                setBaseFilter({ offset: (newPage - 1) * baseFilter.limit, limit: baseFilter.limit })
                                getUserAchievements(match.params.id, { ...baseFilter, offset: (newPage - 1) * baseFilter.limit })
                            }}
                        />
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <div className={styles.pointsContainer} style={{display: window.innerWidth > 768 ?  'block' : localStorage.getItem('UserVisibleBlock')  === 'points'  ? 'block' : 'none'}}>
                <div className='mt3'></div>
                <div className={styles.twoCols}>
                    <div className={styles.giveBCoinsForm}>
                        <div className='titleH1'>Поблагодарить</div>
                        <div className='mb2'></div>
                        <Form
                            onSubmit={e => {
                                e.preventDefault();
                            }}
                        >
                            <TextInput
                                label='Кого'
                                onChange={val => {
                                    updateGivePointsForm("userId", userInfo[0]);
                                }}
                                placeholder='Имя получателя'
                                value={
                                    userInfo[0]
                                        ? userInfo[0].firstName + " " + userInfo[0].lastName ||
                                        userInfo[0].nickname
                                        : null
                                }
                            />
                            <div className='mb1'></div>
                            <Select
                                label='За что'
                                placeholder='Категория благодарности'
                                onSelect={value => {
                                    updateGivePointsForm("gratitudeCategoryId", value);
                                }}
                                selectedValue={givePointsForm.gratitudeCategoryId}
                                options={pointsCategories}
                                clearSelect={() => {
                                    updateGivePointsForm("gratitudeCategoryId", {});
                                }}
                            />
                            <div className='mb1'></div>
                            <Textarea
                                label={"Комментарий"}
                                placeholder={"Краткий комментарий"}
                                minHeight={"60px"}
                                value={givePointsForm.commentary}
                                onChange={value => {
                                    updateGivePointsForm("commentary", value);
                                }}
                            />
                            <div className='mb15'></div>
                            {isSuperuser && (
                                <CheckBox
                                    id='givePointsForm-by-app-craft'
                                    label='от имени AppCraft'
                                    checked={!!givePointsForm.byAppCraft}
                                    onChange={({ target: { checked } }) => {
                                        updateGivePointsForm("byAppCraft", checked);
                                    }}
                                />
                            )}
                            <div className={styles.sendBCoinsFormTwoCols}>
                                <SliderCoin
                                    label='Сколько дарю'
                                    value={givePointsForm.amount}
                                    placeholder={1}
                                    maxValue={
                                        givePointsForm.byAppCraft || maxGivePoints > 10
                                            ? 10
                                            : maxGivePoints
                                    }
                                    minValue={1}
                                    onChange={value => {
                                        updateGivePointsForm("amount", value);
                                    }}
                                />
                            </div>
                            <div className='mb2'></div>
                            <Button
                                disabled={pointsLoading}
                                style={{ float: "right" }}
                                label='Поблагодарить'
                                handleClick={() => {
                                    givePointsToUser(givePointsForm, () => {
                                        setGivePointsForm({});
                                        getReceivedGratitudeByUserId(match.params.id, filter);
                                    });
                                }}
                            />
                        </Form>
                    </div>
                    <div className={styles.bCoinsHistoryList}>
                        <div className={styles.twoCols2}>
                            <div className='titleH1 mb1'>Благодарности</div>
                            {totalPointsReceive > 0 ? (
                                <div className={styles.totalPointsReceive + ' ' + 'mb1'}>
                                    <div style={{ marginTop: 3 }}>
                                        {basicName} {`${gotText} ${totalPointsReceive}`}{" "}
                                    </div>
                                    <BcoinIcon
                                        style={{ marginLeft: 5, marginBottom: -2 }}
                                        width={25}
                                        height={25}
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className={styles.twoCols2 + ' ' + 'pr3'}>
                            <div style={{ marginTop: 17}}>
                                <SearchOnChangeReload
                                    label='Имя'
                                    placeholder={
                                        type === 1 ? "Имя отправителя" : "Имя получателя"
                                    }
                                    onSelect={value => {
                                        updatePointsFilter("grantedById", value);
                                    }}
                                    onKeyPress={() => {
                                        if (filter.grantedById && filter.grantedById.length !== 0) {
                                            updatePointsFilter("grantedById", {id: ''});
                                        }}
                                    }
                                    searchResults={searchUsersList}
                                    searchRequest={val => {
                                        searchUsers({ name: val })
                                    }}
                                    selectedValue={filter.user || {}}
                                />
                                <div className='mt2'></div>
                                <SelectFilter
                                    placeholder='За все время'
                                    onSelect={value => {
                                        updatePointsFilter("time", value);
                                    }}
                                    options={timeFilterOptions}
                                    selectedValue={
                                        timeFilterOptions.find(item => item.id === filter.time) || {}
                                    }
                                    clearSelect={() => {
                                        updatePointsFilter("time", {});
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: 36 }}>
                                <SelectFilter
                                    placeholder='Тип благодарности'
                                    onSelect={value => {
                                        setType(value.id);
                                        window.history.pushState(null, null, `?page=1`);
                                        setBaseFilter({ offset: 0, limit: 4})
                                        value.id === 1 ?
                                            getReceivedGratitudeByUserId(match.params.id, {...filter, limit: window.innerWidth > 768 ? 9999 : 4}) :
                                            getSendedGratitudeByUserId(match.params.id, {...filter, limit: window.innerWidth > 768 ? 9999 : 4})
                                    }}
                                    options={pointsTypes}
                                    selectedValue={
                                        pointsTypes.find(item => item.id === type) || {}
                                    }
                                    clearSelect={() => {
                                        updatePointsFilter("type", {});
                                    }}
                                />
                                <div className='mt2'></div>
                                {pointsCategories.length > 0 ? (
                                    <SelectFilter
                                        placeholder='Категория благодарности'
                                        onSelect={value => {
                                            updatePointsFilter("category", value);
                                        }}
                                        options={pointsCategories}
                                        selectedValue={
                                            pointsCategories.find(
                                                item => item.id === filter.category,
                                            ) || {}
                                        }
                                        clearSelect={() => {
                                            updatePointsFilter("category", {});
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <div className='mb3'></div>
                        <CardsTable
                            loading={pointsLoading}
                            cards={pointsList}
                            FrontSideComponent={({ cardData }) => {
                                return (
                                    <>
                                        <PointsReceivedFrontCard cardData={cardData} typeOf={type} />
                                    </>
                                );
                            }}
                            BackSideComponent={({ cardData }) => {
                                return <PointsReceivedBackCard cardData={cardData} />;
                            }}
                        />
                    </div>
                    {window.innerWidth < 768 &&  pointsTotal > baseFilter.limit ? (
                        <Pagination
                            total={pointsTotal}
                            offset={baseFilter.offset}
                            limit={baseFilter.limit}
                            onChange={newPage => {
                                setBaseFilter({ offset: (newPage - 1) * baseFilter.limit, limit: baseFilter.limit });
                                getReceivedGratitudeByUserId(match.params.id, { offset: (newPage - 1) * baseFilter.limit, limit: baseFilter.limit });
                            }}
                        />
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({
                             achievements,
                             points,
                             users,
                             search,
                             auth,
                             projects,
                         }) => ({
    achievementsList: achievements.achievements,
    achievementsCount: achievements.achievementsCount,
    achievementsLoading: achievements.loading,
    pointsList: points.points,
    pointsCount: points.pointsCount,
    pointsTotal: points.pointsTotal,
    pointsLoading: points.loading,
    pointsCategories: points.pointsCategories,
    searchUsersList: search.users,
    userInfo: users.users,
    usersErrors: users.error,
    maxGivePoints: auth.points,
    isSuperuser: auth.isSuperuser,
    projects: projects.projects,
    projectsLoading: projects.loading,
    mainProjects: projects.mainProjects,
    mainProjectsCount: projects.mainProjectsCount,
    minorProjectsCount: projects.minorProjectsCount,
    minorProjects: projects.minorProjects,
});
const mapDispatchToProps = (
    dispatch,
    { apiService, filter, mainProjectFilter, minorProjectFilter, giveFormData, userId, callback },
) => {
    return bindActionCreators(
        {
            getUserAchievements: getUserAchievements(apiService, userId, filter),
            getGratitudeCategories: getGratitudeCategories(apiService),
            getSendedGratitudeByUserId: getSendedGratitudeByUserId(
                apiService,
                userId,
                filter,
            ),
            getReceivedGratitudeByUserId: getReceivedGratitudeByUserId(
                apiService,
                userId,
                filter,
            ),
            getReceivedGratitudeTotalCount: getReceivedGratitudeTotalCount(
                apiService,
                userId,
                filter,
            ),
            searchUsers: searchUsersByName(apiService, filter),
            givePointsToUser: givePointsToUser(apiService, giveFormData, callback),
            getUser: getUser(apiService, userId),
            getUserMainProjects: getUserMainProjects(apiService, userId, mainProjectFilter),
            getUserMinorProjects: getUserMinorProjects(apiService, userId, minorProjectFilter),
        },
        dispatch,
    );
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps),
)(User);
