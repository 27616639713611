import React from "react";

import { Link } from "react-router-dom";
import styles from "./pointsCard.module.scss";
import getFormattedDate from "../../../utils/getFormattedDate";

import { ReactComponent as UserPlaceholder } from "../../../assets/images/userPlaceholder.svg";
import { ReactComponent as BcoinIcon } from "../../../assets/images/bcoin.svg";
const PointsCard = ({ cardData }) => {
  const { firstName, lastName, firstNameGenitive,
    lastNameGenitive, nickname, isAppcraftUser,
  } = cardData.grantedBy;

  return (
    <div className={styles.card}>
      <div>
        <div className={styles.avatar}>
          {cardData.givenTo.avatar ? (
            <img src={cardData.givenTo.avatar} alt='avatar' />
          ) : (
            <UserPlaceholder />
          )}
        </div>
        <Link to={`/user/${cardData.givenTo.id}`}>
          <div className={styles.name}>
            {cardData.givenTo.firstName}{" "}
            {cardData.givenTo.lastName || cardData.givenTo.nickname}
          </div>
        </Link>
      </div>
      <div className={styles.flex + ' ' + 'mb15'}>
          <div className={styles.category + ' ' + styles.bcoinsBlock}>
            <div className={styles.bcoins}>
              +{cardData.value}
              <BcoinIcon />
             </div>
          </div>
          <div className={styles.category + ' ' + styles.categoryName}>{cardData.category.name}</div>
      </div>
      <div className={styles.comment}>{cardData.commentary}</div>
      <div>
        <div className={styles.blaceTheme}>Получено от</div>
        <Link to={isAppcraftUser ? `${window.location.pathname}` : `/user/${cardData.grantedBy.id}`}>
          {!isAppcraftUser ?
            <div className={styles.givenToName}>
              {firstNameGenitive && lastNameGenitive ? `${firstNameGenitive} ${lastNameGenitive}` : `${firstName} ${lastName}` }
            </div> :
            <div className={styles.givenToName}>
              {nickname}
            </div>
          }
        </Link>
        <div className={styles.createdDate}>
          {getFormattedDate(cardData.createdAt)}
        </div>
      </div>
    </div>
  );
};

export default PointsCard;
