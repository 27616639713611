import {
  getProductsRequest,
  getProductsFailure,
  getProductsSuccess,
  getProductsMoreSuccess,
  getProductByIdRequest,
  getProductByIdFailure,
  getProductByIdSuccess,
  getCrowdfundingByIdRequest,
  getCrowdfundingByIdFailure,
  getCrowdfundingByIdSuccess,
  buyProductRequest,
  buyProductSuccess,
  buyProductFailure,
  createProductRequest,
  createProductSuccess,
  createProductFailure,
  updateProductRequest,
  updateProductSuccess,
  updateProductFailure,
  removeProductRequest,
  removeProductSuccess,
  removeProductFailure,
} from "./actionCreators";
import { getProfile } from "../auth/actions";
import showMessage from "../../components/showMessage";

export const getProducts = api => (filter = {}, force) => dispatch => {
  dispatch(getProductsRequest());
  api
    .getProducts(filter)
    .then(res => {
      console.log(res, 'getProducts')
      if (force) {
        dispatch(getProductsSuccess(res.data));
      } else {
        dispatch(getProductsMoreSuccess(res.data));
      }
    })
    .catch(err => {
      console.log(err, 'err getProducts')
      dispatch(getProductsFailure());
    });
};
export const getProductsByAdmin = api => (filter = {}) => dispatch => {
    dispatch(getProductsRequest());
    api
        .getProductsByAdmin(filter)
        .then(res => {
            console.log(res, 'getProducts')
            dispatch(getProductsSuccess(res.data));
        })
        .catch(err => {
            console.log(err, 'err getProducts')
            dispatch(getProductsFailure());
        });
};
export const getProductById = api => productId => dispatch => {

  dispatch(getProductByIdRequest());
  api
      .getProductById(productId)
      .then(res => {
        console.log(res, 'getProductById')
          dispatch(getProductByIdSuccess(res.data));
      })
      .catch(err => {
        console.log(err, 'err getProductById')
        dispatch(getProductByIdFailure());
      });
};
export const getCrowdfundingById = api => productId => dispatch => {

    dispatch(getCrowdfundingByIdRequest());
    api
        .getCrowdfundingById(productId)
        .then(res => {
            console.log(res, 'getProductById')
            dispatch(getCrowdfundingByIdSuccess(res.data));
        })
        .catch(err => {
            console.log(err, 'err getProductById')
            dispatch(getCrowdfundingByIdFailure());
        });
};
export const buyProduct = api => (productId, productCommentary, callback) => dispatch => {

  dispatch(buyProductRequest());
  api
      .buyProduct(productId, productCommentary)
      .then(res => {
        console.log(res, 'buyProduct')
        dispatch(buyProductSuccess(res.data));
        showMessage({status: 'success', text: "Покупка товара совершена успешно!"})
        getProfile(api)()(dispatch);
        callback();
      })
      .catch(err => {
        console.log(err, 'err buyProduct')
        dispatch(buyProductFailure());
        showMessage({status: err, text: err.response.data ? err.response.data.detail : err.message})
      });
};
export const buyCrowdfundingProduct = api => (productId, productCost, callback) => dispatch => {

  dispatch(buyProductRequest());
  api
      .buyCrowdfundingProduct(productId, productCost)
      .then(res => {
        console.log(res, 'buyCrowdfundingProduct')
        dispatch(buyProductSuccess(res.data));
        showMessage({status: 'success', text: "Пожертование завершено успешно!"})
        getProfile(api)()(dispatch);
        callback();
      })
      .catch(err => {
        console.log(err, 'err buyCrowdfundingProduct')
        dispatch(buyProductFailure());
        showMessage({status: err, text: err.response.data ? err.response.data.detail : err.message})
      });
};
export const createProduct = api => (productData, filter = {}) => dispatch => {
    dispatch(createProductRequest());
    api
        .createProduct(productData)
        .then(res => {
            console.log(res, "res createProduct");
            dispatch(createProductSuccess());
            getProductsByAdmin(api)(filter)(dispatch);
        })
        .catch(err => {
            console.log(err, "createProduct");
            dispatch(createProductFailure());
        });
};
export const updateProduct = api => (productData, filter = {}) => dispatch => {
    dispatch(updateProductRequest());
    api
        .updateProduct(productData)
        .then(res => {
            console.log(res, "res createProduct");
            dispatch(updateProductSuccess());
            getProductsByAdmin(api)(filter)(dispatch);
        })
        .catch(err => {
            console.log(err, "createProduct");
            dispatch(updateProductFailure());
        });
};
export const removeProducts = api => (productDataType, productDataId, filter={}) => dispatch => {
    dispatch(removeProductRequest());
    api
        .removeProduct(productDataType, productDataId)
        .then(res => {
            console.log(res, "res createProduct");
            dispatch(removeProductSuccess());
            getProductsByAdmin(api)(filter)(dispatch);
        })
        .catch(err => {
            console.log(err, "createProduct");
            dispatch(removeProductFailure());
        });
};

