import {
  FETCH_PROJECTS_ALL_REQUEST,
  FETCH_PROJECTS_ALL_SUCCESS,
  FETCH_MORE_PROJECTS_ALL_SUCCESS,
  FETCH_PROJECTS_ALL_FAILURE,
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_FAILURE,
  FETCH_PROJECT_USER_MAIN_REQUEST,
  FETCH_PROJECT_USER_MAIN_SUCCESS,
  FETCH_PROJECT_USER_MAIN_FAILURE,
  FETCH_PROJECT_USER_MINOR_REQUEST,
  FETCH_PROJECT_USER_MINOR_SUCCESS,
  FETCH_PROJECT_USER_MINOR_FAILURE,
  FETCH_PROJECTS_OWN_REQUEST,
  FETCH_PROJECTS_OWN_SUCCESS,
  FETCH_PROJECTS_OWN_FAILURE,
  FETCH_PROJECTS_MAIN_USER_REQUEST,
  FETCH_PROJECTS_MAIN_USER_SUCCESS,
  FETCH_PROJECTS_MAIN_USER_FAILURE,
  FETCH_PROJECTS_MINOR_USER_REQUEST,
  FETCH_PROJECTS_MINOR_USER_SUCCESS,
  FETCH_PROJECTS_MINOR_USER_FAILURE,
  CREATE_PROJECT,
  EDIT_PROJECT,
} from "./types";

const initialState = {
  projects: [],
  mainProjects: [],
  minorProjects: [],
  mainProjectsCount: null,
  minorProjectsCount: null,
  mainUsers: [],
  minorUsers: [],
  mainUsersCount: null,
  minorUsersCount: null,
  projectsCount: null,
  loading: false,
  error: null,
  actualRequestId: null,
};
const projects = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_PROJECTS_ALL_REQUEST:
    case FETCH_PROJECTS_OWN_REQUEST:
    case FETCH_PROJECTS_MAIN_USER_REQUEST:
    case FETCH_PROJECTS_MINOR_USER_REQUEST:
    case FETCH_PROJECT_REQUEST:
    case FETCH_PROJECT_USER_MAIN_REQUEST:
    case FETCH_PROJECT_USER_MINOR_REQUEST:
      return {
        ...state,
        actualRequestId: action.payload,
        loading: true,
        error: null,
      };
    case FETCH_PROJECT_USER_MAIN_SUCCESS:
        return {
          ...state,
          mainUsers: action.payload.entities,
          mainUsersCount: action.payload.totalMembersCount,
          loading: false,
        };
      return { ...state };
    case FETCH_PROJECT_USER_MINOR_SUCCESS:
      return {
        ...state,
        minorUsers: action.payload.entities,
        minorUsersCount: action.payload.totalMembersCount,
        loading: false,
      };
      return { ...state };
    case FETCH_PROJECTS_ALL_SUCCESS:
    case FETCH_PROJECTS_OWN_SUCCESS:
      if (action.payload.actualRequestId === state.actualRequestId) {
        return {
          ...state,
          projects: action.payload.entities,
          projectsCount: action.payload.count,
          loading: false,
        };
      }
      return { ...state };
    case FETCH_PROJECTS_MINOR_USER_SUCCESS:
      if (action.payload.actualRequestId === state.actualRequestId) {
        return {
          ...state,
          minorProjects: action.payload.entities,
          minorProjectsCount: action.payload.count,
          loading: false,
        };
      }
      return { ...state };
    case FETCH_PROJECTS_MAIN_USER_SUCCESS:
      if (action.payload.actualRequestId === state.actualRequestId) {
        return {
          ...state,
          mainProjects: action.payload.entities,
          mainProjectsCount: action.payload.count,
          loading: false,
        };
      }
      return { ...state };
    case FETCH_MORE_PROJECTS_ALL_SUCCESS:
      if (action.payload.actualRequestId === state.actualRequestId) {
        return {
          ...state,
          loading: false,
          projects: [
            ...state.projects,
            ...action.payload.entities,
          ],
        };
      };
    case FETCH_PROJECT_SUCCESS:
        return {
          ...state,
          projects: action.payload.projects,
          loading: false,
        };

      return { ...state };
    case CREATE_PROJECT:
    case EDIT_PROJECT:
      return {
        ...state,
        loading: false,
      };
    case FETCH_PROJECTS_ALL_FAILURE:
    case FETCH_PROJECT_FAILURE:
    case FETCH_PROJECTS_OWN_FAILURE:
    case FETCH_PROJECTS_MAIN_USER_FAILURE:
    case FETCH_PROJECTS_MINOR_USER_FAILURE:
    case FETCH_PROJECT_USER_MAIN_FAILURE:
    case FETCH_PROJECT_USER_MINOR_FAILURE:
      if (action.payload.actualRequestId === state.actualRequestId) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
      return { ...state };

    default:
      return {
        ...state,
      };
  }
};

export default projects;
