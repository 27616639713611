const FETCH_SEARCH_USER_REQUEST = 'FETCH_SEARCH_USER_REQUEST';
const FETCH_SEARCH_USER_SUCCESS = 'FETCH_SEARCH_USER_SUCCESS';
const FETCH_SEARCH_USER_FAILURE = 'FETCH_SEARCH_USER_FAILURE';

const FETCH_SEARCH_USERS_IN_HEADER_REQUEST =
  'FETCH_SEARCH_USERS_IN_HEADER_REQUEST';
const FETCH_SEARCH_USERS_IN_HEADER_SUCCESS =
  'FETCH_SEARCH_USERS_IN_HEADER_FAILURE';
const FETCH_SEARCH_USERS_IN_HEADER_FAILURE =
  'FETCH_SEARCH_USERS_IN_HEADER_FAILURE';

export {
  FETCH_SEARCH_USER_REQUEST,
  FETCH_SEARCH_USER_SUCCESS,
  FETCH_SEARCH_USER_FAILURE,
  FETCH_SEARCH_USERS_IN_HEADER_REQUEST,
  FETCH_SEARCH_USERS_IN_HEADER_SUCCESS,
  FETCH_SEARCH_USERS_IN_HEADER_FAILURE,
};
