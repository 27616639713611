import React from 'react';

import styles from './footer.module.scss';

const Footer = () => {
  return (
    <div className={styles.Footer}>
      <div className={styles.footerLinks}>
        <a className={styles.link} href='https://appcraft.pro' alt=''>
          appcraft.pro
        </a>
        <span className={styles.separator}>|</span>
        <a className={styles.link} href='https://appcraft.team' alt=''>
          appcraft.team
        </a>
      </div>
      <div className={styles.copyright}>
        Copyright &copy; 2011-2020,&nbsp;AppCraft LLC
      </div>
    </div>
  );
};

export default Footer;
