import {
  FETCH_ACHIEVEMENTS_ALL_REQUEST,
  FETCH_ACHIEVEMENTS_ALL_SUCCESS,
  FETCH_ACHIEVEMENTS_ALL_FAILURE,
  FETCH_ACHIEVEMENT_REQUEST,
  FETCH_ACHIEVEMENT_SUCCESS,
  FETCH_ACHIEVEMENT_FAILURE,
  FETCH_ACHIEVEMENT_OWNERS_REQUEST,
  FETCH_ACHIEVEMENT_OWNERS_SUCCESS,
  FETCH_ACHIEVEMENT_OWNERS_FAILURE,
  FETCH_ACHIEVEMENTS_OWN_REQUEST,
  FETCH_ACHIEVEMENTS_OWN_SUCCESS,
  FETCH_ACHIEVEMENTS_OWN_FAILURE,
  FETCH_ACHIEVEMENTS_USER_REQUEST,
  FETCH_ACHIEVEMENTS_USER_SUCCESS,
  FETCH_ACHIEVEMENTS_USER_FAILURE,
  CREATE_ACHIEVEMENT,
  EDIT_ACHIEVEMENT,
} from "./types";

const initialState = {
  achievements: [],
  owners: [],
  ownersCount: null,
  achievementsCount: null,
  loading: false,
  error: null,
  actualRequestId: null,
};
const achievements = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACHIEVEMENTS_ALL_REQUEST:
    case FETCH_ACHIEVEMENTS_OWN_REQUEST:
    case FETCH_ACHIEVEMENTS_USER_REQUEST:
    case FETCH_ACHIEVEMENT_REQUEST:
    case FETCH_ACHIEVEMENT_OWNERS_REQUEST:
      return {
        ...state,
        actualRequestId: action.payload,
        loading: true,
        error: null,
      };
    case FETCH_ACHIEVEMENTS_ALL_SUCCESS:
    case FETCH_ACHIEVEMENTS_OWN_SUCCESS:
    case FETCH_ACHIEVEMENTS_USER_SUCCESS:
      if (action.payload.actualRequestId === state.actualRequestId) {
        return {
          ...state,
          achievements: action.payload.entities,
          achievementsCount: action.payload.count,
          loading: false,
        };
      }
      return { ...state };
    case FETCH_ACHIEVEMENT_SUCCESS:
        return {
          ...state,
          achievements: action.payload.achievements,
          loading: false,
        };
      return { ...state };
    case FETCH_ACHIEVEMENT_OWNERS_SUCCESS:
      if (action.payload.actualRequestId === state.actualRequestId) {
        return {
          ...state,
          owners: action.payload.entities,
          ownersCount: action.payload.count,
          loading: false,
        };
      }
      return { ...state };
    case CREATE_ACHIEVEMENT:
    case EDIT_ACHIEVEMENT:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ACHIEVEMENTS_ALL_FAILURE:
    case FETCH_ACHIEVEMENT_FAILURE:
    case FETCH_ACHIEVEMENT_OWNERS_FAILURE:
    case FETCH_ACHIEVEMENTS_OWN_FAILURE:
    case FETCH_ACHIEVEMENTS_USER_FAILURE:
      if (action.payload.actualRequestId === state.actualRequestId) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
      return { ...state };

    default:
      return {
        ...state,
      };
  }
};

export default achievements;
