import React, { useRef } from "react";
import PlaceholderCardImage from "../../../assets/images/PlaceholderCardImage.svg";
import { ReactComponent as AddIcon } from "../../../assets/images/addIcon.svg";

import styles from "./imageInput.module.scss";

const ImageInput = ({ value, onChange = () => {} , className}) => {
  const fileInput = useRef();
  const fileImage = useRef();

  return (
    <div
      className={styles.ImageInputBlock + ' ' + className}
      onClick={() => {
        fileInput.current.click();
      }}
    >
      <div className={styles.imagePresentBlock}>
        <img
          ref={fileImage}
          src={value ? value : PlaceholderCardImage}
          alt=''
        />
        {value ? "" : <AddIcon />}
      </div>
      <input
        accept='.jpg, .jpeg, .png'
        ref={fileInput}
        onChange={e => {
          let file = e.target.files[0];
          if (file) {
            fileImage.current.src = URL.createObjectURL(e.target.files[0]);
            onChange(file);
          }
        }}
        name='arenaLogo'
        type='file'
      />
    </div>
  );
};

export default ImageInput;
