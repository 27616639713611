const FETCH_GRATITUDE_CATEGORIES_REQUEST = "FETCH_GRATITUDE_CATEGORIES_REQUEST";
const FETCH_GRATITUDE_CATEGORIES_FAILURE = "FETCH_GRATITUDE_CATEGORIES_FAILURE";
const FETCH_GRATITUDE_CATEGORIES_SUCCESS = "FETCH_GRATITUDE_CATEGORIES_SUCCESS";

const FETCH_OWN_RECEIVED_GRATITUDE_REQUEST =
  "FETCH_OWN_RECEIVED_GRATITUDE_REQUEST";
const FETCH_OWN_RECEIVED_GRATITUDE_FAILURE =
  "FETCH_OWN_RECEIVED_GRATITUDE_FAILURE";
const FETCH_OWN_RECEIVED_GRATITUDE_SUCCESS =
  "FETCH_OWN_RECEIVED_GRATITUDE_SUCCESS";

const FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_REQUEST =
    "FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_REQUEST";
const FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_FAILURE =
    "FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_FAILURE";
const FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_SUCCESS =
    "FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_SUCCESS";

const FETCH_RECEIVED_GRATITUDE_BY_USERID_REQUEST =
  "FETCH_RECEIVED_GRATITUDE_BY_USERID_REQUEST";
const FETCH_RECEIVED_GRATITUDE_BY_USERID_FAILURE =
  "FETCH_RECEIVED_GRATITUDE_BY_USERID_FAILURE";
const FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_SUCCESS =
  "FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_SUCCESS";

const FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_REQUEST =
    "FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_REQUEST";
const FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_FAILURE =
    "FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_FAILURE";
const FETCH_RECEIVED_GRATITUDE_BY_USERID_SUCCESS =
    "FETCH_RECEIVED_GRATITUDE_BY_USERID_SUCCESS";

const FETCH_OWN_SENDED_GRATITUDE_REQUEST = "FETCH_OWN_SENDED_GRATITUDE_REQUEST";
const FETCH_OWN_SENDED_GRATITUDE_FAILURE = "FETCH_OWN_SENDED_GRATITUDE_FAILURE";
const FETCH_OWN_SENDED_GRATITUDE_SUCCESS = "FETCH_OWN_SENDED_GRATITUDE_SUCCESS";

const FETCH_SENDED_GRATITUDE_BY_USERID_REQUEST =
  "FETCH_SENDED_GRATITUDE_BY_USERID_REQUEST";
const FETCH_SENDED_GRATITUDE_BY_USERID_FAILURE =
  "FETCH_SENDED_GRATITUDE_BY_USERID_FAILURE";
const FETCH_SENDED_GRATITUDE_BY_USERID_SUCCESS =
  "FETCH_SENDED_GRATITUDE_BY_USERID_SUCCESS";

const FETCH_GIVE_POINTS_TO_USER_REQUEST = "FETCH_GIVE_POINTS_TO_USER_REQUEST";
const FETCH_GIVE_POINTS_TO_USER_FAILURE = "FETCH_GIVE_POINTS_TO_USER_FAILURE";
const FETCH_GIVE_POINTS_TO_USER_SUCCESS = "FETCH_GIVE_POINTS_TO_USER_SUCCESS";

export {
  FETCH_GRATITUDE_CATEGORIES_REQUEST,
  FETCH_GRATITUDE_CATEGORIES_FAILURE,
  FETCH_GRATITUDE_CATEGORIES_SUCCESS,
  FETCH_OWN_RECEIVED_GRATITUDE_REQUEST,
  FETCH_OWN_RECEIVED_GRATITUDE_FAILURE,
  FETCH_OWN_RECEIVED_GRATITUDE_SUCCESS,
  FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_REQUEST,
  FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_FAILURE,
  FETCH_OWN_TOTAL_COUNT_RECEIVED_GRATITUDE_SUCCESS,
  FETCH_RECEIVED_GRATITUDE_BY_USERID_REQUEST,
  FETCH_RECEIVED_GRATITUDE_BY_USERID_FAILURE,
  FETCH_RECEIVED_GRATITUDE_BY_USERID_SUCCESS,
  FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_REQUEST,
  FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_FAILURE,
  FETCH_RECEIVED_GRATITUDE_TOTAL_COUNT_SUCCESS,
  FETCH_OWN_SENDED_GRATITUDE_REQUEST,
  FETCH_OWN_SENDED_GRATITUDE_FAILURE,
  FETCH_OWN_SENDED_GRATITUDE_SUCCESS,
  FETCH_SENDED_GRATITUDE_BY_USERID_REQUEST,
  FETCH_SENDED_GRATITUDE_BY_USERID_FAILURE,
  FETCH_SENDED_GRATITUDE_BY_USERID_SUCCESS,
  FETCH_GIVE_POINTS_TO_USER_REQUEST,
  FETCH_GIVE_POINTS_TO_USER_FAILURE,
  FETCH_GIVE_POINTS_TO_USER_SUCCESS,
};
