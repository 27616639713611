import React from 'react';
import styles from './checkbox.module.scss';

const CheckBox = ({ prevValuesList = [], onChange, value = {} }) => {
  let checkboxStatus =
    prevValuesList.findIndex(item => item.id === value.id) > -1;

  return (
    <div className={styles.Checkbox}>
      <input onChange={() => {}} checked={checkboxStatus} type='checkbox' />
      <label
        onClick={() => {
          if (onChange) {
            let valueIndex = prevValuesList.findIndex(
              item => item.id === value.id,
            );

            // Если в массиве есть value удаляем его
            if (valueIndex > -1) {
              onChange([
                ...prevValuesList.slice(0, valueIndex),
                ...prevValuesList.slice(valueIndex + 1),
              ]);
            } else {
              // Если в массиве нет value добавляем его
              onChange([...prevValuesList, value]);
            }
          }
        }}
      ></label>
    </div>
  );
};

export default CheckBox;
