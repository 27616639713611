const FETCH_HISTORY_GRATITUDE_REQUEST = "FETCH_HISTORY_GRATITUDE_REQUEST";
const FETCH_HISTORY_GRATITUDE_FAILURE = "FETCH_HISTORY_GRATITUDE_FAILURE";
const FETCH_HISTORY_GRATITUDE_SUCCESS = "FETCH_HISTORY_GRATITUDE_SUCCESS";
const FETCH_HISTORY_EVENTS_REQUEST = "FETCH_HISTORY_EVENTS_REQUEST";

const FETCH_HISTORY_ACHIEVEMENTS_REQUEST = "FETCH_HISTORY_ACHIEVEMENTS_REQUEST";
const FETCH_HISTORY_ACHIEVEMENTS_FAILURE = "FETCH_HISTORY_ACHIEVEMENTS_FAILURE";
const FETCH_HISTORY_ACHIEVEMENTS_SUCCESS = "FETCH_HISTORY_ACHIEVEMENTS_SUCCESS";
const FETCH_HISTORY_EVENTS_SUCCESS = "FETCH_HISTORY_EVENTS_SUCCESS";
const FETCH_MORE_HISTORY_EVENTS_SUCCESS = "FETCH_MORE_HISTORY_EVENTS_SUCCESS";

export {
  FETCH_HISTORY_GRATITUDE_REQUEST,
  FETCH_HISTORY_GRATITUDE_FAILURE,
  FETCH_HISTORY_GRATITUDE_SUCCESS,
  FETCH_HISTORY_ACHIEVEMENTS_REQUEST,
  FETCH_HISTORY_ACHIEVEMENTS_FAILURE,
  FETCH_HISTORY_ACHIEVEMENTS_SUCCESS,
  FETCH_HISTORY_EVENTS_REQUEST,
  FETCH_HISTORY_EVENTS_SUCCESS,
  FETCH_MORE_HISTORY_EVENTS_SUCCESS,
};
