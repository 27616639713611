import React, { useEffect, useState } from "react";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApiService } from "../hoc";
import compose from "../../utils/compose";
import { getAchievement, getAchievementOwners } from "../../redux/achievements/actions";
import { ReactComponent as PlaceholderCardImage } from "../../assets/images/PlaceholderCardImage.svg";
import { ReactComponent as UserPlaceholder } from "../../assets/images/userPlaceholder.svg";

import { Button } from "../FormItems";
import { GridTable } from "../Tables";
import LoadScreen from "../LoadScreen";

import styles from "./achievement.module.scss";
import getUrlParam from "../../utils/getUrlParam";
import Pagination from "../Tables/Pagination";

const Achievement = ({
  match,
  history,
  owners,
  ownersCount,
  ownId,
  achievement,
  loading,
  getAchievement,
  getAchievementOwners
}) => {

  const desktop = window.innerWidth < 768 ? false : true;
  let urlParam = getUrlParam('page');
  const [filter, setFilter] = useState({limit: desktop ? 9999 : 8, offset: (urlParam ? urlParam - 1 : 0) * (desktop ? 9999 : 8)});

  useEffect(() => {
    getAchievement(match.params.id);
    getAchievementOwners(match.params.id, filter);
  }, []);

  if (loading) {
    return <LoadScreen />;
  }

  return (
    <>
      <div className='mt3'></div>
      <div className={styles.card}>
        <div className={styles.cardFlexCols}>
          <div className={styles.avatar}>
            {achievement.image ? (
              <img
                src={window.location.origin + achievement.image.image}
                alt='avatar'
              />
            ) : (
              <PlaceholderCardImage />
            )}
          </div>
          <div className={styles.name}>{achievement.name}</div>
        </div>
        <div className={styles.cardFlexCols}>
          <div className={styles.blaceTheme}>Можно получить за:</div>
          <div className={styles.givenToName}>{achievement.description}</div>
        </div>
        <div className='mt2'></div>
        <div className={styles.cardFlexCols}>
          {achievement.holdersPercent > 1 &&
          owners && owners.findIndex(item => item.id === ownId) > -1 ? (
            <div className={styles.blaceTheme}>
              Уже есть у <span style={{ color: "#5f37ff" }}>тебя</span> и еще у{" "}
              {achievement.holdersPercent}% пользователей
            </div>
          ) : (
            ""
          )}

          {achievement.holdersPercent > 1 &&
          owners &&
          !(owners.findIndex(item => item.id === ownId) > -1) ? (
            <div className={styles.blaceTheme}>
              Уже есть у {achievement.holdersPercent}% пользователей, но не у{" "}
              <span style={{ color: "#5f37ff" }}>тебя</span>
            </div>
          ) : (
            ""
          )}

          {achievement.holdersPercent < 0 ? (
            <div className={styles.blaceTheme}>
              Этого достижение пока еще никто не получил
            </div>
          ) : (
            ""
          )}
        </div>
        <div className='mt3'></div>
        <GridTable
          onClickRow={rowData => {
            history.push(`/user/${rowData.id}`);
          }}
          dataSource={owners}
          columns={[
            {
              title: "Пользователи у которых есть это достижение",
              dataIndex: "nickname",
              render: userRowData => {
                return (
                  <Link
                    to={
                      ownId === userRowData.id ? "/" : `/user/${userRowData.id}`
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div className={styles.AchievementsUserAvatar}>
                          {userRowData.avatar ? (
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "block",
                                objectFit: "cover",
                              }}
                              src={userRowData.avatar}
                              alt='achievement'
                            />
                          ) : (
                            <UserPlaceholder
                              width='30px'
                              height='30px'
                              style={{
                                borderRadius: 8,
                                backgroundColor: "#EBEEF1",
                                padding: "0.2rem",
                              }}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            marginLeft: 10,
                          }}
                        >
                          {userRowData.firstName + " " + userRowData.lastName ||
                            userRowData.nickname}
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              },
            },
          ]}
        />
        {!desktop && ownersCount > filter.limit ? (
            <Pagination
                total={ownersCount}
                offset={(urlParam ? (urlParam - 1) : filter.offset) * filter.limit}
                limit={filter.limit}
                onChange={newPage => {
                  setFilter({ ...filter, offset: (newPage - 1) * filter.limit });
                  getAchievementOwners(match.params.id, { ...filter, offset: (newPage - 1) * filter.limit }, true)
                }}
            />
        ) : (
            ""
        )}
        <div className='mt3'></div>

        <Button
          label='Вернуться назад'
          handleClick={() => {
            history.push("/achievements");
          }}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ achievements, auth }) => ({
  ownId: auth.userId,
  achievement: achievements.achievements[0] || {},
  owners: achievements.owners,
  ownersCount: achievements.ownersCount,
  loading: achievements.loading,
});
const mapDispatchToProps = (dispatch, { apiService, achievementId, filter }) => {
  return bindActionCreators(
    {
      getAchievement: getAchievement(apiService, achievementId),
      getAchievementOwners: getAchievementOwners(apiService, achievementId, filter),
    },
    dispatch,
  );
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Achievement));
