import React, { useState } from "react";

import styles from "./gridTableWithToogleRows.module.scss";
import { ReactComponent as EmptyTable } from "../../../assets/images/EmptyTable.svg";

const GridTableWithToogleRows = ({
  dataSource = [],
  differentRowColor = true,
  columns = [],
  highlightRow = () => {},
  onRowClick = () => {},
  openRowComponent: OpenRowComponent = false,
  loading = false,
}) => {
  const [openRowIndex, setOpenRowIndex] = useState();

  if (loading) {
    return (
      <div className={styles.LoadScreen}>
        <div className='mainFountainG'>
          <div className='fountainG fountainG_1'></div>
          <div className='fountainG fountainG_2'></div>
          <div className='fountainG fountainG_3'></div>
          <div className='fountainG fountainG_4'></div>
          <div className='fountainG fountainG_5'></div>
          <div className='fountainG fountainG_6'></div>
          <div className='fountainG fountainG_7'></div>
          <div className='fountainG fountainG_8'></div>
        </div>
      </div>
    );
  }
  if (!loading && dataSource.length === 0) {
    return (
      <div className={styles.EmptyTableBlock}>
        <EmptyTable />
        <div className={styles.Text}>Нет данных</div>
      </div>
    );
  }

  return (
    <table className={styles.GridTableWithToogleRows}>
      <thead>
        <tr>
          {columns.map((headerRowItem, headerRowIndex) => {
            return (
              <th
                key={headerRowIndex + "toogledTableTh" + headerRowItem.title}
                style={
                  headerRowItem.width ? { width: headerRowItem.width } : {}
                }
              >
                {headerRowItem.title}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {dataSource.map((rowItem, rowIndex) => {
          return (
            <>
              <tr
                key={rowIndex + "toogledTableTR" + rowItem.id}
                onClick={() => {
                  setOpenRowIndex(openRowIndex === rowIndex ? null : rowIndex);
                  onRowClick(rowItem, rowIndex);
                }}
                className={
                  rowIndex % 2 === 0 && differentRowColor ? styles.gray : ""
                }
                style={
                  highlightRow(rowItem)
                    ? { backgroundColor: "#5f37ff", color: "#ffffff" }
                    : {}
                }
              >
                {columns.map(headerRowItem => {
                  return (
                    <td
                      key={
                        "toogledTableTd" +
                        rowIndex +
                        rowItem.id +
                        headerRowItem.title
                      }
                      style={
                        headerRowItem.width
                          ? { width: headerRowItem.width }
                          : {}
                      }
                    >
                      {headerRowItem.render
                        ? headerRowItem.render(rowItem)
                        : rowItem[headerRowItem.dataIndex]}
                    </td>
                  );
                })}
              </tr>
              {OpenRowComponent ? (
                  <tr className={styles.openRow}>
                      <td>
                          <div
                              className={
                                  openRowIndex === rowIndex
                                      ? styles.toogledRowActive
                                      : styles.toogledRow
                              }
                          >
                              <OpenRowComponent
                                  key={rowIndex + "toogledTableTROpenRow" + rowItem.id}
                                  rowData={rowItem}
                                  rowIndex={rowIndex}
                              />
                          </div>
                      </td>
                  </tr>
              ) : (
                ""
              )}
            </>
          );
        })}
      </tbody>
    </table>
  );
};

export default GridTableWithToogleRows;
