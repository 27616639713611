import React from "react";

import { Link } from "react-router-dom";
import styles from "./pointsCard.module.scss";
import getFormattedDate from "../../../utils/getFormattedDate";

import { ReactComponent as UserPlaceholder } from "../../../assets/images/userPlaceholder.svg";
import { ReactComponent as BcoinIcon } from "../../../assets/images/bcoin.svg";
const PointsCard = ({ cardData }) => {
  const { firstName, lastName, firstNameGenitive,
    lastNameGenitive, nickname, isAppcraftUser
  } = cardData.grantedBy;

  return (
    <div className={styles.card}>
      <div className={styles.flex} style={{marginBottom: '32px'}}>
        <div className={styles.avatar}>
          {cardData.givenTo.avatar ? (
            <img src={cardData.givenTo.avatar} alt='avatar' />
          ) : (
            <UserPlaceholder />
          )}
        </div>
        <div className={styles.infoUser}>
          <Link to={`/user/${cardData.givenTo.id}`}>
            <div className={styles.name}>
              {cardData.givenTo.firstName}{" "}
              {cardData.givenTo.lastName || cardData.givenTo.nickname}
            </div>
          </Link>
          <Link to={`/user/${cardData.grantedBy.id}`}>
            {!isAppcraftUser ?
              <div className={styles.givenToName}>
                  от {firstNameGenitive && lastNameGenitive ? `${firstNameGenitive} ${lastNameGenitive}` : `${firstName} ${lastName}` }
              </div> :
              <div className={styles.givenToName}>
                  от {nickname}
              </div>
            }
          </Link>
          <div className={styles.createdDate}>
            {getFormattedDate(cardData.createdAt)}
          </div>
        </div>
      </div>

      <div className={styles.flex}>
        <div className={styles.category} style={{marginRight: '12px'}}>
          <div className={styles.bcoins}>
            + {cardData.value}
            <BcoinIcon />
          </div>
        </div>
        <div className={styles.category}>{cardData.category.name}</div>
      </div>

      <div className={styles.comment}>{cardData.commentary}</div>
    </div>
  );
};

export default PointsCard;
