import React from "react";
import styles from "./slider.module.scss";
import RangeSlider from "rc-slider";

const Handle = RangeSlider.Handle;
const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return <Handle value={value} {...restProps} />;
};

const SliderCoin = ({
  label = "",
  defValue = 0,
  maxValue,
  value = 0,

  minValue = 1,
  onChange = () => {},
}) => {
  return (
    <div className={styles.SliderCoin}>
      <label>{label}</label>

      <RangeSlider
        min={minValue}
        max={maxValue}
        value={value}
        step={1}
        marks={{
          1: { label: 1 },
          2: { label: 2 },
          3: { label: 3 },
          4: { label: 4 },
          5: { label: 5 },
          6: { label: 6 },
          7: { label: 7 },
          8: { label: 8 },
          9: { label: 9 },
          10: { label: 10 },
        }}
        onChange={val => {
          if (val >= minValue && val <= maxValue) {
            onChange(+val);
          }
        }}
        handle={handle}
      />
    </div>
  );
};

export default SliderCoin;
