import {
  FETCH_PURCHASE_REQUEST,
  FETCH_PURCHASE_FAILURE,
  FETCH_PURCHASE_SUCCESS,
  FETCH_MORE_PURCHASE_SUCCESS,
  FETCH_PURCHASE_CUSTOMERS_REQUEST,
  FETCH_PURCHASE_CUSTOMERS_FAILURE,
  FETCH_PURCHASE_CUSTOMERS_SUCCESS,
} from './types';

export const getPurchaseRequest = () => {
  return { type: FETCH_PURCHASE_REQUEST };
};
export const getPurchaseFailure = err => {
  return { type: FETCH_PURCHASE_FAILURE, payload: err };
};
export const getPurchaseSuccess = products => {
  return { type: FETCH_PURCHASE_SUCCESS, payload: products };
};
export const getPurchaseMoreSuccess = payload => {
  return { type: FETCH_MORE_PURCHASE_SUCCESS, payload }
};

export const getPurchaseСustomersRequest = () => {
  return { type: FETCH_PURCHASE_CUSTOMERS_REQUEST };
};
export const getPurchaseСustomersFailure = err => {
  return { type: FETCH_PURCHASE_CUSTOMERS_FAILURE, payload: err };
};
export const getPurchaseСustomersSuccess = customers => {
  return { type: FETCH_PURCHASE_CUSTOMERS_SUCCESS, payload: customers };
};