import React, {useEffect, useState} from "react";
import CountUp from "react-countup";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Link} from "react-router-dom";
import {withApiService} from "../hoc";
import compose from "../../utils/compose";
import toDeclension from '../../utils/toDeclension';
import {getOwnAchievements} from "../../redux/achievements/actions";


import {
    getGratitudeCategories,
    getOwnReceivedGratitude,
    getOwnTotalCountReceivedGratitude,
    getOwnSendedGratitude,
    givePointsToUser,
    givePointsToAllUser
} from "../../redux/points/actions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {getUserMainProjects, getUserMinorProjects} from "../../redux/projects/actions";
import {getUsers} from "../../redux/users/actions";
import styles from "./home.module.scss";

import CardsList from "../CardsList";
import {
    Form,
    Search,
    SearchOnChangeReload,
    Select,
    Textarea,
    Button,
    SliderCoin,
    SelectFilter
} from "../FormItems";

import CheckBox from "../lib/CheckBox";
import {CardsTable} from "../Tables";
import PointsReceivedBackCard from "./PointsReceivedBackCard";
import PointsReceivedFrontCard from "./PointsReceivedFrontCard";
import {ReactComponent as PlaceholderCardImage} from "../../assets/images/PlaceholderCardImage.svg";
import {
    ReactComponent as PlaceholderProfileIcon,
    ReactComponent as UserPlaceholderImage
} from "../../assets/images/userPlaceholder.svg";
import {ReactComponent as BcoinIcon} from "../../assets/images/bcoin.svg";
import {ReactComponent as CloseIcon} from "../../assets/images/closeIcon.svg";
import LoadScreen from "../LoadScreen";
import Pagination from "../Tables/Pagination";
import PaginationTwo from "../Tables/PaginationTwo";
import PaginationOne from "../Tables/PaginationOne";
import getUrlParam from "../../utils/getUrlParam";

const findUserRoleInProject = (projectUsers = [], currentUserId) => {
    let userRole = "";
    if (projectUsers.length > 0 && currentUserId) {
        let findendUser = projectUsers.find(user => user.id === currentUserId);
        userRole = findendUser ? findendUser.role : "";
    }
    return userRole;
};


const RowProjectsTableItem = ({projectData, currentUserId, transparentColor = false,}) => {
    return (
        <Link to={`/projects/${projectData.id}`} key={"RowProjectsTableItem" + projectData.id}>
            <div
                className={styles.rowItem}
                style={{backgroundColor: transparentColor ? "transparent" : ""}}
            >
                <div className={styles.imgBlock}>
                    {projectData.image ? (
                        <img
                            src={window.origin + projectData.image.image}
                            alt='project'
                            style={{}}
                        />
                    ) : (
                        <PlaceholderCardImage
                            width='40px'
                            height='40px'
                            style={{
                                marginRight: 15,
                                borderRadius: 8,
                                backgroundColor: "#EBEEF1",
                                padding: "0.2rem",
                            }}
                        />
                    )}
                </div>
                <div className={styles.infoBlock}>
                    <div className={styles.name}>{projectData.name}</div>
                    <div className={styles.role}>
                        {findUserRoleInProject(projectData.users, currentUserId)}
                    </div>
                </div>
            </div>
        </Link>
    );
};
const Home = ({
                  achievementsList = [],
                  achievementsLoading = false,
                  achievementsCount,
                  pointsList = [],
                  pointsTotal,
                  pointsCount,
                  pointsLoading = false,
                  pointsCategories = [],
                  projectsLoading = false,
                  mainProjects = [],
                  mainProjectsCount,
                  minorProjectsCount,
                  minorProjects = [],
                  searchUsersList = [],
                  maxGivePoints = 0,
                  getOwnAchievements,
                  getGratitudeCategories,
                  getOwnReceivedGratitude,
                  getOwnTotalCountReceivedGratitude,
                  getOwnSendedGratitude,
                  getUserMainProjects,
                  getUserMinorProjects,
                  searchUsers,
                  givePointsToUser,
                  givePointsToAllUser,
                  isSuperuser,
                  userId,
                  avatar,
                  firstName,
                  lastName,
                  sex,
              }) => {
    const desktop = window.innerWidth < 768 ? false : true;
    let urlParamOne = getUrlParam('pageOne') === 0 ? 1 : getUrlParam('pageOne');
    let urlParamTwo = getUrlParam('pageTwo') === 0 ? 1 : getUrlParam('pageTwo');
    const [filter, setFilter] = useState(
        JSON.parse(localStorage.getItem("homePointsFilter")) || {
            time: 4, limit: desktop ? 9999 : 4, offset: 0
        },
    );

    const [mainProjectFilter, setMainProjectFilter] = useState({
            limit: desktop ? 20 : 8,
            offset: (urlParamOne ? (urlParamOne - 1) : 0) * (desktop ? 20 : 8),
            order: 'desc'
        }
    );
    const [minorProjectFilter, setMinorProjectFilter] = useState({
            limit: desktop ? 20 : 8,
            offset: (urlParamTwo ? (urlParamTwo - 1) : 0) * (desktop ? 20 : 8),
            order: 'desc'
        }
    );


    const [type, setType] = useState(1);
    const [givePointsForm, setGivePointsForm] = useState({amount: 1});
    const [baseFilter, setBaseFilter] = useState({limit: 10, offset: 0});
    const [givePointsFormMultiplePeople, setGivePointsFormMultiplePeople] = useState([]);

    const updateGivePointsForm = (fieldName, fieldValue) => {
        setGivePointsForm(prevState => ({...prevState, [fieldName]: fieldValue}));
    };

    const updatePointsFilter = (fieldName, fieldValue) => {
        window.history.pushState(null, null, `?page=1`);
        setBaseFilter({offset: 0, limit: 4})

        setFilter(prevState => ({...prevState, [fieldName]: fieldValue.id}));

        if (fieldName !== "grantedById") {
            localStorage.setItem(
                "homePointsFilter",
                JSON.stringify({
                    ...filter,
                    [fieldName]: fieldValue.id,
                }),
            );
        }

        // Если в фильтре выбран пользователь, то ищем только по нему
        type === 1
            ? getOwnReceivedGratitude({...filter, [fieldName]: fieldValue.id, limit: !desktop ? 4 : 9999})
            : getOwnSendedGratitude({...filter, [fieldName]: fieldValue.id, limit: !desktop < 768 ? 4 : 9999});
    };

    useEffect(() => {
        localStorage.removeItem('allAchievementsVisibleBlock');
        localStorage.removeItem('achievementsHistoryFilterTime');

        localStorage.removeItem('pointsRatingFilterTime');
        localStorage.removeItem('pointsRatingFilterCategory');
        localStorage.removeItem('achievementsVisibleBlock');
        localStorage.removeItem('achievementsRatingFilterTime');

        localStorage.removeItem('eventsFilterTime');
        localStorage.removeItem('eventsFilterType');
        localStorage.removeItem('eventsFilterCategory');


        localStorage.removeItem('otherUserPointsFilter');
        localStorage.removeItem('UserVisibleBlock');

        localStorage.removeItem('productsFilterType');
        localStorage.removeItem('coinFilterFrom');
        localStorage.removeItem('coinFilterTo');

        localStorage.setItem('HomeVisibleBlock', 'project')

        getOwnTotalCountReceivedGratitude({time: 1});
        getOwnReceivedGratitude({...filter, limit: desktop ? 9999 : 4});
        getOwnAchievements({limit: desktop ? 9999 : 4, offset: 0});
        getGratitudeCategories();
    }, []);

    useEffect(() => {
        if (userId) {
            getUserMainProjects(userId, mainProjectFilter);
            getUserMinorProjects(userId, minorProjectFilter)
        }
    }, [userId]);

    let totalPointsReceive = pointsList.reduce((accumulator, item) => {
        return accumulator.value
            ? accumulator.value + +item.value
            : accumulator + +item.value;
    }, 0);

    const timeFilterOptions = [
        {id: 1, name: "За все время"},
        {id: 2, name: "За 24 часа"},
        {id: 3, name: "За 7 дней"},
        {id: 4, name: "За 30 дней"},
    ];

    const pointsTypes = [
        {id: 1, name: "Полученные благодарности"},
        {id: 2, name: "Отправленные благодарности"},
    ];

    const renderSelectedPeople =
        givePointsFormMultiplePeople !== undefined && givePointsFormMultiplePeople.length !== 0 ?
            <>
                <div className='mb1'></div>
                <ul className={styles.multiplePointsList}>
                    {
                        givePointsFormMultiplePeople.map((item, index) => {
                            return (
                                <li className={styles.multiplePointsItem}
                                    key={"givePointsFormMultiplePeople" + item.id}>
                                    {item.name}
                                    <CloseIcon
                                        className={styles.multiplePointsItemClose}
                                        onClick={() => {
                                            let arr = givePointsFormMultiplePeople.slice(0, index);
                                            setGivePointsFormMultiplePeople(arr.concat(givePointsFormMultiplePeople.slice(index + 1)))
                                        }
                                        }
                                    />
                                </li>
                            )
                        })
                    }
                </ul>
            </> : ''

    return (
        <>
            <div className='mt3'></div>
            <div className={styles.userContainer}>
                <div className={styles.imgContainer}>
                    {avatar ? (
                        <img src={avatar} alt='user avatar'/>
                    ) : (
                        <UserPlaceholderImage/>
                    )}
                </div>
                <div className={styles.infoContainer}>
                    <div className={styles.name}>{`${firstName || ""} ${lastName ||
                    ""}`}</div>
                    <div className={styles.counters}>
                        <div className={styles.counterBlock}>
                            <CountUp end={mainProjects && mainProjects.length}/>
                            <div
                                className={styles.counterText}>{toDeclension(+mainProjects.length, ['Проект', 'Проекта', 'Проектов'])}</div>
                        </div>
                        <div className={styles.counterBlock}>
                            <CountUp end={achievementsList && achievementsList.length}/>
                            <div
                                className={styles.counterText}>{toDeclension(+achievementsList.length, ['Достижение', 'Достижения', 'Достижений'])}</div>
                        </div>
                        <div className={styles.counterBlock}>
                            <CountUp end={pointsTotal}/>
                            <div
                                className={styles.counterText}>{toDeclension(+pointsTotal, ['Благодарность', 'Благодарности', 'Благодарностей'])}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt3'></div>
            <div className={styles.infoContainerControls}>
                <Slider
                    dots={false}
                    arrows={false}
                    className={"center"}
                    centerMode={true}
                    centerPadding={"70px"}
                    slidesToShow={1}
                    responsive={[
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]}
                >
                    <div className={styles.slidersContainer}>
                        <Button
                            style={{
                                width: '90%',
                                margin: '0 auto',
                                maxWidth: '90%',
                                background: localStorage.getItem('HomeVisibleBlock') === 'project' ? '#5f37ff' : '#ffffff',
                                color: localStorage.getItem('HomeVisibleBlock') === 'project' ? '#ffffff' : '#5f37ff',
                            }}
                            label='Проекты'
                            handleClick={() => {
                                localStorage.setItem('HomeVisibleBlock', 'project');
                                setBaseFilter({limit: 8, offset: 0});
                                window.history.pushState(null, null, `?page=1`);
                                getUserMainProjects(userId, {limit: window.innerWidth > 768 ? 9999 : 8, offset: 0});
                                getUserMinorProjects(userId, {limit: window.innerWidth > 768 ? 9999 : 8, offset: 0})
                            }}
                        />
                    </div>
                    <div className={styles.slidersContainer}>
                        <Button
                            style={{
                                width: '90%',
                                margin: '0 auto',
                                maxWidth: '90%',
                                background: localStorage.getItem('HomeVisibleBlock') === 'points' ? '#5f37ff' : '#ffffff',
                                color: localStorage.getItem('HomeVisibleBlock') === 'points' ? '#ffffff' : '#5f37ff',
                            }}
                            label='Благодарности'
                            handleClick={() => {
                                localStorage.setItem('HomeVisibleBlock', 'points');
                                setBaseFilter({limit: 4, offset: 0});
                                window.history.pushState(null, null, `?page=1`);
                                getOwnReceivedGratitude({...filter, limit: 4, offset: 0})
                            }}
                        />
                    </div>
                    <div className={styles.slidersContainer}>
                        <Button
                            style={{
                                width: '90%',
                                margin: '0 auto',
                                maxWidth: '90%',
                                background: localStorage.getItem('HomeVisibleBlock') === 'achievements' ? '#5f37ff' : '#ffffff',
                                color: localStorage.getItem('HomeVisibleBlock') === 'achievements' ? '#ffffff' : '#5f37ff',
                            }}
                            label='Достижения'
                            handleClick={() => {
                                localStorage.setItem('HomeVisibleBlock', 'achievements');
                                setBaseFilter({limit: 8, offset: 0});
                                window.history.pushState(null, null, `?page=1`);
                                getOwnAchievements({limit: 8, offset: 0});
                            }}
                        />
                    </div>
                </Slider>
            </div>
            <div className={styles.projectsContainer}
                 style={{display: desktop ? 'grid' : localStorage.getItem('HomeVisibleBlock') === 'project' ? 'block' : 'none'}}>
                <div className={styles.achievementsCardsListTitle}
                     style={{display: desktop ? 'block' : 'none'}}>Проекты
                </div>
                <div className='mt2'></div>
                {projectsLoading ? (
                    <div style={{position: 'relative', marginTop: 50, marginBottom: 50}}><LoadScreen/></div>
                ) : mainProjects.length > 0 ? (
                    <div>
                        <div className={styles.achievementsCardsListTitle}>Основное участие</div>
                        <div className='mt2'></div>
                        <div className={styles.allMyProjectsContainer}>
                            {mainProjects.map(item => {
                                return (
                                    <Link to={`/projects/${item.id}`} key={'mainProjects' + item.id}
                                          style={{display: 'flex'}}>
                                        <div className={styles.projectCard}>
                                            <div className={styles.cardImg}>
                                                {item.image ? (
                                                    <img
                                                        className={styles.cardIcon}
                                                        src={window.origin + item.image.image}
                                                        alt={"project icon"}
                                                    />
                                                ) : (
                                                    <PlaceholderCardImage
                                                        width='180px'
                                                        height='180px'
                                                        style={{borderRadius: 8}}
                                                    />
                                                )}
                                            </div>
                                            <div className={styles.cardTitle}>{item.name}</div>
                                            <div className={styles.cardRole}>
                                                {findUserRoleInProject(item.users, userId)}
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                        <div className='mt2'></div>
                        {!desktop && mainProjectsCount > mainProjectFilter.limit ? (
                            <>
                                <PaginationOne
                                    total={mainProjectsCount}
                                    offset={(urlParamOne ? (urlParamOne - 1) : mainProjectFilter.offset) * mainProjectFilter.limit}
                                    limit={mainProjectFilter.limit}
                                    onChange={newPage => {
                                        setMainProjectFilter({
                                            ...mainProjectFilter,
                                            offset: (newPage - 1) * mainProjectFilter.limit
                                        })
                                        getUserMainProjects(userId, {
                                            ...mainProjectFilter,
                                            offset: (newPage - 1) * mainProjectFilter.limit
                                        });
                                    }}
                                />
                                <div className='mt2'></div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                ) : (
                    <div className={styles.projectsContainerText}>Проектов не обнаружено</div>
                )}
                {projectsLoading ? (
                    <div style={{position: 'relative', marginTop: 50, marginBottom: 50}}><LoadScreen/></div>
                ) : minorProjects.length > 0 ? (
                    <div>
                        <div className={styles.achievementsCardsListTitle}>
                            {sex === 1 ? "Принимал" : "Принимала "} участие
                        </div>
                        <div className='mt2'></div>
                        <div className={styles.table}>
                            {minorProjects.length > 0 ? (
                                minorProjects.map((project, projectIndex) => (
                                    <RowProjectsTableItem
                                        key={"RowProjectsTableItem" + projectIndex}
                                        projectData={project}
                                        currentUserId={userId}
                                        transparentColor={projectIndex % 2 > 0}
                                    />
                                ))
                            ) : (
                                ""
                            )}
                        </div>
                        <div className='mt2'></div>
                        {!desktop < 768 && minorProjectsCount > minorProjectFilter.limit ?
                            (<PaginationTwo
                                    total={minorProjectsCount}
                                    offset={(urlParamTwo ? (urlParamTwo - 1) : minorProjectFilter.offset) * minorProjectFilter.limit}
                                    limit={minorProjectFilter.limit}
                                    onChange={newPage => {
                                        setMinorProjectFilter({
                                            ...minorProjectFilter,
                                            offset: (newPage - 1) * minorProjectFilter.limit
                                        })
                                        getUserMinorProjects(userId, {offset: (newPage - 1) * minorProjectFilter.limit});
                                    }}
                                />
                            ) : ''
                        }
                    </div>
                ) : (
                    ''
                )}

            </div>
            <div className={styles.achievementsContainer}
                 style={{display: desktop ? 'block' : localStorage.getItem('HomeVisibleBlock') === 'achievements' ? 'block' : 'none'}}>
                <div className='mt1'></div>
                <div className={styles.achievementsCardsListTitle}>Достижения</div>
                <div className='mt2'></div>
                <div className={styles.achievementsList}>
                    <CardsList
                        loading={achievementsLoading}
                        cards={achievementsList}
                    />
                </div>
                {!desktop && achievementsCount > baseFilter.limit ? (
                    <Pagination
                        total={achievementsCount}
                        offset={baseFilter.offset}
                        limit={baseFilter.limit}
                        onChange={newPage => {
                            setBaseFilter({offset: (newPage - 1) * baseFilter.limit, limit: baseFilter.limit})
                            getOwnAchievements({...baseFilter, offset: (newPage - 1) * baseFilter.limit})
                        }}
                    />
                ) : (
                    ""
                )}
            </div>
            <div className={styles.pointsContainer}
                 style={{display: desktop ? 'block' : localStorage.getItem('HomeVisibleBlock') === 'points' ? 'block' : 'none'}}>
                <div className='mt3'></div>
                <div className={styles.twoCols}>
                    <div className={styles.giveBCoinsForm}>
                        <div className='titleH1'>Поблагодарить</div>
                        <div className='mb2'></div>
                        <Form
                            onSubmit={e => {
                                e.preventDefault();
                            }}
                        >
                            <Search
                                label='Кого'
                                placeholder='Имя получателя'
                                onSelect={value => {
                                    setGivePointsFormMultiplePeople(givePointsFormMultiplePeople.concat({
                                        name: value.firstName + ' ' + value.lastName,
                                        id: value.id
                                    }))
                                    updateGivePointsForm("userId", value);
                                    console.log(searchUsersList);
                                    console.log(givePointsFormMultiplePeople);
                                }}
                                searchResults={searchUsersList.filter(x => !givePointsFormMultiplePeople.find(user => user.id === x.id) && x.id !== userId)}
                                searchRequest={val => {
                                    searchUsers({name: val});
                                }}
                                selectedValue={givePointsForm.userId}
                            />
                            <div className='mb1'></div>
                            {
                                renderSelectedPeople
                            }
                            <div className='mb1'></div>
                            <Select
                                label='За что'
                                placeholder='Категория благодарности'
                                onSelect={value => {
                                    updateGivePointsForm("gratitudeCategoryId", value);
                                }}
                                selectedValue={givePointsForm.gratitudeCategoryId}
                                options={pointsCategories}
                                clearSelect={() => {
                                    updateGivePointsForm("gratitudeCategoryId", {});
                                }}
                            />
                            <div className='mb1'></div>
                            <Textarea
                                label={"Комментарий"}
                                placeholder={"Краткий комментарий"}
                                minHeight={"60px"}
                                value={givePointsForm.commentary}
                                onChange={value => {
                                    updateGivePointsForm("commentary", value);
                                }}
                            />
                            <div className='mb15'></div>
                            {isSuperuser && (
                                <CheckBox
                                    id='givePointsForm-by-app-craft'
                                    label='от имени AppCraft'
                                    checked={!!givePointsForm.byAppCraft}
                                    onChange={({target: {checked}}) => {
                                        updateGivePointsForm("byAppCraft", checked);
                                    }}
                                />
                            )}
                            <div className={styles.sendBCoinsFormTwoCols}>
                                {maxGivePoints == 1 ?
                                    <div className={styles.sliderCoinOnePoint}>
                                        <div className={styles.sliderCoinOnePointIcon}><BcoinIcon/></div>
                                    </div> :
                                    <SliderCoin
                                        label='Сколько дарю'
                                        value={givePointsForm.amount}
                                        placeholder={1}
                                        maxValue={
                                            givePointsForm.byAppCraft || maxGivePoints > 10
                                                ? 10
                                                : maxGivePoints
                                        }
                                        minValue={1}
                                        onChange={value => {
                                            updateGivePointsForm("amount", +value);
                                        }}
                                    />
                                }
                            </div>
                            <div className='mb2'></div>
                            <Button
                                style={{float: "right"}}
                                label='Поблагодарить'
                                handleClick={() => {
                                    if (givePointsFormMultiplePeople.length > 1) {
                                        givePointsToAllUser({
                                            ...givePointsForm,
                                            userIds: givePointsFormMultiplePeople.map(item => item.id)
                                        }, () => {
                                            updateGivePointsForm({userId: {name: "null"}});
                                            updateGivePointsForm("amount", 1);
                                            setGivePointsFormMultiplePeople([]);
                                            getOwnReceivedGratitude(filter);
                                            updateGivePointsForm("gratitudeCategoryId", {});
                                            updateGivePointsForm("commentary", '');
                                        });
                                    } else {
                                        givePointsToUser(givePointsForm, () => {
                                            updateGivePointsForm({userId: {name: "null"}});
                                            updateGivePointsForm("amount", 1);
                                            setGivePointsFormMultiplePeople([]);
                                            getOwnReceivedGratitude(filter);
                                            updateGivePointsForm("gratitudeCategoryId", {});
                                            updateGivePointsForm("commentary", '');
                                        });
                                    }
                                }}
                            />
                        </Form>
                    </div>
                    <div className={styles.bCoinsHistoryList}>
                        <div className={styles.twoCols2}>
                            <div className='titleH1 mb1'>Благодарности</div>
                            {totalPointsReceive > 0 ? (
                                <div className={styles.totalPointsReceive + ' ' + 'mb1'}>
                                    <div style={{marginTop: 3}}>
                                        {type === 1 ? "Вы получили" : "Вы отправили"}{" "}
                                        {totalPointsReceive}{" "}
                                    </div>
                                    <BcoinIcon
                                        style={{marginLeft: 5, marginBottom: -2}}
                                        width={25}
                                        height={25}
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className={styles.twoCols2 + ' ' + 'pr3'}>
                            <div style={{marginTop: 17}}>
                                <SearchOnChangeReload
                                    label='Имя'
                                    placeholder={
                                        type === 1 ? "Имя отправителя" : "Имя получателя"
                                    }
                                    onSelect={value => {
                                        updatePointsFilter("grantedById", value);
                                    }}
                                    onKeyPress={() => {
                                        if (filter.grantedById && filter.grantedById.length !== 0) {
                                            updatePointsFilter("grantedById", {id: ''});
                                        }
                                    }
                                    }
                                    searchResults={searchUsersList}
                                    searchRequest={val => {
                                        searchUsers({name: val});
                                    }}
                                    selectedValue={filter.user || {}}
                                />
                                <div className='mb2'></div>
                                <SelectFilter
                                    placeholder='За все время'
                                    onSelect={value => {
                                        updatePointsFilter("time", value);
                                    }}
                                    options={timeFilterOptions}
                                    selectedValue={
                                        timeFilterOptions.find(item => item.id === filter.time) || {}
                                    }
                                    clearSelect={() => {
                                        updatePointsFilter("time", {});
                                    }}
                                />
                            </div>
                            <div style={{marginTop: 36}}>
                                <SelectFilter
                                    placeholder='Тип благодарности'
                                    onSelect={value => {
                                        setType(value.id);
                                        window.history.pushState(null, null, `?page=1`);
                                        setBaseFilter({offset: 0, limit: 4})
                                        value.id === 1 ? getOwnReceivedGratitude({
                                            ...filter,
                                            limit: desktop ? 9999 : 4
                                        }) : getOwnSendedGratitude({...filter, limit: desktop ? 9999 : 4})
                                    }}
                                    options={pointsTypes}
                                    selectedValue={
                                        pointsTypes.find(item => item.id === type) || {}
                                    }
                                    clearSelect={() => {
                                        updatePointsFilter("type", {});
                                    }}
                                />
                                <div className='mb2'></div>
                                {pointsCategories.length > 0 ? (
                                    <SelectFilter
                                        placeholder='Категория благодарности'
                                        onSelect={value => {
                                            updatePointsFilter("category", value);
                                        }}
                                        options={pointsCategories}
                                        selectedValue={
                                            pointsCategories.find(
                                                item => item.id === filter.category,
                                            ) || {}
                                        }
                                        clearSelect={() => {
                                            updatePointsFilter("category", {});
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <div className='mb3'></div>
                        <CardsTable
                            loading={pointsLoading}
                            cards={pointsList}
                            FrontSideComponent={({cardData}) => {
                                return (
                                    <>
                                        <PointsReceivedFrontCard cardData={cardData} typeOf={type}/>
                                    </>
                                );
                            }}
                            BackSideComponent={({cardData}) => {
                                return <PointsReceivedBackCard cardData={cardData}/>;
                            }}
                        />
                    </div>
                    {!desktop && pointsCount > baseFilter.limit ? (
                        <Pagination
                            total={pointsCount}
                            offset={baseFilter.offset}
                            limit={baseFilter.limit}
                            onChange={newPage => {
                                setBaseFilter({offset: (newPage - 1) * baseFilter.limit, limit: baseFilter.limit})
                                getOwnReceivedGratitude({
                                    offset: (newPage - 1) * baseFilter.limit,
                                    limit: baseFilter.limit
                                })
                            }}
                        />
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({achievements, points, users, auth, projects}) => ({
    achievementsList: achievements.achievements,
    achievementsCount: achievements.achievementsCount,
    achievementsLoading: achievements.loading,
    pointsLoading: points.loading,
    pointsList: points.points,
    pointsCount: points.pointsCount,
    pointsTotal: points.pointsTotal,
    pointsCategories: points.pointsCategories,
    projectsLoading: projects.loading,
    projects: projects.projects,
    mainProjects: projects.mainProjects,
    mainProjectsCount: projects.mainProjectsCount,
    minorProjectsCount: projects.minorProjectsCount,
    minorProjects: projects.minorProjects,
    searchUsersList: users.users,
    maxGivePoints: auth.points,
    isSuperuser: auth.isSuperuser,
    userId: auth.userId,
    avatar: auth.avatar,
    firstName: auth.firstName,
    lastName: auth.lastName,
    sex: auth.sex,
});
const mapDispatchToProps = (
    dispatch,
    {apiService, filter, mainProjectFilter, minorProjectFilter, giveFormData, userId, callback},
) => {
    return bindActionCreators(
        {
            getOwnAchievements: getOwnAchievements(apiService, filter),
            getGratitudeCategories: getGratitudeCategories(apiService),
            getOwnReceivedGratitude: getOwnReceivedGratitude(apiService, filter),
            getOwnTotalCountReceivedGratitude: getOwnTotalCountReceivedGratitude(apiService, filter),
            getOwnSendedGratitude: getOwnSendedGratitude(apiService, filter),
            getUserMainProjects: getUserMainProjects(apiService, userId, mainProjectFilter),
            getUserMinorProjects: getUserMinorProjects(apiService, userId, minorProjectFilter),
            searchUsers: getUsers(apiService, filter),
            givePointsToUser: givePointsToUser(apiService, giveFormData, callback),
            givePointsToAllUser: givePointsToAllUser(apiService, giveFormData, callback)
        },
        dispatch,
    );
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps),
)(Home);
