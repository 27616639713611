export const getRatingsWithPosition = (pointsRating = []) => {
  let newRatings = [];

  let active = true;
  let i = 0;

  while(active) {
    const first = pointsRating[i];

    if (!first) {
      break;
    }

    const second = pointsRating[i + 1];

    if (!second) {
      console.log('NO SECOND EL')
      newRatings.push({ ...first, position: `${i + 1}` });
      active = false;
      break;
    }

    if (first.gratitudeAmount === second.gratitudeAmount) {
      const count = pointsRating.filter(r => r.gratitudeAmount === first.gratitudeAmount).length;
      const firstIndex = i;

      pointsRating.forEach((r) => {
        if (r.gratitudeAmount === first.gratitudeAmount) {
          newRatings.push({ ...r, position: `${firstIndex + 1}-${firstIndex + count}` });
        }
      });

      i = i + count;
    } else {
      newRatings.push({ ...first, position: `${i + 1}` });
      i++;
    }
  }

  return newRatings;
};

export const getAchievementsWithPosition = (achievementsRating) => {
  let newRatings = [];

  let active = true;
  let i = 0;

  while(active) {
    const first = achievementsRating[i];

    if (!first) {
      break;
    }

    const second = achievementsRating[i + 1];

    if (!second) {
      newRatings.push({ ...first, position: `${i + 1}` });
      active = false;
      break;
    }

    if (first.achievementsCount === second.achievementsCount) {
      const count = achievementsRating.filter(r => r.achievementsCount === first.achievementsCount).length;
      const firstIndex = i;

      achievementsRating.forEach((r) => {
        if (r.achievementsCount === first.achievementsCount) {
          newRatings.push({ ...r, position: `${firstIndex + 1}-${firstIndex + count}` });
        }
      });

      i = i + count;
    } else {
      newRatings.push({ ...first, position: `${i + 1}` });
      i++;
    }
  }

  return newRatings;
};