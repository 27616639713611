import React from "react";

import Tabs from "../Tabs";
import UsersTab from "./UsersTab";
import AchievementTab from "./AchievementTab";
import ProjectsTab from "./ProjectsTab";
import ProductsTab from "./ProductsTab";
import PurchaseListTab from "./PurchaseListTab";

const Settings = ({}) => {
    return (
        <>
            <Tabs
                blocks={[
                    {title: "Пользователи", htmlBlock: <UsersTab/>},
                    {title: "Достижения", htmlBlock: <AchievementTab/>},
                    {title: "Проекты", htmlBlock: <ProjectsTab/>},
                    {title: "Товары", htmlBlock: <ProductsTab/>},
                    {title: "Список покупок", htmlBlock: <PurchaseListTab/>},
                ]}
            />
        </>
    );
};

export default Settings;
