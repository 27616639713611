import React from "react";

import styles from "./pointsReceivedFrontCard.module.scss";

import getFormattedDate from "../../../utils/getFormattedDate";

import { ReactComponent as UserPlaceholder } from "../../../assets/images/userPlaceholder.svg";
import { ReactComponent as BcoinIcon } from "../../../assets/images/bcoin.svg";

const PointsReceivedFrontCard = ({ cardData, typeOf }) => {
  const { firstName, lastName, nickname, isAppcraftUser
  } = typeOf === 1 ? cardData.grantedBy : cardData.givenTo;

  const basicName = `${firstName} ${lastName}`;

  return (
    <div className={styles.phoneReceivedFrontCard}>
      <div className={styles.cardRow}>
        <div className={styles.bcoins}>
          {cardData.value}
          <BcoinIcon
            style={{ marginBottom: -5, marginLeft: 5 }}
            width={20}
            height={20}
          />
        </div>
        <div className={styles.createdDate}>
          {getFormattedDate(cardData.createdAt)}
        </div>
      </div>
      <div className='mt1'></div>

      <div className={styles.userInfoRow}>
        <div className={styles.avatar}>
          {cardData.grantedBy.avatar || cardData.givenTo.avatar ? (
            <img src={typeOf ===1 ? cardData.grantedBy.avatar : cardData.givenTo.avatar} alt='avatar' />
          ) : (
            <UserPlaceholder width={"55px"} height={"55px"} />
          )}
        </div>
        <div className='mr1'></div>
        <div>
          {!isAppcraftUser ?
            <div className={styles.givenToName}>
              {basicName}
            </div> :
            <div className={styles.givenToName}>
              {nickname}
            </div>
          }
          <div className='mt1'></div>
          <div className={styles.category}>{cardData.category.name}</div>
        </div>
      </div>
    </div>
  );
};

export default PointsReceivedFrontCard;
