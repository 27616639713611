import { combineReducers } from "redux";
import achievements from "./achievements/reducer";
import auth from "./auth/reducer";
import users from "./users/reducer";
import history from "./history/reducer";
import points from "./points/reducer";
import ratings from "./ratings/reducer";
import search from "./search/reducer";
import tvscreen from "./tvscreen/reducer";
import projects from "./projects/reducer";
import store from "./store/reducer";
import purchase from "./purchase/reducer";

const rootReducer = combineReducers({
  achievements,
  users,
  auth,
  history,
  points,
  ratings,
  search,
  tvscreen,
  projects,
  store,
  purchase
});

export default rootReducer;
