import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  FETCH_USER_UPDATE_REQUEST,
  FETCH_USER_UPDATE_FAILURE,
  FETCH_USER_UPDATE_SUCCESS,
  FETCH_SYNC_SLACK_USERS_REQUEST,
  FETCH_SYNC_SLACK_USERS_FAILURE,
  FETCH_SYNC_SLACK_USERS_SUCCESS,
} from "./types";

const initialState = {
  users: [],
  usersCount: null,
  loading: false,
  error: null,
  actualRequestId: null,
};
const users = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        actualRequestId: action.payload,
      };
    case FETCH_USER_REQUEST:
    case FETCH_SYNC_SLACK_USERS_REQUEST:
    case FETCH_USER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        users: [],
        usersCount: null,
      };

    case FETCH_USERS_FAILURE:
      if (action.payload.actualRequestId === state.actualRequestId) {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }
      return { ...state };

    case FETCH_USER_FAILURE:
    case FETCH_SYNC_SLACK_USERS_FAILURE:
    case FETCH_USER_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FETCH_USER_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };

    case FETCH_USERS_SUCCESS:
      if (action.payload.actualRequestId === state.actualRequestId) {
        return {
          ...state,
          users: action.payload.entities,
          usersCount: action.payload.count,
          loading: false,
        };
      }
      return { ...state };

    case FETCH_USER_UPDATE_SUCCESS:
    case FETCH_SYNC_SLACK_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default users;
