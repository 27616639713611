import React from "react";
import { Link } from "react-router-dom";
import styles from "./achievementsCard.module.scss";
import getFormattedDate from "../../../utils/getFormattedDate";

import { ReactComponent as PlaceholderCardImage } from "../../../assets/images/PlaceholderCardImage.svg";
const AchievementsCard = ({ cardData }) => {

  return (
    <div className={styles.card}>
      <div className={styles.flex} style={{marginBottom: '32px'}}>
        <Link to={`/achievements/${cardData.achievement.id}`}>
          <div className={styles.avatar}>
            {cardData.achievement.image ? (
              <img
                src={window.location.origin + cardData.achievement.image.image}
                alt='avatar'
              />
            ) : (
              <PlaceholderCardImage />
            )}
          </div>
        </Link>
        <div className={styles.infoUser}>
          <Link to={`/user/${cardData.user.id}`}>
            <div className={styles.givenToName}>
              {cardData.user.firstName + " " + cardData.user.lastName ||
              cardData.user.nickname}
            </div>
          </Link>
          <div className={styles.blaceTheme}>достижение</div>
          <div className={styles.createdDate}>
             {getFormattedDate(cardData.createdAt)}
          </div>
        </div>
      </div>

      <div className={styles.name}>{cardData.achievement.name}</div>

      <div className={styles.description}>{cardData.achievement.description}</div>

    </div>
  );
};

export default AchievementsCard;
