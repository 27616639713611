import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withApiService} from "../../hoc";
import compose from "../../../utils/compose";
import styles from "./productsTab.module.scss";
import {GridTable} from "../../Tables";
import {Button, Search, SelectFilter} from "../../FormItems";
import EditProductsForm from "../EditProductsForm";
import ViewProductsHistoryForm from "../EditProductsForm/ViewProductsHistoryForm";
import {ReactComponent as AddIcon} from "../../../assets/images/addIcon.svg";
import {ReactComponent as PlaceholderCardImage} from "../../../assets/images/PlaceholderCardImage.svg";
import {ReactComponent as EditIcon} from "../../../assets/images/editIcon.svg";
import Pagination from "../../Tables/Pagination";
import {ReactComponent as BreadcrumbsBlockArrow} from "../../../assets/images/breadcrumbsBlockArrow.svg";
import getUrlParam from "../../../utils/getUrlParam";
import {getProductsByAdmin, createProduct, updateProduct, removeProducts} from "../../../redux/store/actions";

const ProductsTab = ({
                         products,
                         loading,
                         productsCount,
                         getProductsByAdmin,
                         handleEditProject,
                         handleCreateProject,
                         handleRemoveProject,
                     }) => {

    const desktop = window.innerWidth < 768 ? false : true;
    let urlParam = getUrlParam('page');

    const [productsFilter, setProductsFilter] = useState({
        limit: desktop ? 9999 : 8,
        offset: (urlParam ? (urlParam - 1) : 0) * (desktop ? 9999 : 8),
        productsType: localStorage.getItem('productsSettingTypeFilter') ? localStorage.getItem('productsSettingTypeFilter') : "all",
        available: localStorage.getItem('productsAvailableFilter') ? localStorage.getItem('productsAvailableFilter') : null,
    });

    useEffect(() => {
        getProductsByAdmin(productsFilter);
    }, []);

    const [selectedRow, setSelectedRow] = useState("");
    const [historyProduct, setHistoryProduct] = useState(false);

    const updateProductsFilter = (fieldName, fieldValue) => {
        if (!desktop) {
            window.history.pushState(null, null, `?page=1`)
        }
        setProductsFilter(prevState => ({
            ...prevState,
            [fieldName]: fieldValue.id ? fieldValue.id : fieldValue, offset: 0,
        }));

        if (fieldName === 'productsType') {
            localStorage.setItem('productsSettingTypeFilter', fieldValue.id)
        }
        if (fieldName === 'available') {
            localStorage.setItem('productsAvailableFilter', fieldValue.id);
        }

        getProductsByAdmin(
            {...productsFilter, [fieldName]: fieldValue.id, offset: 0}
        );
    };
    const productsFilterOptions = [
        {id: "all", name: "Все товары"},
        {id: "crowdfunding", name: "Краудфандинговые"},
        {id: "products", name: "Личные покупки"},
        {id: "completed", name: "Завершенные"},
    ];
    const productsAvailableFilterOptions = [
        {id: "false", name: "Отсутствуют"},
        {id: "true", name: "В наличии"},
    ];

    return (
        <div className={styles.UsersTab}>
            {desktop || typeof selectedRow !== "object" ?
                <div className={styles.tableBlock}>
                    <div className='titleH1'>Список товаров ({productsCount})</div>
                    <div className='mt2'></div>
                    <div className={styles.twoFlexColumns}>
                        <div style={{width: '100%'}}>
                            <Search
                                label='Название товара'
                                placeholder='Название товара'
                                onSelect={value => {
                                }}
                                searchResults={[]}
                                requestStartSumbolIndex={0}
                                searchRequest={val => {
                                    getProductsByAdmin({name: val});
                                    setProductsFilter({name: val});
                                }}
                                selectedValue={{}}
                            />
                        </div>
                        <Button
                            style={{height: "48px", width: " 48px", marginTop: 18, marginLeft: 18, padding: 8}}
                            label={<AddIcon/>}
                            handleClick={() => {
                                setSelectedRow({});
                            }}
                        />
                    </div>
                    <div className='mt1'></div>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <SelectFilter
                            width={162}
                            placeholder='Доступность'
                            onSelect={value => {
                                updateProductsFilter("available", value);
                            }}
                            selectedValue={productsAvailableFilterOptions.find(
                                item => item.id === productsFilter.available
                            )}
                            clearSelect={() => {
                                updateProductsFilter("available", {});
                            }}
                            options={[
                                {id: "false", name: "Отсутствуют"},
                                {id: "true", name: "В наличии"},
                            ]}
                        />
                        <div className='mb4'></div>
                        <div className="ml1"></div>
                        <SelectFilter
                            width={206}
                            placeholder='Тип товара'
                            onSelect={value => {
                                updateProductsFilter("productsType", value);
                            }}
                            selectedValue={productsFilterOptions.find(
                                item => item.id === productsFilter.productsType,
                            )}
                            clearSelect={() => {
                                updateProductsFilter("productsType", {});
                            }}
                            options={[
                                {id: "all", name: "Все товары"},
                                {id: "products", name: "Личные покупки"},
                                {id: "crowdfunding", name: "Краудфандинговые"},
                                {id: "completed", name: "Завершенные"},
                            ]}
                        />
                    </div>
                    <GridTable
                        maxHeight={400}
                        loading={loading}
                        differentRowColor={true}
                        onClickRow={rowData => {
                            setSelectedRow(rowData);
                            setHistoryProduct(false)
                        }}
                        highlightRow={rowData => rowData.id === selectedRow.id}
                        dataSource={products}
                        columns={[
                            {
                                title: "",
                                dataIndex: "name",
                                render: rowData => {
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                flexDirection: "row",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {rowData.photo ? (
                                                    <img
                                                        style={{
                                                            width: 36,
                                                            height: 36,
                                                            borderRadius: 8,
                                                            objectFit: 'cover'
                                                        }}
                                                        src={rowData.photo.image}
                                                        alt='achievement'
                                                    />
                                                ) : (
                                                    <PlaceholderCardImage
                                                        width='30px'
                                                        height='30px'
                                                        style={{
                                                            borderRadius: 8,
                                                            backgroundColor: "#EBEEF1",
                                                            padding: "0.2rem",
                                                        }}
                                                    />
                                                )}
                                                <div
                                                    style={{
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        marginLeft: 10,
                                                    }}
                                                >
                                                    {rowData.title}
                                                </div>
                                            </div>
                                            <EditIcon
                                                style={{
                                                    borderRadius: 6,
                                                    boxShadow: "0px 5px 19px -10px rgba(0, 0, 0, 0.75)",
                                                }}
                                            />
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                    {!desktop && productsCount > productsFilter.limit ? (
                        <Pagination
                            total={productsCount}
                            offset={(urlParam ? (urlParam - 1) : productsFilter.offset) * productsFilter.limit}
                            limit={productsFilter.limit}
                            onChange={newPage => {
                                setProductsFilter({...productsFilter, offset: (newPage - 1) * productsFilter.limit});
                                getProductsByAdmin({
                                    ...productsFilter,
                                    offset: (newPage - 1) * productsFilter.limit,
                                    limit: productsFilter.limit
                                })
                            }}
                        />
                    ) : (
                        ""
                    )}
                </div>
                : ''
            }
            <>
                {!desktop && typeof selectedRow === "object" ?
                    <div className={styles.breadcrumbsBlock}>
            <span className={styles.breadcrumbsArrow}>
              <BreadcrumbsBlockArrow
                  style={{marginLeft: 5, width: '12px', height: '12px', fill: '#5f37ff', transform: 'rotate(180deg)'}}
                  width={8}
                  height={12}
              />
            </span>
                        <div
                            className={styles.breadcrumb}
                            onClick={() => {
                                setSelectedRow("");
                                setProductsFilter({...productsFilter, name: ''});
                                setHistoryProduct(false);
                                getProductsByAdmin({...productsFilter, name: ''})
                            }}
                        >
                            Настройки
                        </div>
                    </div> : ''
                }
                {typeof selectedRow === "object" ?
                    <div className={styles.formBlock}>
                        {!historyProduct ?
                            <EditProductsForm
                                onSave={productData => {
                                    selectedRow.id
                                        ? handleEditProject(productData, productsFilter)
                                        : handleCreateProject(productData, productsFilter);
                                    setSelectedRow("");
                                    setProductsFilter({...productsFilter, name: ''});
                                }}
                                onHistory={() => setHistoryProduct(true)}
                                productData={selectedRow || {}}
                                onRemove={
                                    selectedRow.id
                                        ? productData => {
                                            console.log(productData, 'productData');
                                            handleRemoveProject(productData.type, productData.id, productsFilter);
                                            setSelectedRow("");
                                            setProductsFilter({...productsFilter, name: ''});
                                        }
                                        : ""}
                            /> : <ViewProductsHistoryForm productType={selectedRow.type} productId={selectedRow.id}/>}
                    </div>
                    : ("")
                }
            </>
        </div>
    );
};

const mapStateToProps = ({store}) => ({
    products: store.products,
    productsCount: store.productsCount,
    loading: store.loading,
});
const mapDispatchToProps = (dispatch, {apiService, productsFilter, productDataId, productData, productDataType}) => {
    return bindActionCreators(
        {
            getProductsByAdmin: getProductsByAdmin(apiService, productsFilter),
            handleEditProject: updateProduct(apiService, productData, productsFilter),
            handleCreateProject: createProduct(apiService, productData, productsFilter),
            handleRemoveProject: removeProducts(apiService, productDataType, productDataId, productsFilter)
        },
        dispatch,
    );
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps),
)(ProductsTab);
